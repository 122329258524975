import { OfferTypes } from 'oat-common-ui';
import React from 'react';
import FinancialTabBase from '../FinancialBase';
import CashFinancialTabComponent from './CashFinancialTabComponent';

const CashFinancialTab = () => {
  return <FinancialTabBase offerType={OfferTypes.CUSTOMER_CASH} component={<CashFinancialTabComponent />} />;
};

export default CashFinancialTab;
