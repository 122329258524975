import { Efc3dImagesResponse } from 'oat-common-ui';
import React from 'react';
import { getEfcImageFullPath } from '../../../../../utils/getEfcImageFullPath';
import { getLinearGradientColor } from '../../../../../utils/getLinearGradientColor';
import styles from './styles.module.scss';

interface SelectColorProps {
  exteriorColorCode: string[];
  seriesImagesData: Efc3dImagesResponse['DataArea'] | undefined;
  activeModelCode: string;
  changePageType: () => void;
  setActiveExteriorColor: (value: string) => void;
}

const SelectColor = ({ exteriorColorCode, seriesImagesData, activeModelCode, changePageType, setActiveExteriorColor }: SelectColorProps) => {
  return (
    <ul className={styles.efcModalList}>
      {exteriorColorCode.map((value, index) => {
        const exteriorColorObject = seriesImagesData?.model[activeModelCode].exteriorcolor[value];
        const imagePath = exteriorColorObject?.['3dimage'] ? exteriorColorObject?.['3dimage']['864_477_PNG'][1][0] : '';

        return (
          exteriorColorObject &&
          exteriorColorObject['3dimage'] && (
            <li key={index}>
              <button
                id="select-color-btn"
                className={styles.btn}
                onClick={() => {
                  changePageType();
                  setActiveExteriorColor(value);
                }}
              >
                <img className={styles.img} src={getEfcImageFullPath(imagePath || '')} alt="" />
              </button>
              <div className={styles.exteriorColorWrapper}>
                {exteriorColorObject?.hexvalue && <p style={{ backgroundColor: `#${exteriorColorObject?.hexvalue}` }} className={styles.inlineColor}></p>}
                {exteriorColorObject?.hexvaluearray && (
                  <p style={{ background: `linear-gradient(${getLinearGradientColor(exteriorColorObject.hexvaluearray)})` }} className={styles.inlineColor}></p>
                )}
                <p className={styles.exteriorColorName}>{exteriorColorObject?.title.replace(' [extra_cost_color]', '')}</p>
              </div>
            </li>
          )
        );
      })}
    </ul>
  );
};

export default SelectColor;
