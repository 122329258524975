import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'oat-common-ui';

interface PublishWarningModalProps {
  isWarning: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const PublishWarningModal = ({ onClose, isWarning, onSubmit }: PublishWarningModalProps) => {
  return (
    <Modal isOpen onClose={onClose} size={'lg'}>
      <ModalHeader onClose={onClose}>Publish Warning</ModalHeader>
      <ModalBody>
        {isWarning
          ? 'Your incentive period does not currently have College and/or Military offers in it. We highly recommend that you reach out to your Region to add these to your Incentive Period as they are needed by downstream consumers.'
          : 'Your incentive period does not currently have College and/or Military offers in the “Ready to Publish” or “Published” status. We highly recommend that you publish these offers as they are needed by downstream consumers. Do you want to return to your offers to publish College and Military, or publish your incentive period without them?'}
      </ModalBody>
      <ModalFooter>
        {isWarning ? (
          <Button variant={'primary'} onClick={onSubmit}>
            Acknowledge
          </Button>
        ) : (
          <ButtonGroup>
            <Button variant={'primary'} onClick={onSubmit}>
              Publish without
            </Button>
            <Button variant={'primary'} onClick={onClose}>
              Return to offers
            </Button>
          </ButtonGroup>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default PublishWarningModal;
