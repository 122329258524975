const updateRevsByIds = (source: any[], target: any[]) => {
  const sourceMap = new Map<string, string>();
  source.forEach(s => sourceMap.set(s.id, s.rev));
  target.forEach(t => {
    const sourceRev = sourceMap.get(t.id);
    if (sourceRev) {
      t.rev = sourceRev;
    }
  });
};

export default updateRevsByIds;
