import { IOfferStatusError, OfferStatus } from 'oat-common-ui';
import { ReactNode } from 'react';
import { Offer, OfferMarketing, useGetMarketingDataByOfferIdQuery } from '../../../gql/generated';
import OfferingTableRow from './OfferingTableRow';

interface Props {
  offer: Offer;
  sortOrderVal: number;
  maxSortOrder: number;
  offerMarketFlagMap: Map<string, IOfferStatusError>;
  canPreview: boolean;
  renderOfferLink: (offer: Offer) => ReactNode;
  handleSortOffers: (currIndex: number, newIndex: number) => void;
  innerRef?: (element: HTMLElement | null) => any;
  disabled?: boolean;
  onCopy: (offer: Offer) => void;
  onDelete: (offer: Offer) => void;
  onChangeOfferingStatus: (offer: Offer, status: OfferStatus) => void;
}

const OfferingTableRowBase = ({ offer, ...rest }: Props) => {
  const { data } = useGetMarketingDataByOfferIdQuery({
    variables: { offerId: offer.id },
    fetchPolicy: 'no-cache',
    skip: !offer.id || offer.offerType !== 'Other',
  });

  return <OfferingTableRow offer={offer} {...rest} offerMarketing={data?.offerMarketingByOfferId as OfferMarketing[]} />;
};

export default OfferingTableRowBase;
