import { observer } from 'mobx-react-lite';
import { OATWrapper, OfferTabReturnCta, OfferTypes } from 'oat-common-ui';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import MainFooter from '../../../components/MainFooter';
import MainHeader from '../../../components/MainHeader';
import OfferTabBreadCrumbs from '../../../components/OfferTabComponents/OfferTabBreadCrumbs';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';

interface Props {
  offerType: OfferTypes;
  children?: ReactNode;
}

const FinancialTabComponentBase = ({ offerType, children }: Props) => {
  const {
    offeringsStore: { currentOffering },
    tdaStore,
    userInfoStore: { properties },
  } = useStores();

  const { offeringId, tdaCode } = useUrlParams();
  const returnLink = `/${tdaCode}/offering/${offeringId}`;

  return (
    <>
      <MainHeader breadCrumbs={OfferTabBreadCrumbs(tdaStore.currentTda, properties.fullName, currentOffering, offerType)} />
      <OATWrapper withPadding>
        <Link to={returnLink} component={OfferTabReturnCta} />
        {children}
      </OATWrapper>
      <MainFooter isShowPublish darkTheme />
    </>
  );
};

export default observer(FinancialTabComponentBase);
