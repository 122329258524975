import {
  assignBooleanValue,
  assignStringValue,
  Button,
  ButtonGroup,
  Checkbox,
  CustomDatePicker,
  dateFormatISOString,
  dateStringToDate,
  DefaultMultiSelectPanes,
  Dropdown,
  ErrorMessages,
  IMultiItem,
  InlineInputLabel,
  Input,
  IOffering,
  LabelRow,
  MultiSelectPanes,
  OfferTabBodyLayout,
  OfferTabError,
  OfferTypes,
  useDates,
  usePromiseLoading,
} from 'oat-common-ui';
import { ReactNode, useMemo, useState } from 'react';
import { Offer } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import { UserType } from '../../../stores/UserInfoStore';
import useStores from '../../../stores/useStores';
import processVehiclesPayload from '../../../utils/getVehiclesPayload';
import { InfoTabSubmitProps } from './InfoTabComponentBase';
import InfoTabHeader from './InfoTabHeader';
import styles from './styles.module.scss';
import useSeriesManagerTrim from './useSeriesManagerTrim';
import { transformStringToMultiItem } from './utils';

interface Props {
  offering: IOffering;
  offer: Offer | undefined;
  offerType: string;
  userType: UserType;
  headerContent: ReactNode;
  onSubmit: (data: InfoTabSubmitProps) => Promise<void>;
}

const InfoTabTda = ({ offering, offer, offerType, userType, headerContent, onSubmit }: Props) => {
  const { tdaCode } = useUrlParams();
  const { loadPromise, promiseLoading } = usePromiseLoading();
  const { vehiclesStore, regionsStore, offeringsStore, userInfoStore } = useStores();

  const [startDate, endDate, setStartDate, setEndDate, dateError] = useDates(offer?.startDate || offering.startDate, offer?.endDate || offering.endDate, {
    minDate: offering.startDate,
    maxDate: userType !== UserType.SET ? offering.endDate : undefined,
  });
  const [hideEndDate, setHideEndDate] = useState(assignBooleanValue(offer?.isEndDateHidden));

  const [isFeatured, setIsFeatured] = useState<boolean>(offer?.isFeatured || false);
  const [tdaPackage, setTdaPackage] = useState<string>(offer?.tdaPackage || '');

  // when creating a new Other offer
  const defaultIncludedStatesList = regionsStore.regions.find(item => item.code === offeringsStore.currentOffering.regionCode)?.statesList.split(',');
  const [stateList, setStateList] = useState<{ included: IMultiItem[]; excluded: IMultiItem[] }>({
    included: transformStringToMultiItem(
      offer && offer.includedStates ? (offer?.includedStates as string[]) : !offer && offerType === OfferTypes.OTHER ? (defaultIncludedStatesList as string[]) : [],
    ),
    excluded: transformStringToMultiItem(offer && offer.excludedStates ? (offer.excludedStates as string[]) : []),
  });

  const {
    disableExcludeSeriesYear,
    seriesName,
    seriesYear,
    excludedSeriesYear,
    includedSeriesManagerList,
    filteredExcludedSeriesManagerList,
    excludedTrimList,
    includedTrimList,
    excludedSeriesList,
    onExcludedSeriesYearChange,
    onSeriesManagerChange,
    onTrimChange,
    onSeriesNameChange,
    onSeriesYearChange,
  } = useSeriesManagerTrim(offer, offerType, vehiclesStore.otherOfferAllVehicles);

  const isMulti = seriesYear?.value === 'MULTI' || seriesName?.value === 'MULTI';
  const isOther = offer?.offerType === OfferTypes.OTHER;
  const isTrimManagerDisabled = (offerType !== OfferTypes.OTHER || !!offer?.parentOptionDetailId) && !userInfoStore.isLexus();

  const hasError = useMemo(() => {
    return !!dateError || !startDate || !endDate || stateList.included.length === 0 || (isMulti ? includedSeriesManagerList.length === 0 : includedTrimList.length === 0);
  }, [dateError, startDate, endDate, stateList, includedSeriesManagerList, includedTrimList, isMulti]);

  const errorMessage = useMemo(() => {
    if (hasError) {
      if (dateError?.dateOrderError) {
        return ErrorMessages.DATE_ORDER_INVALID;
      } else if (isMulti ? !includedSeriesManagerList.length : !includedTrimList.length) {
        return ErrorMessages.INCLUDED_SERIES_REQUIRED;
      } else if (!stateList.included.length) {
        return ErrorMessages.INCLUDED_STATE_REQUIRED;
      } else if (!startDate || !endDate) {
        return ErrorMessages.OFFER_TABS_REQUIRED_FIELDS;
      } else {
        return;
      }
    } else {
      return;
    }
  }, [hasError, dateError, includedSeriesManagerList, includedTrimList, stateList, startDate, endDate, isMulti]);

  const handleOnStatesChange = (excluded: IMultiItem[], included: IMultiItem[]) => {
    setStateList({
      included,
      excluded,
    });
  };

  const handleOnSubmit = async (next = false) => {
    if (!hasError) {
      const stateInc = stateList.included.map(item => item.data);
      const stateExc = stateList.excluded.map(item => item.data);

      await loadPromise(
        onSubmit({
          id: assignStringValue(offer?.id, ''),
          rev: assignStringValue(offer?.rev, ''),
          next,
          startDate: dateFormatISOString(startDate),
          endDate: dateFormatISOString(endDate),
          series: seriesName?.value,
          seriesYear: seriesYear?.value,
          vehicles: isMulti
            ? processVehiclesPayload(offer?.vehicles, includedSeriesManagerList, true, vehiclesStore.otherOfferAllVehicles)
            : processVehiclesPayload(offer?.vehicles, includedTrimList, false, vehiclesStore.otherOfferAllVehicles),
          includedStates: stateInc,
          excludedStates: stateExc,
          tdaPackage,
          isFeatured,
          isEndDateHidden: hideEndDate,
        }),
      );
    }
  };

  return (
    <>
      <InfoTabHeader endDate={endDate} offerType={offerType} headerContent={headerContent} isError={hasError} />
      <OfferTabBodyLayout
        renderContent={
          <>
            <div className={styles.rowContainer}>
              <InlineInputLabel width={200} label="Start Date">
                <CustomDatePicker
                  className={styles.field}
                  darkTheme
                  error={!!dateError || !startDate}
                  id="info-start-date"
                  maxDate={dateStringToDate(offering.endDate)}
                  minDate={dateStringToDate(offering.startDate)}
                  onChange={setStartDate}
                  selected={startDate}
                />
              </InlineInputLabel>
              <InlineInputLabel width={200} label="End Date">
                <CustomDatePicker
                  className={styles.field}
                  darkTheme
                  error={!!dateError || !endDate}
                  id="info-end-date"
                  maxDate={userType !== UserType.SET ? dateStringToDate(offering.endDate) : undefined}
                  minDate={dateStringToDate(offering.startDate)}
                  onChange={setEndDate}
                  selected={endDate}
                />
              </InlineInputLabel>
            </div>
            {/* Series Year */}
            <div className={styles.rowContainer}>
              <InlineInputLabel width={200} contentWidth={220} label="Series Year" contentGrow={false}>
                <Dropdown
                  id="info-series-year"
                  value={seriesYear}
                  options={offerType === OfferTypes.OTHER ? vehiclesStore.otherOfferSeriesYearOptions : vehiclesStore.seriesYearOptions}
                  disabled={offerType !== OfferTypes.OTHER && offer?.offerType !== OfferTypes.OTHER}
                  onChange={onSeriesYearChange}
                  darkTheme
                />
              </InlineInputLabel>
              {isOther && (
                <div className={styles.checkboxContainer}>
                  <Checkbox id="end-date-chbox" isChecked={hideEndDate} onChange={e => setHideEndDate(e.target.checked)} label="Do Not Display End Date" />
                </div>
              )}
            </div>
            {/* Series Name */}
            <div className={styles.fieldWrapper}>
              <InlineInputLabel width={200} label="Series Name">
                <Dropdown
                  id="info-series-name"
                  value={seriesName}
                  options={offerType === OfferTypes.OTHER ? vehiclesStore.otherOfferSeriesOptions : vehiclesStore.seriesOptions}
                  disabled={offerType !== OfferTypes.OTHER && offer?.offerType !== OfferTypes.OTHER}
                  onChange={onSeriesNameChange}
                  darkTheme
                />
              </InlineInputLabel>
            </div>
            {/* Package */}
            {offer?.isSpecialEdition && (userType === UserType.GST || tdaCode === UserType.GST) && (
              <div className={styles.fieldWrapper}>
                <InlineInputLabel width={200} label="Package">
                  <Input className={styles.field} id="info-series-package" value={tdaPackage} onChange={e => setTdaPackage(e.currentTarget.value)} darkTheme disabled/>
                </InlineInputLabel>
              </div>
            )}
            {offer?.isSpecialEdition && offer?.accessoryCodes && (
              <div className={styles.fieldWrapper}>
                <InlineInputLabel width={200} label="Configurations">
                  <p id="info-series-accessory">{offer?.accessoryCodes}</p>
                </InlineInputLabel>
              </div>
            )}
            <div className={styles.fieldWrapper}>
              <InlineInputLabel width={200} label="Featured">
                <Checkbox id="featured-chbox" isChecked={isFeatured} onChange={() => setIsFeatured(!isFeatured)} />
              </InlineInputLabel>
            </div>
            {/* Series Manager */}
            {isMulti && (
              <>
                <LabelRow label="Series Manager" />
                {/* Excluded Series Year */}
                {seriesName?.value === 'MULTI' && seriesYear?.value === 'MULTI' && (
                  <div className={styles.excludedDropdownContainer}>
                    <InlineInputLabel width={200} label="Excluded Series Year">
                      <Dropdown
                        id="info-excluded-series-years"
                        value={excludedSeriesYear}
                        options={['All', ...excludedSeriesList] }
                        onChange={onExcludedSeriesYearChange}
                        darkTheme
                        disabled={!isMulti || disableExcludeSeriesYear}
                      />
                    </InlineInputLabel>
                  </div>
                )}
                <div className={styles.selectorPanels}>
                  <MultiSelectPanes
                    excludedList={filteredExcludedSeriesManagerList}
                    includedList={includedSeriesManagerList}
                    error={!includedSeriesManagerList.length}
                    disabled={isTrimManagerDisabled}
                    onMove={(exc, inc) => {
                      onSeriesManagerChange(exc, inc, false);
                    }}
                    onSelect={(exc, inc) => {
                      onSeriesManagerChange(exc, inc, true);
                    }}
                  />
                </div>
              </>
            )}

            {/* Trim Manager */}
            {!isMulti && (
              <>
                <LabelRow label="Trim Manager" />
                <div className={styles.selectorPanels}>
                  <MultiSelectPanes
                    excludedList={excludedTrimList}
                    includedList={includedTrimList}
                    error={!includedTrimList.length}
                    onMove={onTrimChange}
                    onSelect={onTrimChange}
                    disabled={isTrimManagerDisabled}
                  />
                </div>
              </>
            )}
            {/* State Restrictions */}
            <LabelRow label="State Restrictions" />
            <div className={styles.selectorPanels}>
              <DefaultMultiSelectPanes
                excludedList={stateList.excluded}
                includedList={stateList.included}
                error={!stateList.included.length}
                returnSelectorData={(_ex, inc) => {
                  handleOnStatesChange(_ex, inc);
                }}
              />
            </div>
            {hasError && <OfferTabError message={errorMessage} />}
          </>
        }
        renderFooter={
          <ButtonGroup>
            <Button id="info-save-btn" variant="primary" disabled={promiseLoading || hasError} onClick={() => handleOnSubmit()}>
              Save
            </Button>
            <Button id="info-next-btn" variant="primary" disabled={promiseLoading || hasError} onClick={() => handleOnSubmit(true)}>
              Next
            </Button>
          </ButtonGroup>
        }
      />
    </>
  );
};

export default InfoTabTda;
