import { AdminConstants } from 'oat-admin-common';
import { OfferTypes } from 'oat-common-ui';
import { useState } from 'react';
import EfcCarJellyLoader from '../../../components/EfcCarJellyLoader';
import ModelCodesLoader from '../../../components/ModelCodesLoader';
import OfferTabBreadCrumbs from '../../../components/OfferTabComponents/OfferTabBreadCrumbs';
import { MarketingSites } from '../../../constants/global';
import {
  Disclaimer,
  OfferMarketing,
  Restriction,
  Snippet,
  TokensMap,
  useGetMarketingDataQuery,
  useGetOfferingByIdQuery,
  useGetTdaImagesQuery,
  useGetTokensSnippetsDisclaimersRestrictionsQuery,
  useRibbonsByTdaQuery,
} from '../../../gql/generated';
import useGetEfcData from '../../../hooks/useGetEfcData';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import Error from '../../Error';
import Loading from '../../Loading';
import { TokenRibbon } from '../../TokenSettings/RibbonsComponent';
import RenderHeaderDetails from '../RenderHeaderDetails';
import MarketingTabComponentBase from './MarketingTabComponentBase';

const { BRAND_LEXUS, BRAND_TOYOTA } = AdminConstants;

interface Props {
  offerType: OfferTypes;
}

const MarketingTabBase = ({ offerType }: Props) => {
  const {
    offersStore,
    offeringsStore,
    userInfoStore: { properties, isLexus },
    tdaStore,
    tokenStore,
    offerMarketingStore,
    tdaImagesStore,
  } = useStores();
  const { offeringId, offerId, tdaCode, site, lang } = useUrlParams();

  const {
    data: offeringData,
    loading: offeringLoading,
    error: offeringError,
  } = useGetOfferingByIdQuery({
    variables: { id: offeringId },
    fetchPolicy: 'no-cache',
  });

  const { data, loading, error } = useGetMarketingDataQuery({
    variables: { offerId, tdaCode },
    fetchPolicy: 'no-cache',
    skip: !offerId,
  });

  const {
    data: tokenData,
    loading: tokenLoading,
    error: tokenError,
  } = useGetTokensSnippetsDisclaimersRestrictionsQuery({
    variables: { tdaCode },
    fetchPolicy: 'no-cache',
    skip: offerId ? true : false,
  });

  const {
    data: ribbonsData,
    loading: ribbonsLoading,
    error: ribbonsError,
  } = useRibbonsByTdaQuery({
    variables: { tdaCode },
    fetchPolicy: 'no-cache',
    skip: isLexus(),
  });

  const { loading: efcLoading } = useGetEfcData();

  const { data: tdaImagesData, loading: tdaImagesLoading } = useGetTdaImagesQuery({
    variables: { tdaCode: tdaStore.currentTda?.code || '' },
    fetchPolicy: 'no-cache',
  });

  const [loaded, setLoaded] = useState(false);

  const breadCrumbs = OfferTabBreadCrumbs(tdaStore.currentTda, properties.fullName, offeringsStore.currentOffering, offerType);

  if (offeringLoading || loading || ribbonsLoading || tdaImagesLoading || efcLoading || tokenLoading) {
    return <Loading breadCrumbs={breadCrumbs} />;
  }

  if (offeringError || error || ribbonsError || tokenError) {
    return <Error breadCrumbs={breadCrumbs} />;
  }

  if (offeringData) {
    offeringsStore.setCurrentOffering(offeringData.offering);
  }

  if (tdaImagesData) {
    tdaImagesStore.setTdaImagesData(tdaImagesData.imagesByTda);
  }

  // loaded prevents reseting data in the store. switching tabs would rerender this component
  // Lexus does not have ribbons
  if (!loaded && data && (isLexus() || ribbonsData)) {
    offersStore.setCurrentOffer(data.offer);
    offerMarketingStore.setApplyValues(data.offer.isOtherOfferAppliedToBat || false, data.offer.isOtherOfferAppliedToTcom || false);
    offerMarketingStore.setOfferMarketing(offerId || '', data.offerMarketing as OfferMarketing[]);
    if (offerId) {
      offerMarketingStore.setCurrentOfferMarketing(offerMarketingStore.getOfferMarketingById(offerId));
    }
    offerMarketingStore.setActiveOfferMarketingIndex(site === MarketingSites.BAT, lang);
    tokenStore.setTokenItems(
      data.tokens as TokensMap,
      data.snippets as Snippet[],
      data.disclaimers as Disclaimer[],
      data.restrictions as Restriction[],
      ribbonsData?.ribbonsByTda as TokenRibbon[],
    );
    setLoaded(true);
  }

  // this is for create offer
  if (!data && tokenData && (isLexus() || ribbonsData)) {
    tokenStore.setTokenItems(
      tokenData?.tokens as TokensMap,
      tokenData?.snippets as Snippet[],
      tokenData?.disclaimers as Disclaimer[],
      tokenData?.restrictions as Restriction[],
      ribbonsData?.ribbonsByTda as TokenRibbon[],
    );
  }

  return (
    <ModelCodesLoader
      brand={isLexus() ? BRAND_LEXUS : BRAND_TOYOTA}
      month={offeringsStore.currentOffering?.startDate.split('-')[1]}
      year={offeringsStore.currentOffering?.startDate.split('-')[0]}
      breadCrumbs={breadCrumbs}
    >
      <EfcCarJellyLoader series={offersStore.currentOffer?.series || ''} year={offersStore.currentOffer?.seriesYear || ''} breadCrumbs={breadCrumbs}>
        <MarketingTabComponentBase offerType={offerType} renderHeaderContent={<RenderHeaderDetails offerType={offerType} />} />
      </EfcCarJellyLoader>
    </ModelCodesLoader>
  );
};

export default MarketingTabBase;
