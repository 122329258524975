import { Efc3dImagesResponse } from 'oat-common-ui';

export const getEfc3dImageExtColors = (seriesImages: Map<string, Efc3dImagesResponse>, year: string, series: string, modelCode?: string) => {
  const mapKey = `${year}_${series}`;
  const seriesItem = seriesImages.get(mapKey);

  if (seriesItem) {
    const modelObject = modelCode ? seriesItem.DataArea.model[modelCode] : Object.values(seriesItem.DataArea.model)[0];
    return modelObject.exteriorcolor;
  }

  return undefined;
};

/**
 * Searches all colors for car jelly
 * @param extColors
 * @returns
 */
const findCarJellyAttribute = (extColors: any[]) => {
  return extColors.find(item => !!item.carjellyimage)?.carjellyimage || undefined;
};

/**
 * Searches by specific dimension
 * @param extColors
 * @param dimensions Lexus dimensions by default
 * @returns
 */
const findCarJellyByDimensions = (extColors: any[]) => {
  const img3dAttr = '3dimage';
  const minW = 650; // min range of image width;
  const maxW = 750; // max range of image wi]dth;
  let carjelly = '';

  // find by specified dimensions
  extColors.forEach(color => {
    if (color[img3dAttr]) {
      Object.entries(color[img3dAttr]).forEach(([dim, item]) => {
        // split dimension to obtain width (ex 700_658_PNG, get 700)
        const dsplit = dim.split('_');
        const width = dsplit[0];

        // then compare if width is within range and jelly is not set
        if (!carjelly && width && Number(width) >= minW && Number(width) <= maxW) {
          carjelly = (Object.values(item as any)[0] as any)[0] as string;
        }
      });
    }
  });

  return carjelly || undefined;
};

/**
 * Main function to retrieve car jellies
 * @param efc3dImageCache
 * @param year
 * @param series
 * @param modelCode
 * @param dimensions
 * @returns
 */
export const getEfcCarJelly = (efc3dImageCache: Map<string, Efc3dImagesResponse>, year: string, series: string, modelCode?: string) => {
  const extColors = getEfc3dImageExtColors(efc3dImageCache, year, series, modelCode); // Returns colors of modelCode or first modelCode in array

  const extColorsArray = extColors ? Object.values(extColors) : []; // convert map to array
  if (extColorsArray.length > 0) {
    return findCarJellyAttribute(extColorsArray) || findCarJellyByDimensions(extColorsArray);
  }

  return undefined;
};
