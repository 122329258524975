import {
  assignStringValue,
  Button,
  ButtonGroup,
  CustomDatePicker,
  dateFormatISOString,
  dateStringToDate,
  Dropdown,
  ErrorMessages,
  getExcludedIncludedList,
  IMultiItem,
  InlineInputLabel,
  Input,
  IOffering,
  LabelRow,
  MultiSelectPanes,
  OfferTabBodyLayout,
  OfferTabError,
  OfferTypes,
  sortFieldsHelper,
  useDates,
  useInput,
  usePromiseLoading,
} from 'oat-common-ui';
import { ReactNode, useMemo, useState } from 'react';
import { FEATURE_OR_4767, LdaCode } from '../../../constants/global';
import { Offer } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import processVehiclesPayload from '../../../utils/getVehiclesPayload';
import { defaultPortsByRegion, portOptions, selectAllSameStates } from '../util';
import { InfoTabSubmitProps } from './InfoTabComponentBase';
import InfoTabHeader from './InfoTabHeader';
import styles from './styles.module.scss';
import useSeriesManagerTrim from './useSeriesManagerTrim';
import { mapIncludedLdas, portStatesHandler } from './utils';

interface Props {
  offer: Offer | undefined;
  offering: IOffering;
  offerType: string;
  headerContent: ReactNode;
  statesOptions: string[];
  ldaOptions: IMultiItem[];
  includedLdas: string[];
  onSubmit: (data: InfoTabSubmitProps) => Promise<void>;
}

const InfoTabLda = ({ offer, offering, offerType, headerContent, statesOptions, ldaOptions, includedLdas, onSubmit }: Props) => {
  const { tdaCode } = useUrlParams();
  const { loadPromise, promiseLoading } = usePromiseLoading();
  const { regionsStore, vehiclesStore, offersStore } = useStores();

  const [packageText, , packageTextError, setPackageText] = useInput(offer?.tdaPackage || '');
  const [startDate, endDate, setStartDate, setEndDate, dateError] = useDates(offer?.startDate || offering.startDate, offer?.endDate || offering.endDate);

  const offerStartDate = dateStringToDate(offer?.startDate || offering?.startDate);
  const offerEndDate = dateStringToDate(offer?.endDate || offering?.endDate);
  const minDate = new Date(offerStartDate.setDate(offerStartDate.getDate() + 1));
  const maxDate = new Date(offerEndDate.setDate(offerEndDate.getDate() - 1));

  const {
    disableExcludeSeriesYear,
    seriesName,
    seriesYear,
    excludedSeriesYear,
    includedSeriesManagerList,
    filteredExcludedSeriesManagerList,
    excludedTrimList,
    includedTrimList,
    excludedSeriesList,
    onExcludedSeriesYearChange,
    onSeriesManagerChange,
    onTrimChange,
    onSeriesNameChange,
    onSeriesYearChange,
  } = useSeriesManagerTrim(offer, offerType, vehiclesStore.otherOfferAllVehicles);

  const isMulti = seriesYear?.value === 'MULTI' || seriesName?.value === 'MULTI';

  // Init LdaOptions Lists
  const includedExludedLDARestrictions = useMemo(() => {
    let ldasIncludedList: IMultiItem[] = [];

    if (offer?.includedLdas?.length) {
      ldasIncludedList = mapIncludedLdas(includedLdas);
    }

    if (!offer && offerType === OfferTypes.OTHER) {
      ldasIncludedList = ldaOptions;
    }

    return getExcludedIncludedList(
      (ldaOptions || []).map((item, index) => ({ id: item.id, data: item.data, selected: false, index })),
      ldasIncludedList.map(item => item.id),
    );
  }, [includedLdas, ldaOptions, offer, offerType]);

  // Init Port Lists
  const includedExludedPorts = useMemo(() => {
    let portsIncludedList: string[] = [];

    if (offer?.includedPorts) {
      portsIncludedList = offer?.includedPorts as string[];
    }

    if (!offer && offerType === OfferTypes.OTHER) {
      portsIncludedList = defaultPortsByRegion(tdaCode, true);
    }

    return getExcludedIncludedList(
      portOptions(true)?.map((item: string, index: number) => ({ id: item, data: item, selected: false, index })),
      portsIncludedList,
    );
  }, [offer, offerType, tdaCode]);
  const [includedPortsList, setIncludedPortsList] = useState<IMultiItem[]>(includedExludedPorts[1]);
  const [excludedPortsList, setExcludedPortsList] = useState<IMultiItem[]>(includedExludedPorts[0]);

  // Init States Lists
  const includedExludedStates = useMemo(() => {
    let includedList: string[] = [];

    if (offer?.includedStates) {
      includedList = offer?.includedStates as string[];
    }

    if (!offer && offerType === OfferTypes.OTHER) {
      const defaultIncludedStatesList = regionsStore.regions.find(item => item.name === tdaCode)?.statesList.split(',');
      includedList = defaultIncludedStatesList as string[];
    }

    if (FEATURE_OR_4767) {
      return getExcludedIncludedList(
        statesOptions.map((item: string, index: number) => ({ id: item, data: item, selected: false, index, disabled: false })),
        includedList,
      );
    } else {
      const stateInList = (statesOptions || []).map((item: string, index: number) => ({
        id: item,
        data: item,
        selected: false,
        index,
        disabled: tdaCode === LdaCode.LWA || tdaCode === LdaCode.LSA,
      }));
      return [
        portStatesHandler(
          (offer?.excludedStates || []).map((state, index) => ({
            id: state || '',
            data: state || '',
            selected: false,
            index,
            disabled: tdaCode === LdaCode.LWA || tdaCode === LdaCode.LSA,
          })),
          excludedPortsList,
          tdaCode,
          regionsStore.portsWithStates,
        ),
        portStatesHandler(stateInList, includedPortsList, tdaCode, regionsStore.portsWithStates),
      ];
    }
  }, [excludedPortsList, includedPortsList, offer, offerType, regionsStore.portsWithStates, regionsStore.regions, statesOptions, tdaCode]);

  const [includedLDARestrictions, setIncludedLDARestrictions] = useState<IMultiItem[]>(includedExludedLDARestrictions[1]);
  const [excludedLDARestrictions, setExcludedLDARestrictions] = useState<IMultiItem[]>(includedExludedLDARestrictions[0]);

  const [includedStateList, setIncludedStateList] = useState<IMultiItem[]>(includedExludedStates[1]);
  const [excludedStateList, setExcludedStateList] = useState<IMultiItem[]>(includedExludedStates[0]);

  const hasError = useMemo(() => {
    return (
      !!dateError ||
      !startDate ||
      !endDate ||
      includedPortsList.length === 0 ||
      includedStateList.length === 0 ||
      includedLDARestrictions.length === 0 ||
      (isMulti ? includedSeriesManagerList.length === 0 : includedTrimList.length === 0)
    );
  }, [dateError, startDate, endDate, includedPortsList, includedStateList, includedSeriesManagerList, includedTrimList, includedLDARestrictions, isMulti]);

  const errorMessage = useMemo(() => {
    if (hasError) {
      if (dateError?.dateOrderError) {
        return ErrorMessages.DATE_ORDER_INVALID;
      } else if (isMulti ? !includedSeriesManagerList.length : !includedTrimList.length) {
        return ErrorMessages.INCLUDED_SERIES_REQUIRED;
      } else if (!includedStateList.length) {
        return ErrorMessages.INCLUDED_STATE_REQUIRED;
      } else if (!includedPortsList.length) {
        return ErrorMessages.INCLUDED_PORT_REQUIRED;
      } else if (!includedLDARestrictions.length) {
        return ErrorMessages.INCLUDED_LDA_REQUIRED;
      } else if (!startDate || !endDate) {
        return ErrorMessages.OFFER_TABS_REQUIRED_FIELDS;
      } else {
        return;
      }
    } else {
      return;
    }
  }, [hasError, dateError, includedSeriesManagerList, includedTrimList, includedStateList, includedLDARestrictions, includedPortsList, startDate, endDate, isMulti]);

  const handleChangeStates = (excList: IMultiItem[], incList: IMultiItem[]) => {
    setExcludedStateList(excList);
    setIncludedStateList(incList);
  };

  const handleMoveStates = (incList: IMultiItem[], excList: IMultiItem[], chosenStates: IMultiItem[], isExclusion: boolean) => {
    handleChangeStates(excList, incList);

    if (FEATURE_OR_4767) {
      let sourcePorts = isExclusion ? [...includedPortsList] : [...excludedPortsList];
      let targetPorts = isExclusion ? [...excludedPortsList] : [...includedPortsList];

      if (chosenStates) {
        const filteredList = sourcePorts.filter(port => {
          let hasSelected = false;
          let hasSource = false;

          chosenStates.forEach(state => {
            regionsStore.portsWithStates[tdaCode][port.id].forEach((portState: string) => {
              if (state.id === portState) {
                hasSelected = true;
              }
            });
          });

          if (isExclusion) {
            incList.forEach(state => {
              regionsStore.portsWithStates[tdaCode][port.id].forEach((portState: string) => {
                if (state.id === portState) {
                  hasSource = true;
                }
              });
            });
          }

          return hasSelected && !hasSource;
        });

        targetPorts = targetPorts.concat(filteredList).sort(sortFieldsHelper('id', true));
        sourcePorts = sourcePorts.filter(port => filteredList.findIndex(excPort => excPort.id === port.id) === -1).sort(sortFieldsHelper('id', true));
      }

      setExcludedPortsList(isExclusion ? targetPorts : sourcePorts);
      setIncludedPortsList(isExclusion ? sourcePorts : targetPorts);
    }
  };

  const handleChangePorts = (excluded: IMultiItem[], included: IMultiItem[]) => {
    setExcludedPortsList(excluded);
    setIncludedPortsList(included);
  };

  const handleMovePorts = (includedPorts: IMultiItem[], excludedPorts: IMultiItem[], chosenPorts: IMultiItem[], toExcluded: boolean) => {
    handleChangePorts(excludedPorts, includedPorts);

    let sourceStates = toExcluded ? [...includedStateList] : [...excludedStateList];
    let targetStates = toExcluded ? [...excludedStateList] : [...includedStateList];

    if (chosenPorts && regionsStore.portsWithStates) {
      const filteredList = sourceStates.filter(state => {
        let hasSelected = false;
        let hasSource = false;

        chosenPorts.forEach(port => {
          regionsStore.portsWithStates[tdaCode][port.id].forEach((portState: string) => {
            if (state.id === portState) {
              hasSelected = true;
            }
          });
        });

        if (toExcluded) {
          includedPorts.forEach(port => {
            regionsStore.portsWithStates[tdaCode][port.id].forEach((portState: string) => {
              if (state.id === portState) {
                hasSource = true;
              }
            });
          });
        }

        return hasSelected && !hasSource;
      });

      targetStates = targetStates.concat(filteredList).sort(sortFieldsHelper('id', true));
      sourceStates = sourceStates.filter(state => filteredList.findIndex(excState => excState.id === state.id) === -1).sort(sortFieldsHelper('id', true));
    }

    setExcludedStateList(toExcluded ? targetStates : sourceStates);
    setIncludedStateList(toExcluded ? sourceStates : targetStates);
  };

  const handleChangeLDARestrictions = (excList: IMultiItem[], incList: IMultiItem[]) => {
    setExcludedLDARestrictions(excList);
    setIncludedLDARestrictions(incList);
  };

  const handleOnSubmit = async (next: boolean) => {
    if (!hasError) {
      const notes =
        packageText !== offer?.tdaPackage
          ? `${offersStore.currentOffer?.notes ? `${offersStore.currentOffer?.notes}, ` : ''}${packageText}`
          : assignStringValue(offersStore.currentOffer?.notes);

      await loadPromise(
        onSubmit({
          id: offersStore.currentOffer?.id || '',
          rev: offersStore.currentOffer?.rev || '',
          next,
          startDate: dateFormatISOString(startDate),
          endDate: dateFormatISOString(endDate),
          series: seriesName?.value,
          seriesYear: seriesYear?.value,
          vehicles: isMulti
            ? processVehiclesPayload(offersStore.currentOffer?.vehicles, includedSeriesManagerList, true, vehiclesStore.otherOfferAllVehicles)
            : processVehiclesPayload(offersStore.currentOffer?.vehicles, includedTrimList, false, vehiclesStore.otherOfferAllVehicles),
          includedStates: includedStateList.map(state => state.id),
          excludedStates: excludedStateList.map(state => state.id),
          tdaPackage: packageText,
          paymentMethod: offersStore.currentOffer?.paymentMethod || '',
          notes,
          includedPorts: includedPortsList.map(port => port.id),
          excludedPorts: excludedPortsList.map(port => port.id),
          includedLdas: includedLDARestrictions.map(lda => lda.id),
          excludedLdas: excludedLDARestrictions.map(lda => lda.id),
        }),
      );
    }
  };

  return (
    <>
      <InfoTabHeader endDate={endDate} offerType={offerType} headerContent={headerContent} isError={hasError} />
      <OfferTabBodyLayout
        renderContent={
          <>
            {/* Dates */}
            <div className={styles.rowContainer}>
              <InlineInputLabel width={200} label="Start Date">
                <CustomDatePicker
                  id="info-start-date"
                  className={styles.field}
                  selected={startDate}
                  onChange={setStartDate}
                  darkTheme
                  minDate={minDate}
                  maxDate={maxDate}
                  error={!!dateError || !startDate}
                />
              </InlineInputLabel>
              <InlineInputLabel width={200} label="End Date">
                <CustomDatePicker
                  id="info-end-date"
                  className={styles.field}
                  selected={endDate}
                  onChange={setEndDate}
                  darkTheme
                  minDate={offerStartDate}
                  error={!!dateError || !endDate}
                />
              </InlineInputLabel>
            </div>

            {/* Series Year */}
            <div className={styles.rowContainer}>
              <InlineInputLabel width={200} label="Series Year" inputWrapperClass={styles.dropdownContainer}>
                <Dropdown
                  id="info-series-year"
                  value={seriesYear}
                  options={offerType === OfferTypes.OTHER ? vehiclesStore.otherOfferSeriesYearOptions : vehiclesStore.seriesYearOptions}
                  onChange={onSeriesYearChange}
                  darkTheme
                  disabled={offerType !== OfferTypes.OTHER}
                />
              </InlineInputLabel>
            </div>

            {/* Series Name */}
            <div className={styles.rowContainer}>
              <InlineInputLabel width={200} label="Series Name" inputWrapperClass={styles.dropdownContainer}>
                <Dropdown
                  id="info-series-name"
                  value={seriesName}
                  options={offerType === OfferTypes.OTHER ? vehiclesStore.otherOfferSeriesOptions : vehiclesStore.seriesOptions}
                  onChange={onSeriesNameChange}
                  darkTheme
                  disabled={offerType !== OfferTypes.OTHER}
                />
              </InlineInputLabel>
            </div>

            {/* Package */}
            {offer?.isSpecialEdition && (
              <div className={styles.dropdownContainer}>
                <InlineInputLabel width={200} label="Package">
                  <Input darkTheme value={packageText} error={packageTextError} onChange={e => setPackageText(e.target.value)} disabled />
                </InlineInputLabel>
              </div>
            )}

            {/* Series Manager */}
            {isMulti && (
              <>
                <LabelRow label="Series Manager" />
                {/* Excluded Series Year */}
                {seriesName?.value === 'MULTI' && seriesYear?.value === 'MULTI' && (
                  <div className={styles.excludedDropdownContainer}>
                    <InlineInputLabel width={200} label="Excluded Series Year">
                      <Dropdown
                        id="info-excluded-series-years"
                        value={excludedSeriesYear}
                        options={['All', ...excludedSeriesList]}
                        onChange={onExcludedSeriesYearChange}
                        darkTheme
                        disabled={!isMulti || disableExcludeSeriesYear}
                      />
                    </InlineInputLabel>
                  </div>
                )}
                <div className={styles.selectorPanels}>
                  <MultiSelectPanes
                    excludedList={filteredExcludedSeriesManagerList}
                    includedList={includedSeriesManagerList}
                    error={!includedSeriesManagerList.length}
                    onMove={(exc, inc) => {
                      onSeriesManagerChange(exc, inc, false);
                    }}
                    onSelect={(exc, inc) => onSeriesManagerChange(exc, inc, true)}
                  />
                </div>
              </>
            )}

            {/* Trim Manager */}
            {!isMulti && (
              <>
                <LabelRow label="Trim Manager" />
                <div className={styles.selectorPanels}>
                  <MultiSelectPanes
                    excludedList={excludedTrimList}
                    includedList={includedTrimList}
                    error={!includedTrimList.length}
                    onMove={onTrimChange}
                    onSelect={onTrimChange}
                  />
                </div>
              </>
            )}

            {/* State Restrictions */}
            <LabelRow label="State Restrictions" />
            <div className={styles.selectorPanels}>
              <MultiSelectPanes
                excludedList={excludedStateList}
                includedList={includedStateList}
                error={!includedStateList.length}
                disabled={(tdaCode === 'LWA' || tdaCode === 'LSA') && !FEATURE_OR_4767}
                onMove={(exc, inc, selList, isExlusion) => {
                  handleMoveStates(inc, exc, selList, isExlusion);
                }}
                onSelect={handleChangeStates}
              />
            </div>

            {/* Port Restrictions */}
            <LabelRow label="Port Restrictions" />
            <div className={styles.selectorPanels}>
              <MultiSelectPanes
                excludedList={excludedPortsList}
                includedList={includedPortsList}
                error={!includedPortsList.length}
                onMove={(exc, inc, selList, toExcluded) => {
                  handleMovePorts(inc, exc, selList, toExcluded);
                }}
                onSelect={handleChangePorts}
              />
            </div>
            {/* LDA Restrictions */}
            <LabelRow label="LDA Restrictions" />
            <div className={styles.selectorPanels}>
              <MultiSelectPanes
                excludedList={excludedLDARestrictions}
                includedList={includedLDARestrictions}
                error={!includedLDARestrictions.length}
                onMove={handleChangeLDARestrictions}
                onSelect={(exc, inc, selectedItem) => {
                  if (selectedItem) {
                    handleChangeLDARestrictions(selectAllSameStates(exc, selectedItem), selectAllSameStates(inc, selectedItem));
                  }
                }}
              />
            </div>

            {hasError && <OfferTabError message={errorMessage} />}
          </>
        }
        renderFooter={
          <ButtonGroup>
            <Button
              id="info-save-btn"
              variant="primary"
              disabled={promiseLoading || hasError}
              onClick={() => {
                handleOnSubmit(false);
              }}
            >
              Save
            </Button>
            <Button
              id="info-next-btn"
              variant="primary"
              disabled={promiseLoading || hasError}
              onClick={() => {
                handleOnSubmit(true);
              }}
            >
              Next
            </Button>
          </ButtonGroup>
        }
      />
    </>
  );
};

export default InfoTabLda;
