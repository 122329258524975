import { OATLink } from 'oat-common-ui';
import { Link } from 'react-router-dom';
import { Offering } from '../../gql/generated';
import useStores from '../../stores/useStores';
import styles from './styles.module.scss';

interface DashboardRowProps {
  item: Offering;
  isStarted?: boolean;
  tdaCode: string;
}

const DashboardRow = ({ item, isStarted, tdaCode }: DashboardRowProps) => {
  const { offeringsStore } = useStores();

  const handleLinkClick = () => {
    offeringsStore.setCurrentOffering(item);
  };

  return (
    <tr className={styles.offeringRow}>
      <td>
        <Link component={OATLink} onClick={handleLinkClick} to={`/${tdaCode}/offering/${item.id}`}>
          {item.name}
        </Link>
      </td>
      <td>{item.startDate}</td>
      <td>{item.endDate}</td>
      <td>{item.status}</td>
    </tr>
  );
};

export default DashboardRow;
