import { AdminConstants } from 'oat-admin-common';
import { ReactNode } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useGetUserInfoQuery } from '../../gql/generated';
import useUrlParams from '../../hooks/useUrlParams';
import TdaOption from '../../models/TdaType';
import Error from '../../pages/Error';
import useStores from '../../stores/useStores';
import MainHeader from '../MainHeader';

const { BRAND_LEXUS, BRAND_TOYOTA, TDAS } = AdminConstants;

/**
 * Do not remove. This component can grab tdaCode from urlParams.
 * @returns
 */
const TdaLoader = ({ children }: { children: ReactNode }) => {
  const { tdaStore, userInfoStore } = useStores();
  const { tdaCode } = useUrlParams();

  if (!tdaStore.isLoaded) {
    const tdasByBrand = TDAS.filter(item => item.brand === (!userInfoStore?.isLexus() ? BRAND_TOYOTA : BRAND_LEXUS));
    tdaStore.setTdaOptions(tdasByBrand as TdaOption[], userInfoStore.userInfo.tdaCodes);
    tdaStore.setCurrentTdaOptionByCode(tdaCode);
  }

  return <>{children}</>;
};

export const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const { userInfoStore } = useStores();
  const { data, loading, error } = useGetUserInfoQuery();

  if (loading) {
    return <MainHeader showMiscLinks={false} showProgressBar breadCrumbs={[{ name: 'Initializing' }]} />;
  }

  if (error) {
    return <Error message="Error occurred when logging in. Please logout completely and try again" />;
  }

  if (data && data.userInfo) {
    userInfoStore.setUserInfo(data.userInfo, true);
  }

  return (
    <Route {...rest}>
      <TdaLoader>{children}</TdaLoader>
    </Route>
  );
};

export default PrivateRoute;
