import React from 'react';
import styles from './styles.module.scss';

interface Props {
  sectionTitle: string;
}

const OfferTemplateTitle = ({ sectionTitle }: Props) => {
  return (
    <div className={styles.sectionTitleContainer}>
      <h3 className={styles.sectionTitle}>{sectionTitle}</h3>
    </div>
  );
};

export default OfferTemplateTitle;
