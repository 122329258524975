import { OfferRate, RateInput } from '../gql/generated';

const getRatesPayload = (rates: OfferRate[]): RateInput[] => {

  const payload: RateInput[] = [];
  if (rates?.length) {
    rates.forEach( rate => payload.push({
      tier: rate.tier,
      term: rate.term,
      isAdvertised: rate.isAdvertised,
      buyRate: rate.buyRate,
      subventionRate: rate.subventionRate,
      standardRcf: rate.standardRcf,
      subventedRcf: rate.subventedRcf,
      subventionCash: rate.subventionCash,
      extraCashAmount: rate.extraCashAmount,
      dollarPerThousand: rate.dollarPerThousand
    }));
  }

  return payload;
};

export default getRatesPayload;
