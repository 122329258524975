import { observer } from 'mobx-react-lite';
import { useToast } from 'oat-common-ui';
import { useHistory, useLocation } from 'react-router-dom';
import MainHeader from '../../components/MainHeader';
import { SettingsBreadCrumbs } from '../../components/SettingsComponents';
import { Template, TemplateMarketing, TemplateMarketingInput, useSaveTemplateMarketingMutation, useSaveTemplateMutation } from '../../gql/generated';
import useUrlParams from '../../hooks/useUrlParams';
import useStores from '../../stores/useStores';
import OfferTemplateForm from './OfferTemplateForm';

const OfferTemplateComponentBase = () => {
  const [saveTemplateMutation] = useSaveTemplateMutation();
  const [saveTemplateMarketingMutation] = useSaveTemplateMarketingMutation();
  const { success, error } = useToast();
  const { tdaCode, offerTemplateId, copy, tdaName } = useUrlParams();
  const history = useHistory();

  const {
    tokenStore: { snippets, ribbons },
    offerTemplatesStore: { setOfferTemplate, setCurrentOfferTemplate, setCurrentTemplateMarketing },
    userInfoStore,
  } = useStores();
  const location = useLocation();
  const isFeatureMasterToken = location.pathname.includes('master');

  const routeBack = () => {
    if (userInfoStore.isLexus()) {
      history.push(`/${tdaCode}/settings/offerTemplates/master`);
    } else {
      history.push(`/${tdaCode}/settings/offerTemplates`);
    }
  };

  const handleOnSubmit = async (
    discard: boolean,
    templatePayload: {
      name: string;
      status: string;
      offerType: string;
      tdaCode: string;
      tiers?: string;
      terms?: string;
      rev?: string;
      id?: string;
    },
    templateMarketingPayload: {
      marketingDetails: TemplateMarketingInput[];
      offerTemplateId?: string;
    },
  ) => {
    if (discard) {
      routeBack();
      return;
    }

    try {
      const resTemplate = await saveTemplateMutation({ variables: { input: templatePayload } });
      const resTemplateData = resTemplate.data?.saveTemplate.template as Template;
      const templateId = resTemplateData?.id || '';
      setOfferTemplate(templateId, resTemplateData);
      setCurrentOfferTemplate(resTemplateData);

      const resTemplateMarketing = await saveTemplateMarketingMutation({
        variables: {
          input: {
            marketingDetails: templateMarketingPayload.marketingDetails,
            templateId,
          },
        },
      });
      const newTemplateMarketingData = resTemplateMarketing.data?.saveTemplateMarketing.marketingDetails as TemplateMarketing[];
      setCurrentTemplateMarketing(newTemplateMarketingData);

      success(`Template ${copy ? 'copy' : 'data'} saved successfully.`);
      routeBack();
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <>
      <MainHeader
        showMiscLinks={false}
        breadCrumbs={SettingsBreadCrumbs(
          userInfoStore.properties.fullName,
          tdaCode,
          isFeatureMasterToken ? 'Master Template' : 'Template',
          userInfoStore.isLexus(),
          offerTemplateId || '',
          true,
          tdaName,
        )}
      />
      <OfferTemplateForm snippets={snippets} ribbons={ribbons} onSubmit={handleOnSubmit} />
    </>
  );
};

export default observer(OfferTemplateComponentBase);
