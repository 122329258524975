import { ModelCodes, OfferVehicle } from '../gql/generated';
import IOfferVehicle from '../models/IOfferVehicle';

enum SeriesType {
  MULTI = 'MULTI',
}

class VehiclesStore {
  isLoaded = false;
  allVehicles: OfferVehicle[] = [];
  defaultSeriesOptions: string[] = [];
  seriesOptions: string[] = [];
  defaultSeriesYearOptions: string[] = [];
  seriesYearOptions: string[] = [];
  allVehiclesOptions: string[] = [];
  trimOptions: string[] = [];

  allMiscOptions: string[] = [];
  excludedSeriesMiscOptions: string[] = [];
  includedSeriesMiscOptions: string[] = [];

  modelCodesDb: { [series: string]: string } = {};
  defaultOtherOfferSeriesYearOptions: string[] = [];
  defaultOtherOfferSeriesOptions: string[] = [];
  otherOfferAllVehicles: OfferVehicle[] = [];
  otherOfferVehicleOptions: string[] = [];
  otherOfferSeriesYearOptions: string[] = [];
  otherOfferSeriesOptions: string[] = [];

  setVehiclesFromOffer = (vehicles: OfferVehicle[]) => {
    this.allVehicles = vehicles;
  };

  setVehiclesFromModelCodes = (vehicles: ModelCodes[]) => {
    const defaultOtherAllVehicles: OfferVehicle[] = [];

    vehicles.forEach(item => {
      item.models.forEach(model => {
        defaultOtherAllVehicles.push({
          isAdvertised: false,
          isInclusion: false,
          modelCode: model.modelCode,
          series: item.series,
          seriesYear: item.year,
          vehicleDescription: model.vehicleDescription,
        });
      });
    });

    this.otherOfferAllVehicles = defaultOtherAllVehicles;
  };

  setSeriesOptionsFromModelCodes = () => {
    const defaultModelYearSet = new Set();

    if (this.otherOfferAllVehicles) {
      this.otherOfferVehicleOptions = this.removeDuplicates(this.otherOfferAllVehicles.map(item => `${item.seriesYear} ${item.series}`));
      this.otherOfferAllVehicles.forEach(vehicle => {
        if (!this.modelCodesDb[vehicle.series]) {
          this.modelCodesDb[vehicle.series] = vehicle.seriesYear;
        } else {
          const seriesYears = (this.modelCodesDb[vehicle.series] += `,${vehicle.seriesYear}`);
          this.modelCodesDb[vehicle.series] = this.removeDuplicates(seriesYears.split(',').sort()).join();
        }
      });

      let vehiclesArr = Object.keys(this.modelCodesDb).sort();
      !vehiclesArr.includes(SeriesType.MULTI) ? (vehiclesArr = [SeriesType.MULTI, ...vehiclesArr]) : (vehiclesArr = [...vehiclesArr]);
      this.otherOfferSeriesOptions = this.removeDuplicates(vehiclesArr);
      this.defaultOtherOfferSeriesOptions = this.removeDuplicates(vehiclesArr);

      Object.values(this.modelCodesDb).forEach(modelYear => {
        modelYear.split(',').forEach(yearItem => {
          defaultModelYearSet.add(yearItem);
        });
      });

      let yearsArr = Array.from(defaultModelYearSet).sort().reverse();
      !yearsArr.includes(SeriesType.MULTI) ? (yearsArr = [SeriesType.MULTI, ...yearsArr]) : (yearsArr = [...yearsArr]);

      this.defaultOtherOfferSeriesYearOptions = yearsArr as string[];
      this.otherOfferSeriesYearOptions = yearsArr as string[];
    }
  };

  setSeriesYearOptions = (seriesName: string) => {
    // for other offers
    if (seriesName && this.modelCodesDb[seriesName]) {
      this.otherOfferSeriesYearOptions = [SeriesType.MULTI, ...this.modelCodesDb[seriesName].split(',')];
    } else {
      this.otherOfferSeriesYearOptions = this.defaultOtherOfferSeriesYearOptions;
    }
  };

  setSeriesNameOptions = (seriesYear: string) => {
    // for other offers
    const seriesOptions: string[] = [];

    for (const [key, value] of Object.entries(this.modelCodesDb)) {
      if (value.includes(seriesYear)) {
        seriesOptions.push(key);
      }
    }

    if (seriesYear !== SeriesType.MULTI) {
      this.otherOfferSeriesOptions = [SeriesType.MULTI, ...seriesOptions.sort()];
    } else {
      this.otherOfferSeriesOptions = this.defaultOtherOfferSeriesOptions;
    }
  };

  setSeriesOptions = () => {
    const vehicleDb: { [vehicleSeries: string]: string } = {};
    const vehicleYearSet = new Set();
    if (this.allVehicles) {
      this.allVehiclesOptions = this.removeDuplicates(this.allVehicles.map(item => `${item.seriesYear} ${item.series}`));

      this.allVehicles.forEach(vehicle => {
        vehicleDb[vehicle.series] = vehicle.seriesYear;
      });
      let vehiclesArr = Object.keys(vehicleDb).sort();
      !vehiclesArr.includes(SeriesType.MULTI) ? (vehiclesArr = [SeriesType.MULTI, ...vehiclesArr]) : (vehiclesArr = [...vehiclesArr]);
      this.defaultSeriesOptions = this.removeDuplicates(vehiclesArr);
      this.seriesOptions = vehiclesArr;

      Object.values(vehicleDb).forEach(vehicleYear => {
        vehicleYearSet.add(vehicleYear);
      });
      let yearsArr = Array.from(vehicleYearSet).sort().reverse();
      !yearsArr.includes(SeriesType.MULTI) ? (yearsArr = [SeriesType.MULTI, ...yearsArr]) : (yearsArr = [...yearsArr]);
      this.defaultSeriesYearOptions = this.removeDuplicates(yearsArr as string[]);
      this.seriesYearOptions = this.removeDuplicates(yearsArr as string[]);
    }
  };

  setSeriesMiscOptions = (vehicles: IOfferVehicle[]) => {
    this.allMiscOptions = this.removeDuplicates(vehicles.map(v => v.vehicleDescription)).sort();
    this.excludedSeriesMiscOptions = this.removeDuplicates(vehicles.filter(v => !v.isInclusion).map(v => v.vehicleDescription)).sort();
    this.includedSeriesMiscOptions = this.removeDuplicates(vehicles.filter(v => v.isInclusion).map(v => v.vehicleDescription)).sort();
  };

  removeDuplicates = (list: string[]) => {
    return list.filter((item, index) => list.indexOf(item) === index);
  };
}

export default VehiclesStore;
