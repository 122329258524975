import { sortFieldsHelper, uuidv4 } from 'oat-common-ui';
import { TokenRibbon } from '.';

export const processStoreRibbons = (ribbons: TokenRibbon[], language: string) => {
  return ribbons
    .filter(r => r.language.toLowerCase() === language)
    .map(r => ({ ...r, uid: uuidv4() }))
    .sort(sortFieldsHelper('sortOrder', true));
};

export const processPayloadRibbons = (engPayload: TokenRibbon[], espPayload: TokenRibbon[]) => {
  return engPayload.concat(espPayload).map(ribbon => {
    if (ribbon.id && ribbon.rev) {
      return { name: ribbon.name, id: ribbon.id, rev: ribbon.rev, language: ribbon.language, sortOrder: ribbon.sortOrder };
    }

    return { name: ribbon.name, language: ribbon.language, sortOrder: ribbon.sortOrder };
  });
};
