import { Button } from 'oat-common-ui';
import styles from './styles.module.scss';

type Props = {
  reset: () => void;
  email: () => void;
};

const FinanceModificationDialogue = ({ reset, email }: Props) => {
  return (
    <div className={styles.finDialogue}>
      <p>The updates made to this offer will impact your regional office’s budget. Please select 1 of the following:</p>
      <div>
        {'1. '}
        <Button id="fin-reset-change" variant="text" onClick={reset}>
          Reset your change
        </Button>
      </div>
      <div>
        {'2. '}
        <Button id="fin-send-email" variant="text" onClick={email}>
          Send email
        </Button>
      </div>
    </div>
  );
};

export default FinanceModificationDialogue;
