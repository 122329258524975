import { OfferTypes } from 'oat-common-ui';
import React from 'react';
import EfcCarJellyLoader from '../../../components/EfcCarJellyLoader';
import OfferTabBreadCrumbs from '../../../components/OfferTabComponents/OfferTabBreadCrumbs';
import { useGetOfferingWithOfferQuery } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import Error from '../../Error';
import Loading from '../../Loading';

interface Props {
  offerType: OfferTypes;
  component: JSX.Element;
}

const FinancialTabBase = ({ offerType, component }: Props) => {
  const { offersStore, offeringsStore, tdaStore, userInfoStore } = useStores();
  const { offeringId, offerId } = useUrlParams();

  const { data, loading, error } = useGetOfferingWithOfferQuery({ variables: { offeringId: offeringId, offerId: offerId }, fetchPolicy: 'no-cache' });

  const breadCrumbs = OfferTabBreadCrumbs(tdaStore.currentTda, userInfoStore.properties.fullName, offeringsStore.currentOffering, offerType);

  if (loading) {
    return <Loading breadCrumbs={breadCrumbs} />;
  }

  if (error) {
    return <Error breadCrumbs={breadCrumbs} />;
  }

  if (data) {
    offersStore.setCurrentOffer(data.offer);
    offeringsStore.setCurrentOffering(data.offering);
  }

  return (
    <EfcCarJellyLoader series={data?.offer.series || ''} year={data?.offer.seriesYear || ''} breadCrumbs={breadCrumbs}>
      {component}
    </EfcCarJellyLoader>
  );
};

export default FinancialTabBase;
