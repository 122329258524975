import { AdminConstants } from 'oat-admin-common';
import { defaultProperties, lexusProperties, toyotaProperties } from '../constants/brandProperties';
import { User } from '../gql/generated';

const { TdaCodes, BRAND_LEXUS, BRAND_TOYOTA } = AdminConstants;

export enum UserType {
  SET = 'SET',
  GST = 'GST',
  DEFAULT = 'default',
}

class UserInfoStore {
  isLoaded = false;
  properties = defaultProperties;
  userInfo: User = {
    azureGroups: '',
    brand: '',
    email: '',
    name: '',
    objectId: '',
    regionCodes: '',
    roles: '',
    tdaCodes: '',
    userTypes: '',
  };

  isLexus = () => {
    return this.userInfo.brand === BRAND_LEXUS;
  };

  getBrand = () => {
    return this.userInfo.brand === BRAND_LEXUS ? BRAND_LEXUS : BRAND_TOYOTA;
  };

  setUserInfo = (userInfo: User, isLoaded: boolean) => {
    this.userInfo = userInfo;
    this.isLoaded = isLoaded;

    if (userInfo.brand === BRAND_TOYOTA) {
      this.properties = toyotaProperties;
    }
    if (userInfo.brand === BRAND_LEXUS) {
      this.properties = lexusProperties;
    }
  };

  isSETUser = (userInfo: User) => {
    return userInfo.userTypes.includes('TDA') && userInfo.userTypes.includes('Regional') && userInfo.tdaCodes.includes(TdaCodes.SET) && userInfo.regionCodes.includes('500');
  };

  isGst = (userInfo: User) => {
    return userInfo.userTypes.includes('TDA') && userInfo.tdaCodes.includes(TdaCodes.GST);
  };

  getUserType = (userInfo: User): UserType => {
    if (this.isSETUser(userInfo)) {
      return UserType.SET;
    } else if (this.isGst(userInfo)) {
      return UserType.GST;
    } else {
      return UserType.DEFAULT;
    }
  };

  hasUserAccessToAllAreas = (userInfo: User) => {
    if (
      userInfo.tdaCodes.includes(TdaCodes.LCA) &&
      userInfo.tdaCodes.includes(TdaCodes.LEA) &&
      userInfo.tdaCodes.includes(TdaCodes.LSA) &&
      userInfo.tdaCodes.includes(TdaCodes.LWA)
    ) {
      return true;
    }

    return false;
  };
}
export default UserInfoStore;
