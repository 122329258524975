import { observer } from 'mobx-react-lite';
import { OATWrapper, OfferTabReturnCta, OfferTypes, useToast } from 'oat-common-ui';
import React from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Link, useHistory } from 'react-router-dom';
import MainFooter from '../../../components/MainFooter';
import MainHeader from '../../../components/MainHeader';
import OfferTabBreadCrumbs from '../../../components/OfferTabComponents/OfferTabBreadCrumbs';
import { Offer, useCreateOfferMutation, useUpdateOfferMutation } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import IOfferVehicle from '../../../models/IOfferVehicle';
import useStores from '../../../stores/useStores';
import buildOfferUrl from '../../../utils/buildOfferUrl';
import RenderHeaderDetails from '../RenderHeaderDetails';
import InfoTabLda from './InfoTabLda';
import InfoTabTda from './InfoTabTda';

export type InfoTabSubmitProps = {
  // common fields
  id: string;
  rev: string;
  next: boolean;
  startDate?: string;
  endDate?: string;
  series?: string;
  seriesYear?: string;
  vehicles?: IOfferVehicle[];
  includedStates?: string[];
  excludedStates?: string[];
  tdaPackage?: string;

  // tda fields
  isFeatured?: boolean;
  isEndDateHidden?: boolean;

  // lda fields
  paymentMethod?: string;
  notes?: string;
  includedPorts?: string[];
  excludedPorts?: string[];
  includedLdas?: string[];
  excludedLdas?: string[];
};

interface Props {
  offerType: OfferTypes;
}

const InfoTabComponentBase = ({ offerType }: Props) => {
  const [createOfferMutation] = useCreateOfferMutation();
  const [updateOfferMutation] = useUpdateOfferMutation();
  const {
    userInfoStore: { userInfo, getUserType, properties },
    userInfoStore,
    offersStore: { currentOffer, updateOffer, setCurrentOffer, addOffer },
    offeringsStore: { currentOffering },
    tdaStore,
    regionsStore,
    ldaStore,
  } = useStores();
  const { offeringId, offerId, lang, tdaCode, site } = useUrlParams();
  const { error } = useToast();

  const history = useHistory();

  const handleOnSubmit = async (data: InfoTabSubmitProps) => {
    const { next, ...rest } = data;

    const payload = {
      ...rest,
    };

    try {
      if (offerId) {
        const res = await trackPromise(updateOfferMutation({ variables: { input: { ...payload } } }));
        const resOffer = res.data?.updateOffer.offer as Offer;
        updateOffer(resOffer.id, resOffer);
        setCurrentOffer(resOffer);

        if (data.next) {
          const nextTab = resOffer.offerType !== OfferTypes.OTHER ? 'financial' : 'marketing';
          history.push(buildOfferUrl(currentOffering.id, tdaCode, offerType, nextTab, lang, offerId || '', site));
        }
      } else {
        const processedPayload = {
          offeringId,
          startDate: data.startDate || '',
          endDate: data.endDate || '',
          series: data.series || '',
          seriesYear: data.seriesYear || '',
          vehicles: data.vehicles || [],
          includedStates: data.includedStates || [],
          excludedStates: data.excludedStates || [],
          isFeatured: data.isFeatured || false,
          isEndDateHidden: data.isEndDateHidden || false,
          includedPorts: data.includedPorts || [],
          excludedPorts: data.excludedPorts || [],
          includedLdas: data.includedLdas || [],
          excludedLdas: data.excludedLdas || [],
        };

        const res = await trackPromise(
          createOfferMutation({
            variables: { input: { ...processedPayload } },
          }),
        );
        const resOffer = res.data?.createOffer.offer as Offer;
        addOffer(resOffer);
        setCurrentOffer(resOffer);

        // for when creating a new Other offer
        const module = data.next ? 'marketing' : 'info';
        const defaultOfferId = !offerId ? resOffer.id : offerId || '';

        history.push(buildOfferUrl(currentOffering.id, tdaCode, offerType, module, lang, defaultOfferId, site));
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const returnLink = `/${tdaCode}/offering/${currentOffering?.id}`;

  return (
    <>
      <MainHeader breadCrumbs={OfferTabBreadCrumbs(tdaStore.currentTda, properties.fullName, currentOffering, offerType)} />
      <OATWrapper withPadding>
        <Link to={returnLink} component={OfferTabReturnCta} />

        {userInfoStore.isLexus() ? (
          <InfoTabLda
            offer={currentOffer}
            offering={currentOffering}
            offerType={offerType}
            headerContent={<RenderHeaderDetails offerType={offerType} />}
            onSubmit={handleOnSubmit}
            statesOptions={regionsStore.statesOptions}
            ldaOptions={ldaStore.ldaOptions}
            includedLdas={ldaStore.includedLdas}
          />
        ) : (
          <InfoTabTda
            offer={currentOffer}
            offering={currentOffering}
            offerType={offerType}
            headerContent={<RenderHeaderDetails offerType={offerType} />}
            userType={getUserType(userInfo)}
            onSubmit={handleOnSubmit}
          />
        )}
      </OATWrapper>
      <MainFooter isShowPublish darkTheme />
    </>
  );
};

export default observer(InfoTabComponentBase);
