import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import {
  APRHeaderDetails,
  Button,
  ButtonGroup,
  DefaultModal,
  Dropdown,
  InlineInputLabel,
  Input,
  OATIcon,
  OfferTabBodyLayout,
  OfferTabError,
  OfferTabsHeader,
  OfferTabsNav,
  OfferTypes,
  assignStringValue,
  useInput,
  usePromiseLoading,
  useToast,
} from 'oat-common-ui';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import OfferTabNavList from '../../../../components/OfferTabComponents/OfferTabNavList';
import { AprExtraCashTypes, FEATURE_OR_4616, SEND_EMAIL_PROMPT, SEND_EMAIL_SUCCESS } from '../../../../constants/global';
import { Offer, OfferRate, useRequestRegionalAtcMutation, useUpdateOfferMutation } from '../../../../gql/generated';
import useUrlParams from '../../../../hooks/useUrlParams';
import { UserType } from '../../../../stores/UserInfoStore';
import useStores from '../../../../stores/useStores';
import buildOfferUrl from '../../../../utils/buildOfferUrl';
import { getEfcCarJelly } from '../../../../utils/efc3dImageUtils';
import getRatesPayload from '../../../../utils/getRatesPayload';
import getSeriesInfoFromOffer from '../../../../utils/getSeriesInfoFromOffer';
import { getUserType } from '../../../../utils/getUserType';
import FinanceModificationDialogue from '../FinanceModificationDialogue';
import FinancialTabComponentBase from '../FinancialTabComponentBase';
import StackedOffer from '../components/StackedOffer';
import StackedOfferModal from '../components/StackedOfferModal';
import RateTierContainer from './RateTierContainer';
import styles from './styles.module.scss';
import { calcDptForSubmit, sortRatesByTerm, validateRates } from './utils';

const APRFinancialTabComponent = () => {
  const {
    offersStore: { currentOffer, updateOffer, setCurrentOffer },
    offeringsStore: { currentOffering },
    efcStore,
    userInfoStore: { userInfo, isLexus },
    stackedOffersStore: { stackedOffers },
  } = useStores();

  const userType = getUserType(userInfo);
  const { tdaCode, offerId, lang, site } = useUrlParams();
  const [fundingSource, fundingSourceBindFn, fundingSourceError] = useInput(currentOffer?.fundingSource, { required: true });
  const [ecLabel, ecLabelBindFn, ecLabelError] = useInput(currentOffer?.extraCashLabel);
  const [extraCashLabelSpanish, extraCashLabelSpanishBindFn, extraCashLabelSpanishError] = useInput(currentOffer?.extraCashLabelSpanish);
  const [extraCashType, setExtraCashType] = useState(assignStringValue(currentOffer?.extraCashOfferType));
  const [rateTiersData, setRateTiersData] = useState(sortRatesByTerm(currentOffer?.rates)); // assuming only one tier with terms is populated
  const [selectedRate, setSelectedRate] = useState(currentOffer?.rates?.find(item => item.isAdvertised));
  const [redirect, setRedirect] = useState('');
  const [updateCounter, setUpdateCounter] = useState(0); // Update child state with reset values
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [tdaPackage, tdaPackageBindFn, tdaPackageError, setTdaPackage] = useInput<string>(assignStringValue(currentOffer?.tdaPackage), { required: true });

  const [isOpenAddStackedOfferModal, setIsOpenAddStackedOfferModal] = useState(false);

  const [updateOfferMutation] = useUpdateOfferMutation();
  const [requestRegionalAtc] = useRequestRegionalAtcMutation();

  const { promiseLoading, loadPromise } = usePromiseLoading();

  const { success, error } = useToast();

  const labelWidth = 250;

  const showSpanish = !isLexus();
  const showTdaPackage = !currentOffer?.isSpecialEdition && extraCashType === AdminConstants.SPECIAL_EDITION_BONUS_TYPE && (userType === UserType.GST || tdaCode === UserType.GST);
  const isExtraCashTypeDisabled = !ecLabel && (!showSpanish || !extraCashLabelSpanish);
  const isExtraCashTypeError = !isLexus() && !isExtraCashTypeDisabled && !extraCashType;
  const rateValidations = validateRates(rateTiersData);
  const hasError = fundingSourceError || isExtraCashTypeError || rateValidations.invalidRates || rateValidations.requiredError || (showTdaPackage && !!tdaPackageError);
  const showRequiredFieldMessage = fundingSourceError || isExtraCashTypeError || rateValidations.requiredError;

  const handleOnSubmit = async (back: boolean, next: boolean) => {
    try {
      // bugfix for or-1087 - calculate dpt if its null
      const rates = calcDptForSubmit(rateTiersData);

      const res = await loadPromise(
        updateOfferMutation({
          variables: {
            input: {
              id: assignStringValue(currentOffer?.id),
              rev: assignStringValue(currentOffer?.rev),
              extraCashLabel: ecLabel,
              extraCashLabelSpanish: extraCashLabelSpanish,
              extraCashOfferType: extraCashType,
              rates: getRatesPayload(rates),
              fundingSource,
              tdaPackage,
            },
          },
        }),
      );
      const newOffer = res.data?.updateOffer.offer as Offer;
      updateOffer(assignStringValue(currentOffer?.id), newOffer);
      setCurrentOffer(newOffer);

      if (back) {
        setRedirect(buildOfferUrl(currentOffering?.id, tdaCode, 'apr', 'info', lang, currentOffer?.id, site));
      }

      if (next) {
        setRedirect(buildOfferUrl(currentOffering?.id, tdaCode, 'apr', 'marketing', lang, currentOffer?.id, site));
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleUpdateRates = (offerRate: OfferRate) => {
    const newTiersData = rateTiersData.map(item => {
      if (item.term === offerRate.term && item.tier === offerRate.tier) {
        return offerRate;
      }

      // if incoming rate is advertised, mark the rest as false
      return {
        ...item,
        isAdvertised: offerRate.isAdvertised ? false : item.isAdvertised,
      };
    });

    setRateTiersData(newTiersData);
    if (offerRate.isAdvertised) {
      setSelectedRate(offerRate);
    }
  };

  const checkEmptyExtraCashValues = (label: string, labelSpanish: string) => {
    if (!label && !labelSpanish) {
      setExtraCashType('');
      setTdaPackage(assignStringValue(currentOffer?.tdaPackage));
    }
  };

  const handleExtraCashOfferLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    checkEmptyExtraCashValues(e.currentTarget.value, extraCashLabelSpanish);
    ecLabelBindFn.onChange(e);
  };

  const handleExtraCashOfferLabelSpanishChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    checkEmptyExtraCashValues(ecLabel, e.currentTarget.value);
    extraCashLabelSpanishBindFn.onChange(e);
  };

  const handleExtraCashTypeChange = (val: string) => {
    if (val !== AdminConstants.SPECIAL_EDITION_BONUS_TYPE && !currentOffer?.isSpecialEdition) {
      setTdaPackage(assignStringValue(currentOffer?.tdaPackage));
    }
    setExtraCashType(val);
  };

  const handleResetToApproved = () => {
    const newRateTiers = rateTiersData.map(item => ({
      ...item,
      subventionRate: item.approvedSubventionRate,
      subventionCash: item.approvedSubventionCash,
    }));
    setRateTiersData(newRateTiers);
    setUpdateCounter(updateCounter + 1);
  };

  const handleSendEmail = async () => {
    try {
      await requestRegionalAtc({
        variables: {
          input: {
            offerId: assignStringValue(currentOffer?.id),
          },
        },
      });
      success(SEND_EMAIL_SUCCESS);
    } catch (e) {
      error((e as Error).message);
    } finally {
      setOpenEmailModal(false);
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <FinancialTabComponentBase offerType={OfferTypes.APR}>
      <OfferTabsHeader
        endDate={assignStringValue(currentOffer?.endDate)}
        status={assignStringValue(currentOffer?.status)}
        seriesMap={efcStore.uniqueYearSeries}
        offerImage={getEfcCarJelly(efcStore.seriesImages, ...getSeriesInfoFromOffer(currentOffer))}
        offerSeries={assignStringValue(currentOffer?.series)}
        offerSeriesYear={assignStringValue(currentOffer?.seriesYear)}
        offerType={OfferTypes.APR}
        renderHeaderContent={
          <APRHeaderDetails
            apr={assignStringValue(selectedRate?.subventionRate?.toString())}
            terms={assignStringValue(selectedRate?.term.toString())}
            subVen={assignStringValue(selectedRate?.subventionCash?.toString())}
          />
        }
        renderNav={<OfferTabsNav navList={OfferTabNavList(currentOffering?.id, tdaCode, OfferTypes.APR, lang, offerId, site)} />}
      />
      <OfferTabBodyLayout
        renderContent={
          <>
            <div className={styles.section}>
              <InlineInputLabel label="Offer Type" vertical width={labelWidth}>
                <Input id="apr-fin-offer-type-input" value="APR" disabled />
              </InlineInputLabel>
              <InlineInputLabel label="Funding Source" vertical width={labelWidth}>
                <Input id="apr-fin-funding-source-input" darkTheme value={fundingSource} {...fundingSourceBindFn} error={fundingSourceError} />
              </InlineInputLabel>
            </div>
            <RateTierContainer rateTiers={rateTiersData} updateRates={handleUpdateRates} updateCounter={updateCounter} />
            <div className={styles.section}>
              {!FEATURE_OR_4616 && (
                <>
                  <InlineInputLabel label="Extra Cash Label" vertical width={labelWidth}>
                    <Input id="apr-extra-cash-input" darkTheme {...ecLabelBindFn} value={ecLabel} error={ecLabelError} onChange={handleExtraCashOfferLabelChange} />
                  </InlineInputLabel>
                  {showSpanish && (
                    <InlineInputLabel label="Extra Cash Label (Spanish)" vertical width={labelWidth}>
                      <Input
                        id="apr-extra-cash-es-input"
                        darkTheme
                        {...extraCashLabelSpanishBindFn}
                        value={extraCashLabelSpanish}
                        error={extraCashLabelSpanishError}
                        onChange={handleExtraCashOfferLabelSpanishChange}
                      />
                    </InlineInputLabel>
                  )}
                  <InlineInputLabel vertical width={labelWidth} label="Extra Cash Offer Type">
                    <Dropdown
                      id="fin-extra-cash-offer-type-dd"
                      darkTheme
                      value={extraCashType}
                      onChange={item => handleExtraCashTypeChange(item.value)}
                      options={AprExtraCashTypes(userInfo.brand, tdaCode)}
                      error={isExtraCashTypeError}
                      disabled={!ecLabel}
                    />
                  </InlineInputLabel>
                </>
              )}
              {showTdaPackage && (
                <InlineInputLabel vertical width={labelWidth} label="Package">
                  <Input id="fin-series-package" value={tdaPackage} {...tdaPackageBindFn} onChange={e => setTdaPackage(e.currentTarget.value)} darkTheme error={tdaPackageError} />
                </InlineInputLabel>
              )}
            </div>
            {currentOffer?.needsReview && <FinanceModificationDialogue reset={handleResetToApproved} email={() => setOpenEmailModal(true)} />}
            {showRequiredFieldMessage && <OfferTabError />}
            {FEATURE_OR_4616 && (
              <>
                <StackedOffer labelWidth={labelWidth} showSpanish={showSpanish} />
                <StackedOfferModal offerType="APR" open={isOpenAddStackedOfferModal} onClose={() => setIsOpenAddStackedOfferModal(!isOpenAddStackedOfferModal)} />
                {stackedOffers.length < 2 && (
                  <Button className={styles.addStackedOfferCta} onClick={() => setIsOpenAddStackedOfferModal(true)}>
                    <OATIcon icon="add" size={18} colorTheme="blue" />
                    <span>Add Stacked Offer</span>
                  </Button>
                )}
              </>
            )}
          </>
        }
        renderFooter={
          <ButtonGroup>
            <Button id="fin-back-btn" variant="primary" disabled={promiseLoading || hasError} onClick={() => handleOnSubmit(true, false)}>
              Back
            </Button>
            <Button id="fin-save-btn" variant="primary" disabled={promiseLoading || hasError} onClick={() => handleOnSubmit(false, false)}>
              Save
            </Button>
            <Button id="fin-next-btn" variant="primary" disabled={promiseLoading || hasError} onClick={() => handleOnSubmit(false, true)}>
              Next
            </Button>
          </ButtonGroup>
        }
      />
      {openEmailModal && <DefaultModal title="Send Email" message={SEND_EMAIL_PROMPT} open onSubmit={handleSendEmail} onClose={() => setOpenEmailModal(false)} />}
    </FinancialTabComponentBase>
  );
};

export default observer(APRFinancialTabComponent);
