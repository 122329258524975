import OfferTabBreadCrumbs from '../../components/OfferTabComponents/OfferTabBreadCrumbs';
import { Offer, Offering, useGetAvailableReportsQuery, useGetOfferingWithOffersQuery } from '../../gql/generated';
import useUrlParams from '../../hooks/useUrlParams';
import useStores from '../../stores/useStores';
import Error from '../Error';
import Loading from '../Loading';
import OfferingComponent from './OfferingComponent';

const OfferingPage = () => {
  const { userInfoStore, offeringsStore, offersStore, tdaStore, reportsStore } = useStores();
  const { offeringId } = useUrlParams();

  const { data, loading, error } = useGetOfferingWithOffersQuery({ variables: { id: offeringId }, fetchPolicy: 'no-cache' });
  const { data: reportsData, loading: reportsLoading } = useGetAvailableReportsQuery({
    variables: { offeringId: offeringId, brand: userInfoStore.getBrand() },
    fetchPolicy: 'no-cache',
  });
  const breadCrumbs = OfferTabBreadCrumbs(tdaStore.currentTda, userInfoStore.properties.fullName, offeringsStore.currentOffering);

  if (loading || reportsLoading) {
    return <Loading breadCrumbs={breadCrumbs} />;
  }

  if (error) {
    return <Error breadCrumbs={breadCrumbs} />;
  }

  if (data) {
    offeringsStore.setCurrentOffering(data.offering as Offering);
    offersStore.loadOffers(offeringId, data.offers as Offer[]);
  }

  if (reportsData) {
    reportsStore.setAvailableReports(reportsData.availableReports);
  }

  return <OfferingComponent />;
};

export default OfferingPage;
