import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { MarketingSites } from '../../constants/global';
import buildOfferUrl from '../../utils/buildOfferUrl';
import styles from './styles.module.scss';

const OfferTabNavList = (offeringId: string, tdaCode: string, offerType: string, lang: string, offerId?: string, site?: string, isDisabled?: boolean) => {
  let navList = [
    <NavLink to={buildOfferUrl(offeringId, tdaCode, offerType, 'info', lang, offerId, site)}>Info</NavLink>,
    <NavLink to={buildOfferUrl(offeringId, tdaCode, offerType, 'financial', lang, offerId, site)}>Financial</NavLink>,
    <NavLink to={buildOfferUrl(offeringId, tdaCode, offerType, 'marketing', lang, offerId, site)}>Marketing</NavLink>,
  ];

  if (offerType === 'Other') {
    // for when creating a new Other offer
    const defaultLangParam = !offerId ? 'en' : lang;
    const defaultSiteParam = !offerId ? MarketingSites.BAT : site;

    navList = [
      <NavLink to={buildOfferUrl(offeringId, tdaCode, offerType, 'info', lang, offerId, site)}>Info</NavLink>,
      <NavLink to={buildOfferUrl(offeringId, tdaCode, offerType, 'marketing', defaultLangParam, offerId, defaultSiteParam)} className={clsx(isDisabled && styles.disableTab)}>
        Marketing
      </NavLink>,
    ];
  }

  return navList;
};

export default OfferTabNavList;
