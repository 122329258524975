import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'oat-common-ui';
import React from 'react';

interface Props {
  id?: string;
  title: string;
  message: string;
  open: boolean;
  disabled?: boolean;
  submitText?: string;
  addlSubmitText?: string;
  className?: string;
  onSubmitOne: () => void;
  onSubmitTwo: () => void;
  onClose: () => void;
}

const MarketingModal = ({ id, title, message, open, disabled, submitText, addlSubmitText, className, onSubmitOne, onSubmitTwo, onClose }: Props) => {
  const handleOnSubmitOne = () => {
    onSubmitOne();
    onClose();
  };

  const handleOnSubmitTwo = () => {
    onSubmitTwo();
    onClose();
  };

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalHeader onClose={onClose}>{title}</ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter className={className}>
        <ButtonGroup>
          <Button id={`default-modal-close-${id}`} variant="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button id={`default-modal-submit-${id}`} variant="primary" onClick={handleOnSubmitOne} disabled={disabled}>
            {submitText || 'Submit'}
          </Button>
          <Button id={`default-modal-addl-submit-${id}`} variant="primary" onClick={handleOnSubmitTwo} disabled={disabled}>
            {addlSubmitText || 'Submit'}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default MarketingModal;
