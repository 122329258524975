import { makeObservable, observable, runInAction } from 'mobx';
import { sortFieldsHelper } from 'oat-common-ui';
import { GetOfferingsForDashboardQuery, Offering } from '../gql/generated';

class DashboardStore {
  isLoading = true;
  activeOfferings: Offering[] = [];
  archivedOfferings: Offering[] = [];

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      activeOfferings: observable,
      archivedOfferings: observable,
    });
  }

  processQuery(data: GetOfferingsForDashboardQuery | undefined) {
    runInAction(() => {
      if (data) {
        const sortField = 'startDate';
        const sortAscending = false;
        this.activeOfferings = (data.active as Offering[]).slice().sort(sortFieldsHelper(sortField, sortAscending));
        this.archivedOfferings = (data.archived as Offering[]).slice().sort(sortFieldsHelper(sortField, sortAscending));
      }
    });
  }

  addActiveOffering = (offering: Offering) => {
    runInAction(() => {
      this.activeOfferings = [...this.activeOfferings, offering];
    });
  };

  deleteOfferingById = (offeringId: string) => {
    const deleteItem = (offerings: Offering[]) => {
      if (offerings.find(item => item.id === offeringId)) {
        return offerings.filter(item => item.id !== offeringId);
      }

      return undefined;
    };

    runInAction(() => {
      const newActiveOfferings = deleteItem(this.activeOfferings);
      if (newActiveOfferings) {
        this.activeOfferings = newActiveOfferings;
      }

      const newArchivedOfferings = deleteItem(this.archivedOfferings);
      if (newArchivedOfferings) {
        this.archivedOfferings = newArchivedOfferings;
      }
    });
  };

  updateByOffering = (offering: Offering) => {
    runInAction(() => {
      this.activeOfferings = this.activeOfferings.map(item => (item.id === offering.id ? offering : item));
      this.archivedOfferings = this.archivedOfferings.map(item => (item.id === offering.id ? offering : item));
    });
  };

  sortActive = (field: string, sortAscending: boolean) => {
    runInAction(() => {
      this.activeOfferings = this.activeOfferings.slice().sort(sortFieldsHelper(field, sortAscending));
    });
  };

  sortArchived = (field: string, sortAscending: boolean) => {
    runInAction(() => {
      this.archivedOfferings = this.archivedOfferings.slice().sort(sortFieldsHelper(field, sortAscending));
    });
  };
}

export default DashboardStore;
