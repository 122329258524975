import { OfferTypes } from 'oat-common-ui';
import { Offer } from '../../gql/generated';

enum OfferTypeFiltersPosition {
  Cash = 1,
  APR = 2,
  Lease = 3,
  Misc = 4,
  Other = 5,
}

type Filters = {
  status: string;
  series: string;
  offerType: string;
};

const getOffersBySelection = (offers: Offer[], filters: Filters, includedFilters: string[]): Offer[] => {
  let offersBySelection: Offer[] = offers;

  includedFilters.forEach(curFilter => {
    if (filters[curFilter as keyof Filters] !== '') {
      offersBySelection = offersBySelection.filter(offer => {
        if (curFilter !== 'series') {
          return offer[curFilter as keyof Offer] === filters[curFilter as keyof Filters];
        }

        // When MULTI is selected We want to show all offers with created by TDA (Other offerType).
        // Misc offers from Region has multi as series and year, but they have their own dropdown option: “Loyalty”, “Military”, “Graduate”, etc...
        if (filters.series === 'MULTI') {
          return offer.offerType === OfferTypes.OTHER;
        } else {
          return offer.seriesProfileName === filters[curFilter as keyof Filters];
        }
      });
    }
  });

  return offersBySelection;
};

// removing year from seriesProfileName for vehicle column 2022 CAMRY SEDAN
const trimSeriesName = (seriesName?: string | null) => {
  if (!seriesName) {
    return {};
  }

  const [year, ...rest] = seriesName.split(' ');
  return { year, seriesName: rest.join(' ') };
};

export const getSeriesDisplayName = (offer: Offer) => {
  // We want to show MULTI on all fields if offer is created by TDA (is Other offerType)
  if (offer.offerType === OfferTypes.OTHER) {
    return {
      year: offer.seriesYear,
      seriesDropdown: offer.series,
      series: offer.series,
    };
  } else {
    return {
      // If series/year is NOT "MULTI" that means that offer is APR, CASH or LEASE and we have all necceesseary infomartion in seriesProfileName (e.g. 2022 CAMRY SEDAN)
      // If series/year is "MULTI" that we have information in dropdown label in seriesProfileName (e.g. “Loyalty”, “Military”, “Graduate”, etc...)
      year: offer.seriesYear === 'MULTI' ? 'MULTI' : offer.seriesYear || trimSeriesName(offer.seriesProfileName).year,
      seriesDropdown: offer.seriesProfileName || '',
      series: offer.series === 'MULTI' ? 'MULTI' : trimSeriesName(offer.seriesProfileName).seriesName,
    };
  }
};

export const getOfferingFilters = (offers: Offer[], filters: Filters) => {
  const getOfferTypeFilters = (includedFilters: string[]) => {
    const offersBySelection = getOffersBySelection(offers, filters, includedFilters);
    // creating an unique set of offerTypes from previously filtered offers
    return Array.from(new Set(offersBySelection.map(item => item.offerType).sort((a: any, b: any) => (OfferTypeFiltersPosition[a] > OfferTypeFiltersPosition[b] ? 1 : -1))));
  };

  const getSeriesFilterList = (includedFilters: string[]) => {
    const offersBySelection = getOffersBySelection(offers, filters, includedFilters);
    // creating an unique set of series from previously filtered offers
    return Array.from(new Set(offersBySelection.map(item => getSeriesDisplayName(item).seriesDropdown))).sort((a, b) => (a > b || a === 'MULTI' ? 1 : -1));
  };

  const getStatusFilterList = (includedFilters: string[]) => {
    const offersBySelection = getOffersBySelection(offers, filters, includedFilters);
    // creating an unique set of statuses from previously filtered offers
    return Array.from(new Set(offersBySelection.map(item => item.status).sort((a, b) => (a > b ? 1 : -1))));
  };

  return {
    offerTypeFilters: getOfferTypeFilters(['series', 'status']),
    seriesFilters: getSeriesFilterList(['status', 'offerType']),
    statusFilters: getStatusFilterList(['series', 'offerType']),
  };
};
