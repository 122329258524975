import { Button, OATIcon, Tooltip } from 'oat-common-ui';
import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';

interface Props {
  offerId: string;
}

const CopyOfferIdButton = ({ offerId }: Props) => {
  const timerRef = useRef<NodeJS.Timeout | undefined>();
  const [tooltipMessage, setTooltipMessage] = useState('Copy');

  const clearTimerRef = () => {
    if (timerRef?.current) {
      clearTimeout(timerRef.current);
    }
  };

  useEffect(() => {
    return () => clearTimerRef();
  }, []);

  const copyToClipboard = async (offerId: string) => {
    clearTimerRef();

    try {
      await navigator.clipboard.writeText(offerId);
      setTooltipMessage('Link copied');
      timerRef.current = setTimeout(() => setTooltipMessage('Copy'), 3000);
      return;
    } catch (e) {
      setTooltipMessage('Copy');
    }
  };

  return (
    <Button className={styles.copyIdButton} id={`${offerId}-copy-id-btn`} onClick={() => copyToClipboard(offerId)}>
      <Tooltip message={tooltipMessage} id={tooltipMessage === 'Copy' ? `${offerId}-copy` : `${offerId}-copied`}>
        <OATIcon icon="copy" />
      </Tooltip>
    </Button>
  );
};

export default CopyOfferIdButton;
