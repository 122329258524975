import { observer } from 'mobx-react-lite';
import { DefaultTable, Dropdown, DropdownItem, OfferingsLayout, sortFieldsHelper } from 'oat-common-ui';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import MainFooter from '../../components/MainFooter';
import MainHeader from '../../components/MainHeader';
import { Offering } from '../../gql/generated';
import useUrlParams from '../../hooks/useUrlParams';
import TdaOption from '../../models/TdaType';
import useStores from '../../stores/useStores';
import { isOutOfDate } from '../../utils/isOutOfDate';
import DashboardRow from './DashboardRow';

const DashboardComponent = () => {
  const { offeringsStore, userInfoStore, tdaStore } = useStores();
  const history = useHistory();
  const { tdaCode } = useUrlParams();

  const [sortFieldActive, setSortFieldActive] = useState('startDate');
  const [sortAscActive, setSortAscActive] = useState(true);
  const [sortFieldArchived, setSortFieldArchived] = useState('startDate');
  const [sortAscArchived, setSortAscArchived] = useState(false);

  // For Dropdown
  const tdaItems = tdaStore.tdaOptions.map((option: TdaOption) => ({ value: option.code, label: option.name }));
  const [tdaItem, setTdaItem] = useState<DropdownItem | undefined>(tdaItems.find(item => item.value === tdaCode));

  const columns = [
    {
      header: 'NAME',
      sortable: true,
      field: 'name',
    },
    {
      header: 'START DATE',
      sortable: true,
      field: 'startDate',
      descendingFirst: true,
    },
    {
      header: 'END DATE',
      sortable: true,
      field: 'endDate',
      descendingFirst: true,
    },
    {
      header: 'Status',
      sortable: true,
      field: 'status',
      descendingFirst: true,
    },
  ];

  const renderRow = (offerings: Offering[]) => {
    return (
      <>
        {offerings.map(item => {
          return <DashboardRow key={item.id} item={item} isStarted={isOutOfDate(item.startDate)} tdaCode={tdaItem?.value || ''} />;
        })}
      </>
    );
  };

  const onSortActive = (field: string, asc: boolean) => {
    setSortFieldActive(field);
    setSortAscActive(asc);
  };

  const onSortArchived = (field: string, asc: boolean) => {
    setSortFieldArchived(field);
    setSortAscArchived(asc);
  };

  const activeOfferings = offeringsStore.activeOfferings.slice().sort(sortFieldsHelper(sortFieldActive, sortAscActive));
  const archivedOfferings = offeringsStore.archivedOfferings.slice().sort(sortFieldsHelper(sortFieldArchived, sortAscArchived));

  const handleDropdown = async (item: DropdownItem) => {
    setTdaItem(item);

    tdaStore.setCurrentTdaOptionByCode(item.value);
    history.push(`/${item.value}/dashboard`);
  };

  return (
    <>
      <OfferingsLayout
        renderHeader={<MainHeader breadCrumbs={[{ name: `${tdaItem?.label} ${userInfoStore.properties.fullName}` }]} showMiscLinks={false} />}
        renderDropdown={<Dropdown darkTheme options={tdaItems} id="tda-lda" value={tdaItem} onChange={handleDropdown} />}
        renderActiveTable={
          <DefaultTable
            id="activeTbl"
            title="Active Incentive Periods"
            columns={columns}
            renderRows={renderRow(activeOfferings)}
            onSort={onSortActive}
            defaultSortField={'startDate'}
            defaultSortAscending={false}
          />
        }
        renderArchiveTable={
          <DefaultTable
            id="archiveTbl"
            title="Archived Incentive Periods"
            collapsable
            columns={columns}
            renderRows={renderRow(archivedOfferings)}
            onSort={onSortArchived}
            defaultSortField={'startDate'}
            defaultSortAscending={false}
          />
        }
      >
        <MainFooter />
      </OfferingsLayout>
    </>
  );
};

export default observer(DashboardComponent);
