import { dateFormatISOString, OfferTabsHeader, OfferTabsNav } from 'oat-common-ui';
import { ReactNode } from 'react';
import OfferTabNavList from '../../../components/OfferTabComponents/OfferTabNavList';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import { getEfcCarJelly } from '../../../utils/efc3dImageUtils';
import getSeriesInfoFromOffer from '../../../utils/getSeriesInfoFromOffer';

type Props = {
  endDate: Date | undefined;
  offerType: string;
  headerContent: ReactNode;
  isError: boolean;
};

const InfoTabHeader = ({ endDate, offerType, headerContent, isError }: Props) => {
  const { offeringId, tdaCode, lang, offerId, site } = useUrlParams();
  const {
    offersStore: { currentOffer },
    efcStore,
  } = useStores();

  const navList = isError ? OfferTabNavList(offeringId, tdaCode, offerType, lang, offerId, site, true) : OfferTabNavList(offeringId, tdaCode, offerType, lang, offerId, site);

  return (
    <OfferTabsHeader
      endDate={endDate ? dateFormatISOString(endDate) : ''}
      status={currentOffer?.status || ''}
      seriesMap={efcStore.uniqueYearSeries}
      offerImage={getEfcCarJelly(efcStore.seriesImages, ...getSeriesInfoFromOffer(currentOffer))}
      offerSeries={currentOffer?.series || ''}
      offerSeriesYear={currentOffer?.seriesYear || ''}
      offerType={offerType}
      renderHeaderContent={headerContent}
      renderNav={<OfferTabsNav navList={navList} />}
    />
  );
};

export default InfoTabHeader;
