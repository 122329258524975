import { observer } from 'mobx-react-lite';
import { OfferTypes, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { CashMiscExtraCashTypes } from '../../../../constants/global';
import { Offer, useUpdateOfferMutation } from '../../../../gql/generated';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import buildOfferUrl from '../../../../utils/buildOfferUrl';
import CashMiscFinancialForm from '../CashMiscFinancialForm';
import FinancialTabComponentBase from '../FinancialTabComponentBase';

const MiscFinancialTabComponent = () => {
  const {
    userInfoStore: { userInfo, isLexus },
    offersStore: { currentOffer, updateOffer, setCurrentOffer },
    offeringsStore: { currentOffering },
  } = useStores();
  const { lang, tdaCode, site } = useUrlParams();
  const [redirect, setRedirect] = useState('');
  const { error } = useToast();

  const [updateOfferMutation] = useUpdateOfferMutation();

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const handleOnSubmit = async (
    cashOfferAmount: number,
    extraCashAmount: number | null,
    extraCashLabel: string,
    extraCashLabelSpanish: string,
    fundingSource: string,
    back: boolean,
    next: boolean,
    extraCashOfferType: string,
    tdaPackage: string | undefined,
    targetedAudience: string | undefined,
    targetedAudienceSpanish?: string | undefined,
  ) => {
    try {
      const res = await updateOfferMutation({
        variables: {
          input: {
            id: currentOffer?.id || '',
            rev: currentOffer?.rev || '',
            combinedPerUnitCost: cashOfferAmount,
            extraCashAmount,
            extraCashLabel,
            extraCashLabelSpanish,
            extraCashOfferType,
            tdaPackage,
            fundingSource,
            targetedAudience,
            targetedAudienceSpanish,
          },
        },
      });
      const newOffer = res.data?.updateOffer.offer as Offer;
      updateOffer(currentOffer?.id || '', newOffer);
      setCurrentOffer(newOffer);

      if (back) {
        setRedirect(buildOfferUrl(currentOffering?.id, tdaCode, 'misc', 'info', lang, currentOffer?.id, site));
      }

      if (next) {
        setRedirect(buildOfferUrl(currentOffering?.id, tdaCode, 'misc', 'marketing', lang, currentOffer?.id, site));
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <FinancialTabComponentBase offerType={OfferTypes.MISCELLANEOUS}>
      <CashMiscFinancialForm isMisc showSpanish={!isLexus()} offer={currentOffer} extraCashTypes={CashMiscExtraCashTypes(userInfo.brand, tdaCode)} onSubmit={handleOnSubmit} />
    </FinancialTabComponentBase>
  );
};

export default observer(MiscFinancialTabComponent);
