import { FieldTitle, sortFieldsHelper, Table, TableContainer } from 'oat-common-ui';
import { useState } from 'react';
import { Template } from '../../../gql/generated';
import headerColumns from './headerColumns';
import OfferTemplateMenuTableRow from './OfferTemplateMenuTableRow';
import styles from './styles.module.scss';

interface ColumnProps {
  header: string;
  width?: number;
  onClick?: () => void;
  sortable?: boolean;
  field?: string;
  descendingFirst?: boolean;
}

interface Props {
  id: string;
  activeTemplates: Template[];
  archivedTemplates: Template[];
  defaultSortField?: string;
  defaultSortAscending?: boolean;
  isArchived: boolean;
  onDelete: (template: Template) => void;
  onArchive: (template: Template) => void;
  onStatusChange: (template: Template) => void;
  isMasterTemplate: boolean;
}

const OfferTemplateTable = ({
  id,
  activeTemplates,
  archivedTemplates,
  isArchived,
  defaultSortField = '',
  defaultSortAscending = false,
  onDelete,
  onArchive,
  onStatusChange,
  isMasterTemplate,
}: Props) => {
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortAscending, setSortAscending] = useState(defaultSortAscending);

  const [sortFieldActive, setSortFieldActive] = useState('');
  const [sortAscActive, setSortAscActive] = useState(false);

  const onSortActive = (field: string, asc: boolean) => {
    setSortFieldActive(field);
    setSortAscActive(asc);
  };

  const handleOnClick = (headerField: string, descendingFirst?: boolean) => {
    const sortAsc = sortField !== headerField ? !descendingFirst : !sortAscending;

    if (headerField === 'status') {
      setSortFieldActive(headerField);
    } else {
      onSortActive(headerField, sortAsc);
    }
    setSortField(headerField);
    setSortAscending(sortAsc);
  };

  const archivedHeaderColumns = headerColumns.filter(header => header.field !== 'status');

  //Sort status by this Order
  const sortStatusBy = ['Draft', 'Unpublished', 'Published'];

  const activeTemplate =
    sortFieldActive !== 'status'
      ? activeTemplates.slice().sort(sortFieldsHelper(sortFieldActive, sortAscActive))
      : activeTemplates.slice().sort((a, b) => sortStatusBy.indexOf((sortAscending ? a : b).status) - sortStatusBy.indexOf((sortAscending ? b : a).status));
  const archivedTemplate = archivedTemplates.slice().sort(sortFieldsHelper(sortFieldActive, sortAscActive));

  const HeaderRow = () => {
    return (
      <thead>
        <tr>
          {(isArchived ? archivedHeaderColumns : headerColumns).map((col: ColumnProps) => {
            const style = col.width ? { width: col.width } : undefined;
            const key = `${id}-${col.header}-hdr-btn`;

            return (
              <th key={key} style={style} className={sortField === col.field ? styles.sortedField : undefined}>
                {col.sortable ? (
                  <FieldTitle
                    id={key}
                    field={col.field}
                    header={col.header}
                    sortField={sortField}
                    sortAscending={sortAscending}
                    descendingFirst={col.descendingFirst}
                    handleOnClick={handleOnClick}
                  />
                ) : (
                  <>{col.header}</>
                )}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const renderRow = (templates: Template[]) => {
    return (
      <>
        {templates.map(item => {
          return (
            <OfferTemplateMenuTableRow
              key={item.id}
              template={item}
              isArchived={isArchived}
              onDelete={onDelete}
              onArchive={onArchive}
              onStatusChange={onStatusChange}
              isMasterTemplate={isMasterTemplate}
            />
          );
        })}
      </>
    );
  };

  const BodyRows = () => {
    return <tbody>{!isArchived ? renderRow(activeTemplate) : renderRow(archivedTemplate)}</tbody>;
  };

  return (
    <TableContainer className={styles.tableContainer}>
      <Table>
        <HeaderRow />
        <BodyRows />
      </Table>
    </TableContainer>
  );
};

export default OfferTemplateTable;
