import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_NAME, BreadCrumbType, DefaultHeaderLayout, OATHeaderLogo, OATHeaderTitle, OATIcon, OATLoadingBar, OATToastContainer } from 'oat-common-ui';
import { useMemo } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { Link } from 'react-router-dom';
import useUrlParams from '../../hooks/useUrlParams';
import useStores from '../../stores/useStores';

interface Props {
  breadCrumbs?: BreadCrumbType[];
  showProgressBar?: boolean;
  showMiscLinks?: boolean;
}

const MainHeader = ({ breadCrumbs = [], showProgressBar, showMiscLinks = true }: Props) => {
  const { promiseInProgress } = usePromiseTracker();
  const { tdaCode } = useUrlParams();
  const {
    userInfoStore: {
      isLoaded,
      properties: { abbrName },
      isLexus,
      userInfo,
      isSETUser,
    },
  } = useStores();

  const settingsUrl = `/${tdaCode}/settings/personal`;
  const isLowerEnv = useMemo(() => {
    return window.location.hostname.indexOf('nonprod') !== -1 || window.location.hostname.indexOf('localhost') !== -1;
  }, []);

  const getTitle = () => {
    const appName = abbrName ? ` - ${abbrName}` : '';
    return `${APP_NAME}${appName}`;
  };

  const tdaNav = [{ name: '', icon: <OATIcon icon="gear" size={14} />, component: <Link to={settingsUrl}>Settings</Link> }];
  const ldaNav = [
    {
      name: '',
      icon: <FontAwesomeIcon icon={faShareSquare} />,
      component: <a href={`${process.env.REACT_APP_CUV_URL}`}>LCERT</a>,
    },
    {
      name: '',
      icon: <OATIcon icon="gear" size={14} />,
      component: <Link to={settingsUrl}>Settings</Link>,
    },
  ];

  if (isSETUser(userInfo)) {
    tdaNav.unshift({
      name: '',
      icon: <FontAwesomeIcon icon={faShareSquare} />,
      component: <a href={process.env.REACT_APP_REGIONAL_URL}>OAT</a>,
    });
  }

  return (
    <>
      <DefaultHeaderLayout
        isProd={!isLowerEnv}
        breadCrumbs={breadCrumbs}
        renderLogo={isLoaded && <OATHeaderLogo isLexus={isLexus()} nav={!isLexus() ? tdaNav : ldaNav} />}
        renderTitle={<OATHeaderTitle>{getTitle()}</OATHeaderTitle>}
      />
      <OATToastContainer />
      {(promiseInProgress || showProgressBar) && <OATLoadingBar />}
    </>
  );
};

export default MainHeader;
