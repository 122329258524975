import { BreadCrumbType } from 'oat-common-ui';
import { ReactNode } from 'react';
import { useGetModelCodesQuery } from '../../gql/generated';
import Error from '../../pages/Error';
import Loading from '../../pages/Loading';
import useStores from '../../stores/useStores';

interface Props {
  brand: string;
  month: string;
  year: string;
  breadCrumbs: BreadCrumbType[];
  children?: ReactNode;
}

const ModelCodesLoader = ({ brand, month, year, breadCrumbs, children }: Props) => {
  const { vehiclesStore } = useStores();
  const { data, loading, error } = useGetModelCodesQuery({
    variables: {
      brand,
      offeringMonth: month,
      offeringYear: year,
    },
  });

  if (loading) {
    return <Loading breadCrumbs={breadCrumbs} />;
  }

  if (error) {
    console.log(error);
    return <Error breadCrumbs={breadCrumbs} />;
  }

  if (data) {
    const modelCodes = data?.modelCodes.map(item => ({
      models: item?.models || [],
      series: item?.series || '',
      seriesNameFromFile: item?.seriesNameFromFile || '',
      year: item?.year || '',
    }));

    vehiclesStore.setVehiclesFromModelCodes(modelCodes);
    vehiclesStore.setSeriesOptionsFromModelCodes();
  }

  return <>{children}</>;
};

export default ModelCodesLoader;
