import { observer } from 'mobx-react-lite';
import { OfferTypes } from 'oat-common-ui';
import React from 'react';
import FinancialTabBase from '../../FinancialTab/FinancialBase';
import APRFinancialTabComponent from './APRFinancialTabComponent';

const APRFinancialTab = () => {
  return <FinancialTabBase offerType={OfferTypes.APR} component={<APRFinancialTabComponent />} />;
};

export default observer(APRFinancialTab);
