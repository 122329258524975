import { Button, ButtonGroup, InlineInputLabel, Input, SnippetInput } from 'oat-common-ui';
import { useState } from 'react';
import { TemplateMarketing } from '../../../gql/generated';
import VehicleImagePickerModal from './components/ImagePickerModal';
import TeamsitePickerModal from './components/ImagePickerModal/TeamsitePickerModal';
import DisplayOfferImage from './DisplayOfferImage';
import styles from './styles.module.scss';

interface OfferImageProps {
  imagePath: string;
  imagePathLabel: string;
  imagePathError: boolean;
  labelWidth: number;
  imageAlt: string;
  imageAltLabel: string;
  imageAltError: boolean;
  setImageAltValue: (imageAltValue: string) => void;
  destinationUrl: string;
  destinationUrlLabel: string;
  destinationUrlError: boolean;
  isDisabledFields?: boolean;
  setDestinationUrlValue: (destinatiorUrlValue: string) => void;
  setImagePath: (imageSrc: string) => void;
  updateMarketingState?: (marketingData: Partial<TemplateMarketing>) => void;
  badgeFieldName?: string;
  destinationUrlFieldName?: string;
  destinationUrlAltFieldName?: string;
}

const OfferBadgeImage = ({
  imagePathLabel,
  labelWidth,
  imagePath,
  imagePathError,
  setImagePath,
  imageAlt,
  imageAltLabel,
  imageAltError,
  setImageAltValue,
  destinationUrl,
  destinationUrlLabel,
  destinationUrlError,
  isDisabledFields = false,
  setDestinationUrlValue,
  updateMarketingState,
  badgeFieldName = '',
  destinationUrlFieldName = '',
  destinationUrlAltFieldName = '',
}: OfferImageProps) => {
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  // used for template marketing
  const handleOnChangeAfter = (key: string, val: string) => {
    if (updateMarketingState && key) {
      updateMarketingState({ [key]: val });
    }
  };

  return (
    <div className={styles.offerCardLabelWrapper}>
      <div className={styles.offerBadgeDataImage}>
        <InlineInputLabel width={labelWidth} label={imagePathLabel} contentClass={styles.inputLabel}>
          <Input darkTheme disabled={isDisabledFields} value={imagePath} error={imagePathError} readOnly />
        </InlineInputLabel>
        <InlineInputLabel width={labelWidth} label={imageAltLabel} inputWrapperClass={styles.inputBadgeLabel} contentClass={styles.inputLabel} vertical>
          <SnippetInput
            disabled={isDisabledFields}
            contentClass={styles.titleTextField}
            darkTheme
            value={imageAlt}
            error={imageAltError}
            setInputValue={val => {
              setImageAltValue(val);
              handleOnChangeAfter(destinationUrlAltFieldName, val);
            }}
            id="image-alt"
          />
        </InlineInputLabel>
        <InlineInputLabel width={labelWidth} label={destinationUrlLabel} inputWrapperClass={styles.inputBadgeLabel} contentClass={styles.inputLabel} vertical>
          <SnippetInput
            disabled={isDisabledFields}
            contentClass={styles.titleTextField}
            darkTheme
            value={destinationUrl}
            error={destinationUrlError}
            setInputValue={val => {
              setDestinationUrlValue(val);
              handleOnChangeAfter(destinationUrlFieldName, val);
            }}
            id="destination-url"
          />
        </InlineInputLabel>
        <div className={styles.badgeImage}>
          <DisplayOfferImage path={imagePath} />
        </div>
      </div>
      <ButtonGroup className={styles.browseBtnGroup}>
        {imagePath && (
          <Button
            variant="primary"
            id="remove-badge-btn"
            onClick={() => {
              setImagePath('');
              handleOnChangeAfter(badgeFieldName, '');
            }}
          >
            Remove
          </Button>
        )}
        <Button variant="primary" id="browse-badge-btn" disabled={isDisabledFields} onClick={() => setIsImageModalOpen(true)}>
          Browse
        </Button>
      </ButtonGroup>
      <VehicleImagePickerModal
        title="Images"
        onClose={() => {
          setIsImageModalOpen(false);
        }}
        open={isImageModalOpen}
      >
        <TeamsitePickerModal
          onSelect={imageSrc => {
            setImagePath(imageSrc);
            handleOnChangeAfter(badgeFieldName, imageSrc);
          }}
          closeModal={() => {
            setIsImageModalOpen(false);
          }}
        />
      </VehicleImagePickerModal>
    </div>
  );
};

export default OfferBadgeImage;
