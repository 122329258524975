import { createContext, useContext } from 'react';
import DashboardStore from './DashboardStore';
import EFCStore from './EFCStore';
import LdaStore from './LdaStore';
import MiscTypesStore from './MiscTypesStore';
import OfferMarketingStore from './OfferMarketingStore';
import OfferTemplatesStore from './OfferTemplatesStore';
import OfferingsStore from './OfferingsStore';
import OffersStore from './OffersStore';
import RegionsStore from './RegionsStore';
import ReportsStore from './ReportsStore';
import StackedOffersStore from './StackedOffersStore';
import TdaImagesStore from './TdaImagesStore';
import TdaStore from './TdaStore';
import TokenStore from './TokenStore';
import TrimStore from './TrimStore';
import UserInfoStore from './UserInfoStore';
import VehiclesStore from './VehiclesStore';

const storesContext = createContext({
  dashboardStore: new DashboardStore(),
  efcStore: new EFCStore(),
  offeringsStore: new OfferingsStore(),
  offerMarketingStore: new OfferMarketingStore(),
  offersStore: new OffersStore(),
  tokenStore: new TokenStore(),
  tdaImagesStore: new TdaImagesStore(),
  userInfoStore: new UserInfoStore(),
  tdaStore: new TdaStore(),
  regionsStore: new RegionsStore(),
  ldaStore: new LdaStore(),
  trimStore: new TrimStore(),
  vehiclesStore: new VehiclesStore(),
  miscTypesStore: new MiscTypesStore(),
  offerTemplatesStore: new OfferTemplatesStore(),
  reportsStore: new ReportsStore(),
  stackedOffersStore: new StackedOffersStore(),
});

const useStores = () => useContext(storesContext);

export default useStores;
