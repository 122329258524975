import {
  Button,
  DefaultModal,
  IconMenu,
  IconMenuItem,
  Input,
  IOfferStatusError,
  OATIcon,
  OATStatusIcon,
  OfferStatus,
  OfferTypes,
  Tooltip,
  useComponentVisible,
  useInput,
} from 'oat-common-ui';
import { ReactNode, useState } from 'react';
import { Offer, OfferMarketing } from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import { getSeriesDisplayName } from '../utils';
import CopyOfferIdButton from './CopyOfferIdButton';
import renderNotes from './renderNotes';
import renderOfferDetails from './renderOfferDetails';
import styles from './styles.module.scss';

interface Props {
  offer: Offer;
  sortOrderVal: number;
  maxSortOrder: number;
  offerMarketFlagMap: Map<string, IOfferStatusError>;
  canPreview: boolean;
  renderOfferLink: (offer: Offer) => ReactNode;
  handleSortOffers: (currIndex: number, newIndex: number) => void;
  innerRef?: (element: HTMLElement | null) => any;
  disabled?: boolean;
  onCopy: (offer: Offer) => void;
  onDelete: (offer: Offer) => void;
  onChangeOfferingStatus: (offer: Offer, status: OfferStatus) => void;
  offerMarketing?: OfferMarketing[];
}

const OfferingTableRow = ({
  offer,
  sortOrderVal,
  maxSortOrder,
  offerMarketFlagMap,
  canPreview,
  renderOfferLink,
  handleSortOffers,
  innerRef,
  disabled,
  onCopy,
  onDelete,
  onChangeOfferingStatus,
  offerMarketing,
  ...rest
}: Props) => {
  const [sortOrder, sortOrderBindFn, sortOrderError] = useInput(sortOrderVal, { required: true, isWhole: true, min: 1, max: maxSortOrder });
  const [showDelModal, setShowDelModal] = useState('');
  const { offeringsStore } = useStores();

  const { isComponentVisible, setIsComponentVisible, ref } = useComponentVisible(false);

  const statuses = [
    OfferStatus.IN_PROGRESS,
    OfferStatus.READY_TO_PREVIEW,
    OfferStatus.PREVIEWED,
    OfferStatus.READY_TO_PUBLISH,
    OfferStatus.PAUSED,
    OfferStatus.PUBLISHED_PENDING_CHANGES,
  ];

  const showCopy = !offer.parentTdaOfferId;
  const showDelete = offer.offerType === OfferTypes.OTHER || !!offer.parentTdaOfferId;

  const handleOnCopy = () => {
    onCopy(offer);
  };

  const handleOnDelete = () => {
    onDelete(offer);
    setShowDelModal('');
  };

  const handleChangeOfferingStatus = (offer: Offer, status: OfferStatus) => {
    onChangeOfferingStatus(offer, status);
    setIsComponentVisible(false);
  };

  const canShowMenuItem = (status: string, item: string) => {
    let showMenuItem = true;
    if (
      status === item ||
      (status === OfferStatus.PAUSED && item === OfferStatus.PUBLISHED_PENDING_CHANGES) ||
      (!canPreview && (item === OfferStatus.READY_TO_PREVIEW || item === OfferStatus.PREVIEWED)) ||
      (item === OfferStatus.PUBLISHED_PENDING_CHANGES && !offer.isPublished)
    ) {
      showMenuItem = false;
    }
    return showMenuItem;
  };

  const renderOfferStatusButton = () => {
    const offerStatusError = offerMarketFlagMap?.get(offer.id || '');
    const statusError = offerStatusError?.error && !offerStatusError.success;

    return (
      <>
        <Button title={offer.status} id={`offer-item-${offer.id}`} className={styles.offerStatusBtn} onClick={() => setIsComponentVisible(!isComponentVisible)}>
          <OATStatusIcon status={offer.status} size={24} />
        </Button>
        {statusError && (
          <Tooltip message={offerStatusError.error || ''}>
            <OATIcon className={styles.red} icon="warning" size={24} />
          </Tooltip>
        )}
      </>
    );
  };

  const renderStatusMenu = (status: string) => {
    const offerStatusError = offerMarketFlagMap?.get(offer.id || '');
    const hasError = offerStatusError?.error;
    const menuPos = hasError ? styles.errorPos : styles.pos;

    return (
      <IconMenu ref={ref} className={menuPos}>
        {statuses.map((item, index) => {
          if (canShowMenuItem(status, item)) {
            return (
              <IconMenuItem
                key={`o-t-m-${index}`}
                id={`offer-item-menu-${index}`}
                renderIcon={<OATStatusIcon status={item} size={14} />}
                onClick={() => handleChangeOfferingStatus(offer, item)}
              >
                {item}
              </IconMenuItem>
            );
          }
          return null;
        })}
      </IconMenu>
    );
  };

  const renderOfferId = (offerId: string) => {
    return (
      <>
        <span className={styles.partialOfferId}>{offerId.substring(0, 5)}</span>
        <CopyOfferIdButton offerId={offerId} />
      </>
    );
  };

  return (
    <tr ref={innerRef} {...rest}>
      <td>
        {!disabled && (
          <div className={styles.dragIcon}>
            <OATIcon icon="drag" size={20} />
          </div>
        )}
      </td>
      <td>
        <Input
          className={styles.sortOrderInput}
          value={sortOrder}
          disabled={disabled}
          {...sortOrderBindFn}
          error={sortOrderError}
          onBlur={e => {
            if (e.target.value) {
              handleSortOffers(sortOrderVal, Number(e.target.value));
            }
          }}
        />
      </td>
      <td>{renderOfferLink(offer)}</td>
      <td>{getSeriesDisplayName(offer).year}</td>
      <td>{getSeriesDisplayName(offer).series}</td>
      <td>{renderOfferDetails(offer, offerMarketing)}</td>
      <td>{renderOfferId(offer.id)}</td>
      <td className={styles.notesCell}>{renderNotes(offeringsStore.currentOffering, offer)}</td>
      <td className={styles.actionCol}>
        {showCopy && (
          <Tooltip id={`copyIcon-${offer.id}`} message="Copy">
            <OATIcon className={styles.copyIcon} icon="copy" size={14} onClick={handleOnCopy} />
          </Tooltip>
        )}
        {showDelete && (
          <Tooltip id={`delIcon-${offer.id}`} message="Delete">
            <OATIcon className={styles.delIcon} icon="trash" size={14} onClick={() => setShowDelModal(offer.offerType === OfferTypes.OTHER ? 'Other' : 'Child')} />
          </Tooltip>
        )}
      </td>
      <td>
        <div className={styles.statusIcon}>
          {renderOfferStatusButton()}
          {isComponentVisible && renderStatusMenu(offer.status)}
        </div>
        {showDelModal && (
          <DefaultModal
            title={`Delete ${showDelModal} Offer`}
            message={`Deleting this offer will erase it, as well as any data you've entered for the offer`}
            open
            submitText="OK"
            onSubmit={handleOnDelete}
            onClose={() => setShowDelModal('')}
          />
        )}
      </td>
    </tr>
  );
};

export default OfferingTableRow;
