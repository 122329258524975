import { ApolloProvider } from '@apollo/client';
import { config } from '@fortawesome/fontawesome-svg-core';
import { AuthWrapper } from 'oat-common-ui';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import apolloClient from './apolloClient';
import authClient from './authClient';
import './index.css';
import reportWebVitals from './reportWebVitals';

// Disable automatic CSS insertion
config.autoAddCss = false;

ReactDOM.render(
  <AuthWrapper client={authClient}>
    <ApolloProvider client={apolloClient}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ApolloProvider>
  </AuthWrapper>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
