import { IMultiItem } from 'oat-common-ui';
import { OfferVehicle } from '../gql/generated';
import IOfferVehicle from '../models/IOfferVehicle';

const processVehiclesPayload = (vehicles: (OfferVehicle | null)[] | undefined, includedList: IMultiItem[], seriesYearOnly = false, otherOfferAllVehicles: OfferVehicle[]) => {
  const payload: IOfferVehicle[] = [];
  const vehiclesToCompare = vehicles ? vehicles : otherOfferAllVehicles || [];

  if (vehiclesToCompare) {
    vehiclesToCompare.forEach(veh => {
      if (veh) {
        const compareValue = seriesYearOnly ? `${veh.seriesYear} ${veh.series}` : `${veh.seriesYear} ${veh.modelCode}`;
        const found = includedList.find(listItem => listItem.id === compareValue);

        payload.push({
          isAdvertised: veh.isAdvertised,
          modelCode: veh.modelCode,
          series: veh.series,
          seriesYear: veh.seriesYear,
          vehicleDescription: veh.vehicleDescription,
          isInclusion: !!found,
        });
      }
    });
  }

  return payload;
};

export default processVehiclesPayload;
