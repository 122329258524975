import { faArchive, faCopy, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { DefaultModal, InputModal, ToggleButton, Tooltip } from 'oat-common-ui';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Template } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import styles from './styles.module.scss';

interface Props {
  template: Template;
  onDelete: (template: Template) => void;
  onArchive: (template: Template) => void;
  onStatusChange: (template: Template) => void;
  isArchived: boolean;
  isMasterTemplate: boolean;
}
const OfferTemplateMenuTableRow = ({ template, isArchived, onDelete, onArchive, onStatusChange, isMasterTemplate }: Props) => {
  const {
    userInfoStore: { isLexus },
  } = useStores();
  const [changeStatus, setChangeStatus] = useState(template.status === 'Published');
  const [showDelete, setShowDelete] = useState(false);
  const [showArchive, setShowArchive] = useState(false);
  const [input, setInput] = useState('');
  const history = useHistory();
  const { tdaCode, tdaName } = useUrlParams();

  const handleDelete = () => {
    if (input === `Delete ${template.name}`) {
      onDelete(template);
    } else {
      return undefined;
    }
  };

  const handleToCopy = () => {
    if (process.env.REACT_APP_FEATURE_OR_4594 === 'true' && isLexus()) {
      history.push(`/${tdaCode}/settings/offerTemplate/${isMasterTemplate ? 'master' : tdaName}/${template.id}?copy=true`);
    } else {
      history.push(`/${tdaCode}/settings/offerTemplate/${isLexus() ? 'lda' : 'tda'}/${template.id}?copy=true`);
    }

    window.scrollTo(0, 0);
  };

  const handleArchive = () => {
    onArchive(template);
    setShowArchive(!showArchive);
  };

  const handleEdit = () => {
    if (process.env.REACT_APP_FEATURE_OR_4594 === 'true' && isLexus()) {
      history.push(`/${tdaCode}/settings/offerTemplate/${isMasterTemplate ? 'master' : tdaName}/${template.id}?`);
    } else {
      history.push(`/${tdaCode}/settings/offerTemplate/${isLexus() ? 'lda' : 'tda'}/${template.id}?`);
    }

    window.scrollTo(0, 0);
  };

  const handleStatusChange = () => {
    setChangeStatus(!changeStatus);
    onStatusChange(template);
  };

  return (
    <tr>
      <td>{template.id.substr(0, 4)}</td>
      <td className={styles.ellipsis}>
        <Tooltip id={`name-${template.id}`} message={template.name}>
          {template.name}
        </Tooltip>
      </td>

      <td>{template.offerType}</td>
      <td className={styles.ellipsis}>
        {!template.tiers || !template.terms ? (
          'N/A'
        ) : (
          <Tooltip id={`tiers/terms-${template.id}`} message={`Tiers: ${template.tiers} | Terms: ${template.terms}`}>
            {`Tiers: ${template.tiers} | Terms: ${template.terms}`}
          </Tooltip>
        )}
      </td>
      {!isArchived && (
        <td>
          {template.status === 'Draft' ? (
            <Tooltip id={`draft-${template.id}`} message={'Please finish template process'}>
              {template.status}
            </Tooltip>
          ) : (
            <Tooltip id={`status-${template.id}`} message={!changeStatus ? 'Template will not be applied to incoming offers' : 'Template will be applied to incoming offers'}>
              <ToggleButton id="statusButton" selected={changeStatus} toggleSelected={handleStatusChange}></ToggleButton>
            </Tooltip>
          )}
        </td>
      )}

      <td className={styles.actionIcons}>
        {!isMasterTemplate && (
          <Tooltip id={`archive-${template.id}`} message={isArchived ? 'Move back to Template' : 'Archive'}>
            <FontAwesomeIcon
              icon={faArchive}
              className={template.status === 'Draft' ? styles.archiveDraftIcon : styles.archiveIcon}
              size="lg"
              onClick={() => setShowArchive(!showArchive)}
            ></FontAwesomeIcon>
          </Tooltip>
        )}

        {!isArchived && (
          <Tooltip id={`copy-${template.id}`} message={'Copy'}>
            <FontAwesomeIcon icon={faCopy} className={template.status === 'Draft' ? styles.draftIcon : styles.icon} size={'lg'} onClick={() => handleToCopy()}></FontAwesomeIcon>
          </Tooltip>
        )}
        <Tooltip id={`delete-${template.id}`} message={'Delete'}>
          <FontAwesomeIcon icon={faTrash} className={styles.icon} size="lg" onClick={() => setShowDelete(!showDelete)}></FontAwesomeIcon>
        </Tooltip>
        <Tooltip id={`edit-${template.id}`} message={'Edit'}>
          <FontAwesomeIcon icon={faPen} className={styles.icon} size="lg" onClick={() => handleEdit()}></FontAwesomeIcon>
        </Tooltip>

        {/* Delete Modal */}
        {showDelete && (
          <InputModal
            id={template.id}
            title={'Delete Template'}
            message={
              <>
                {`Are you sure you want to delete "${template.name}"? Type in `} <span className={styles.bold}>{`"Delete ${template.name}" `}</span>
                {'before confirmation.'}
              </>
            }
            onClose={() => setShowDelete(!showDelete)}
            onSubmit={() => handleDelete()}
            onBlur={e => {
              if (e.target.value) {
                setInput(e.target.value);
              }
            }}
            placeHolderText={`Type in Delete ${template.name}`}
            submitText="Confirm"
          />
        )}
        {/* Archive Modal */}
        {showArchive && !isArchived && (
          <DefaultModal
            open
            title={'Archive Template'}
            message={`Are you sure you want to archive "${template.name}"?`}
            submitText={'Archive Template'}
            onSubmit={handleArchive}
            onClose={() => setShowArchive(!showArchive)}
          />
        )}

        {/* Unarchive Modal */}
        {showArchive && isArchived && (
          <DefaultModal
            open
            title={'Move to Templates'}
            message={`Are you sure you want to unarchive "${template.name}"?`}
            submitText={'Unarchive Template'}
            onSubmit={handleArchive}
            onClose={() => setShowArchive(!showArchive)}
          />
        )}
      </td>
    </tr>
  );
};

export default observer(OfferTemplateMenuTableRow);
