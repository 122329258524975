import { AdminConstants } from 'oat-admin-common';
import {
  BlockInputLabel,
  Button,
  ButtonGroup,
  DnDType,
  Dropdown,
  DropdownItem,
  InlineInputLabel,
  Input,
  OATIcon,
  SnippetInput,
  Textarea,
  ToggleButton,
  useInput,
} from 'oat-common-ui';
import React, { useEffect, useState } from 'react';
import { FINANCE_OFFER_TYPE, FINANCE_OFFER_TYPE_2, MarketingSites } from '../../../constants/global';
import { Disclaimer, Restriction, Snippet, TemplateMarketing, TemplateMarketingBullet, TemplateMarketingDisclaimer, TemplateMarketingRestriction } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import MarketingDndItems from '../../OfferTabs/MarketingTab/components/MarketingDndItems';
import OfferBadgeImage from '../../OfferTabs/MarketingTab/OfferBadgeImage';
import useDnd from '../../OfferTabs/MarketingTab/useDnd';
import { getRibbonId, getRibbonName } from '../../OfferTabs/MarketingTab/utils';
import { TokenRibbon } from '../../TokenSettings/RibbonsComponent';
import Language from '../../TokenSettings/RibbonsComponent/Language';
import { secondaryOfferCardLabelOptions } from '../defaultLists';
import OfferTemplateTitle from '../OfferTemplateTitle';
import styles from '../styles.module.scss';
import { processCopiedBullets, processCopiedDisclaimers, processCopiedRestrictions, processDisclaimersRestrictions } from './util';

const { OfferTypes, SPECIAL_EDITION_BONUS_TYPE, TdaCodes } = AdminConstants;

interface Props {
  marketingData: TemplateMarketing | undefined;
  showSpanish: boolean;
  setShowSpanish: (val: boolean) => void;
  siteSelected: MarketingSites;
  offerType: string;
  snippets: Snippet[];
  tokens: string[];
  ribbons: TokenRibbon[];
  disclaimers: Disclaimer[];
  restrictions: Restriction[];
  lang: string;
  style: { display: string };
  isBAT?: boolean;
  copyEngData?: TemplateMarketing;
  copyEngBatData?: TemplateMarketing;
  copyEspBatData?: TemplateMarketing;
}

const labelWidth = 200;

const OfferDetailsForm = ({ marketingData, siteSelected, lang, isBAT, copyEngData, copyEngBatData, copyEspBatData, style, ...props }: Props) => {
  const {
    userInfoStore: { isLexus },
    offerTemplatesStore: { setActiveTemplateMarketingIndex, updateTemplateMarketing },
  } = useStores();
  const { tdaCode } = useUrlParams();
  const { offerType, ribbons, showSpanish, setShowSpanish, snippets, tokens, disclaimers, restrictions } = props;

  const isMainOffer =
    offerType === OfferTypes.APR ||
    offerType === OfferTypes.LEASE ||
    offerType === OfferTypes.CUSTOMER_CASH ||
    offerType === FINANCE_OFFER_TYPE ||
    offerType === FINANCE_OFFER_TYPE_2;
  const [primaryOfferCardLabel, , primaryOfferCardLabelError, setPrimaryOfferCardLabel] = useInput(marketingData?.primaryOfferCardLabel);

  useEffect(() => {
    if (offerType) {
      setPrimaryOfferCardLabel(isMainOffer ? offerType : marketingData?.primaryOfferCardLabel || '');
    }
  }, [isMainOffer, offerType, setPrimaryOfferCardLabel, marketingData?.primaryOfferCardLabel, isLexus]);

  const [secondaryOfferCardLabel, , secondaryOfferCardLabelError, setSecondaryOfferCardLabel] = useInput(marketingData?.secondaryOfferCardLabel);
  const [offerCardTitle, , offerCardTitleError, setOfferCardTitle] = useInput(marketingData?.offerCardTitle);
  const [offerCardAltText, , offerCardAltTextError, setOfferCardAltText] = useInput(marketingData?.offerCardAltText);
  const [offerDetailTitle, , offerDetailTitleError, setOfferDetailTitle] = useInput(marketingData?.offerDetailTitle);
  const [offerDetailAltText, , offerDetailAltTextError, setOfferDetailAltText] = useInput(marketingData?.offerDetailAltText);
  const [description, , descriptionError, setDescription] = useInput(marketingData?.description);
  const [imageDisclaimer, , imageDisclaimerError, setImageDisclaimer] = useInput(marketingData?.imageDisclaimer);

  const englishRibbons = ribbons.filter(item => item.language === Language.ENGLISH);
  const spanishRibbons = ribbons.filter(item => item.language === Language.SPANISH);
  const [offerRibbon, setOfferRibbon] = useState<DropdownItem>(getRibbonName(marketingData?.offerRibbonId || '', ribbons));

  const updateMarketingState = (marketingData: Partial<TemplateMarketing>) => {
    updateTemplateMarketing(!!isBAT, lang, marketingData);
  };

  // Bullets, Disclaimers, Restrictions
  const isDisabledFields = false;
  const defaultBD = [{ isStandard: false, link: '', sortOrder: isLexus() ? 1 : 2, text: '' }];

  const [templateBullets, setTemplateBullets, templateBulletFn] = useDnd(
    (marketingData?.bullets as TemplateMarketingBullet[]) || defaultBD,
    DnDType.BULLETS,
    isDisabledFields,
    !isLexus() ? 1 : undefined,
    newList => updateMarketingState({ bullets: newList as TemplateMarketingBullet[] }),
  );
  const [templateRestrictions, setTemplateRestrictions, templateRestrictionFn] = useDnd(
    (marketingData?.restrictions as TemplateMarketingRestriction[]) || [{ isStandard: false, link: '', sortOrder: 1, text: '' }],
    DnDType.RESTRICTIONS,
    isDisabledFields,
    undefined,
    newList => updateMarketingState({ restrictions: processDisclaimersRestrictions(newList) as TemplateMarketingRestriction[] }),
  );
  const [templateDisclaimers, setTemplateDisclaimers, templateDisclaimerFn] = useDnd(
    (marketingData?.disclaimers as TemplateMarketingDisclaimer[]) || defaultBD,
    DnDType.DISCLAIMERS,
    isDisabledFields,
    !isLexus() ? 1 : undefined,
    newList => updateMarketingState({ disclaimers: processDisclaimersRestrictions(newList) as TemplateMarketingDisclaimer[] }),
  );

  // Offer Badges
  const [offerBadgeImage1, , offerBadgeImageError1, setOfferBadgeImage1] = useInput(marketingData?.offerBadgeImage1);
  const [offerBadgeImage1DestinationUrlAltText, , offerBadgeImage1DestinationUrlAltTextError, setOfferBadgeImage1DestinationUrlAltText] = useInput(
    marketingData?.offerBadgeImage1DestinationUrlAltText,
  );
  const [offerBadgeImage1DestinationUrl, , offerBadgeImage1DestinationUrlError, setOfferBadgeImage1DestinationUrl] = useInput(marketingData?.offerBadgeImage1DestinationUrl);
  const [offerBadgeImage2, , offerBadgeImageError2, setOfferBadgeImage2] = useInput(marketingData?.offerBadgeImage2);
  const [offerBadgeImage2DestinationUrlAltText, , offerBadgeImage2DestinationUrlAltTextError, setOfferBadgeImage2DestinationUrlAltText] = useInput(
    marketingData?.offerBadgeImage2DestinationUrlAltText,
  );
  const [offerBadgeImage2DestinationUrl, , offerBadgeImage2DestinationUrlError, setOfferBadgeImage2DestinationUrl] = useInput(marketingData?.offerBadgeImage2DestinationUrl);
  const [offerBadgeImage3, , offerBadgeImageError3, setOfferBadgeImage3] = useInput(marketingData?.offerBadgeImage3);
  const [offerBadgeImage3DestinationUrlAltText, , offerBadgeImage3DestinationUrlAltTextError, setOfferBadgeImage3DestinationUrlAltText] = useInput(
    marketingData?.offerBadgeImage3DestinationUrlAltText,
  );
  const [offerBadgeImage3DestinationUrl, , offerBadgeImage3DestinationUrlError, setOfferBadgeImage3DestinationUrl] = useInput(marketingData?.offerBadgeImage3DestinationUrl);

  const copyValues = (copyData: TemplateMarketing) => {
    // used for both copy from English & copy from BAT
    const primaryOfferCardLabel = copyData?.primaryOfferCardLabel || '';
    const secondaryOfferCardLabel = copyData?.secondaryOfferCardLabel || '';
    const offerCardTitle = copyData?.offerCardTitle || '';
    const offerCardAltText = copyData?.offerCardAltText || '';
    const offerDetailTitle = copyData?.offerDetailTitle || '';
    const offerDetailAltText = copyData?.offerDetailAltText || '';
    const description = copyData?.description || '';
    const imageDisclaimer = copyData?.imageDisclaimer || '';
    const bullets = processCopiedBullets(copyData?.bullets as TemplateMarketingBullet[]);
    const disclaimers = processCopiedDisclaimers(copyData?.disclaimers as TemplateMarketingDisclaimer[]);
    const restrictions = processCopiedRestrictions(copyData?.restrictions as TemplateMarketingRestriction[]);
    const offerBadgeImage1 = copyData?.offerBadgeImage1 || '';
    const offerBadgeImage1DestinationUrlAltText = copyData?.offerBadgeImage1DestinationUrlAltText || '';
    const offerBadgeImage1DestinationUrl = copyData?.offerBadgeImage1DestinationUrl || '';
    const offerBadgeImage2 = copyData?.offerBadgeImage2 || '';
    const offerBadgeImage2DestinationUrlAltText = copyData?.offerBadgeImage2DestinationUrlAltText || '';
    const offerBadgeImage2DestinationUrl = copyData?.offerBadgeImage2DestinationUrl || '';
    const offerBadgeImage3 = copyData?.offerBadgeImage3 || '';
    const offerBadgeImage3DestinationUrlAltText = copyData?.offerBadgeImage3DestinationUrlAltText || '';
    const offerBadgeImage3DestinationUrl = copyData?.offerBadgeImage3DestinationUrl || '';

    setPrimaryOfferCardLabel(primaryOfferCardLabel);
    setSecondaryOfferCardLabel(secondaryOfferCardLabel);
    setOfferCardTitle(offerCardTitle);
    setOfferCardAltText(offerCardAltText);
    setOfferDetailTitle(offerDetailTitle);
    setOfferDetailAltText(offerDetailAltText);
    setDescription(description);
    setImageDisclaimer(imageDisclaimer);
    setTemplateBullets(bullets); // other bullets
    setTemplateDisclaimers(disclaimers); // other disclaimers
    setTemplateRestrictions(restrictions); // all restrictions
    setOfferBadgeImage1(offerBadgeImage1);
    setOfferBadgeImage1DestinationUrlAltText(offerBadgeImage1DestinationUrlAltText);
    setOfferBadgeImage1DestinationUrl(offerBadgeImage1DestinationUrl);
    setOfferBadgeImage2(offerBadgeImage2);
    setOfferBadgeImage2DestinationUrlAltText(offerBadgeImage2DestinationUrlAltText);
    setOfferBadgeImage2DestinationUrl(offerBadgeImage2DestinationUrl);
    setOfferBadgeImage3(offerBadgeImage3);
    setOfferBadgeImage3DestinationUrlAltText(offerBadgeImage3DestinationUrlAltText);
    setOfferBadgeImage3DestinationUrl(offerBadgeImage3DestinationUrl);

    updateMarketingState({
      primaryOfferCardLabel,
      secondaryOfferCardLabel,
      offerCardTitle,
      offerCardAltText,
      offerDetailTitle,
      offerDetailAltText,
      description,
      imageDisclaimer,
      bullets,
      disclaimers,
      restrictions,
      offerBadgeImage1,
      offerBadgeImage1DestinationUrlAltText,
      offerBadgeImage1DestinationUrl,
      offerBadgeImage2,
      offerBadgeImage2DestinationUrlAltText,
      offerBadgeImage2DestinationUrl,
      offerBadgeImage3,
      offerBadgeImage3DestinationUrlAltText,
      offerBadgeImage3DestinationUrl,
    });
  };

  const handleCopyFromEnglish = (copyData: TemplateMarketing) => {
    copyValues(copyData);
  };

  const handleCopyFromBat = (copyData: TemplateMarketing) => {
    copyValues(copyData);
    setOfferRibbon(getRibbonName(copyData?.offerRibbonId || '', ribbons));
    updateMarketingState({ offerRibbonId: copyData?.offerRibbonId || '' });
  };

  const returnSectionIdString = (section: string) => {
    return `${marketingData?.isBuyAToyota ? MarketingSites.BAT : MarketingSites.TCOM_OTHER}-${marketingData?.language}-${section}`;
  };

  return (
    <div id="details-form" style={style}>
      {!isLexus() && (
        <>
          <div className={styles.offerDetailsNavContainer}>
            {(marketingData?.language === 'es' || siteSelected !== MarketingSites.BAT) && (
              <ButtonGroup>
                {showSpanish && (
                  <Button
                    variant="primary"
                    onClick={() => {
                      if (copyEngData) {
                        handleCopyFromEnglish(copyEngData);
                      }
                    }}
                  >
                    Copy from English
                  </Button>
                )}
                {siteSelected !== MarketingSites.BAT && (
                  <Button
                    variant="primary"
                    onClick={() => {
                      if (copyEspBatData) {
                        handleCopyFromBat(copyEspBatData);
                      }

                      if (copyEngBatData) {
                        handleCopyFromBat(copyEngBatData);
                      }
                    }}
                  >
                    Copy from BAT
                  </Button>
                )}
              </ButtonGroup>
            )}
          </div>
          <InlineInputLabel vertical label="View in Spanish">
            <ToggleButton
              id="Spanish Toggle Button"
              selected={showSpanish}
              toggleSelected={() => {
                setActiveTemplateMarketingIndex(marketingData?.isBuyAToyota || false, marketingData?.language || '');
                setShowSpanish(!showSpanish);
              }}
            />
          </InlineInputLabel>
        </>
      )}

      {/* Offer Details */}
      <div className={styles.offerDetails} id={returnSectionIdString('offerDetailsSection')}>
        <OfferTemplateTitle sectionTitle="Offer Details" />
        <div className={styles.formInputs}>
          <ButtonGroup>
            <BlockInputLabel label="Primary Offer Card Label" className={styles.inputFull}>
              {/* when offertype dropdown is engaged from parent form, render a different input depending on the offertype */}
              {isMainOffer && <Input value={offerType} disabled />}
              {!isMainOffer && (
                <Input
                  id="primaryOfferCardLabel"
                  value={primaryOfferCardLabel}
                  error={primaryOfferCardLabelError}
                  onChange={e => {
                    setPrimaryOfferCardLabel(e.target.value);
                    updateMarketingState({ primaryOfferCardLabel: e.target.value });
                  }}
                />
              )}
            </BlockInputLabel>
            <BlockInputLabel label="Secondary Offer Card Label" className={styles.inputFull}>
              <Dropdown
                options={
                  tdaCode === TdaCodes.GST || isLexus() ? secondaryOfferCardLabelOptions : secondaryOfferCardLabelOptions.filter(option => option !== SPECIAL_EDITION_BONUS_TYPE)
                }
                id="Secondary Offer Card Label Dropdown"
                value={secondaryOfferCardLabel}
                onChange={(option: DropdownItem) => {
                  setSecondaryOfferCardLabel(option.value);
                  updateMarketingState({ secondaryOfferCardLabel: option.value });
                }}
                error={secondaryOfferCardLabelError}
              />
              <Button
                className={styles.trashIconBtn}
                id="delete-secondary-label"
                onClick={() => {
                  setSecondaryOfferCardLabel('');
                  updateMarketingState({ secondaryOfferCardLabel: '' });
                }}
              >
                <OATIcon icon="trash" size={18} />
              </Button>
            </BlockInputLabel>
          </ButtonGroup>
          <ButtonGroup>
            <BlockInputLabel label="Offer Card Title" className={styles.inputFull}>
              <SnippetInput
                id="Offer Card Title"
                value={offerCardTitle}
                snippets={snippets}
                tokens={tokens}
                error={offerCardTitleError}
                setInputValue={val => {
                  setOfferCardTitle(val);
                  updateMarketingState({ offerCardTitle: val });
                }}
                contentClass={styles.inputWidth}
              />
            </BlockInputLabel>
            <BlockInputLabel label="Offer Card Alt Text" className={styles.inputFull}>
              <Input
                value={offerCardAltText}
                error={offerCardAltTextError}
                onChange={e => {
                  setOfferCardAltText(e.target.value);
                  updateMarketingState({ offerCardAltText: e.target.value });
                }}
              />
            </BlockInputLabel>
          </ButtonGroup>
          <ButtonGroup>
            <BlockInputLabel label="Offer Detail Title" className={styles.inputFull}>
              <SnippetInput
                id="Offer Detail Title"
                value={offerDetailTitle}
                error={offerDetailTitleError}
                snippets={snippets}
                tokens={tokens}
                setInputValue={val => {
                  setOfferDetailTitle(val);
                  updateMarketingState({ offerDetailTitle: val });
                }}
                contentClass={styles.inputWidth}
              />
            </BlockInputLabel>
            <BlockInputLabel label="Offer Detail Alt Text" className={styles.inputFull}>
              <Input
                value={offerDetailAltText}
                error={offerDetailAltTextError}
                onChange={e => {
                  setOfferDetailAltText(e.target.value);
                  updateMarketingState({ offerDetailAltText: e.target.value });
                }}
              />
            </BlockInputLabel>
          </ButtonGroup>
          <BlockInputLabel label="Description:">
            <Textarea
              id="Offer Template Description"
              value={description}
              onChange={e => {
                setDescription(e.target.value);
                updateMarketingState({ description: e.target.value });
              }}
              error={descriptionError}
              tokens={tokens.map(name => ({ name, char: name }))}
              secondaryTokens={snippets.map(item => ({ name: item.name, char: item.text }))}
              className={styles.description}
            />
          </BlockInputLabel>
          <BlockInputLabel label="Image Disclaimer:">
            <Input
              value={imageDisclaimer}
              error={imageDisclaimerError}
              onChange={e => {
                setImageDisclaimer(e.target.value);
                updateMarketingState({ imageDisclaimer: e.target.value });
              }}
            />
          </BlockInputLabel>
          {!isLexus() && (
            <BlockInputLabel label="Offer Ribbon:">
              <Dropdown
                id="offer-ribbons"
                value={offerRibbon}
                onChange={(option: DropdownItem) => {
                  setOfferRibbon(option);
                  updateMarketingState({ offerRibbonId: getRibbonId(option.label, ribbons) });
                }}
                options={!showSpanish ? englishRibbons.map(item => item.name) : spanishRibbons.map(item => item.name)}
              />
              <Button
                className={styles.trashIconBtn}
                id="delete-secondary-label"
                onClick={() => {
                  setOfferRibbon({ value: '', label: '' });
                  updateMarketingState({ offerRibbonId: '' });
                }}
              >
                <OATIcon icon="trash" size={18} />
              </Button>
            </BlockInputLabel>
          )}
        </div>
      </div>
      <div className={styles.dividerLine} />

      {/* Bullets, Disclaimers, Restrictions */}
      <div className={styles.bullets} id={returnSectionIdString('bdrsSection')}>
        <OfferTemplateTitle sectionTitle="Bullets, Disclaimers, Restrictions" />
        <MarketingDndItems
          {...templateBulletFn}
          droppableId={DnDType.BULLETS}
          items={templateBullets || []}
          label="Bullet"
          isDisabledFields={isDisabledFields}
          site={siteSelected}
          offerType={offerType}
          isTemplate
        />
        <MarketingDndItems
          {...templateDisclaimerFn}
          droppableId={DnDType.DISCLAIMERS}
          items={templateDisclaimers || []}
          label="Disclaimer"
          isDisabledFields={isDisabledFields}
          modalItems={disclaimers}
          site={siteSelected}
          offerType={offerType}
          isTemplate
        />
        <MarketingDndItems
          {...templateRestrictionFn}
          droppableId={DnDType.RESTRICTIONS}
          items={templateRestrictions || []}
          label="Restriction"
          isDisabledFields={isDisabledFields}
          modalItems={restrictions}
          site={siteSelected}
          offerType={offerType}
          isTemplate
        />
      </div>
      <div className={styles.dividerLine} />

      {/* Offer Badges */}
      {!isLexus() && (
        <div className={styles.offerBadges} id={returnSectionIdString('offerBadgesSection')}>
          <OfferTemplateTitle sectionTitle="Offer Badge Images" />
          <OfferBadgeImage
            labelWidth={labelWidth}
            imagePathLabel="Offer Badge Image 1"
            imagePath={offerBadgeImage1}
            imagePathError={offerBadgeImageError1}
            imageAlt={offerBadgeImage1DestinationUrlAltText}
            imageAltLabel="Image 1 alt text"
            imageAltError={offerBadgeImage1DestinationUrlAltTextError}
            setImageAltValue={setOfferBadgeImage1DestinationUrlAltText}
            destinationUrl={offerBadgeImage1DestinationUrl}
            destinationUrlLabel="Destination URL 1"
            destinationUrlError={offerBadgeImage1DestinationUrlError}
            setDestinationUrlValue={setOfferBadgeImage1DestinationUrl}
            setImagePath={setOfferBadgeImage1}
            isDisabledFields={isDisabledFields}
            updateMarketingState={updateMarketingState}
            badgeFieldName="offerBadgeImage1"
            destinationUrlFieldName="offerBadgeImage1DestinationUrl"
            destinationUrlAltFieldName="offerBadgeImage1DestinationUrlAltText"
          />
          <OfferBadgeImage
            labelWidth={labelWidth}
            imagePathLabel="Offer Badge Image 2"
            imagePath={offerBadgeImage2}
            imagePathError={offerBadgeImageError2}
            imageAlt={offerBadgeImage2DestinationUrlAltText}
            imageAltLabel="Image 2 alt text"
            imageAltError={offerBadgeImage2DestinationUrlAltTextError}
            setImageAltValue={setOfferBadgeImage2DestinationUrlAltText}
            destinationUrl={offerBadgeImage2DestinationUrl}
            destinationUrlLabel="Destination URL 2"
            destinationUrlError={offerBadgeImage2DestinationUrlError}
            setDestinationUrlValue={setOfferBadgeImage2DestinationUrl}
            setImagePath={setOfferBadgeImage2}
            isDisabledFields={isDisabledFields}
            updateMarketingState={updateMarketingState}
            badgeFieldName="offerBadgeImage2"
            destinationUrlFieldName="offerBadgeImage2DestinationUrl"
            destinationUrlAltFieldName="offerBadgeImage2DestinationUrlAltText"
          />
          <OfferBadgeImage
            labelWidth={labelWidth}
            imagePathLabel="Offer Badge Image 3"
            imagePath={offerBadgeImage3}
            imagePathError={offerBadgeImageError3}
            imageAlt={offerBadgeImage3DestinationUrlAltText}
            imageAltLabel="Image 3 alt text"
            imageAltError={offerBadgeImage3DestinationUrlAltTextError}
            setImageAltValue={setOfferBadgeImage3DestinationUrlAltText}
            destinationUrl={offerBadgeImage3DestinationUrl}
            destinationUrlLabel="Destination URL 3"
            destinationUrlError={offerBadgeImage3DestinationUrlError}
            setDestinationUrlValue={setOfferBadgeImage3DestinationUrl}
            setImagePath={setOfferBadgeImage3}
            isDisabledFields={isDisabledFields}
            updateMarketingState={updateMarketingState}
            badgeFieldName="offerBadgeImage3"
            destinationUrlFieldName="offerBadgeImage3DestinationUrl"
            destinationUrlAltFieldName="offerBadgeImage3DestinationUrlAltText"
          />
        </div>
      )}
    </div>
  );
};

export default OfferDetailsForm;
