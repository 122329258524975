import { APRCalcFns } from 'oat-admin-common';
import { Input, stringToNumber, useInput } from 'oat-common-ui';
import React, { useEffect, useState } from 'react';
import { FEATURE_OR_4616 } from '../../../../../constants/global';
import { OfferRate } from '../../../../../gql/generated';
import InlineSpanToggle from '../InlineSpanToggle';
import styles from './styles.module.scss';

const { calculateDollarsPerThousand } = APRCalcFns;

interface RateTierRowProps {
  rateTier: OfferRate;
  updateRates: (offerRate: OfferRate) => void;
  updateCounter: number;
}

const calculateDpt = (rate: number | undefined | null, term: number) => {
  return rate !== undefined && rate !== null && term ? calculateDollarsPerThousand(rate, term) : '';
};

const RateTierRow = ({ rateTier, updateRates, updateCounter }: RateTierRowProps) => {
  const [subventionRate, subventionRateBindFn, subventionRateError, setSubventionRate] = useInput(rateTier?.subventionRate, { isDecimal: true, required: true, min: 0 });
  const [dollarPerThousand, dollarPerThousandFn, dollarPerThousandError, setDollarPerThousand] = useInput(
    rateTier?.dollarPerThousand || calculateDpt(rateTier?.subventionRate, rateTier.term),
    { min: 0 },
  );
  const [extraCashAmount, extraCashAmountBindFn, extraCashAmountError] = useInput(rateTier?.extraCashAmount, { isWhole: true, min: 0 });
  const [subventionCash, subventionCashBindFn, subventionCashError, setSubventionCash] = useInput(rateTier?.subventionCash, { isWhole: true, min: 0 });
  const [counter, setCounter] = useState(updateCounter);

  // watches updateCounter to reset values back to national
  useEffect(() => {
    if (updateCounter !== counter) {
      setSubventionRate(rateTier.approvedSubventionRate?.toString() || '');
      setSubventionCash(rateTier.approvedSubventionCash?.toString() || '');
      setDollarPerThousand(calculateDpt(rateTier?.approvedSubventionRate, rateTier.term));
      setCounter(updateCounter);
    }
  }, [rateTier, counter, updateCounter, setDollarPerThousand, setSubventionCash, setSubventionRate]);

  const handleOnChange = (data: Partial<OfferRate>) => {
    updateRates({
      ...rateTier,
      ...data,
    });
  };

  const handleOnTermClick = () => {
    updateRates({
      ...rateTier,
      isAdvertised: true,
    });
  };

  // const handleReCalculation = (rate: string, term: string) => {
  const handleRecalculation = (e: React.ChangeEvent<HTMLInputElement>) => {
    subventionRateBindFn.onChange(e);
    const rate = e.currentTarget.value;
    const term = rateTier.term?.toString() || '';

    const dpt = calculateDpt(Number(rate), Number(term));
    setDollarPerThousand(dpt);

    handleOnChange({ subventionRate: stringToNumber(e.currentTarget.value), dollarPerThousand: stringToNumber(dpt) });
  };

  const approvedSubventionRate = rateTier.approvedSubventionRate || subventionRate;
  const subventionRateModError = subventionRateError || approvedSubventionRate < subventionRate;

  const approvedSubventionCash = rateTier.approvedSubventionCash || subventionCash;
  const subventionCashModError = subventionCashError || approvedSubventionCash > subventionCash;

  return (
    <div className={styles.termRow}>
      <InlineSpanToggle className={styles.termCell} label={rateTier.term?.toString() || ''} onClick={handleOnTermClick} advertised={rateTier.isAdvertised || false} />
      <div className={styles.cell}>
        <Input
          type="number"
          darkTheme
          className={styles.input}
          inputWrapperClass={styles.inputWrapper}
          value={subventionRate}
          error={subventionRateModError}
          {...subventionRateBindFn}
          onChange={handleRecalculation}
        />
      </div>
      <div className={styles.cell}>
        <Input
          type="number"
          darkTheme
          disabled
          className={styles.input}
          inputWrapperClass={styles.inputWrapper}
          dollarSign
          value={dollarPerThousand}
          {...dollarPerThousandFn}
          onChange={e => {
            dollarPerThousandFn.onChange(e);
            handleOnChange({ dollarPerThousand: stringToNumber(e.currentTarget.value) });
          }}
          error={dollarPerThousandError}
        />
      </div>
      {!FEATURE_OR_4616 && (
        <div className={styles.cell}>
          <Input
            type="number"
            darkTheme
            className={styles.input}
            inputWrapperClass={styles.inputWrapper}
            dollarSign
            value={extraCashAmount}
            {...extraCashAmountBindFn}
            onChange={e => {
              extraCashAmountBindFn.onChange(e);
              handleOnChange({ extraCashAmount: stringToNumber(e.currentTarget.value) });
            }}
            error={extraCashAmountError}
          />
        </div>
      )}
      <div className={styles.cell}>
        <Input
          type="number"
          darkTheme
          className={styles.input}
          inputWrapperClass={styles.inputWrapper}
          dollarSign
          value={subventionCash}
          error={subventionCashModError}
          {...subventionCashBindFn}
          onChange={e => {
            subventionCashBindFn.onChange(e);
            handleOnChange({ subventionCash: stringToNumber(e.currentTarget.value) });
          }}
        />
      </div>
    </div>
  );
};

export default RateTierRow;
