import { observer } from 'mobx-react-lite';
import { useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import { Template, useArchiveTemplateMutation, useDeleteTemplateMutation, useUpdateTemplateStatusMutation } from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import OfferTemplateMenuLayout from './OfferTemplateMenuLayout';

interface Props {
  isMasterTemplate: boolean;
}

const OfferTemplateMenuComponent = ({ isMasterTemplate }: Props) => {
  const { offerTemplatesStore } = useStores();

  const { error } = useToast();

  const [deleteTemplate] = useDeleteTemplateMutation();
  const [archiveTemplate] = useArchiveTemplateMutation();
  const [updateStatusTemplate] = useUpdateTemplateStatusMutation();

  const activeTemplates = offerTemplatesStore.activeTemplates;
  const archivedTemplates = offerTemplatesStore.archivedTemplates;

  const handleDeleteTemplate = async (template: Template) => {
    try {
      await trackPromise(deleteTemplate({ variables: { templateId: template.id, rev: template.rev } }));
      offerTemplatesStore.deleteTemplate(template.id, template.isArchived);
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleArchiveTemplate = async (template: Template) => {
    try {
      const res = await trackPromise(archiveTemplate({ variables: { templateId: template.id, rev: template.rev, archive: !template.isArchived } }));
      const resTemplate = res.data?.archiveTemplate.template;
      if (resTemplate) {
        return offerTemplatesStore.archiveTemplate(resTemplate);
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleStatusChange = async (template: Template) => {
    try {
      const res = await trackPromise(
        updateStatusTemplate({
          variables: {
            templateId: template.id,
            rev: template.rev,
            status: template.status === 'Published' ? 'Unpublished' : 'Published',
          },
        }),
      );
      const resTemplate = res.data?.updateTemplateStatus.template;
      if (resTemplate) {
        offerTemplatesStore.updateTemplateStatus(resTemplate);
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <>
      <OfferTemplateMenuLayout
        activeTemplates={activeTemplates}
        archivedTemplates={archivedTemplates}
        onDelete={handleDeleteTemplate}
        onArchive={handleArchiveTemplate}
        onStatusChange={handleStatusChange}
        isMasterTemplate={isMasterTemplate}
      ></OfferTemplateMenuLayout>
    </>
  );
};

export default observer(OfferTemplateMenuComponent);
