/**
 * Moves item into new index within an array
 * @param currIndex M
 * @param newIndex
 * @param list
 * @returns new array
 */
const moveArrayItem = (currIndex: number, newIndex: number, list: any[]) => {
  const maxIndex = list.length - 1;
  const nIndex = newIndex > maxIndex ? maxIndex : newIndex;
  const cIndex = currIndex > maxIndex ? maxIndex : currIndex;

  const copyList = list.slice();
  const itemToMove = copyList[cIndex];

  if (nIndex <= cIndex) {
    copyList.splice(nIndex, 0, itemToMove);
    copyList.splice(cIndex + 1, 1);
  }

  if (nIndex > cIndex) {
    copyList.splice(nIndex + 1, 0, itemToMove);
    copyList.splice(cIndex, 1);
  }

  return copyList;
};

export default moveArrayItem;
