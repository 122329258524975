import { runInAction } from 'mobx';

class MiscTypesStore {
  isLoaded = false;
  miscTypes: { value: string; label: string }[] = [];

  setMiscTypes = (miscTypes: string[]) => {
    runInAction(() => {
      this.miscTypes = miscTypes.map(item => ({ value: item, label: item }));
      this.isLoaded = true;
    });
  };
}

export default MiscTypesStore;
