import { AdminConstants } from 'oat-admin-common';
import { uuidv4 } from 'oat-common-ui';
import { FINANCE_OFFER_TYPE } from '../../../constants/global';
import { TemplateMarketing, TemplateMarketingBullet, TemplateMarketingDisclaimer, TemplateMarketingRestriction } from '../../../gql/generated';
import { OfferMarketingItem } from '../../OfferTabs/MarketingTab/useDnd';
import { formatBulletData, formatDisclaimerData, formatRestrictionData } from '../../OfferTabs/util';

const { OfferTypes } = AdminConstants;

export const removeDuplicates = (arr: any[]) =>
  arr.filter((item, index, array) => {
    return array.findIndex(arrayItem => arrayItem.uid === item.uid || (arrayItem.sortOrder === item.sortOrder && arrayItem.text === item.text)) === index;
  });

export const formatTemplateMarketingData = (offerType: string, marketingData: TemplateMarketing[], isLexus: boolean, copy?: boolean) => {
  if (isLexus) {
    const data = { ...marketingData.find(marketing => !marketing.isBuyAToyota && marketing.language === 'en') };

    return {
      ...data,
      id: copy ? '' : data?.id,
      rev: copy ? '' : data?.rev,
      primaryOfferCardLabel: getDefaultPrimaryOfferCardLabel(offerType, true) ?? data?.primaryOfferCardLabel,
      bullets: data?.bullets ? formatBulletData(data.bullets as OfferMarketingItem[], true) : undefined,
      restrictions: data?.restrictions ? formatRestrictionData(data.restrictions as OfferMarketingItem[]) : undefined,
      disclaimers: data?.disclaimers ? formatDisclaimerData(data.disclaimers as OfferMarketingItem[], true) : undefined,
      created: undefined,
      createdBy: undefined,
      isBuyAToyota: false,
      language: 'en',
      updated: undefined,
      updatedBy: undefined,
      __typename: undefined,
    };
  }

  return marketingData.map(data => {
    const payload: TemplateMarketing = {
      ...data,
      id: copy ? '' : data.id,
      rev: copy ? '' : data.rev,
      primaryOfferCardLabel: offerType === OfferTypes.APR || offerType === OfferTypes.LEASE || offerType === OfferTypes.CUSTOMER_CASH ? offerType : data.primaryOfferCardLabel,
      bullets: data.bullets ? (formatBulletData(data.bullets as OfferMarketingItem[], true) as TemplateMarketingBullet[]) : undefined,
      restrictions: data.restrictions ? formatRestrictionData(data.restrictions as OfferMarketingItem[]) : undefined,
      disclaimers: data.disclaimers ? formatDisclaimerData(data.disclaimers as OfferMarketingItem[], true) : undefined,
      created: undefined,
      createdBy: undefined,
      updated: undefined,
      updatedBy: undefined,
      __typename: undefined,
    };

    return payload;
  });
};

export const processTiersTerms = (selectedItems: string[]) => {
  return selectedItems.join(',');
};

export const processDisclaimersRestrictions = (items: OfferMarketingItem[]) => {
  return items.map(item => ({
    ...item,
    tdaRestrictionFileId: item.file || '',
    tdaRestrictionFileName: item.file || '',
  }));
};

export const getCopyDataFromSource = (currentTemplateMarketing: TemplateMarketing[] | undefined, isLexus: boolean) => {
  let englishBatData = undefined;
  let spanishBatData = undefined;
  let englishTcomData = undefined;
  let spanishTcomData = undefined;

  if (isLexus) {
    return {
      englishTcomData: currentTemplateMarketing ? currentTemplateMarketing[0] : undefined,
      spanishBatData,
      englishBatData,
      spanishTcomData,
    };
  }

  if (currentTemplateMarketing) {
    currentTemplateMarketing.forEach(data => {
      if (data.language === 'en' && data.isBuyAToyota) {
        englishBatData = data;
      }

      if (data.language === 'es' && data.isBuyAToyota) {
        spanishBatData = data;
      }

      if (data.language === 'en' && !data.isBuyAToyota) {
        englishTcomData = data;
      }

      if (data.language === 'es' && !data.isBuyAToyota) {
        spanishTcomData = data;
      }
    });
  }

  return {
    englishBatData,
    spanishBatData,
    englishTcomData,
    spanishTcomData,
  };
};

export const processCopiedBullets = (bullets: TemplateMarketingBullet[]) => {
  return bullets.map((item, i) => ({
    ...item,
    uid: uuidv4(),
    isStandard: item?.isStandard || false,
    link: item?.copyLinkToToyota ? item?.link || '' : '',
    sortOrder: item?.sortOrder || i + 1,
    text: item?.text || '',
  }));
};

export const processCopiedDisclaimers = (disclaimers: TemplateMarketingDisclaimer[]) => {
  return disclaimers?.map((item, i) => ({
    ...item,
    uid: uuidv4(),
    isStandard: item?.isStandard || false,
    tdaDisclaimerFileId: item?.tdaDisclaimerFileId || item?.text,
    tdaDisclaimerFileName: item?.tdaDisclaimerFileName || item?.text,
    sortOrder: item?.sortOrder || i + 1,
    text: item?.text || '',
  }));
};

export const processCopiedRestrictions = (restrictions: TemplateMarketingRestriction[]) => {
  return restrictions?.map((item, i) => ({
    ...item,
    uid: uuidv4(),
    sortOrder: item?.sortOrder || i,
    text: item?.text || '',
  }));
};

export const getDefaultPrimaryOfferCardLabel = (offerType: string, isLexus: boolean, value?: string | null) => {
  if (value) {
    return value;
  }
  if (offerType === OfferTypes.APR && isLexus) {
    return FINANCE_OFFER_TYPE;
  }
  if (
    offerType === OfferTypes.APR || //
    offerType === OfferTypes.LEASE ||
    offerType === OfferTypes.CUSTOMER_CASH
  ) {
    return offerType;
  }
  return undefined;
};
