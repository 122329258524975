import React, { useState } from 'react';
import { TokenType } from '../types';
import styles from './styles.module.scss';
import { Modal, ModalHeader, ModalBody, Input, Textarea, ModalFooter, ModalError, ButtonGroup, Button } from 'oat-common-ui';

interface Props {
  group: string;
  tokens: string[];
  disabled: boolean;
  dataTokens?: TokenType[];
  token?: TokenType;
  error?: string;
  onClose: () => void;
  onSubmit: (token: string, content: string) => void;
}

const TokenSettingsModal = ({ group, token, tokens, disabled, dataTokens, error, onClose, onSubmit }: Props) => {
  const [nameInput, setNameInput] = useState(token?.name || '');
  const [textInput, setTextInput] = useState(token?.text || '');

  const handleOnSubmit = () => {
    if (nameInput && textInput) {
      onSubmit(nameInput, textInput);
    }
  };

  return (
    <Modal isOpen onClose={onClose}>
      <ModalHeader>{group}</ModalHeader>
      <ModalBody>
        <Input
          id="ts-modal-token-input"
          value={nameInput}
          className={styles.tokenInput}
          onChange={e => {
            setNameInput(e.currentTarget.value);
          }}
          error={!nameInput}
          errorMsg="Name required"
          autoFocus
          inputWrapperClass={styles.tokenInputWrapper}
        />
        <Textarea
          id="ts-modal-content-input"
          value={textInput}
          tokens={tokens.map(name => ({ name, char: name }))}
          secondaryTokens={dataTokens?.map(item => ({ name: `${item.name}`, char: item.text }))}
          className={styles.contentInput}
          onChange={e => {
            setTextInput(e.target.value);
          }}
          error={!textInput}
          errorMsg="Text required"
        />
      </ModalBody>
      <ModalFooter>
        {error && <ModalError errorMessage={error} />}
        <ButtonGroup>
          <Button id="ts-modal-cancel-btn" variant="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button id="ts-modal-submit-btn" variant="primary" onClick={handleOnSubmit} disabled={disabled}>
            {token ? 'Save' : 'Create'}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default TokenSettingsModal;
