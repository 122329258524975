import { observer } from 'mobx-react-lite';
import { OfferTypes, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { CashMiscExtraCashTypes } from '../../../../constants/global';
import { Offer, useUpdateOfferMutation } from '../../../../gql/generated';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import buildOfferUrl from '../../../../utils/buildOfferUrl';
import CashMiscFinancialForm from '../CashMiscFinancialForm';
import FinancialTabComponentBase from '../FinancialTabComponentBase';

const CashFinancialTabComponent = () => {
  const {
    userInfoStore: { userInfo, isLexus },
    offersStore: { currentOffer, updateOffer, setCurrentOffer },
    offeringsStore: { currentOffering },
  } = useStores();
  const { lang, tdaCode, site } = useUrlParams();
  const [redirect, setRedirect] = useState('');
  const { error } = useToast();

  const [updateOfferMutation] = useUpdateOfferMutation();

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const handleOnSubmit = async (
    cashOfferAmount: number,
    extraCashOfferAmount: number | null,
    extraCashOfferAmountLabel: string,
    extraCashOfferAmountLabelSpanish: string,
    fundingSource: string,
    back: boolean,
    next: boolean,
    extraCashType: string,
    tdaPackage?: string,
  ) => {
    try {
      const res = await updateOfferMutation({
        variables: {
          input: {
            id: currentOffer?.id || '',
            rev: currentOffer?.rev || '',
            combinedPerUnitCost: cashOfferAmount,
            extraCashAmount: extraCashOfferAmount,
            extraCashLabel: extraCashOfferAmountLabel,
            extraCashLabelSpanish: extraCashOfferAmountLabelSpanish,
            extraCashOfferType: extraCashType,
            tdaPackage,
            fundingSource,
          },
        },
      });
      const newOffer = res.data?.updateOffer.offer as Offer;
      updateOffer(currentOffer?.id || '', newOffer);
      setCurrentOffer(newOffer);

      if (back) {
        setRedirect(buildOfferUrl(currentOffering?.id, tdaCode, 'cash', 'info', lang, currentOffer?.id, site));
      }

      if (next) {
        setRedirect(buildOfferUrl(currentOffering?.id, tdaCode, 'cash', 'marketing', lang, currentOffer?.id, site));
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <FinancialTabComponentBase offerType={OfferTypes.CUSTOMER_CASH}>
      <CashMiscFinancialForm
        offer={currentOffer}
        isMisc={false}
        extraCashTypes={CashMiscExtraCashTypes(userInfo.brand, tdaCode)}
        showSpanish={!isLexus()}
        onSubmit={handleOnSubmit}
      />
    </FinancialTabComponentBase>
  );
};

export default observer(CashFinancialTabComponent);
