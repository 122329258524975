import clsx from 'clsx';
import React from 'react';
import styles from './styles.module.scss';

interface Props {
  width?: number;
  advertised?: boolean;
  label: string;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const defaultWidth = 70;

const InlineSpanToggle = ({ label, width, advertised, className, onClick }: Props) => {
  const getStyles = () => {
    return { width: `${width || defaultWidth}px` };
  };

  return (
    <button style={getStyles()} type="button" onClick={onClick} className={clsx(styles.inlineSpanToggle, className, advertised && styles.selected)}>
      {label}
    </button>
  );
};

export default InlineSpanToggle;
