import React from 'react';
import { getEfcImageFullPath } from '../../../../../utils/getEfcImageFullPath';
import styles from './styles.module.scss';

interface ConfirmProps {
  localImagePath: string;
}

const ConfirmPage = ({ localImagePath }: ConfirmProps) => {
  return <img className={styles.confirmImg} src={getEfcImageFullPath(localImagePath)} alt="" />;
};

export default ConfirmPage;
