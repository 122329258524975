import { Button, ButtonGroup, ModalBody, ModalFooter } from 'oat-common-ui';
import React, { FC } from 'react';
import useStores from '../../../../../stores/useStores';
import styles from './styles.module.scss';
import useTeamsitePicker, { TreeNode } from './useTeamsitePicker';

interface TSImageModal {
  onSelect: (imageSrc: string) => void;
  closeModal: () => void;
}

const TeamsitePickerModal: FC<TSImageModal> = ({ onSelect, closeModal }) => {
  const {
    tdaImagesStore: { imagesByTda },
  } = useStores();

  const { activeFolder, selectFolder, parentFolderPath, selectedImage, setSelectedImage, imgSrc } = useTeamsitePicker(imagesByTda);

  return (
    <>
      <ModalBody className={styles.teamsiteModalContent}>
        <div className={styles.teamsiteImgSelectorWrapper}>
          <p className={styles.imagePath}>
            <span>{activeFolder.getFullPath() === '/' ? 'Images' : activeFolder.getDisplayPath()}</span>
          </p>
          <ul className={styles.teamsiteImgSelector}>
            {parentFolderPath !== undefined && (
              <li
                id="parent-folder-link"
                onClick={() => {
                  setSelectedImage('');
                  selectFolder(parentFolderPath);
                }}
              >
                ..
              </li>
            )}
            {activeFolder.children.map((child: TreeNode) => (
              <li
                id={`${child.name}-link`}
                key={child.name}
                className={selectedImage === child.getFullPath() ? styles.selected : undefined}
                onClick={() => {
                  if (child.isLastNode()) {
                    setSelectedImage(child.getFullPath());
                  } else {
                    setSelectedImage('');
                    selectFolder(child.getFullPath());
                  }
                }}
              >
                {child.name}
              </li>
            ))}
          </ul>
        </div>
        <div className={styles.teamsiteImgWrapper}>
          <img src={imgSrc} alt="" />
        </div>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup className={styles.buttonWrapper}>
          <Button
            id="select-tda-image"
            variant="primary"
            onClick={() => {
              onSelect(selectedImage);
              closeModal();
            }}
            disabled={!selectedImage}
          >
            Select
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
};

export default TeamsitePickerModal;
