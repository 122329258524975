import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import useStores from '../../../stores/useStores';
import styles from './styles.module.scss';
import { getActiveIndexFromScroll, getScrollTopFromClick } from './utils';

interface Props {
  navList: string[];
  returnTemplateIdString: (section: string) => string;
  addIndex?: boolean;
  addActiveBg?: boolean;
  className?: string;
  setValue?: (val: string) => void;
  main?: boolean;
  scrollTo?: boolean;
}

const OfferTemplateNav = ({ navList, returnTemplateIdString, addIndex, setValue, addActiveBg, main, className, scrollTo }: Props) => {
  const {
    userInfoStore: { isLexus },
  } = useStores();

  const [activeIndex, setActiveIndex] = useState(0);
  const navClass = main ? styles.offerTabNavMain : styles.batTcomTabNav;

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  const handleScroll = () => {
    let scrollTop = window.scrollY;

    if (main) {
      setActiveIndex(getActiveIndexFromScroll(isLexus(), scrollTop, returnTemplateIdString));
    }
  };

  const handleActiveNav = (index: number, item: string) => {
    setActiveIndex(index);

    if (setValue) {
      setValue(item);
    }
  };

  const handleScrollOnClick = (index: number) => {
    window.scrollTo({
      top: getScrollTopFromClick(index, isLexus(), returnTemplateIdString),
      behavior: 'smooth',
    });
  };

  const handleActive = (index: number, item: string, useHandleScrollOnClick?: boolean) => {
    if (!useHandleScrollOnClick || !scrollTo) {
      handleActiveNav(index, item);
    }

    if (scrollTo && useHandleScrollOnClick) {
      handleScrollOnClick(index);
    }
  };

  return (
    <div className={clsx(navClass, className)}>
      <ul>
        <div className={styles.navItemsWrapper}>
          {navList.map((item, i) => {
            const activeClass = activeIndex === i ? (addActiveBg ? styles.activeNavWithBg : styles.activeNav) : '';
            const activeNavIndexClass = activeIndex === i ? styles.activeNavIndex : '';

            return (
              <li key={i} className={clsx(styles.navItem, activeClass)} onClick={() => handleActive(i, item, true)}>
                {addIndex && <div className={clsx(styles.navIndex, activeNavIndexClass)}>{i + 1}</div>} {item}
              </li>
            );
          })}
        </div>
      </ul>
    </div>
  );
};

export default OfferTemplateNav;
