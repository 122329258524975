import { observer } from 'mobx-react-lite';
import { OfferTypes, useToast } from 'oat-common-ui';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Offer, UpdateOfferInput, useUpdateOfferMutation } from '../../../../gql/generated';
import useUrlParams from '../../../../hooks/useUrlParams';
import useStores from '../../../../stores/useStores';
import buildOfferUrl from '../../../../utils/buildOfferUrl';
import getLeasePayload from '../../../../utils/getLeasePayload';
import FinancialTabComponentBase from '../FinancialTabComponentBase';
import LeaseFinancialForm from './LeaseFinancialForm';

const LeaseFinancialTabComponent = () => {
  const {
    offersStore: { currentOffer, updateOffer, setCurrentOffer },
    offeringsStore: { currentOffering },
  } = useStores();
  const { lang, tdaCode, site } = useUrlParams();
  const [redirect, setRedirect] = useState('');
  const { error } = useToast();

  const [updateOfferMutation] = useUpdateOfferMutation();

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const handleOnSubmit = async (id: string, rev: string, payload: Partial<UpdateOfferInput>, back: boolean, next: boolean) => {
    try {
      const res = await updateOfferMutation({
        variables: {
          input: {
            ...getLeasePayload(payload),
            id,
            rev,
          },
        },
      });
      const newOffer = res.data?.updateOffer.offer as Offer;
      updateOffer(currentOffer?.id || '', newOffer);
      setCurrentOffer(newOffer);

      if (back) {
        setRedirect(buildOfferUrl(currentOffering?.id, tdaCode, 'lease', 'info', lang, currentOffer?.id, site));
      }

      if (next) {
        setRedirect(buildOfferUrl(currentOffering?.id, tdaCode, 'lease', 'marketing', lang, currentOffer?.id, site));
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  return (
    <FinancialTabComponentBase offerType={OfferTypes.LEASE}>{currentOffer && <LeaseFinancialForm offer={currentOffer} onSubmit={handleOnSubmit} />}</FinancialTabComponentBase>
  );
};

export default observer(LeaseFinancialTabComponent);
