import { OfferTabBodyLayout } from 'oat-common-ui';
import React from 'react';
import { MarketingSites } from '../../../constants/global';
import { OfferMarketingInput, Snippet } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import Language from '../../TokenSettings/RibbonsComponent/Language';
import MarketingForm from './MarketingForm';
import { getCopyDataFromSource } from './utils';
import { GraphQLError } from 'graphql';

interface Props {
  isLexus: boolean;
  onSubmit: (toBoth: boolean, payload: OfferMarketingInput, back: boolean) => void;
  snippets: Snippet[];
  tokens: string[];
  offerType: string;
  setLangParam: (langParam: string) => void;
  httpError: GraphQLError | null;
}

const MarketingTab = ({ isLexus, snippets, tokens, onSubmit, offerType, setLangParam, httpError }: Props) => {
  const {
    tokenStore: { ribbons },
    offersStore: { currentOffer },
    offeringsStore: { currentOffering },
    offerMarketingStore: { currentOfferMarketing },
  } = useStores();

  const { site, lang, tdaCode } = useUrlParams();

  // englishTcomData is also lexus data
  const { englishBatData, spanishBatData, englishTcomData, spanishTcomData } = getCopyDataFromSource(currentOfferMarketing, currentOffer?.id);

  const englishRibbons = ribbons.filter(item => item.language === Language.ENGLISH);
  const spanishRibbons = ribbons.filter(item => item.language === Language.SPANISH);

  const formParams = {
    offerId: currentOffer?.id || '',
    offeringId: currentOffering?.id || '',
    isLexus,
    snippets,
    tokens,
    ribbons: lang === 'es' ? spanishRibbons : englishRibbons,
    offerType,
    tdaCode,
    lang,
    site: site as MarketingSites,
    onSubmit,
    setLangParam,
    httpError,
  };

  return (
    <>
      <OfferTabBodyLayout
        renderContent={
          isLexus ? (
            <MarketingForm {...formParams} marketingData={englishTcomData} />
          ) : (
            <>
              {/* BAT English */}
              {site === MarketingSites.BAT && lang === 'en' && <MarketingForm {...formParams} marketingData={englishBatData} />}

              {/* BAT Spanish */}
              {site === MarketingSites.BAT && lang === 'es' && <MarketingForm {...formParams} marketingData={spanishBatData} copyEngData={englishBatData} />}

              {/* TCOM English */}
              {site === MarketingSites.TCOM && lang === 'en' && <MarketingForm {...formParams} marketingData={englishTcomData} copyEngBatData={englishBatData} />}

              {/* TCOM Spanish */}
              {site === MarketingSites.TCOM && lang === 'es' && (
                <MarketingForm {...formParams} marketingData={spanishTcomData} copyEngData={englishTcomData} copyEspBatData={spanishBatData} />
              )}
            </>
          )
        }
      />
    </>
  );
};

export default MarketingTab;
