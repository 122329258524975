import clsx from 'clsx';
import { OfferTypes } from 'oat-common-ui';
import { NavLink } from 'react-router-dom';
import { MarketingSites } from '../../../../../constants/global';
import useUrlParams from '../../../../../hooks/useUrlParams';
import useStores from '../../../../../stores/useStores';
import buildOfferUrl from '../../../../../utils/buildOfferUrl';
import styles from './styles.module.scss';

const TdaMarketingTabNavList = (offeringId: string, tdaCode: string, offerType: string, lang: string, applyBat: boolean, applyTcom: boolean, site?: string) => {
  const {
    offerMarketingStore: { setActiveOfferMarketingIndex },
  } = useStores();

  const { offerId } = useUrlParams();

  const setToActive = (site: string) => {
    return window.location.hash.indexOf(site) > -1;
  };

  const handleSetActiveOfferMarketingIndex = (marketingSite: string) => {
    setActiveOfferMarketingIndex(marketingSite === MarketingSites.BAT, lang);
  };

  return [
    <NavLink
      to={buildOfferUrl(offeringId, tdaCode, offerType, 'marketing', lang, offerId, MarketingSites.BAT)}
      isActive={() => setToActive(MarketingSites.BAT)}
      onClick={() => handleSetActiveOfferMarketingIndex(MarketingSites.BAT)}
      className={clsx(!applyBat && offerType === OfferTypes.OTHER && styles.inactive)}
    >
      BAT
    </NavLink>,
    <NavLink
      to={buildOfferUrl(offeringId, tdaCode, offerType, 'marketing', lang, offerId, MarketingSites.TCOM)}
      isActive={() => setToActive(MarketingSites.TCOM)}
      onClick={() => handleSetActiveOfferMarketingIndex(MarketingSites.TCOM)}
      className={clsx(!applyTcom && offerType === OfferTypes.OTHER && styles.inactive)}
    >
      TCOM / Other Sites
    </NavLink>,
  ];
};

export default TdaMarketingTabNavList;
