import React from 'react';
import styles from './styles.module.scss';

interface LoadingModalProps {
  message: string;
}

const LoadingModal = ({ message }: LoadingModalProps) => {
  return <span className={styles.loadingModal}>{message}</span>;
};

export default LoadingModal;
