import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { BlockUi, DropdownItem, IOfferStatusError, OATLink, OATWrapper, OfferingLayoutHeader, OfferingLayoutSearch, OfferStatus, sortFieldsHelper, useToast } from 'oat-common-ui';
import { useMemo, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Link, Redirect } from 'react-router-dom';
import MainFooter from '../../components/MainFooter';
import MainHeader from '../../components/MainHeader';
import OfferTabBreadCrumbs from '../../components/OfferTabComponents/OfferTabBreadCrumbs';
import { HELP_URL, MarketingSites } from '../../constants/global';
import {
  Offer,
  useCopyFromTdaMutation,
  useCopyOfferMutation,
  useDeleteOfferMutation,
  usePrioritizeOffersMutation,
  useUpdateOfferingStatusMutation,
  useUpdateOfferStatusMutation,
} from '../../gql/generated';
import useUrlParams from '../../hooks/useUrlParams';
import useStores from '../../stores/useStores';
import buildOfferUrl from '../../utils/buildOfferUrl';
import moveArrayItem from '../../utils/moveArrayItem';
import updateRevsByIds from '../../utils/updateRevsByIds';
import OfferingTable from './OfferingTable';
import { getOfferingFilters, getSeriesDisplayName } from './utils';

const OfferingComponent = () => {
  const {
    offeringsStore: { currentOffering, setCurrentOffering },
    offersStore,
    tdaStore,
    userInfoStore,
  } = useStores();

  const { success, error } = useToast();
  const { offeringId, tdaCode } = useUrlParams();

  const [copyOffer] = useCopyOfferMutation();
  const [deleteOffer] = useDeleteOfferMutation();
  const [prioritizeOffers] = usePrioritizeOffersMutation();
  const [updateOfferStatus] = useUpdateOfferStatusMutation();
  const [updateOfferingStatus] = useUpdateOfferingStatusMutation();
  const [copyFromTda] = useCopyFromTdaMutation();

  const [createOfferType, setCreateOfferType] = useState('');

  const [redirect, setRedirect] = useState('');

  const [offerMarketFlagMap, setOfferMarketFlagMap] = useState(new Map<string, IOfferStatusError>());
  const [block] = useState(false);
  const [previewBlock] = useState(false);

  const [statusFilter, setStatusFilter] = useState('');
  const [seriesFilter, setSeriesFilter] = useState('');
  const [offerTypeFilter, setOfferTypeFilter] = useState('');

  const { offerTypeFilters, seriesFilters, statusFilters } = useMemo(
    () => getOfferingFilters(offersStore.offers, { status: statusFilter, series: seriesFilter, offerType: offerTypeFilter }),
    [statusFilter, offerTypeFilter, seriesFilter, offersStore.offers],
  );

  const maxSortOrder = offersStore.offers.length;

  const filteredOffers = offersStore.offers.filter(
    offer =>
      (!statusFilter || offer.status === statusFilter) &&
      (!seriesFilter || getSeriesDisplayName(offer).seriesDropdown === seriesFilter) &&
      (!offerTypeFilter || offer.offerType === offerTypeFilter),
  );

  const isDragDisabled = !!statusFilter || !!seriesFilter || !!offerTypeFilter;

  const handleOnStatusFilter = (item: DropdownItem) => {
    setStatusFilter(item.value);
  };

  const handleOnSeriesFilter = (item: DropdownItem) => {
    setSeriesFilter(item.value);
  };

  const handleOfferTypeFilter = (item: DropdownItem) => {
    setOfferTypeFilter(item.value);
  };

  const handleChangeOffering = async (statusParam: OfferStatus) => {
    try {
      const res = await trackPromise(updateOfferingStatus({ variables: { id: currentOffering.id || '', rev: currentOffering.rev || '', status: statusParam.toString() || '' } }));
      setCurrentOffering({
        ...currentOffering,
        status: res?.data?.updateOfferingStatus.status || '',
        rev: res?.data?.updateOfferingStatus.rev || '',
      });

      if (res.data?.updateOfferingStatus.offers) {
        let newUpdatedOffers: Offer[] = [];
        let newOfferMarketFlagMap = new Map();

        res.data.updateOfferingStatus.offers.forEach(offer => {
          const currOffer = offersStore.offers.filter(o => o.id === offer.offerId)[0];
          currOffer.rev = offer.rev;
          currOffer.status = offer.status;
          newOfferMarketFlagMap.set(offer.offerId, { success: offer.success, error: offer.error });
          newUpdatedOffers.push(currOffer);
        });

        setOfferMarketFlagMap(newOfferMarketFlagMap);
        offersStore.setCurrentOffers(newUpdatedOffers);
      }

      success('Offering status updated successfully.');
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleOnCreateOffer = (type: string) => {
    setCreateOfferType(type);
  };

  if (createOfferType) {
    return <Redirect to={buildOfferUrl(currentOffering.id, tdaCode, createOfferType, 'info', 'en')} />;
  }

  const renderOfferLink = (offer: Offer) => {
    return (
      <Link component={OATLink} id={`offer-link-${offer.id}`} to={buildOfferUrl(offeringId, tdaCode, offer.offerType, 'info', 'en', offer.id, MarketingSites.BAT)}>
        {offer.offerType}
      </Link>
    );
  };

  const handlePublish = async () => {};

  const handleOnCopyOffer = async (offer: Offer) => {
    try {
      const res = await trackPromise(copyOffer({ variables: { id: offer.id } }));
      const copiedOffer = res.data?.copyOffer.offers.find(item => !offersStore.offerExists(item?.id || ''));
      // copying an item updates revs due to sort order
      updateRevsByIds(res.data?.copyOffer.offers || [], offersStore.offers);
      if (copiedOffer) {
        offersStore.copyOffer(offer, copiedOffer as Offer);
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleOnDeleteOffer = async (offer: Offer) => {
    try {
      const res = await trackPromise(deleteOffer({ variables: { id: offer.id, rev: offer.rev } }));
      // deleting an item updates revs due to sort order
      updateRevsByIds(res.data?.deleteOffer.offers || [], offersStore.offers);
      offersStore.removeOffer(offer.id);
    } catch (e) {
      error((e as Error).message);
    }
  };

  const handleOnSort = async (currSortOrder: number, newSortOrder: number) => {
    const currIndex = currSortOrder - 1;
    const newIndex = newSortOrder - 1;
    const sortedOffers = moveArrayItem(currIndex, newIndex, offersStore.offers);
    const updatedSortedOffers = sortedOffers.map((offer, index) => ({ ...offer, sortOrder: index + 1 }));

    offersStore.loadOffers(offeringId, updatedSortedOffers);
    if (currIndex !== newIndex)
      try {
        const res = await trackPromise(prioritizeOffers({ variables: { offerId: sortedOffers[newIndex].id, oldPosition: currSortOrder, newPosition: newSortOrder } }));
        // update revs due to sort order
        updateRevsByIds(res.data?.prioritizeOffers.offers || [], offersStore.offers);
      } catch (e) {
        error((e as Error).message);
      }
  };

  const handleColumnSort = (header: string, asc: boolean) => {
    const sortedOffers = offersStore.offers.slice().sort(sortFieldsHelper(header, asc));
    offersStore.loadOffers(offeringId, sortedOffers);
  };

  const handleChangeOfferStatus = async (offer: Offer, status: OfferStatus) => {
    try {
      const res = await trackPromise(updateOfferStatus({ variables: { id: offer.id || '', rev: offer.rev || '', status: status.toString() || '' } }));
      const resOffer = res.data?.updateOfferStatus?.offer as Offer;
      offersStore.updateOffer(resOffer.id, resOffer);
      success('Offer status updated successfully.');
    } catch (e) {
      offerMarketFlagMap.set(offer.id || '', { success: false, error: (e as Error).message });
      error((e as Error).message);
    }
  };

  const handleCopyFromTda = async () => {
    try {
      const res = await trackPromise(
        copyFromTda({
          variables: { sourceTdaCode: tdaCode === AdminConstants.TdaCodes.INW ? AdminConstants.TdaCodes.PTL : AdminConstants.TdaCodes.GNY, targetOfferingId: offeringId },
        }),
      );
      if (res) {
        window.location.reload();
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  const statusOptions = !userInfoStore.isLexus()
    ? [OfferStatus.IN_PROGRESS, OfferStatus.READY_TO_PREVIEW, OfferStatus.PREVIEWED, OfferStatus.READY_TO_PUBLISH, OfferStatus.PAUSED]
    : [OfferStatus.IN_PROGRESS, OfferStatus.READY_TO_PUBLISH, OfferStatus.PAUSED];

  return (
    <>
      <MainHeader breadCrumbs={OfferTabBreadCrumbs(tdaStore.currentTda, userInfoStore.properties.fullName, currentOffering)} />
      <OATWrapper withPadding>
        <OfferingLayoutHeader
          offeringName={currentOffering.name}
          startDate={currentOffering.startDate}
          endDate={currentOffering.endDate}
          status={currentOffering.status}
          onChangeOfferingStatus={handleChangeOffering}
          statusOptions={statusOptions}
        />
        <OfferingLayoutSearch
          offerTypeFilters={offerTypeFilters}
          seriesFilters={seriesFilters}
          statusFilters={statusFilters}
          seriesMap={[]}
          createButtonText="Create Other"
          createOfferCallback={() => setRedirect(buildOfferUrl(offeringId, tdaCode, 'other', 'info', 'en', undefined, MarketingSites.BAT))}
          onOfferTypeFilterChange={handleOfferTypeFilter}
          onSeriesFilterChange={handleOnSeriesFilter}
          onStatusFilterChange={handleOnStatusFilter}
          onCreateOffer={handleOnCreateOffer}
          tdaCode={tdaCode}
          onCopyFromTDA={handleCopyFromTda}
        />
        <OfferingTable
          canPreview={!userInfoStore.isLexus()}
          id="tdaLdaOffersTable"
          isDragDisabled={isDragDisabled}
          maxSortOrder={maxSortOrder}
          offerMarketFlagMap={offerMarketFlagMap}
          offers={filteredOffers}
          onChangeOfferingStatus={handleChangeOfferStatus}
          onColumnSort={handleColumnSort}
          onCopy={handleOnCopyOffer}
          onDelete={handleOnDeleteOffer}
          onSort={handleOnSort}
          renderOfferLink={renderOfferLink}
        />
      </OATWrapper>
      <BlockUi blocking={block} title="Publishing Offering ..." />
      <BlockUi blocking={previewBlock} title="Publishing to Preview ..." />
      <MainFooter isShowExport isShowPublish onPublish={handlePublish} darkTheme helpUrl={HELP_URL} />
    </>
  );
};

export default observer(OfferingComponent);
