import { makeObservable, observable, runInAction } from 'mobx';
import { TdaImages } from '../gql/generated';

class TdaImagesStore {
  imagesByTda: TdaImages = {
    imageNames: [],
    imagePrefix: '',
  };

  constructor() {
    makeObservable(this, {
      imagesByTda: observable,
    });
  }

  setTdaImagesData = (tdaImages: TdaImages) => {
    runInAction(() => {
      this.imagesByTda = tdaImages;
    });
  };
}

export default TdaImagesStore;
