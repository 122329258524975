import { blankCarImg, EFC_IMG_DOMAIN } from 'oat-common-ui';
import useStores from '../../../stores/useStores';

interface Props {
  path: string;
}

const getFullUrl = (path: string, batPrefix: string) => {
  let fullUrl = '';

  if (path.includes('http')) {
    fullUrl = path;
  } else {
    const rx = new RegExp('^/config/pub');
    const domain = rx.test(path) ? EFC_IMG_DOMAIN : batPrefix;
    fullUrl = `${domain}${path}`;
  }

  return fullUrl;
};

const DisplayOfferImage = ({ path }: Props) => {
  const {
    tdaImagesStore: {
      imagesByTda: { imagePrefix },
    },
  } = useStores();

  return <img src={path ? getFullUrl(path, imagePrefix) : blankCarImg} alt="car_img" />;
};

export default DisplayOfferImage;
