import { observer } from 'mobx-react-lite';
import { useGetOfferingsForDashboardQuery } from '../../gql/generated';
import useUrlParams from '../../hooks/useUrlParams';
import useStores from '../../stores/useStores';
import Error from '../Error';
import Loading from '../Loading';
import DashboardComponent from './DashboardComponent';

const Dashboard = () => {
  const { userInfoStore, dashboardStore, offeringsStore } = useStores();
  const { tdaCode } = useUrlParams();

  const { data, loading, error } = useGetOfferingsForDashboardQuery({
    variables: { tdaCode },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <Loading breadCrumbs={[{ name: userInfoStore.properties.abbrName }]} />;
  }

  if (error) {
    return <Error />;
  }

  if (data) {
    offeringsStore.loadOfferings(data);
    dashboardStore.processQuery(data);
  }

  return <DashboardComponent />;
};

export default observer(Dashboard);
