export const defaultProperties = {
  abbrName: '',
  fullName: '',
};

export const toyotaProperties = {
  abbrName: 'TDA',
  fullName: 'Toyota Dealer Association',
};

export const lexusProperties = {
  abbrName: 'LDA',
  fullName: 'Lexus Dealer Association',
};
