import { observer } from 'mobx-react-lite';
import { Checkbox, OfferTypes } from 'oat-common-ui';
import React, { ReactNode } from 'react';
import { MarketingSites } from '../../../../../constants/global';
import useStores from '../../../../../stores/useStores';
import styles from './styles.module.scss';

interface Props {
  navList: ReactNode[];
}

const TdaMarketingTabNav = ({ navList }: Props) => {
  const {
    offersStore: { currentOffer },
    offerMarketingStore: { applyBat, applyTcom, setApply },
  } = useStores();

  return (
    <div className={styles.offerTabNav}>
      <ul>
        <div className={styles.navItemsWrapper}>
          {navList.map((item, index) => {
            const i = index;

            return (
              <li key={i} className={styles.navItem}>
                {item}
              </li>
            );
          })}
        </div>
        {(currentOffer?.offerType === OfferTypes.OTHER || !currentOffer?.offerType) && (
          <div className={styles.applyCheckboxsWarpper}>
            <li className={styles.navItem}>
              <Checkbox label="Apply to BAT" isChecked={applyBat} onChange={() => setApply(MarketingSites.BAT)} className={styles.applyCheckBox} />
            </li>
            <li className={styles.navItem}>
              <Checkbox label="Apply to TCOM/Other Sites" isChecked={applyTcom} onChange={() => setApply(MarketingSites.TCOM)} className={styles.applyCheckBox} />
            </li>
          </div>
        )}
      </ul>
    </div>
  );
};

export default observer(TdaMarketingTabNav);
