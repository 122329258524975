import { makeObservable, observable, runInAction } from 'mobx';
import { GetTemplatesByTdaQuery, Template, TemplateMarketing } from '../gql/generated';

interface IOfferTemplate {
  [templateOfferId: string]: Template;
}

interface IOfferMarketingTemplate {
  [templateOfferId: string]: TemplateMarketing[];
}

class OfferTemplatesStore {
  activeTemplates: Template[] = [];
  archivedTemplates: Template[] = [];
  offerTemplate = {} as IOfferTemplate;
  offerMarketingTemplate = {} as IOfferMarketingTemplate;

  currentOfferTemplate: Template | undefined = undefined;
  defaultOfferTemplate: Template = {
    name: '',
    tiers: '',
    terms: '',
    offerType: '',
    status: '',
    tdaCode: '',
    isArchived: false,
    subventionCashIncluded: false,
    id: '',
    rev: '',
    created: '',
    createdBy: '',
    updated: '',
    updatedBy: '',
  };

  currentTemplateMarketing: TemplateMarketing[] = [];
  defaultTemplateMarketing: TemplateMarketing[] = [
    { language: 'en', isBuyAToyota: true, id: '', rev: '' },
    { language: 'es', isBuyAToyota: true, id: '', rev: '' },
    { language: 'en', isBuyAToyota: false, id: '', rev: '' },
    { language: 'es', isBuyAToyota: false, id: '', rev: '' },
  ];
  activeTemplateMarketingIndex: number = 0;

  templatesLoaded = false;

  constructor() {
    makeObservable(this, {
      activeTemplates: observable,
      archivedTemplates: observable,
      offerTemplate: observable,
      offerMarketingTemplate: observable,
      currentOfferTemplate: observable,
      currentTemplateMarketing: observable,
      activeTemplateMarketingIndex: observable,
    });
  }

  loadOfferTemplates = (data: GetTemplatesByTdaQuery | undefined) => {
    runInAction(() => {
      if (data) {
        this.activeTemplates = [...(data.active as Template[])];
        this.archivedTemplates = [...(data.archived as Template[])];
        this.templatesLoaded = true;
      }
    });
  };

  getOfferTemplateById = (offerTemplateId: string) => {
    return this.offerTemplate[offerTemplateId];
  };

  setOfferTemplate = (offerTemplateId: string, offerTemplate: Template) => {
    runInAction(() => {
      this.offerTemplate = { ...this.offerTemplate, [offerTemplateId]: offerTemplate };
    });
  };

  getTemplateMarketingById = (offerTemplateId: string) => {
    return this.offerMarketingTemplate[offerTemplateId];
  };

  setTemplateMarketing = (offerTemplateId: string, templateMarketing: TemplateMarketing[]) => {
    runInAction(() => {
      this.offerMarketingTemplate = { ...this.offerMarketingTemplate, [offerTemplateId]: templateMarketing };
    });
  };

  setCurrentOfferTemplate = (offerTemplate: Template) => {
    runInAction(() => {
      this.currentOfferTemplate = offerTemplate;
    });
  };

  setCurrentTemplateMarketing = (templateMarketing: TemplateMarketing[]) => {
    runInAction(() => {
      this.currentTemplateMarketing = templateMarketing;
    });
  };

  updateTemplateMarketing = (isBAT: boolean, lang: string, newState: Partial<TemplateMarketing>) => {
    runInAction(() => {
      this.currentTemplateMarketing = this.currentTemplateMarketing.map(item => {
        if (item.language === lang && item.isBuyAToyota === isBAT) {
          return { ...item, ...newState };
        }

        return item;
      });
    });
  };

  getCurrentTemplateMarketingIndex = (isBuyAToyota: boolean, language: string) => {
    return this.currentTemplateMarketing.findIndex(templateMarketing => templateMarketing.isBuyAToyota === isBuyAToyota && language === templateMarketing.language);
  };

  setActiveTemplateMarketingIndex = (isBuyAToyota: boolean, language: string) => {
    runInAction(() => {
      const activeOfferMarketingIndex = this.getCurrentTemplateMarketingIndex(isBuyAToyota, language);

      if (activeOfferMarketingIndex === -1) {
        this.activeTemplateMarketingIndex = this.getCurrentTemplateMarketingIndex(isBuyAToyota, language);
      } else {
        this.activeTemplateMarketingIndex = activeOfferMarketingIndex;
      }
    });
  };

  deleteTemplate = (templateId: string, isArchived: boolean) => {
    const deleteItem = (templates: Template[]) => {
      return templates.filter(item => item.id !== templateId);
    };

    runInAction(() => {
      if (isArchived) {
        this.archivedTemplates = deleteItem(this.archivedTemplates);
      } else {
        this.activeTemplates = deleteItem(this.activeTemplates);
      }
    });
  };

  archiveTemplate = (template: Template) => {
    runInAction(() => {
      if (template.isArchived === false) {
        this.archivedTemplates = this.archivedTemplates.filter(item => item.id !== template.id);
        this.activeTemplates = [...this.activeTemplates, template];
      } else {
        this.activeTemplates = this.activeTemplates.filter(item => item.id !== template.id);
        this.archivedTemplates = [...this.archivedTemplates, template];
      }
    });
  };

  updateTemplateStatus = (template: Template) => {
    runInAction(() => {
      this.activeTemplates = this.activeTemplates.map(item => {
        if (item.id === template.id) {
          return {
            ...item,
            ...template,
          };
        }

        return item;
      });
    });
  };
}

export default OfferTemplatesStore;
