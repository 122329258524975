import { AdminConstants } from 'oat-admin-common';
import { brands, DnDType, IMultiItem, OfferTypes } from 'oat-common-ui';
import { LdaCode, Port } from '../../constants/global';
import {
  OfferMarketing,
  OfferMarketingBulletInput,
  OfferMarketingDisclaimerInput,
  OfferMarketingRestrictionInput,
  TemplateMarketingBullet,
  TemplateMarketingDisclaimer,
  TemplateMarketingRestriction,
} from '../../gql/generated';
import { copyDndItems } from '../OfferTabs/MarketingTab/utils';
import { OfferMarketingItem } from './MarketingTab/useDnd';

const { BRAND_TOYOTA, BRAND_LEXUS } = AdminConstants;

export const getPlusOneYears5 = () => {
  const newestYear = new Date().getFullYear();
  const lowestYear = newestYear - 4;

  const years: string[] = [];
  for (let year = newestYear; year >= lowestYear; year -= 1) {
    years.push(year.toString());
  }
  return years;
};

export const portOptions = (isLexus: boolean) => {
  if (!isLexus) {
    return brands[BRAND_TOYOTA].ports.portsAvailable;
  }

  return brands[BRAND_LEXUS].ports.portsAvailable;
};

export const defaultPortsByRegion = (tdaCode: string, isLexus: boolean) => {
  let filteredPorts: string[] = [];

  switch (tdaCode) {
    case LdaCode.LCA:
      filteredPorts = portOptions(isLexus).filter((port: string) => port === Port.PT);
      break;
    case LdaCode.LEA:
      filteredPorts = portOptions(isLexus).filter((port: string) => port === Port.NY);
      break;
    case LdaCode.LWA:
      filteredPorts = portOptions(isLexus).filter((port: string) => port === Port.LA || port === Port.PT);
      break;
    case LdaCode.LSA:
      filteredPorts = portOptions(isLexus).filter((port: string) => port === Port.LA || port === Port.JX);
      break;
    default:
      break;
  }

  return filteredPorts;
};

export const selectAllSameStates = (list: IMultiItem[], selectedItem: IMultiItem) => {
  return list.map(listItem => {
    if (listItem.data.includes(selectedItem.data.split('-')[0])) {
      listItem.selected = selectedItem.selected;

      return listItem;
    }

    return listItem;
  });
};

export const formatBulletData = (data: OfferMarketingItem[], forTemplates = false): OfferMarketingBulletInput[] | TemplateMarketingBullet[] => {
  return data.map(item => ({
    copyLinkToToyota: item.copyLinkToToyota || false,
    isStandard: item.isStandard || false,
    isIdMe: item.isIdMe || false,
    stdDiscBullId: forTemplates ? undefined : item.stdDiscBullId || '',
    link: item.link || '',
    sortOrder: item?.sortOrder,
    text: item.text || '',
  }));
};

export const formatDisclaimerData = (data: OfferMarketingItem[], forTemplates = false): OfferMarketingDisclaimerInput[] | TemplateMarketingDisclaimer[] => {
  return data.map(item => ({
    isStandard: item.isStandard || false,
    stdDiscBullId: forTemplates ? undefined : item.stdDiscBullId || '',
    sortOrder: item.sortOrder,
    tdaDisclaimerFileId: item.fileId || '',
    tdaDisclaimerFileName: item.file || '',
    text: item.text || '',
  }));
};

export const formatRestrictionData = (data: OfferMarketingItem[]): OfferMarketingRestrictionInput[] | TemplateMarketingRestriction[] => {
  return data.map(item => ({
    isStandard: item.isStandard,
    sortOrder: item.sortOrder,
    tdaRestrictionFileId: item.fileId || '',
    tdaRestrictionFileName: item.file || '',
    text: item.text || '',
  }));
};

export const formatMarketingPayload = (data: OfferMarketing, marketingCopy: OfferMarketing[], toBoth: boolean, offerType: string, isGst: boolean) => {
  let marketingDetails: OfferMarketing[] = [];

  if (!toBoth) {
    // just save current form, no need to send all 4 anymore
    marketingDetails.push(data);
  } else {
    // need to save to both bat and tcom. If eng, do eng only. Same with spanish.
    // marketingDetails = [...marketingCopy];
    const tcomData = marketingCopy.find(item => !item.isBuyAToyota && item.language === data.language);
    const dataToBoth = data;
    const dataToNotBoth = marketingCopy.find(item => item.language === dataToBoth.language && item.isBuyAToyota !== dataToBoth.isBuyAToyota);

    const arrToSend = [
      // dataToBoth = BAT data
      {
        ...dataToBoth,
      },
      {
        ...dataToBoth,
        bullets: copyDndItems(DnDType.BULLETS, dataToBoth.bullets, tcomData?.bullets),
        restrictions: copyDndItems(DnDType.RESTRICTIONS, dataToBoth.restrictions || [], tcomData?.restrictions || []),
        disclaimers: copyDndItems(DnDType.DISCLAIMERS, dataToBoth.disclaimers, tcomData?.disclaimers),
        offerCardImage: !dataToNotBoth?.isBuyAToyota && isGst ? dataToNotBoth?.offerCardImage : dataToBoth?.offerCardImage,
        offerCardImage2: !dataToNotBoth?.isBuyAToyota && isGst ? dataToNotBoth?.offerCardImage2 : dataToBoth?.offerCardImage2,
        offerDetailImage: !dataToNotBoth?.isBuyAToyota && isGst ? dataToNotBoth?.offerDetailImage : dataToBoth?.offerDetailImage,
        cashLabel: offerType === OfferTypes.CUSTOMER_CASH || offerType === OfferTypes.MISCELLANEOUS ? dataToBoth.cashLabel : dataToNotBoth?.cashLabel,
        primaryOfferCardLabel: offerType !== OfferTypes.OTHER ? dataToBoth.primaryOfferCardLabel || '' : dataToNotBoth?.primaryOfferCardLabel || '',
        secondaryOfferCardLabel: offerType !== OfferTypes.OTHER ? dataToBoth.secondaryOfferCardLabel : dataToNotBoth?.secondaryOfferCardLabel,
        isBuyAToyota: dataToNotBoth?.isBuyAToyota || false,
        rev: dataToNotBoth?.rev || '',
        id: dataToNotBoth?.id || '',
      },
    ];

    // Need to put items from arrToSend to marketingDetails, but need to filter out the arrToSend first
    // marketingDetails = marketingDetails.filter(item => !arrToSend.find(iItem => iItem.isBuyAToyota === item.isBuyAToyota && iItem.language === item.language));
    arrToSend.forEach(item => marketingDetails.push(item));
  }

  return marketingDetails.map(item => {
    return {
      bullets: item.bullets.map(b => ({
        copyLinkToToyota: b.copyLinkToToyota,
        isStandard: b.isStandard,
        isIdMe: b.isIdMe,
        stdDiscBullId: b.stdDiscBullId || '',
        link: b.link || '',
        sortOrder: b.sortOrder,
        text: b.text || '',
      })),
      cashLabel: item.cashLabel || '',
      description: item.description,
      disclaimers: item.disclaimers.map(d => ({
        isStandard: d.isStandard,
        stdDiscBullId: d.stdDiscBullId || '',
        sortOrder: d.sortOrder,
        tdaDisclaimerFileId: d.tdaDisclaimerFileId || '',
        tdaDisclaimerFileName: d.tdaDisclaimerFileName || '',
        text: d.text || '',
      })),
      isBuyAToyota: item.isBuyAToyota,
      offerCardDesc: item.offerCardDesc,
      offerCardAltText: item.offerCardAltText,
      offerCardTitle: item.offerCardTitle,
      offerDetailAltText: item.offerDetailAltText,
      offerDetailTitle: item.offerDetailTitle,
      primaryOfferCardLabel: item.primaryOfferCardLabel,
      secondaryOfferCardLabel: item.secondaryOfferCardLabel,
      offerBadgeImage1: item.offerBadgeImage1,
      offerBadgeImage2: item.offerBadgeImage2,
      offerBadgeImage3: item.offerBadgeImage3,
      offerBadgeImage1DestinationUrlAltText: item.offerBadgeImage1DestinationUrlAltText,
      offerBadgeImage2DestinationUrlAltText: item.offerBadgeImage2DestinationUrlAltText,
      offerBadgeImage3DestinationUrlAltText: item.offerBadgeImage3DestinationUrlAltText,
      offerBadgeImage1DestinationUrl: item.offerBadgeImage1DestinationUrl,
      offerBadgeImage2DestinationUrl: item.offerBadgeImage2DestinationUrl,
      offerBadgeImage3DestinationUrl: item.offerBadgeImage3DestinationUrl,
      offerCardImage: item.offerCardImage,
      offerCardImage2: item.offerCardImage2,
      offerDetailImage: item.offerDetailImage,
      otherMiscType: item.otherMiscType,
      restrictions: item.restrictions?.map(r => ({
        sortOrder: r?.sortOrder,
        tdaRestrictionFileId: r?.tdaRestrictionFileId,
        tdaRestrictionFileName: r?.tdaRestrictionFileName,
        text: r?.text || '',
      })),
      otherOfferCategory: item.otherOfferCategory,
      offerRibbonId: item.offerRibbonId || '',
      imageDisclaimer: item.imageDisclaimer,
      language: item.language,
      rev: item.rev || '',
      id: item.id || '',
    };
  });
};
