import { Button, ButtonGroup, DefaultModal, useToast } from 'oat-common-ui';
import React, { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Ribbon, useSaveTdaRibbonsMutation } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import Language from './Language';
import RibbonList from './RibbonList';
import styles from './styles.module.scss';
import { processPayloadRibbons, processStoreRibbons } from './util';

export interface TokenRibbon extends Ribbon {
  uid?: string;
}

export interface TokenRibbon extends Ribbon {
  uid?: string;
}

interface Props {
  ribbons: TokenRibbon[];
}

const RibbonsComponent = ({ ribbons }: Props) => {
  const { tokenStore } = useStores();
  const { tdaCode } = useUrlParams();
  const [saveTdaRibbons] = useSaveTdaRibbonsMutation();
  const {
    tokenStore: { setRibbons },
  } = useStores();
  const { error } = useToast();

  const [engRibbons, setEngRibbons] = useState<TokenRibbon[]>(processStoreRibbons(ribbons, Language.ENGLISH));
  const [espRibbons, setEspRibbons] = useState<TokenRibbon[]>(processStoreRibbons(ribbons, Language.SPANISH));
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const handleOnEngUpdate = (ribbons: TokenRibbon[]) => {
    setEngRibbons(ribbons);
  };

  const handleOnEspUpdate = (ribbons: TokenRibbon[]) => {
    setEspRibbons(ribbons);
  };

  const handleResetChanges = () => {
    setEngRibbons(processStoreRibbons(tokenStore.ribbons, Language.ENGLISH));
    setEspRibbons(processStoreRibbons(tokenStore.ribbons, Language.SPANISH));
    setShowCancelModal(false);
  };

  const handleSaveChanges = async () => {
    const engRibbonsPayload = engRibbons.filter(ribbon => ribbon.name !== '');
    const espRibbonsPayload = espRibbons.filter(ribbon => ribbon.name !== '');

    // removing any ribbons with no text on save
    if (!engRibbons.every(ribbon => ribbon.name)) {
      setEngRibbons(engRibbonsPayload);
    }

    if (!espRibbons.every(ribbon => ribbon.name)) {
      setEspRibbons(espRibbonsPayload);
    }

    try {
      const res = await trackPromise(
        saveTdaRibbons({
          variables: {
            tdaCode,
            ribbons: processPayloadRibbons(engRibbonsPayload, espRibbonsPayload),
          },
        }),
      );

      setRibbons(res.data?.saveTdaRibbons.ribbons as TokenRibbon[]);
      setEngRibbons(processStoreRibbons(res.data?.saveTdaRibbons.ribbons as TokenRibbon[], Language.ENGLISH));
      setEspRibbons(processStoreRibbons(res.data?.saveTdaRibbons.ribbons as TokenRibbon[], Language.SPANISH));
    } catch (e) {
      error((e as Error).message);
    } finally {
      handleCloseSaveModal();
      handleCloseCancelModal();
    }
  };

  const handleCloseSaveModal = () => {
    setShowSaveModal(false);
  };

  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
  };

  return (
    <>
      {showSaveModal && (
        <DefaultModal
          title="Save Changes"
          message="Are you sure you want to save all your changes?"
          open={showSaveModal}
          onClose={handleCloseSaveModal}
          onSubmit={handleSaveChanges}
          submitText="OK"
          className={styles.defaultModal}
        />
      )}
      {showCancelModal && (
        <DefaultModal
          title="Cancel Changes"
          message="Are you sure you want to cancel and reset all of your changes?"
          open={showCancelModal}
          onClose={handleCloseCancelModal}
          onSubmit={handleResetChanges}
          submitText="OK"
          className={styles.defaultModal}
        />
      )}
      <div className={styles.ribbonsContainer}>
        <RibbonList ribbons={engRibbons} title="English" handleOnUpdate={handleOnEngUpdate} droppableId="engRibbons" />
        <RibbonList ribbons={espRibbons} title="Spanish" handleOnUpdate={handleOnEspUpdate} droppableId="espRibbons" />
      </div>
      <ButtonGroup className={styles.saveGroup}>
        <Button variant="primary" onClick={() => setShowCancelModal(true)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => setShowSaveModal(true)}>
          Save
        </Button>
      </ButtonGroup>
    </>
  );
};

export default RibbonsComponent;
