import { AdminConstants } from 'oat-admin-common';
import { DropdownItem, IMultiItem, OfferTypes } from 'oat-common-ui';
import { useMemo, useState } from 'react';
import { Offer, OfferVehicle } from '../../../gql/generated';
import useStores from '../../../stores/useStores';
import getVehicleInclusionList from '../../../utils/getVehicleInclusionList';
import { applyExcludedMultiFilters, doesYearSeriesIdMatch, getTrimListsFromVehicles, mapExcludedSeriesYearsFromMultiItems, processIncomingFilteredLists } from './utils';

const ddItemAll = { value: 'All', label: 'All' };

const useSeriesManagerTrim = (offer: Offer | undefined, offerType?: string, otherOfferAllVehicles?: OfferVehicle[]) => {
  const { vehiclesStore } = useStores();

  // Series Year Filters
  const [seriesYear, setSeriesYear] = useState<DropdownItem | undefined>({
    value: offer?.seriesYear || (!offer && offerType === OfferTypes.OTHER ? 'MULTI' : '') || '',
    label: offer?.seriesYear || (!offer && offerType === OfferTypes.OTHER ? 'MULTI' : '') || '',
  });
  const [seriesName, setSeriesName] = useState<DropdownItem | undefined>({
    value: offer?.series || (!offer && offerType === OfferTypes.OTHER ? 'MULTI' : '') || '',
    label: offer?.series || (!offer && offerType === OfferTypes.OTHER ? 'MULTI' : '') || '',
  });
  const [excludedSeriesYear, setExcludedSeriesYear] = useState<DropdownItem | undefined>(ddItemAll);

  // Init Series Manager List
  const seriesManagerLists = useMemo(() => getVehicleInclusionList(offer, true, otherOfferAllVehicles), [offer, otherOfferAllVehicles]);

  const [includedSeriesManagerList, setIncludedSeriesManagerList] = useState(seriesManagerLists[0]);
  const [excludedSeriesManagerList, setExcludedSeriesManagerList] = useState(seriesManagerLists[1]);
  const [filteredExcludedSeriesManagerList, setFilteredExcludedSeriesManagerList] = useState(
    seriesManagerLists[1].filter(item => doesYearSeriesIdMatch(item.id, seriesYear?.value || '', seriesName?.value || '')),
  );
  const excludedSeriesList = mapExcludedSeriesYearsFromMultiItems(seriesManagerLists.flat(), includedSeriesManagerList);

  // Init Trim/Model Code List
  const trimLists = useMemo(() => getVehicleInclusionList(offer, false, otherOfferAllVehicles), [offer, otherOfferAllVehicles]);
  const [includedTrimList, setIncludedTrimList] = useState(trimLists[0]);
  const [excludedTrimList, setExcludedTrimList] = useState(trimLists[1]);

  const checkSeriesManagerIncludesAll = (exc: IMultiItem[]) => {
    if (exc.length === 0) {
      setExcludedSeriesYear(ddItemAll);
    }
  };

  const onSeriesManagerChange = (excluded: IMultiItem[], included: IMultiItem[], selectOnly: boolean) => {
    if (selectOnly) {
      setFilteredExcludedSeriesManagerList(excluded);
      setIncludedSeriesManagerList(included);
    } else {
      const { includedList, excludedList } = processIncomingFilteredLists(excludedSeriesManagerList, includedSeriesManagerList, included);
      const filteredExcluded = applyExcludedMultiFilters(seriesYear?.value, seriesName?.value, excludedSeriesYear?.value, excludedList);

      checkSeriesManagerIncludesAll(excludedList);
      setFilteredExcludedSeriesManagerList(filteredExcluded);
      setExcludedSeriesManagerList(excludedList);
      setIncludedSeriesManagerList(includedList);
    }
  };

  const onExcludedSeriesYearChange = (option: DropdownItem) => {
    const filteredExcluded = applyExcludedMultiFilters(seriesYear?.value, seriesName?.value, option.value, excludedSeriesManagerList);
    setFilteredExcludedSeriesManagerList(filteredExcluded);
    setExcludedSeriesYear(option);
  };

  const processSeriesYearNameChange = (year: string, name: string, excludedYear: string | undefined, prevYear: string, prevName: string) => {
    const isMulti = [year, name].includes(AdminConstants.MULTI);
    const prevIsMulti = [prevYear, prevName].includes(AdminConstants.MULTI);
    const switchMode = isMulti !== prevIsMulti;
    // handle Series Manager
    if (isMulti) {
      let currentIncluded = includedSeriesManagerList.filter(item => doesYearSeriesIdMatch(item.id, year, name));
      // if mode switch from trim manager and trim list has inclusions of series/year, move excluded to included in series manager
      if (switchMode) {
        currentIncluded = includedTrimList.length > 0 ? excludedSeriesManagerList.filter(item => item.id === `${prevYear} ${prevName}`) : [];
      }
      const { includedList, excludedList } = processIncomingFilteredLists(excludedSeriesManagerList, includedSeriesManagerList, currentIncluded);
      const filteredExcluded = applyExcludedMultiFilters(year, name, excludedYear, excludedList);

      checkSeriesManagerIncludesAll(excludedList);
      setFilteredExcludedSeriesManagerList(filteredExcluded);
      setExcludedSeriesManagerList(excludedList);
      setIncludedSeriesManagerList(includedList);
      // handle Trim
    } else {
      // if mode switch from series manager and series manager has inclusions of series/year, load up inclusions.
      let currentIncluded = includedSeriesManagerList;
      if (switchMode) {
        currentIncluded = includedSeriesManagerList.filter(item => item.id === `${year} ${name}`);
      }

      const { includedTrimList, excludedTrimList } = getTrimListsFromVehicles(offer?.vehicles || [], name, year, currentIncluded, otherOfferAllVehicles);
      setExcludedTrimList(excludedTrimList);
      setIncludedTrimList(includedTrimList);
    }
  };

  const onTrimChange = (excluded: IMultiItem[], included: IMultiItem[]) => {
    setExcludedTrimList(excluded);
    setIncludedTrimList(included);
  };

  const onSeriesNameChange = (option: DropdownItem) => {
    // reset excluded series year,
    const excludedYear = ddItemAll;
    setExcludedSeriesYear(excludedYear);

    processSeriesYearNameChange(seriesYear?.value || '', option.value, excludedYear?.value, seriesYear?.value || '', seriesName?.value || '');
    vehiclesStore.setSeriesYearOptions(option.value);
    setSeriesName(option);
  };

  const onSeriesYearChange = (option: DropdownItem) => {
    // reset excluded series year,
    const excludedYear = ddItemAll;
    setExcludedSeriesYear(excludedYear);

    processSeriesYearNameChange(option.value, seriesName?.value || '', excludedYear?.value, seriesYear?.value || '', seriesName?.value || '');
    vehiclesStore.setSeriesNameOptions(option.value);
    setSeriesYear(option);
  };

  const disableExcludeSeriesYear = offer && excludedSeriesManagerList.length === 0;

  return {
    disableExcludeSeriesYear,
    seriesYear,
    setSeriesYear,
    seriesName,
    setSeriesName,
    excludedSeriesYear,
    setExcludedSeriesYear,
    excludedSeriesManagerList,
    includedSeriesManagerList,
    filteredExcludedSeriesManagerList,
    excludedTrimList,
    includedTrimList,
    excludedSeriesList,
    onExcludedSeriesYearChange,
    onSeriesManagerChange,
    onTrimChange,
    onSeriesNameChange,
    onSeriesYearChange,
  };
};

export default useSeriesManagerTrim;
