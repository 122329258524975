import { OfferTypes } from 'oat-common-ui';
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import Dashboard from './pages/Dashboard';
import Error from './pages/Error';
import InitialRoute from './pages/InitialRoute';
import APRFinancialTab from './pages/OfferTabs/FinancialTab/APRFinancialTab';
import CashFinancialTab from './pages/OfferTabs/FinancialTab/CashFinancialTab';
import LeaseFinancialTab from './pages/OfferTabs/FinancialTab/LeaseFinancialTab';
import MiscFinancialTab from './pages/OfferTabs/FinancialTab/MiscFinancialTab';
import InfoTabBase from './pages/OfferTabs/InfoTab/InfoTabBase';
import MarketingTabBase from './pages/OfferTabs/MarketingTab/MarketingTabBase';
import OfferTemplates from './pages/OfferTemplates';
import OfferTemplateBase from './pages/OfferTemplates/OfferTemplateBase';
import Offering from './pages/Offering';
import PersonalSettings from './pages/PersonalSettings';
import TokenSettings from './pages/TokenSettings';

const Routes = () => {
  return (
    <Switch>
      {/* Added duplicate Dashboard to load all data for redirect to work */}
      <PrivateRoute exact path="/">
        <InitialRoute />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/dashboard">
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/settings/personal">
        <PersonalSettings />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/settings/tokens/:tdaName">
        <TokenSettings />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/settings/tokens">
        <TokenSettings />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/settings/offerTemplates/:tdaName">
        <OfferTemplates />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/settings/offerTemplates">
        <OfferTemplates />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/settings/offerTemplate/:tdaName">
        <OfferTemplateBase />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/settings/offerTemplate">
        <OfferTemplateBase />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/settings/offerTemplate/:tdaName/:offerTemplateId">
        <OfferTemplateBase />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/settings/offerTemplate/:offerTemplateId">
        <OfferTemplateBase />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/offering/:offeringId">
        <Offering />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/offering/:offeringId/apr/info">
        <InfoTabBase offerType={OfferTypes.APR} />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/offering/:offeringId/cash/info">
        <InfoTabBase offerType={OfferTypes.CUSTOMER_CASH} />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/offering/:offeringId/lease/info">
        <InfoTabBase offerType={OfferTypes.LEASE} />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/offering/:offeringId/other/info">
        <InfoTabBase offerType={OfferTypes.OTHER} />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/offering/:offeringId/misc/info">
        <InfoTabBase offerType={OfferTypes.MISCELLANEOUS} />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/offering/:offeringId/apr/financial">
        <APRFinancialTab />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/offering/:offeringId/cash/financial">
        <CashFinancialTab />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/offering/:offeringId/lease/financial">
        <LeaseFinancialTab />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/offering/:offeringId/misc/financial">
        <MiscFinancialTab />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/offering/:offeringId/apr/marketing">
        <MarketingTabBase offerType={OfferTypes.APR} />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/offering/:offeringId/cash/marketing">
        <MarketingTabBase offerType={OfferTypes.CUSTOMER_CASH} />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/offering/:offeringId/lease/marketing">
        <MarketingTabBase offerType={OfferTypes.LEASE} />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/offering/:offeringId/misc/marketing">
        <MarketingTabBase offerType={OfferTypes.MISCELLANEOUS} />
      </PrivateRoute>
      <PrivateRoute exact path="/:tdaCode/offering/:offeringId/other/marketing">
        <MarketingTabBase offerType={OfferTypes.OTHER} />
      </PrivateRoute>
      <Route exact path="/error">
        <Error />
      </Route>
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Routes;
