import { Modal, ModalHeader } from 'oat-common-ui';
import { ReactNode } from 'react';

import styles from './styles.module.scss';

interface Props {
  title: string;
  open: boolean;
  onClose: () => void;
  children: ReactNode;
}

const VehicleImagePickerModal = ({ title, open, onClose, children }: Props) => {
  return (
    <Modal isOpen={open} onClose={onClose} size="lg">
      <ModalHeader className={styles.imageModalHeader} onClose={onClose}>
        {title}
      </ModalHeader>
      {children}
    </Modal>
  );
};

export default VehicleImagePickerModal;
