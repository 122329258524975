import React from 'react';
import { Redirect } from 'react-router';
import useStores from '../../stores/useStores';

const InitialRoute = () => {
  const {
    tdaStore: { currentTda },
  } = useStores();
  return <Redirect to={`/${currentTda?.code}/dashboard`} />;
};

export default InitialRoute;
