import { PersonalSettingsLayout, SettingsLayout } from 'oat-common-ui';
import authClient from '../../authClient';
import MainHeader from '../../components/MainHeader';
import { SettingsBreadCrumbs, SettingsNav } from '../../components/SettingsComponents';
import useUrlParams from '../../hooks/useUrlParams';
import useStores from '../../stores/useStores';

const PersonalSettings = () => {
  const { userInfoStore } = useStores();
  const { tdaCode } = useUrlParams();
  const leftPanelMessage = `Reset your password or log out of the ${userInfoStore.properties.abbrName} Offers Admin Tool.`;
  const logoutText = `Log Out of ${userInfoStore.properties.abbrName} Offers Admin Tool`;
  const resetUrl = 'https://account.activedirectory.windowsazure.com/Profile/Default.aspx';

  const handleOnLogout = () => {
    authClient.logout();
  };

  return (
    <>
      <MainHeader showMiscLinks={false} breadCrumbs={SettingsBreadCrumbs(userInfoStore.properties.fullName, tdaCode)} />
      <SettingsLayout settings={SettingsNav(tdaCode, userInfoStore.isLexus(), userInfoStore.userInfo.tdaCodes)}>
        <PersonalSettingsLayout leftPanelMessage={leftPanelMessage} logoutText={logoutText} onClickLogout={handleOnLogout} resetUrl={resetUrl} />
      </SettingsLayout>
    </>
  );
};

export default PersonalSettings;
