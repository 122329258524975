import { AdminConstants } from 'oat-admin-common';
import { Button, ButtonGroup, DnDItem, DnDType, DragDrop, Draggable, DraggableProvided, DropResult, OATIcon, OfferTypes } from 'oat-common-ui';
import React, { useMemo } from 'react';
import { FINANCE_OFFER_TYPE, FINANCE_OFFER_TYPE_2, MarketingSites } from '../../../../../constants/global';
import { Disclaimer, Restriction, TokensMap } from '../../../../../gql/generated';
import useStores from '../../../../../stores/useStores';
import { OfferMarketingItem } from '../../useDnd';
import styles from './styles.module.scss';

interface MarketingDndItemsProps {
  droppableId: DnDType;
  isDisabledFields: boolean;
  items: OfferMarketingItem[];
  label: string;
  modalItems?: Disclaimer[] | Restriction[];
  offerType?: string;
  site: MarketingSites;
  isTemplate?: boolean;
  handleAddItem: (index: number) => void;
  handleRemoveItem: (type: DnDType, uid: string) => void;
  handleSortItems: (newSortOrder: number, currentSortOrder: number) => void;
  handleOnDragEnd: (result: DropResult) => void;
  handleSetItemList: (type: DnDType, item: OfferMarketingItem) => void;
}

const MarketingDndItems: React.FC<MarketingDndItemsProps> = ({
  droppableId,
  isDisabledFields,
  items,
  label,
  modalItems,
  offerType,
  site,
  isTemplate,
  handleAddItem,
  handleRemoveItem,
  handleSortItems,
  handleOnDragEnd,
  handleSetItemList,
}) => {
  const {
    tokenStore: { tokens, snippets },
    userInfoStore: { isLexus },
  } = useStores();

  const dndTokens: string[] = useMemo(() => {
    if (!tokens) {
      return [];
    }

    const returnOfferTypeKey = () => {
      let offerTypeKey;

      switch (offerType) {
        case FINANCE_OFFER_TYPE:
        case FINANCE_OFFER_TYPE_2:
        case OfferTypes.APR:
          offerTypeKey = OfferTypes.APR;
          break;
        case AdminConstants.OfferTypes.CUSTOMER_CASH:
        case OfferTypes.CUSTOMER_CASH:
          offerTypeKey = OfferTypes.CUSTOMER_CASH;
          break;
        case OfferTypes.LEASE:
        case OfferTypes.MISCELLANEOUS:
          offerTypeKey = offerType;
          break;
        default:
          offerTypeKey = OfferTypes.OTHER;
          break;
      }

      return offerTypeKey;
    };

    return tokens[returnOfferTypeKey() as keyof TokensMap] as string[];
  }, [tokens, offerType]);

  const handleItemChange = (item: OfferMarketingItem) => {
    handleSetItemList(droppableId, item);
  };

  const handleRenderDeleteIcon = (sortOrder: number, type: DnDType, uid: string, isStandard: boolean, isIdMe: boolean) => {
    if (isTemplate) {
      if (sortOrder === 1 && type === DnDType.RESTRICTIONS) {
        return null;
      }

      if (!isLexus()) {
        if (sortOrder === 2 && (type === DnDType.BULLETS || type === DnDType.DISCLAIMERS)) {
          return null;
        }
      } else {
        if (sortOrder === 1 && (type === DnDType.BULLETS || type === DnDType.DISCLAIMERS)) {
          return null;
        }
      }
    }

    if (!isStandard && !isIdMe) {
      return (
        <div className={styles.deleteDndItemContainer}>
          <Button
            onClick={() => {
              handleRemoveItem(type, uid);
            }}
            id={`delete-btn-${uid}`}
            disabled={isDisabledFields}
            className={styles.trashIconButton}
          >
            <OATIcon icon="trash" size={22} />
            <span className={styles.deleteText}>{`Delete ${type.slice(0, -1)}`}</span>
          </Button>
        </div>
      );
    }

    return null;
  };

  return (
    <div style={{ position: 'relative' }}>
      <DragDrop droppableId={droppableId} handleOnDragEnd={handleOnDragEnd}>
        {items.map((item: OfferMarketingItem, i) => {
          return (
            <Draggable key={item.uid} draggableId={item.uid} index={i}>
              {(draggableProvided: DraggableProvided) => {
                return (
                  <>
                    <DnDItem
                      isDisabledFields={isDisabledFields || item.isIdMe}
                      innerRef={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      addItem={handleAddItem}
                      copyLinkToToyota={item.copyLinkToToyota}
                      file={item.file}
                      fileId={item.fileId}
                      handleItemsSort={handleSortItems}
                      id={item.uid || ''}
                      isLexus={isLexus()}
                      isStandard={item.isStandard}
                      itemsList={items}
                      key={item.uid}
                      label={label}
                      link={item.link || ''}
                      modalItems={modalItems || []}
                      removeItem={handleRemoveItem}
                      setItemsList={handleItemChange}
                      site={site}
                      snippets={snippets.map(snippet => ({ name: snippet.name, char: snippet.text }))}
                      sortOrder={item.sortOrder}
                      text={item.text || ''}
                      tokens={dndTokens && dndTokens?.map(name => ({ name, char: name }))}
                      type={droppableId}
                      uid={item.uid || ''}
                      isTemplate={isTemplate}
                    />
                    <ButtonGroup className={styles.addContainer}>
                      <div className={styles.addDndItemContainer}>
                        <Button onClick={() => handleAddItem(items.length)} className={styles.iconButton} id="add-btn" disabled={isDisabledFields}>
                          <OATIcon icon="add" size={22} />
                          <span className={styles.addDndItemText}>{`Add ${label}`}</span>
                        </Button>
                      </div>
                      {handleRenderDeleteIcon(item.sortOrder, droppableId, item.uid, item.isStandard || false, item.isIdMe || false)}
                    </ButtonGroup>
                  </>
                );
              }}
            </Draggable>
          );
        })}
      </DragDrop>
      {!isTemplate && items.length < 1 && (
        <div className={styles.addDndItemContainer}>
          <Button onClick={() => handleAddItem(items.length)} className={styles.iconButton} id="add-btn" disabled={isDisabledFields}>
            <OATIcon icon="add" size={22} />
            <span className={styles.addDndItemText}>{`Add ${label}`}</span>
          </Button>
        </div>
      )}
    </div>
  );
};

export default MarketingDndItems;
