const headerColumns = [
  { header: '' },
  { header: ' ' },
  { header: 'Offer Type', sortable: true, field: 'offerType' },
  { header: 'Year', sortable: true, field: 'seriesYear' },
  { header: 'Vehicle', sortable: true, field: 'series' },
  { header: 'Offer Detail', width: 200 },
  { header: 'Offer ID' },
  { header: 'Notes', width: 250 },
  { header: 'Action' },
  { header: 'Offer Status' },
];

export default headerColumns;
