import { useEffect, useState } from 'react';
import useStores from '../stores/useStores';
import useUrlParams from './useUrlParams';

const useGetEfcData = () => {
  const { userInfoStore, efcStore, offersStore } = useStores();
  const { lang } = useUrlParams();

  const [loading, setLoading] = useState(!efcStore.series);
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    if (!efcStore.series) {
      (async () => {
        try {
          await efcStore.getSeriesV1(userInfoStore.userInfo.brand);
          setLoading(false);
        } catch (e) {
          setError(e as Error);
          setLoading(false);
        }
      })();
    }
  }, [efcStore, userInfoStore, lang, offersStore.currentOffer]);

  return {
    loading,
    error,
  };
};

export default useGetEfcData;
