import { OfferTypes } from 'oat-common-ui';
import { useState } from 'react';
import EfcCarJellyLoader from '../../../components/EfcCarJellyLoader';
import ModelCodesLoader from '../../../components/ModelCodesLoader';
import OfferTabBreadCrumbs from '../../../components/OfferTabComponents/OfferTabBreadCrumbs';
import { useGetOfferByIdQuery, useGetOfferingByIdQuery } from '../../../gql/generated';
import useGetEfcData from '../../../hooks/useGetEfcData';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import Error from '../../Error';
import Loading from '../../Loading';
import InfoTabComponentBase from './InfoTabComponentBase';

interface Props {
  offerType: OfferTypes;
}
const InfoTabBase = ({ offerType }: Props) => {
  const { offersStore, offeringsStore, offerMarketingStore, regionsStore, ldaStore, trimStore, vehiclesStore, tdaStore, userInfoStore } = useStores();
  const { offeringId, offerId, tdaCode } = useUrlParams();

  const { data, loading, error } = useGetOfferingByIdQuery({
    variables: { id: offeringId },
    fetchPolicy: 'no-cache',
  });

  const {
    data: offerData,
    loading: offerLoading,
    error: offerError,
  } = useGetOfferByIdQuery({
    variables: { id: offerId },
    fetchPolicy: 'no-cache',
    skip: !offerId,
  });

  const { loading: efcLoading } = useGetEfcData();

  const [loaded, setLoaded] = useState(false);
  const breadCrumbs = OfferTabBreadCrumbs(tdaStore.currentTda, userInfoStore.properties.fullName, offeringsStore.currentOffering, offerType);

  if (loading || offerLoading || efcLoading) {
    return <Loading breadCrumbs={breadCrumbs} />;
  }

  if (error || offerError) {
    return <Error breadCrumbs={breadCrumbs} />;
  }

  // init create
  if (!loaded && !offerId && data) {
    offeringsStore.setCurrentOffering(data.offering);
    offersStore.setCurrentOffer(undefined);

    vehiclesStore.setSeriesOptions();

    regionsStore.setStatesOptions(tdaCode);
    regionsStore.setRegions(userInfoStore.getBrand());

    // will need to add additional condition when saving with marketingData
    offerMarketingStore.setApplyValues(false, false);

    setLoaded(true);
  }

  if (!loaded && data && offerData) {
    offeringsStore.setCurrentOffering(data.offering);
    offersStore.setCurrentOffer(offerData.offer);

    // set vehicles from offer
    vehiclesStore.setVehiclesFromOffer(offerData.offer.vehicles || []);
    trimStore.setVehicles(offerData.offer.vehicles || []);
    vehiclesStore.setSeriesOptions();

    // load included LDAs, state options, LDA options
    const includedLdas = offersStore.currentOffer && offersStore.currentOffer.includedLdas ? (offersStore.currentOffer.includedLdas as string[]) : [];
    ldaStore.setIncludedLdas(includedLdas);
    ldaStore.setLdaOptions(tdaCode);

    // load regions
    regionsStore.setStatesOptions(tdaCode);
    regionsStore.setRegions(userInfoStore.getBrand());

    // will need to add additional condition when saving with marketingData
    offerMarketingStore.setApplyValues(false, false);

    // misc options ??
    if (offersStore.currentOffer?.offerType === OfferTypes.MISCELLANEOUS || offersStore.currentOffer?.offerType === OfferTypes.OTHER) {
      vehiclesStore.setSeriesMiscOptions(offersStore.currentOffer?.vehicles || []);
    }

    setLoaded(true);
  }

  return (
    <ModelCodesLoader
      brand={userInfoStore.getBrand()}
      month={offeringsStore.currentOffering?.startDate.split('-')[1]}
      year={offeringsStore.currentOffering?.startDate.split('-')[0]}
      breadCrumbs={breadCrumbs}
    >
      <EfcCarJellyLoader series={offersStore.currentOffer?.series || ''} year={offersStore.currentOffer?.seriesYear || ''} breadCrumbs={breadCrumbs}>
        <InfoTabComponentBase offerType={offerType} />
      </EfcCarJellyLoader>
    </ModelCodesLoader>
  );
};
export default InfoTabBase;
