import { OfferTabHeaderDetailContainer, OfferTabHeaderDetailLabel, OfferTabHeaderDetailLabelValue, OfferTabHeaderDetailRow } from 'oat-common-ui';
import React from 'react';

interface Props {
  rcf?: string;
  term?: string;
  isTda?: boolean;
  targetPayment?: string;
  dueAtSigning?: string;
}

const LeaseTdaHeaderDetails = ({ term, targetPayment, dueAtSigning }: Props) => {
  return (
    <OfferTabHeaderDetailRow>
      <OfferTabHeaderDetailContainer isMargin={false}>
        <OfferTabHeaderDetailLabelValue>${targetPayment}</OfferTabHeaderDetailLabelValue>
        <OfferTabHeaderDetailLabel> Per Month</OfferTabHeaderDetailLabel>
      </OfferTabHeaderDetailContainer>
      <OfferTabHeaderDetailContainer isMargin={false}>
        <OfferTabHeaderDetailLabelValue>{term}</OfferTabHeaderDetailLabelValue>
        <OfferTabHeaderDetailLabel> Months</OfferTabHeaderDetailLabel>
      </OfferTabHeaderDetailContainer>
      <OfferTabHeaderDetailContainer isMargin={false}>
        <OfferTabHeaderDetailLabelValue>${dueAtSigning}</OfferTabHeaderDetailLabelValue>
        <OfferTabHeaderDetailLabel> due at signing</OfferTabHeaderDetailLabel>
      </OfferTabHeaderDetailContainer>
    </OfferTabHeaderDetailRow>
  );
};

export default LeaseTdaHeaderDetails;
