import { Button, ButtonGroup, InlineInputLabel, Input } from 'oat-common-ui';
import { useState } from 'react';
import { MULTI } from '../../../constants/global';
import useStores from '../../../stores/useStores';
import VehicleImagePickerModal from './components/ImagePickerModal';
import EfcImagePickerModal from './components/ImagePickerModal/EfcImagePickerModal';
import TeamsitePickerModal from './components/ImagePickerModal/TeamsitePickerModal';
import DisplayOfferImage from './DisplayOfferImage';
import styles from './styles.module.scss';

interface OfferImageProps {
  label: string;
  labelWidth: number;
  value: string;
  isDisabledFields?: boolean;
  error: boolean;
  setImagePath: (imageSrc: string) => void;
}

const OfferImage = ({ label, labelWidth, value, error, isDisabledFields = false, setImagePath }: OfferImageProps) => {
  const [imagePickerType, setImagePickerType] = useState<'efc' | 'teamsite' | undefined>(undefined);
  const {
    offersStore: { currentOffer },
  } = useStores();

  return (
    <>
      <div className={styles.offerCardLabelWrapper}>
        <div className={styles.offerCardDataImage}>
          <InlineInputLabel width={labelWidth} label={label} contentClass={styles.inputLabel}>
            <Input darkTheme disabled value={value} error={error} readOnly />
          </InlineInputLabel>
          <div className={styles.carImage}>
            <DisplayOfferImage path={value} />
          </div>
        </div>
        <ButtonGroup className={styles.browseBtnGroup}>
          {value && (
            <Button variant="primary" id="remove-btn" onClick={() => setImagePath('')}>
              Remove
            </Button>
          )}
          <Button variant="primary" id="browse-btn" disabled={isDisabledFields} onClick={() => setImagePickerType('teamsite')}>
            Browse
          </Button>
          {currentOffer && currentOffer.series !== MULTI && (
            <Button variant="primary" id="browse-efc-btn" disabled={isDisabledFields} onClick={() => setImagePickerType('efc')}>
              Browse EFC
            </Button>
          )}
        </ButtonGroup>
        <VehicleImagePickerModal
          title={imagePickerType === 'efc' ? 'Vehicle Image Picker' : 'Images'}
          onClose={() => {
            setImagePickerType(undefined);
          }}
          open={imagePickerType !== undefined}
        >
          {imagePickerType === 'efc' ? (
            <EfcImagePickerModal
              setImagePath={setImagePath}
              imagePath={value}
              closeModal={() => {
                setImagePickerType(undefined);
              }}
            />
          ) : (
            <TeamsitePickerModal
              onSelect={setImagePath}
              closeModal={() => {
                setImagePickerType(undefined);
              }}
            />
          )}
        </VehicleImagePickerModal>
      </div>
    </>
  );
};

export default OfferImage;
