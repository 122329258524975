import { OfferTypes } from 'oat-common-ui';
import React from 'react';
import { FEATURE_OR_5454_PHASE_2 } from '../../../../constants/global';
import data from '../../../../json/miscTypes.json';
import dataV2 from '../../../../json/miscTypesV2.json';
import useStores from '../../../../stores/useStores';
import FinancialTabBase from '../FinancialBase';
import MiscFinancialTabComponent from './MiscFinancialTabComponent';

const MiscFinancialTab = () => {
  const { miscTypesStore } = useStores();

  miscTypesStore.setMiscTypes(FEATURE_OR_5454_PHASE_2 ? dataV2.miscTypes : data.miscTypes);

  return <FinancialTabBase offerType={OfferTypes.MISCELLANEOUS} component={<MiscFinancialTabComponent />} />;
};

export default MiscFinancialTab;
