import { Efc3dImagesResponse } from 'oat-common-ui';
import React, { useState } from 'react';
import useStores from '../../../../../stores/useStores';
import { getEfcImageFullPath } from '../../../../../utils/getEfcImageFullPath';
import styles from './styles.module.scss';

interface SelectTrimProps {
  changePageType: () => void;
  setActiveModelCode: (value: string) => void;
  setExteriorColorCode: (value: string[]) => void;
  seriesImagesData: Efc3dImagesResponse['DataArea'] | undefined;
  activeMultiSeries: { series: string; year: string };
}

const SelectTrim = ({ changePageType, seriesImagesData, setActiveModelCode, setExteriorColorCode, activeMultiSeries }: SelectTrimProps) => {
  const {
    offersStore: { currentOffer },
  } = useStores();

  let filteredVehicles;

  if (activeMultiSeries.year && activeMultiSeries.series) {
    filteredVehicles = currentOffer?.vehicles.filter(vehicle => vehicle.series === activeMultiSeries.series && vehicle.seriesYear === activeMultiSeries.year);
  }

  const [vehicles] = useState(activeMultiSeries.year ? filteredVehicles : currentOffer?.vehicles);

  return (
    <ul className={styles.efcModalList}>
      {vehicles?.map((value, index) => {
        if (!seriesImagesData?.model[value.modelCode]) {
          return <React.Fragment key={index}></React.Fragment>;
        }

        const exterior = Object.keys(seriesImagesData?.model[value.modelCode].exteriorcolor || '');
        const imagePath =
          seriesImagesData?.model[value.modelCode].exteriorcolor[exterior[0]].carjellyimage ||
          seriesImagesData?.model[value.modelCode].exteriorcolor[exterior[0]]['3dimage']['864_477_PNG'][2][0];

        return (
          <li key={index}>
            <button
              id="select-trim-btn"
              className={styles.btn}
              onClick={() => {
                changePageType();
                setActiveModelCode(value.modelCode);
                setExteriorColorCode(exterior);
              }}
            >
              <img className={styles.img} src={getEfcImageFullPath(imagePath || '')} alt="" />
            </button>
            <p>{`${vehicles[index].vehicleDescription} (${vehicles[index].seriesYear}-${vehicles[index].modelCode})`}</p>
          </li>
        );
      })}
    </ul>
  );
};

export default SelectTrim;
