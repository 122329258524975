import { Efc3dImagesResponse } from 'oat-common-ui';
import React from 'react';
import { getEfcImageFullPath } from '../../../../../utils/getEfcImageFullPath';
import styles from './styles.module.scss';

interface SelectAngleProps {
  seriesImagesData: Efc3dImagesResponse['DataArea'] | undefined;
  changePageType: () => void;
  activeModelCode: string;
  activeExteriorColor: string;
  setLocalImagePath: (value: string) => void;
  setAngleImageIndex: (value: number) => void;
}

const SelectAngle = ({ seriesImagesData, changePageType, activeModelCode, activeExteriorColor, setLocalImagePath, setAngleImageIndex }: SelectAngleProps) => {
  const resolutionImageValue = Object.values(seriesImagesData?.model[activeModelCode].exteriorcolor[activeExteriorColor]['3dimage']['864_477_PNG'] || '');

  return (
    <ul className={styles.efcModalList}>
      {resolutionImageValue.map((value, index) => {
        return (
          <li key={value}>
            <button
              id="select-angle-btn"
              className={styles.btn}
              onClick={() => {
                changePageType();
                setLocalImagePath(`${value}`);
                setAngleImageIndex(index + 1);
              }}
            >
              <img className={styles.img} src={getEfcImageFullPath(value)} alt="" />
            </button>
            <p>{seriesImagesData?.['3dimagetags']['exterior'][index + 1]}</p>
          </li>
        );
      })}
    </ul>
  );
};

export default SelectAngle;
