import { AdminConstants } from 'oat-admin-common';
import { User } from '../gql/generated';

const { TdaCodes, RegionCodes } = AdminConstants;

type UserType = 'GST' | 'SET' | 'default';

export const getUserType = (userInfo: User): UserType => {
  if (isSETUser(userInfo)) {
    return TdaCodes.SET;
  } else if (isGst(userInfo)) {
    return TdaCodes.GST;
  } else {
    return 'default';
  }
};

export const isSETUser = (userInfo: User) => {
  return (
    userInfo.userTypes.includes('TDA') && userInfo.userTypes.includes('Regional') && userInfo.tdaCodes.includes(TdaCodes.SET) && userInfo.regionCodes.includes(RegionCodes.SET)
  );
};

const isGst = (userInfo: User) => {
  return userInfo.regionCodes.includes(RegionCodes.GST);
};
