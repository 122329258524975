export const returnOffset = (id: string, offset: number) => {
  return Math.max(document.getElementById(id)?.offsetTop || 0) - offset;
};

const getActiveIndex = (isLexus: boolean, scrollTop: number, templateIdFn: (id: string) => string) => {
  const offset = 300;

  if (scrollTop < offset) {
    return 0;
  } else if (scrollTop < returnOffset(templateIdFn('bdrsSection'), offset)) {
    return 1;
  } else {
    if (!isLexus) {
      if (scrollTop < returnOffset(templateIdFn('offerBadgesSection'), offset)) {
        return 2;
      } else {
        return 3;
      }
    }

    return 2;
  }
};

export const getActiveIndexFromScroll = (isLexus: boolean, scrollTop: number, templateIdFn: (id: string) => string) => {
  return getActiveIndex(isLexus, scrollTop, templateIdFn);
};

export const getScrollTopFromClick = (index: number, isLexus: boolean, templateIdFn: (id: string) => string) => {
  const offset = 265;

  switch (index) {
    case 0:
      return 0;
    case 1:
      return returnOffset(templateIdFn('offerDetailsSection'), isLexus ? offset : 345);
    case 2:
      return returnOffset(templateIdFn('bdrsSection'), offset);
    case 3:
      return returnOffset(templateIdFn('offerBadgesSection'), offset);
    default:
      return undefined;
  }
};
