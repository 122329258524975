import { useLazyQuery } from '@apollo/client';
import { OATFooter, OfferStatus, useToast } from 'oat-common-ui';
import { ReactNode, useEffect, useRef, useState } from 'react';
import authClient from '../../authClient';
import { GetDownloadReportsDocument, useGenerateReportMutation, usePublishOfferingMutation } from '../../gql/generated';
import useStores from '../../stores/useStores';
import LoadingModal from '../LoadingModal';
import PreviewOfferingModal from '../PreviewOfferingModal';
import PublishWarningModal from '../PublishWarningModal';

interface Props {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  darkTheme?: boolean;
  isShowExport?: boolean;
  isShowPublish?: boolean;
  onPublish?: () => void;
  onPreviewPublish?: () => void;
  helpUrl?: string;
}

const MainFooter = ({ onPublish, onPreviewPublish, isShowPublish, ...rest }: Props) => {
  const {
    userInfoStore: { isLexus },
    offersStore: { offers },
    offeringsStore: { currentOffering, setCurrentOffering },
    reportsStore: { availableReports, setAvailableReports },
  } = useStores();

  const { success } = useToast();

  const [openModal, setOpenModal] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [disablePublish, setDisablePublish] = useState(true);
  const [isPublishing, setIsPublishing] = useState(false);
  // get true state if availableReports have at least 1 report inside array
  const [showDownload, setShowDownload] = useState(availableReports.length > 0);

  const downloadLinkRef = useRef<HTMLAnchorElement>(null);

  const [publishOffering] = usePublishOfferingMutation();

  const [generateReport] = useGenerateReportMutation();
  const [runDownloadQuery] = useLazyQuery(GetDownloadReportsDocument, {
    onCompleted: data => {
      const a = document.createElement('a');
      a.href = data.downloadReport;
      a.click();
    },
  });

  const onOfferExport = async () => {
    const { data } = await generateReport({ variables: { offeringId: currentOffering.id, brand: currentOffering.brand } });
    if (data?.generateReport.success) {
      setAvailableReports([data.generateReport.reportUrl]);
      setShowDownload(true);
    }
  };

  const onOfferDownload = () => {
    runDownloadQuery({
      variables: {
        offeringId: currentOffering.id,
        brand: currentOffering.brand,
        key: availableReports[0],
      },
    });
  };

  useEffect(() => {
    const filteredOffers = offers.filter(offer => offer.status === OfferStatus.READY_TO_PUBLISH || (offer.isPublished && offer.status === OfferStatus.PAUSED));

    setDisablePublish(!filteredOffers.length);
  }, [offers]);

  const checkPublish = () => {
    const hasMilitary = offers.find(offer => offer.series === 'MULTI' && offer.optionType === 'Military');
    const hasCollege = offers.find(offer => offer.series === 'MULTI' && offer.optionType === 'College');

    if (hasMilitary && hasCollege) {
      const readyToPublish =
        (hasMilitary.status === OfferStatus.READY_TO_PUBLISH || hasMilitary.status === OfferStatus.PUBLISHED) &&
        (hasCollege.status === OfferStatus.READY_TO_PUBLISH || hasCollege.status === OfferStatus.PUBLISHED);

      if (readyToPublish) {
        handlePublish();
      } else {
        setIsWarning(false);
        setOpenModal(true);
      }
    } else {
      setIsWarning(true);
      setOpenModal(true);
    }
  };

  const handlePublish = async () => {
    setOpenModal(false);
    setIsPublishing(true);
    if (onPublish) {
      onPublish();
    }

    const { data } = await publishOffering({ variables: { id: currentOffering.id, rev: currentOffering.rev } });

    if (data?.publishOffering.success) {
      setIsPublishing(false);
      success('Published offering successfully');
      setCurrentOffering(data?.publishOffering.offering);

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };

  const handlePreviewPublish = () => {
    if (onPreviewPublish) {
      onPreviewPublish();
    }
    setShowPreviewModal(true);
  };

  return (
    <>
      {isShowPublish && showPreviewModal && (
        <PreviewOfferingModal
          onClose={() => {
            setShowPreviewModal(false);
          }}
          isToyota={!isLexus()}
        />
      )}
      {isPublishing && <LoadingModal message="Publishing offering…" />}
      <OATFooter
        {...rest}
        downloadLinkRef={downloadLinkRef}
        isShowDownload={showDownload}
        isShowPreview={isLexus()}
        isShowPublish={isShowPublish}
        onClickLogout={() => authClient.logout()}
        onOfferDownload={onOfferDownload}
        onOfferExport={onOfferExport}
        onPublish={checkPublish}
        onPreviewPublish={handlePreviewPublish}
        isDisablePublish={disablePublish}
      />
      {openModal && <PublishWarningModal isWarning={isWarning} onSubmit={handlePublish} onClose={() => setOpenModal(false)} />}
    </>
  );
};

export default MainFooter;
