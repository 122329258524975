import { AdminConstants } from 'oat-admin-common';
import { BreadCrumbType } from 'oat-common-ui';
import { Link, NavLink } from 'react-router-dom';
import { getRegionNameFromRegionCode } from '../../utils/getRegionNameFromRegionCode';
import { getUserTdas } from '../../utils/getUserTdas';
import styles from './styles.module.scss';
import { FEATURE_OR_4159 } from '../../constants/global';
import clsx from 'clsx';
import TdaOption from '../../models/TdaType';

const { BRAND_LEXUS } = AdminConstants;

export const SettingsNav = (tdaCode: string, isLexus: boolean, userTdas: string, setCurrentTda?: (region: TdaOption) => void, isTokenPage = false, isTemplatePage = false) => {
  const links = [
    { name: '', linkComponent: <NavLink to={`/${tdaCode}/settings/personal`}>Personal Settings</NavLink> },
    {
      name: 'Token Settings',
      linkComponent: (
        <NavLink to={`/${tdaCode}/settings/tokens${FEATURE_OR_4159 && isLexus ? '/master' : ''}`} className={clsx(isTokenPage && styles.isActive)}>
          Token Settings
        </NavLink>
      ),
    },
    {
      name: 'Templates',
      linkComponent: (
        <NavLink to={`/${tdaCode}/settings/offerTemplates${FEATURE_OR_4159 && isLexus ? '/master' : ''}`} className={clsx(isTemplatePage && styles.isActive)}>
          Templates
        </NavLink>
      ),
    },
  ];

  const tdas = getUserTdas(userTdas, BRAND_LEXUS);

  if (isLexus && isTokenPage && FEATURE_OR_4159) {
    const tokenIndex = links.findIndex(link => link.name.includes('Token Settings')) + 1;

    links.splice(tokenIndex, 0, {
      name: '',
      linkComponent: (
        <NavLink to={`/${tdaCode}/settings/tokens/master`} className={styles.regionToken}>
          <p className={styles.regionName}>Master Tokens</p>
        </NavLink>
      ),
    });

    tdas.forEach((region, index) => {
      if (tokenIndex) {
        // add after master tokens
        links.splice(tokenIndex + 1 + index, 0, {
          name: '',
          linkComponent: (
            <NavLink
              to={`/${region.code}/settings/tokens/${region.name.toLocaleLowerCase()}`}
              onClick={() => setCurrentTda && setCurrentTda(region)}
              className={styles.regionToken}
            >
              <p className={styles.regionName}>{region.name}</p>
            </NavLink>
          ),
        });
      }
    });
  }
  if (isLexus && isTemplatePage && process.env.REACT_APP_FEATURE_OR_4594 === 'true') {
    const templateIndex = links.findIndex(link => link.name.includes('Templates')) + 1;

    links.splice(templateIndex, 0, {
      name: '',
      linkComponent: (
        <NavLink to={`/${tdaCode}/settings/offerTemplates/master`} className={styles.regionToken}>
          <p className={styles.regionName}>Master Templates</p>
        </NavLink>
      ),
    });

    tdas.forEach((region, index) => {
      if (templateIndex) {
        // add after master template
        links.splice(templateIndex + 1 + index, 0, {
          name: '',
          linkComponent: (
            <NavLink
              to={`/${region.code}/settings/offerTemplates/${region.name.toLocaleLowerCase()}`}
              onClick={() => setCurrentTda && setCurrentTda(region)}
              className={styles.regionToken}
            >
              <p className={styles.regionName}>{region.name}</p>
            </NavLink>
          ),
        });
      }
    });
  }

  return links;
};

export const SettingsBreadCrumbs = (
  title: string,
  tdaCode: string,
  type?: 'Template' | 'Token' | 'Master Token' | 'Master Template',
  isLexus?: boolean,
  offerTemplateId?: string,
  isTemplateDetailPage = false,
  tdaName?: string,
) => {
  const settingsBreadCrumbs: BreadCrumbType[] = [{ name: '', component: <Link to={`/${tdaCode}/dashboard`}>{title}</Link> }];
  const isTemplate = type === 'Template' || type === 'Master Template';
  const isMaster = type === 'Master Template' || type === 'Master Token';
  const isToken = type === 'Token' || type === 'Master Token';
  const settingsText = isTemplate ? 'Templates' : isToken ? 'Token Settings' : 'Settings';

  if ((FEATURE_OR_4159 || process.env.REACT_APP_FEATURE_OR_4594 === 'true') && isLexus) {
    settingsBreadCrumbs.push(
      {
        name: `${settingsText}`,
        component: isTemplateDetailPage && <Link to={`/${tdaCode}/settings/offerTemplates/${tdaName}`}>{settingsText}</Link>,
      },
      {
        name: `${isMaster ? `${type}s` : getRegionNameFromRegionCode(tdaCode)}`,
        component: isTemplateDetailPage && <Link to={`/${tdaCode}/settings/offerTemplates/${tdaName}`}>{`${isMaster ? `${type}s` : getRegionNameFromRegionCode(tdaCode)}`}</Link>,
      },
    );
  } else {
    settingsBreadCrumbs.push(isTemplate ? { name: '', component: <Link to={`/${tdaCode}/settings/offerTemplates`}>Settings</Link> } : { name: 'Settings' });
  }

  if (isTemplateDetailPage) {
    const newTemplate = !offerTemplateId ? 'New' : '';
    const marketingTeam = (!isLexus ? AdminConstants.APP_TDA : AdminConstants.APP_LDA).toUpperCase();
    const templateName = `${newTemplate} ${marketingTeam} Template`;

    settingsBreadCrumbs.push({ name: templateName });
  }

  return settingsBreadCrumbs;
};
