import { APRCalcFns } from 'oat-admin-common';
import { isNullOrUndefined, sortFieldsHelper } from 'oat-common-ui';
import { OfferRate } from '../../../../gql/generated';

export const validateRates = (rates: OfferRate[]) => {
  let invalidRates = false;
  let requiredError = false;

  invalidRates =
    rates.filter(item => {
      if (item.subventionRate === undefined || item.subventionRate === null) {
        requiredError = true;
      }

      let rateSubCashInvalid = false;

      if (!isNullOrUndefined(item.subventionRate) || !isNullOrUndefined(item.approvedSubventionRate)) {
        const subRate = item.subventionRate || 0;
        const apprRate = item.approvedSubventionRate || 0;
        rateSubCashInvalid = subRate > apprRate;
      }

      if (!isNullOrUndefined(item.subventionCash) || !isNullOrUndefined(item.approvedSubventionCash)) {
        const subCash = item.subventionCash || 0;
        const apprCash = item.approvedSubventionCash || 0;
        rateSubCashInvalid = subCash < apprCash || rateSubCashInvalid;
      }

      return rateSubCashInvalid;
    }).length > 0;

  return {
    invalidRates,
    requiredError,
  };
};

export const sortRatesByTerm = (rates: OfferRate[] | undefined | null) => {
  return (rates || []).slice().sort(sortFieldsHelper('term', true));
};

export const calcDptForSubmit = (rates: OfferRate[]) => {
  return rates.map(item => {
    // bugfix for or-1087 - calculate dpt if its null
    const newItem = item;
    if (newItem.dollarPerThousand === null && newItem.subventionRate !== null && newItem.subventionRate !== undefined) {
      newItem.dollarPerThousand = Number(APRCalcFns.calculateDollarsPerThousand(newItem.subventionRate, newItem.term));
    }
    return newItem;
  });
};
