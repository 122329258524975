import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import {
  assignBooleanValue,
  assignStringValue,
  Button,
  ButtonGroup,
  CashHeaderDetails,
  DefaultModal,
  Dropdown,
  formatNumber,
  InlineInputLabel,
  Input,
  OATIcon,
  OfferTabBodyLayout,
  OfferTabError,
  OfferTabsHeader,
  OfferTabsNav,
  OfferTypes,
  stringToNumber,
  Textarea,
  useInput,
  usePromiseLoading,
  useToast,
} from 'oat-common-ui';
import React, { useState } from 'react';
import OfferTabNavList from '../../../../components/OfferTabComponents/OfferTabNavList';
import { FEATURE_OR_4616, FEATURE_OR_5454_PHASE_2, SEND_EMAIL_PROMPT, SEND_EMAIL_SUCCESS } from '../../../../constants/global';
import { Offer, useRequestRegionalAtcMutation } from '../../../../gql/generated';
import useUrlParams from '../../../../hooks/useUrlParams';
import { UserType } from '../../../../stores/UserInfoStore';
import useStores from '../../../../stores/useStores';
import { getEfcCarJelly } from '../../../../utils/efc3dImageUtils';
import getSeriesInfoFromOffer from '../../../../utils/getSeriesInfoFromOffer';
import { getUserType } from '../../../../utils/getUserType';
import StackedOffer from '../components/StackedOffer';
import StackedOfferModal from '../components/StackedOfferModal';
import FinanceModificationDialogue from '../FinanceModificationDialogue';
import styles from './styles.module.scss';

const { CASH_TYPES, OPTION_TYPE_NAMES } = AdminConstants;

interface Props {
  isMisc: boolean;
  offer?: Offer;
  extraCashTypes?: string[];
  showSpanish?: boolean;
  onSubmit: (
    cashOfferAmount: number,
    extraCashOfferAmount: number | null,
    extraCashOfferAmountLabel: string,
    extraCashOfferAmountLabelSpanish: string,
    fundingSource: string,
    back: boolean,
    next: boolean,
    extraCashType: string,
    tdaPackage?: string,
    targetedAudience?: string,
    targetedAudienceSpanish?: string,
  ) => Promise<void>;
}

const labelWidth = 250;

const targetTypes: string[] = [
  CASH_TYPES.LOYALTY,
  CASH_TYPES.LEASE_LOYALTY,
  CASH_TYPES.CONQUEST,
  CASH_TYPES.CAMPAIGN,
  CASH_TYPES.TARGETED_CERTIFICATE,
  FEATURE_OR_5454_PHASE_2 ? CASH_TYPES.COLLEGE : CASH_TYPES.COLLEGE_GRADUATE,
  CASH_TYPES.MILITARY,
];

const CashMiscFinancialForm = ({ isMisc, offer, extraCashTypes, showSpanish, onSubmit }: Props) => {
  const {
    efcStore,
    offersStore: { currentOffer },
    offeringsStore: { currentOffering },
    userInfoStore: { isLexus, userInfo },
    stackedOffersStore: { stackedOffers },
  } = useStores();

  const userType = getUserType(userInfo);

  const showTargetAudTrans = isMisc && targetTypes.includes(assignStringValue(offer?.optionType));

  const [cashOfferAmount, cashOfferAmountBindFn, cashOfferAmountError, setCashOfferAmount] = useInput(offer?.combinedPerUnitCost, {
    required: true,
    isWhole: true,
    min: 0,
  });
  const [extraCashOfferAmount, extraCashOfferBindFn] = useInput(offer?.extraCashAmount, { isWhole: true, min: 0 });
  const [extraCashOfferAmountLabel, extraCashOfferAmountLabelBindFn] = useInput(offer?.extraCashLabel);
  const [extraCashOfferAmountLabelSpanish, extraCashOfferAmountLabelSpanishBindFn] = useInput(offer?.extraCashLabelSpanish);
  const [extraCashType, setExtraCashType] = useState(extraCashTypes?.find(item => item === offer?.extraCashOfferType) || '');
  const [fundingSource, fundingSourceBindFn, fundingSourceError] = useInput(offer?.fundingSource, { required: true });
  const [targetedAudience, targetedAudienceBindFn, targetedAudienceError] = useInput(offer?.targetedAudience);
  const [targetedAudienceSpanish, targetedAudienceSpanishBindFn, targetedAudienceSpanishError] = useInput(offer?.targetedAudienceSpanish, {
    required: showTargetAudTrans && !isLexus(),
  });
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [tdaPackage, tdaPackageBindFn, tdaPackageError, setTdaPackage] = useInput<string>(assignStringValue(offer?.tdaPackage), { required: true });

  const [isOpenAddStackedOfferModal, setIsOpenAddStackedOfferModal] = useState(false);

  const { lang, offerId, tdaCode, site } = useUrlParams();

  const [requestRegionalAtc] = useRequestRegionalAtcMutation();

  const { promiseLoading, loadPromise } = usePromiseLoading();
  const { success, error } = useToast();

  const offerType = isMisc ? OfferTypes.MISCELLANEOUS : OfferTypes.CUSTOMER_CASH;
  const showTdaPackage = !offer?.isSpecialEdition && extraCashType === AdminConstants.SPECIAL_EDITION_BONUS_TYPE && (userType === UserType.GST || tdaCode === UserType.GST);

  // Extra Cash Type flags
  const isExtraCashTypeDisabled = !extraCashOfferAmount && !extraCashOfferAmountLabel && (!showSpanish || !extraCashOfferAmountLabelSpanish);
  const isExtraCashTypeError = !isLexus() && !isExtraCashTypeDisabled && !extraCashType;
  const cashAmountMinError = Number(cashOfferAmount || 0) < (offer?.approvedCombinedPerUnitCost || 0);
  const hasError =
    !!cashOfferAmountError ||
    !!fundingSourceError ||
    isExtraCashTypeError ||
    !!targetedAudienceError ||
    !!targetedAudienceSpanishError ||
    cashAmountMinError ||
    (showTdaPackage && !!tdaPackageError);

  const handleOnSubmit = async (back: boolean, next: boolean) => {
    if (hasError) {
      return;
    }

    await loadPromise(
      onSubmit(
        Number(cashOfferAmount),
        stringToNumber(extraCashOfferAmount),
        extraCashOfferAmountLabel,
        extraCashOfferAmountLabelSpanish,
        fundingSource,
        back,
        next,
        extraCashType,
        tdaPackage,
        targetedAudience,
        targetedAudienceSpanish,
      ),
    );
  };

  const checkEmptyExtraCashValues = (amount: string, label: string, labelSpanish: string) => {
    if (!amount && !label && !labelSpanish) {
      setExtraCashType('');
      setTdaPackage(assignStringValue(offer?.tdaPackage));
    }
  };

  const handleExtraCashOfferAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    checkEmptyExtraCashValues(e.currentTarget.value, extraCashOfferAmountLabel, extraCashOfferAmountLabelSpanish);
    extraCashOfferBindFn.onChange(e);
  };

  const handleExtraCashOfferLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    checkEmptyExtraCashValues(extraCashOfferAmount, e.currentTarget.value, extraCashOfferAmountLabelSpanish);
    extraCashOfferAmountLabelBindFn.onChange(e);
  };

  const handleExtraCashOfferLabelSpanishChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    checkEmptyExtraCashValues(extraCashOfferAmount, extraCashOfferAmountLabel, e.currentTarget.value);
    extraCashOfferAmountLabelSpanishBindFn.onChange(e);
  };

  const handleResetToApproved = () => {
    setCashOfferAmount(assignStringValue(offer?.approvedCombinedPerUnitCost?.toString()));
  };

  const handleSendEmail = async () => {
    try {
      await requestRegionalAtc({
        variables: {
          input: {
            offerId: assignStringValue(currentOffer?.id),
          },
        },
      });
      success(SEND_EMAIL_SUCCESS);
    } catch (e) {
      error((e as Error).message);
    } finally {
      setOpenEmailModal(false);
    }
  };

  const handleExtraCashTypeChange = (val: string) => {
    if (val !== AdminConstants.SPECIAL_EDITION_BONUS_TYPE && !offer?.isSpecialEdition) {
      setTdaPackage(assignStringValue(offer?.tdaPackage));
    }
    setExtraCashType(val);
  };

  return (
    <>
      <OfferTabsHeader
        endDate={assignStringValue(offer?.endDate)}
        status={assignStringValue(offer?.status)}
        seriesMap={efcStore.uniqueYearSeries}
        offerImage={getEfcCarJelly(efcStore.seriesImages, ...getSeriesInfoFromOffer(offer))}
        offerSeries={assignStringValue(offer?.series)}
        offerSeriesYear={assignStringValue(offer?.seriesYear)}
        offerType={offerType}
        renderHeaderContent={<CashHeaderDetails isMisc={isMisc} cashAmount={cashOfferAmount} miscType={assignStringValue(offer?.optionType)} />}
        renderNav={<OfferTabsNav navList={OfferTabNavList(currentOffering.id, tdaCode, offerType, lang, offerId, site)} />}
      />
      <OfferTabBodyLayout
        renderContent={
          <>
            <div className={styles.form}>
              <InlineInputLabel vertical width={labelWidth} label="Offer Type">
                <Dropdown id="fin-offer-type-dd" darkTheme value={{ value: offerType, label: offerType }} disabled onChange={() => {}} options={[]} />
              </InlineInputLabel>
              {offer && offer.optionType === OPTION_TYPE_NAMES.TFS_LEASE_SUBVENTION_CASH && (
                <InlineInputLabel vertical width={labelWidth} label="Term">
                  <Dropdown
                    id="fin-sub-cash-term-dd"
                    darkTheme
                    value={{ value: `${offer.subCashTerm}`, label: `${offer.subCashTerm}` }}
                    disabled
                    onChange={() => {}}
                    options={[]}
                  />
                </InlineInputLabel>
              )}
              <InlineInputLabel vertical width={labelWidth} label="Cash Offer Amount">
                <Input
                  id="fin-cash-offer-amount"
                  darkTheme
                  dollarSign
                  value={cashOfferAmount}
                  displayValue={formatNumber(cashOfferAmount, true)}
                  {...cashOfferAmountBindFn}
                  error={cashOfferAmountError || cashAmountMinError}
                />
              </InlineInputLabel>
              {!FEATURE_OR_4616 && (
                <>
                  <InlineInputLabel vertical width={labelWidth} label="Extra Cash Offer Amount">
                    <Input
                      id="fin-extra-cash-offer-amount"
                      darkTheme
                      dollarSign
                      value={extraCashOfferAmount}
                      displayValue={formatNumber(extraCashOfferAmount, true)}
                      {...extraCashOfferBindFn.onBlur}
                      onChange={handleExtraCashOfferAmountChange}
                    />
                  </InlineInputLabel>
                  <InlineInputLabel vertical width={labelWidth} label="Extra Cash Offer Amount Label">
                    <Input
                      id="fin-extra-cash-offer-amount-label"
                      darkTheme
                      value={extraCashOfferAmountLabel}
                      {...extraCashOfferAmountLabelBindFn}
                      onChange={handleExtraCashOfferLabelChange}
                    />
                  </InlineInputLabel>
                  {showSpanish && (
                    <InlineInputLabel vertical width={labelWidth} label="Extra Cash Offer Amount Label (Spanish)">
                      <Input
                        id="fin-extra-cash-offer-amount-label-spanish"
                        darkTheme
                        value={extraCashOfferAmountLabelSpanish}
                        {...extraCashOfferAmountLabelSpanishBindFn}
                        onChange={handleExtraCashOfferLabelSpanishChange}
                      />
                    </InlineInputLabel>
                  )}
                  {extraCashTypes && (
                    <InlineInputLabel vertical width={labelWidth} label="Extra Cash Offer Type">
                      <Dropdown
                        id="fin-extra-cash-offer-type-dd"
                        darkTheme
                        value={extraCashType}
                        onChange={item => handleExtraCashTypeChange(item.value)}
                        options={extraCashTypes}
                        error={isExtraCashTypeError}
                        disabled={isExtraCashTypeDisabled}
                      />
                    </InlineInputLabel>
                  )}
                </>
              )}
              {showTdaPackage && (
                <InlineInputLabel vertical width={labelWidth} label="Package">
                  <Input
                    className={styles.field}
                    id="fin-series-package"
                    value={tdaPackage}
                    {...tdaPackageBindFn}
                    onChange={e => setTdaPackage(e.currentTarget.value)}
                    darkTheme
                    error={tdaPackageError}
                  />
                </InlineInputLabel>
              )}
              {isMisc && (
                <InlineInputLabel vertical width={labelWidth} label="Miscellaneous Type">
                  <Input id="fin-misc-type" disabled value={offer?.optionType || ''} />
                </InlineInputLabel>
              )}
              <InlineInputLabel vertical width={labelWidth} label="Funding Source">
                <Input id="fin-funding-source" darkTheme value={fundingSource} {...fundingSourceBindFn} error={fundingSourceError} />
              </InlineInputLabel>
              {showTargetAudTrans && (
                <>
                  <InlineInputLabel vertical width={labelWidth} label="Targeted Audience (English)">
                    <Textarea
                      id="fin-target-audience"
                      className={clsx(styles.textArea, targetedAudienceError && styles.error)}
                      darkTheme
                      value={targetedAudience}
                      {...targetedAudienceBindFn}
                      error={targetedAudienceError}
                      disabled
                    />
                  </InlineInputLabel>
                  <InlineInputLabel vertical width={labelWidth} label="Targeted Audience (Spanish)">
                    <Textarea
                      id="fin-target-audience-es"
                      className={clsx(styles.textArea, targetedAudienceSpanishError && styles.error)}
                      darkTheme
                      value={targetedAudienceSpanish}
                      error={targetedAudienceSpanishError}
                      {...targetedAudienceSpanishBindFn}
                      disabled={isLexus()}
                    />
                  </InlineInputLabel>
                  <InlineInputLabel vertical width={labelWidth} label="Transferable">
                    <Input id="fin-transferable" disabled darkTheme value={offer?.isTransferable ? 'Yes' : 'No'} />
                  </InlineInputLabel>
                </>
              )}
            </div>
            {offer?.needsReview && <FinanceModificationDialogue reset={handleResetToApproved} email={() => setOpenEmailModal(true)} />}
            {cashOfferAmount === '' && <OfferTabError />}
            {FEATURE_OR_4616 && (
              <>
                <StackedOffer labelWidth={labelWidth} showSpanish={assignBooleanValue(showSpanish)} />
                <StackedOfferModal offerType={offerType} open={isOpenAddStackedOfferModal} onClose={() => setIsOpenAddStackedOfferModal(!isOpenAddStackedOfferModal)} />
                {stackedOffers.length < 2 && (
                  <Button className={styles.addStackedOfferCta} onClick={() => setIsOpenAddStackedOfferModal(true)}>
                    <OATIcon icon="add" size={18} colorTheme="blue" />
                    <span>Add Stacked Offer</span>
                  </Button>
                )}
              </>
            )}
          </>
        }
        renderFooter={
          <ButtonGroup>
            <Button id="fin-save-btn" variant="primary" disabled={promiseLoading || hasError} onClick={() => handleOnSubmit(true, false)}>
              Back
            </Button>
            <Button id="fin-submit-btn" variant="primary" disabled={promiseLoading || hasError} onClick={() => handleOnSubmit(false, false)}>
              Save
            </Button>
            <Button id="fin-submit-btn" variant="primary" disabled={promiseLoading || hasError} onClick={() => handleOnSubmit(false, true)}>
              Next
            </Button>
          </ButtonGroup>
        }
      />
      {openEmailModal && <DefaultModal title="Send Email" message={SEND_EMAIL_PROMPT} open onSubmit={handleSendEmail} onClose={() => setOpenEmailModal(false)} />}
    </>
  );
};

export default observer(CashMiscFinancialForm);
