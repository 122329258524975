import { APRHeaderDetails, CashHeaderDetails, OfferTypes } from 'oat-common-ui';
import useStores from '../../stores/useStores';
import getAPRHeaderDetails from '../../utils/getAPRHeaderDetails';
import LeaseTdaHeaderDetails from './LeaseTdaHeaderDetails';

type Props = {
  offerType: OfferTypes;
};

/**
 * Renders header details. Not used in financial tab because values need to change
 */
const RenderHeaderDetails = ({ offerType }: Props) => {
  const {
    offersStore: { currentOffer },
  } = useStores();

  switch (offerType) {
    case OfferTypes.APR: {
      const { subventionCash, subventionRate, term } = getAPRHeaderDetails(currentOffer);
      return <APRHeaderDetails apr={subventionRate} terms={term} subVen={subventionCash} />;
    }
    case OfferTypes.LEASE: {
      const dueAtSigning = currentOffer?.leaseDetails && currentOffer?.leaseDetails[0] ? currentOffer?.leaseDetails[0].dueAtSigning.toString() : '';
      const term = currentOffer?.rates && currentOffer?.rates[0] ? currentOffer?.rates[0].term.toString() : '';
      const targetPayment = currentOffer?.leaseDetails && currentOffer?.leaseDetails[0] ? currentOffer?.leaseDetails[0].targetPayment.toString() : '';

      return <LeaseTdaHeaderDetails dueAtSigning={dueAtSigning} term={term} targetPayment={targetPayment} />;
    }
    case OfferTypes.CUSTOMER_CASH: {
      return <CashHeaderDetails isMisc={false} cashAmount={currentOffer?.combinedPerUnitCost?.toString() || ''} miscType="" />;
    }
    case OfferTypes.MISCELLANEOUS: {
      return <CashHeaderDetails isMisc cashAmount={currentOffer?.combinedPerUnitCost?.toString() || ''} miscType={currentOffer?.optionType || ''} />;
    }
    default: {
      return null;
    }
  }
};

export default RenderHeaderDetails;
