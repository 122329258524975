import { useLocation, useParams } from 'react-router-dom';

type ParamsType = {
  offeringId: string;
  tdaCode: string;
  offerTemplateId: string;
  tdaName: string;
};

const useUrlParams = () => {
  const { offeringId, tdaCode, offerTemplateId, tdaName } = useParams<ParamsType>();
  const query = new URLSearchParams(useLocation().search);
  const offerId = query.get('offerId') ?? '';
  const lang = query.get('lang') ?? '';
  const site = query.get('site') ?? '';
  const copy = query.get('copy') ?? '';

  return {
    site,
    tdaCode,
    offeringId,
    offerTemplateId,
    offerId,
    lang,
    copy,
    tdaName,
  };
};

export type TUrlParams = ReturnType<typeof useUrlParams>;

export default useUrlParams;
