import { Offer } from '../gql/generated';

const getAPRHeaderDetails = (offer?: Offer) => {
  const aprData = offer?.rates?.find(rate => rate?.isAdvertised === true);
  const subventionRate = aprData && aprData.subventionRate ? aprData.subventionRate.toString() : '0';
  const term = aprData && aprData.term ? aprData.term.toString() : '';
  const subventionCash = aprData && aprData.subventionCash ? aprData.subventionCash.toString() : '';

  return { subventionRate, term, subventionCash };
};

export default getAPRHeaderDetails;
