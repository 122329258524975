import { UpdateOfferInput } from '../gql/generated';

const getLeasePayload = (payload: Partial<UpdateOfferInput>): Partial<UpdateOfferInput> => {
  const leaseDetails = payload.leaseDetails;
  if (leaseDetails?.length && leaseDetails[0]) {
    const leaseDetail = leaseDetails[0];
    payload.leaseDetails = [
      {
        name: leaseDetail.name,
        isExample: leaseDetail.isExample,
        estimatedCost: leaseDetail.estimatedCost,
        targetPayment: leaseDetail.targetPayment,
        dueAtSigning: leaseDetail.dueAtSigning,
        dealerGross: leaseDetail.dealerGross,
        downPayment: leaseDetail.downPayment,
        additionalCash: leaseDetail.additionalCash,
        isAddtlCashAppliedToDueAtSigning: leaseDetail.isAddtlCashAppliedToDueAtSigning,
        series: leaseDetail.series,
        seriesYear: leaseDetail.seriesYear,
        modelCode: leaseDetail.modelCode,
        vehicleDescription: leaseDetail.vehicleDescription,
        vin: leaseDetail.vin,
        baseMsrp: leaseDetail.baseMsrp,
        totalMsrp: leaseDetail.totalMsrp,
        residualAmount: leaseDetail.residualAmount,
        grossCapCost: leaseDetail.grossCapCost,
        netCapCost: leaseDetail.netCapCost,
        totalDealerGross: leaseDetail.totalDealerGross,
        acquisitionFee: leaseDetail.acquisitionFee,
        dealerCostPerInvoice: leaseDetail.dealerCostPerInvoice,
        adjustmentFactor: leaseDetail.adjustmentFactor,
        tfsShare: leaseDetail.tfsShare,
        financialReserve: leaseDetail.financialReserve,
        holdback: leaseDetail.holdback,
        tdaFee: leaseDetail.tdaFee,
        dispositionFee: leaseDetail.dispositionFee,
        mileageOverageFee: leaseDetail.mileageOverageFee,
        isSpecialEdition: leaseDetail.isSpecialEdition,
        port: leaseDetail.port,
        mileage: leaseDetail.mileage,
        accessories: leaseDetail.accessories,
      },
    ];
  }

  return payload;
};

export default getLeasePayload;
