import { AdminConstants } from 'oat-admin-common';
import { IMultiItem, sortFieldsHelper } from 'oat-common-ui';
import { LdaCode } from '../../../constants/global';
import { OfferVehicle } from '../../../gql/generated';
import ldasJSON from '../../../json/ldas.json';
import LdaOption from '../../../models/LdaOption';
import { PortStates } from '../../../stores/RegionsStore';


/**
 * Combines excluded/included to make full list. Uses selected included to output
 * new full excluded/included lists.
 * @param excluded full list
 * @param included full list
 * @param includedSelections selections from MultiPane
 */
export const processIncomingFilteredLists = (excluded: IMultiItem[], included: IMultiItem[], includedSelections: IMultiItem[]) => {
  const wholeList = [...excluded, ...included];
  const currentExcluded = wholeList.filter(item => !includedSelections.find(iItem => iItem.id === item.id));
  currentExcluded.forEach(item => (item.selected = false));

  return {
    includedList: includedSelections.sort(sortFieldsHelper('index', true)),
    excludedList: currentExcluded.sort(sortFieldsHelper('index', true)),
  };
};

/**
 * Applies year filter from excluded year dropdown
 * @param year
 * @param name
 * @param excluded full list
 */
export const applyExcludedMultiFilters = (year: string | undefined, name: string | undefined, yearFilter: string | undefined, excluded: IMultiItem[]) => {
  const yearToUse = year === AdminConstants.MULTI ? yearFilter : year;
  return excluded.filter(item => {
    return doesYearSeriesIdMatch(item.id, yearToUse || '', name || '');
  });
};

/**
 * Create multiItem trimlists from vehicles data
 * Used when going from seriesManager to trim
 * @param vehicles
 * @param series
 * @param seriesYear
 * @param selectedSeriesManager
 * @returns
 */
export const getTrimListsFromVehicles = (
  vehicles: OfferVehicle[],
  series: string,
  seriesYear: string,
  selectedSeriesManager?: IMultiItem[],
  otherOfferAllVehicles?: OfferVehicle[],
) => {
  const vehiclesToFilter = otherOfferAllVehicles ? otherOfferAllVehicles : vehicles || [];

  const filteredVehicles: IMultiItem[] = vehiclesToFilter
    .sort(sortFieldsHelper('seriesYear', true))
    .sort(sortFieldsHelper('series', true))
    .filter(item => item.series === series && item.seriesYear === seriesYear)
    .map((item, index) => ({
      id: `${item.seriesYear} ${item.modelCode}`,
      data: getTrimDescription(item.vehicleDescription, item.modelCode),
      selected: false,
      index,
    }));

  const inclusionValue = selectedSeriesManager ? selectedSeriesManager.filter(sItem => sItem.id === `${seriesYear} ${series}`).length > 0 : false;
  return {
    excludedTrimList: inclusionValue ? [] : filteredVehicles,
    includedTrimList: inclusionValue ? filteredVehicles : [],
  };
};

export const getTrimDescription = (description: string, modelCode: string) => {
  return description.includes(modelCode) ? description : `(${modelCode}) ${description}`;
};

export const transformStringToMultiItem = (list: string[]): IMultiItem[] => {
  return list?.map((item: string, index: number) => ({
    id: item,
    data: item,
    selected: false,
    index,
  }));
};

// for on initial render
export const portStatesHandler = (statesList: IMultiItem[], portsList: IMultiItem[], tdaCode: string, portStates: PortStates) => {
  const newStatesList: IMultiItem[] = [];

  if (tdaCode === LdaCode.LWA || tdaCode === LdaCode.LSA) {
    portsList.forEach(port => {
      if (portStates[tdaCode][port.data].length) {
        portStates[tdaCode][port.data].forEach(state => {
          if (!newStatesList.find(item => item.data === state)) {
            newStatesList.push({ id: state, data: state, selected: false, disabled: true });
          }
        });
      }
    });

    return newStatesList;
  }

  return statesList;
};


export const doesYearSeriesIdMatch = (id: string, year: string, series: string) => {
  const split = id.split(' ');
  return (year === AdminConstants.MULTI || year === 'All' || year === split[0]) && (series === AdminConstants.MULTI || series === split[1]);
};

export const processLdaOptions = (options: LdaOption[]): IMultiItem[] => {
  return options.map(lda => ({ id: lda.code, data: `${lda.name}-${lda.code}` }));
};

export const mapIncludedLdas = (values: string[]) => {
  // Vals were stores like "Denver-23423",
  const getVal = (val: string) => {
    const split = val.split('-');
    const index = split.length - 1;
    return index >= 0 ? split[index] : val;
  };

  return processLdaOptions(ldasJSON.ldas.filter(lda => values.find(val => getVal(val) === lda.code)));
};

export const mapExcludedSeriesYearsFromMultiItems = (multiItems: IMultiItem[], includedMultiItems: IMultiItem[]) => {
  const seriesYears: string[] = [];
  multiItems.forEach(item => {
    const split = item.id.split(' ');
    if (!includedMultiItems.some(excluded => excluded.id === item.id) && !seriesYears.includes(split[0])) {
      seriesYears.push(split[0]);
    }
  });  
  
  return seriesYears.sort((year1, year2) => year1.localeCompare(year2));
}
