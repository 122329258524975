import { isFeatureEnabled } from 'oat-admin-common';
import { IMultiItem, sortFieldsHelper } from 'oat-common-ui';
import { FeatureFlags } from '../constants/global';
import { Offer, OfferVehicle } from '../gql/generated';
import { getTrimDescription } from '../pages/OfferTabs/InfoTab/utils';

/**
 * Initial function for retrieving exclusions/inclusion list from vehicle data
 * @param vehicles
 * @param seriesYearOnly
 * @returns
 */
const getVehicleInclusionList = (offer: Offer | undefined, seriesYearOnly = false, otherOfferAllVehicles?: OfferVehicle[]): [IMultiItem[], IMultiItem[]] => {
  const vehicles = offer && offer.vehicles ? offer.vehicles : otherOfferAllVehicles || [];

  const inclusions: IMultiItem[] = [];
  const exclusions: IMultiItem[] = [];

  const idMap = new Set<string>();

  vehicles
    .sort(sortFieldsHelper('seriesYear', true))
    .sort(sortFieldsHelper('series', true))
    .map((item, i) => ({ ...item, index: i }))
    .forEach((item, index) => {
      const id = seriesYearOnly ? `${item.seriesYear} ${item.series}` : `${item.seriesYear} ${item.modelCode}`;
      const description = seriesYearOnly ? `${item.seriesYear} ${item.series}` : getTrimDescription(item.vehicleDescription, item.modelCode);
      const seriesMatch = seriesYearOnly || (item.seriesYear === offer?.seriesYear && item.series === offer?.series);
      const canPush = !idMap.has(id) && seriesMatch;
      if (canPush) {
        const itemToPush = {
          id,
          data: description,
          selected: false,
          index,
        };

        const isIncluded = vehicles.find(
          series =>
            ((seriesYearOnly && series.series === item.series && series.seriesYear === item.seriesYear) ||
              (!seriesYearOnly && series.series === item.series && series.modelCode === item.modelCode)) &&
            series.isInclusion,
        );

        if ((isFeatureEnabled(FeatureFlags.OR_4619) && isIncluded) || (!isFeatureEnabled(FeatureFlags.OR_4619) && item.isInclusion)) {
          inclusions.push(itemToPush);
        } else {
          exclusions.push(itemToPush);
        }
        idMap.add(id);
      }
    });

  return [inclusions, exclusions];
};

export default getVehicleInclusionList;
