import { BlockInputLabel, CustomDatePicker, dateFormatISOString, InlineInputLabel, InputContainer, Modal, ModalBody, ModalFooter, ModalHeader } from 'oat-common-ui';
import { useState } from 'react';
import { usePreviewOfferingMutation } from '../../gql/generated';
import useStores from '../../stores/useStores';
import styles from './styles.module.scss';

interface PreviewOfferingModalProps {
  onClose: () => void;
  isToyota: boolean;
}

const PreviewOfferingModal = ({ onClose, isToyota }: PreviewOfferingModalProps) => {
  const {
    tdaStore: { currentTda },
    offeringsStore: { currentOffering },
  } = useStores();

  const [effectiveDate, setEffectiveDate] = useState(new Date());
  const [application, setApplication] = useState('BAT');
  const [previewOffering] = usePreviewOfferingMutation();
  const showApplication = process.env.REACT_APP_FEATURE_OR_4801 === 'true' && isToyota;
  const previewURL = application === 'RCT' && showApplication ? process.env.REACT_APP_REVIEW_RCT : process.env.REACT_APP_REVIEW_JSDS;

  const handlePreview = async () => {
    const { data } = await previewOffering({ variables: { id: currentOffering.id, rev: currentOffering.rev } });

    if (data?.previewOffering.success) {
      window.location.reload();
    }

    onClose();
  };

  return (
    <Modal isOpen onClose={onClose} size={'lg'}>
      <ModalHeader onClose={onClose} className={styles.previewModalHeader}>
        Preview Offering
      </ModalHeader>
      <ModalBody>
        <div className={styles.previewContainer}>
          <InputContainer className={styles.previewDate} label="Effective Date: ">
            <CustomDatePicker
              id="effective-date"
              onChange={value => {
                setEffectiveDate(value as Date);
              }}
              selected={effectiveDate}
            />
          </InputContainer>
          {showApplication && (
            <BlockInputLabel label="Application:" className={styles.application}>
              <InlineInputLabel
                vertical
                labelComponent={
                  <input
                    type="radio"
                    onClick={() => {
                      setApplication('BAT');
                    }}
                    checked={application === 'BAT'}
                  />
                }
              >
                BAT
              </InlineInputLabel>
              <InlineInputLabel
                vertical
                labelComponent={
                  <input
                    type="radio"
                    onClick={() => {
                      setApplication('RCT');
                    }}
                    checked={application === 'RCT'}
                  />
                }
              >
                RCT
              </InlineInputLabel>
            </BlockInputLabel>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <a
          className={styles.previewLink}
          onClick={handlePreview}
          id="preview-link"
          href={`${previewURL}/${currentTda?.batTda}/review-offers/?effectiveDate=${dateFormatISOString(effectiveDate)}`}
          target="_blank"
          rel="noreferrer"
        >
          Preview
        </a>
      </ModalFooter>
    </Modal>
  );
};

export default PreviewOfferingModal;
