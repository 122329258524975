import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { Modal, ModalBody, ModalHeader, OfferTypes, Table, TableContainer } from 'oat-common-ui';
import { stackedOfferTableHeaderColumns } from '../../../../../constants/global';
import useStores from '../../../../../stores/useStores';
import styles from './styles.module.scss';

interface Props {
  offerType: string;
  onClose: () => void;
  open: boolean;
}

const StackedOfferModal = ({ offerType, open, onClose }: Props) => {
  const {
    stackedOffersStore: { mockStackedOffers, addOffer },
  } = useStores();
  const offerTypeName = offerType === OfferTypes.CUSTOMER_CASH ? AdminConstants.OfferTypes.CUSTOMER_CASH : offerType;

  const HeaderRow = () => {
    return (
      <thead>
        <tr>
          {stackedOfferTableHeaderColumns.map(col => {
            const key = `${col}-hdr-btn`;

            return <th key={key}>{col}</th>;
          })}
        </tr>
      </thead>
    );
  };

  const BodyRows = () => {
    return (
      <>
        {mockStackedOffers.map(item => (
          <tr key={item.extraCashOfferId}>
            <td className={styles.offerNameLink} onClick={() => addOffer(item)}>
              {item.extraCashOfferName}
            </td>
            <td>{`$ ${item.extraCashAmount}`}</td>
            <td>{item.extraCashOfferId}</td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalHeader onClose={onClose}>{`Offers Compatible with ${offerTypeName}`}</ModalHeader>
      <ModalBody>
        <p className={styles.modalText}>{`Select one of the compatible offers below to stack it on top of the ${offerTypeName} offer.`}</p>
        <TableContainer>
          <Table>
            <HeaderRow />
            <tbody>
              <BodyRows />
            </tbody>
          </Table>
        </TableContainer>
      </ModalBody>
    </Modal>
  );
};

export default observer(StackedOfferModal);
