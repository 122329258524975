import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { Button, ButtonGroup, Checkbox, Modal, ModalBody, ModalFooter, ModalHeader, TokenType } from 'oat-common-ui';
import { useState } from 'react';
import { Template, useSendMasterTemplatesToAreasMutation, useSendToAreasMutation } from '../../gql/generated';
import useStores from '../../stores/useStores';
import { getUserTdas } from '../../utils/getUserTdas';
import styles from './styles.module.scss';

const { BRAND_LEXUS } = AdminConstants;

interface SendToAreasModalProps {
  onClose: () => void;
  template?: Template[] | [];
  tokens?: TokenType[] | [];
  preSelectedTokenId?: string | undefined;
  title?: string;
}

const SendToAreasModal = ({ onClose, template, tokens, preSelectedTokenId, title }: SendToAreasModalProps) => {
  const {
    userInfoStore: {
      userInfo: { tdaCodes },
    },
  } = useStores();
  const areas = getUserTdas(tdaCodes, BRAND_LEXUS);
  const [sendToAreas] = useSendToAreasMutation();
  const [sendMasterTemplatesToAreas] = useSendMasterTemplatesToAreasMutation();
  const [selectedAreas, setSelectedAreas] = useState(areas);
  // default to 1st item OR-4594
  const [selectedTemplate, setSelectedTemplate] = useState(template?.length ? [template[0]] : []);
  const [selectedTokens, setSelectedTokens] = useState(tokens?.length ? tokens.filter(item => item.id === preSelectedTokenId) : []);
  const isPartiallySelected = (selectedTemplate.length && selectedTemplate.length !== template?.length) || (selectedTokens.length && selectedTokens.length !== tokens?.length);

  const handleSelectAllTemplates = () => {
    if (template && selectedTemplate.length !== template?.length) {
      setSelectedTemplate(template);
    } else {
      setSelectedTemplate([]);
    }
  };

  const handleSelectAllTokens = () => {
    if (tokens && selectedTokens?.length !== tokens.length) {
      setSelectedTokens(tokens);
    } else {
      setSelectedTokens([]);
    }
  };

  const handleSelectAllAreas = () => {
    if (selectedAreas.length !== areas.length) {
      setSelectedAreas(areas);
    } else {
      setSelectedAreas([]);
    }
  };

  const handleSendToAreas = async () => {
    onClose();
    if (tokens) {
      await sendToAreas({
        variables: {
          input: { tokenType: title ? title?.toLocaleLowerCase() : '', masterTokenIds: selectedTokens.map(token => token.id), tdaCodes: selectedAreas.map(area => area.code) },
        },
      });
    }
    if (template) {
      await sendMasterTemplatesToAreas({
        variables: {
          input: { masterTemplateIds: selectedTemplate.map(template => template.id), tdaCodes: selectedAreas.map(area => area.code) },
        },
      });
    }
  };

  return (
    <Modal isOpen onClose={onClose} size={'lg'}>
      <ModalHeader onClose={onClose} className={styles.previewModalHeader}>
        {`Select ${title ? title : 'Template'}(s)`}
      </ModalHeader>
      <ModalBody className={styles.modalBody}>
        <div>
          <div className={styles.modalBodyTitle}>
            <Checkbox
              onChange={template ? handleSelectAllTemplates : handleSelectAllTokens}
              isChecked={template ? selectedTemplate.length === template?.length : selectedTokens?.length === tokens?.length}
              checkmarkClass={clsx([{ [styles.isPreselectedCheckmark]: isPartiallySelected }])}
              label={`${title ? title : 'Template'} Name`}
            />
          </div>
          <div>
            <section className={styles.panesContainer}>
              <div className={styles.templateList}>
                <ul>
                  {template?.map(template => (
                    <li key={template.id}>
                      <Checkbox
                        isChecked={selectedTemplate && selectedTemplate.includes(template)}
                        onChange={() => {
                          setSelectedTemplate(prevState => {
                            if (prevState.includes(template)) {
                              return prevState.filter(pS => pS.id !== template.id);
                            }
                            return [...prevState, template];
                          });
                        }}
                        label={template.name}
                      />
                    </li>
                  ))}
                  {tokens?.map(item => (
                    <li key={item.id}>
                      <Checkbox
                        isChecked={selectedTokens && selectedTokens.includes(item)}
                        onChange={() => {
                          setSelectedTokens(prevState => {
                            if (prevState?.includes(item)) {
                              return prevState.filter(pS => pS.id !== item.id);
                            }
                            return [...prevState, item];
                          });
                        }}
                        label={item.name}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </section>
          </div>
        </div>
        <div>
          <div className={clsx(styles.modalBodyTitle, styles.areasTitle)}>
            <Checkbox
              onChange={handleSelectAllAreas}
              isChecked={selectedAreas.length === areas.length}
              checkmarkClass={clsx([{ [styles.isPreselectedCheckmark]: selectedAreas.length > 0 && selectedAreas.length !== areas.length }])}
              label="Area(s)"
            />
          </div>
          <div>
            <ul className={styles.areasList}>
              {areas.map(area => (
                <li key={area.id} className={styles.area}>
                  <p>
                    <Checkbox
                      isChecked={selectedAreas.includes(area)}
                      onChange={() => {
                        setSelectedAreas(prevState => {
                          if (prevState.includes(area)) {
                            return prevState.filter(pS => pS.id !== area.id);
                          }
                          return [...prevState, area];
                        });
                      }}
                      label={area.name}
                    />
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <Button variant="text" id="cancel-btn" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            id="submit-btn"
            onClick={handleSendToAreas}
            disabled={template ? !selectedTemplate.length || !selectedAreas.length : !selectedTokens.length || !selectedAreas.length}
          >
            Send to Area(s)
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default observer(SendToAreasModal);
