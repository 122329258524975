import { DnDType, DropResult, uuidv4 } from 'oat-common-ui';
import { useState } from 'react';
import {
  OfferMarketingBullet,
  OfferMarketingDisclaimer,
  OfferMarketingRestriction,
  TemplateMarketingBullet,
  TemplateMarketingDisclaimer,
  TemplateMarketingRestriction,
} from '../../../gql/generated';
import moveArrayItem from '../../../utils/moveArrayItem';

export interface OfferMarketingItem {
  uid: string;
  fileId?: string;
  file?: string;
  sortOrder: number;
  text?: string;
  isStandard?: boolean;
  isIdMe?: boolean;
  stdDiscBullId?: string;
  link?: string;
  copyLinkToToyota?: boolean;
}

type ReturnHandlers = {
  handleAddItem: (index: number) => void;
  handleRemoveItem: (type: DnDType, uid: string) => void;
  handleSortItems: (newSortOrder: number, currentSortOrder: number) => void;
  handleOnDragEnd: (result: DropResult) => void;
  handleSetItemList: (type: DnDType, item: OfferMarketingItem) => void;
};
type ReturnType = [OfferMarketingItem[], (val: OfferMarketingItem[] | any[]) => void, ReturnHandlers];

export const transformTokenItem = (
  items:
    | OfferMarketingDisclaimer[]
    | OfferMarketingRestriction[]
    | OfferMarketingBullet[]
    | TemplateMarketingDisclaimer[]
    | TemplateMarketingRestriction[]
    | TemplateMarketingBullet[],
): OfferMarketingItem[] => {
  return items.map((item: any, index: number) => {
    let file = '';
    let fileId = '';

    if (item?.tdaRestrictionFileName) {
      file = item?.tdaRestrictionFileName;
      fileId = item?.tdaRestrictionFileId;
    }

    if (item?.tdaDisclaimerFileName) {
      file = item?.tdaDisclaimerFileName;
      fileId = item?.tdaDisclaimerFileId;
    }

    return {
      uid: item.uid || uuidv4(),
      sortOrder: item?.sortOrder || index,
      text: item?.text,
      file,
      fileId,
      isStandard: item?.isStandard,
      isIdMe: item?.isIdMe ?? false,
      stdDiscBullId: item.stdDiscBullId || '',
      link: item?.link || '',
      copyLinkToToyota: item?.copyLinkToToyota || false,
    };
  });
};

const useDnd = (
  items:
    | OfferMarketingDisclaimer[]
    | OfferMarketingRestriction[]
    | OfferMarketingBullet[]
    | TemplateMarketingBullet[]
    | TemplateMarketingDisclaimer[]
    | TemplateMarketingRestriction[],
  field: DnDType,
  isDisabled = false,
  startingIndex?: number,
  onChangeAfter?: (dndList: OfferMarketingItem[]) => void, // used for template marketing
): ReturnType => {
  const [itemList, setItemList] = useState<OfferMarketingItem[]>(items?.length ? transformTokenItem(items) : []);

  const handleAddCustomStartingIndex = () => {
    const copyList = itemList.slice();
    if (startingIndex) {
      let currIndex = startingIndex;
      for (let i = 0; i < copyList.length; i++) {
        copyList[i].sortOrder = currIndex + 1;
        currIndex += 1;
      }
    }

    return copyList;
  };

  /**
   * ex sortOrder array [2, 3, 4]
   * If sortOrder = 3 and startingIndex = 1, it should return 1
   * @param sortOrder
   * @returns
   */
  const sortOrderToIndex = (sortOrder: number) => {
    return sortOrder - 1 - (startingIndex ?? 0);
  };

  const indexToSortOrder = (index: number) => {
    return index + (startingIndex ?? 0) + 1;
  };

  const handleOnChangeAfter = (newList: OfferMarketingItem[]) => {
    if (onChangeAfter) {
      onChangeAfter(newList);
    }
  };

  const handleAddItem = (index: number) => {
    const order = startingIndex ? itemList.length + 2 : index + 1;
    const newList = [...itemList, { uid: uuidv4(), sortOrder: order, text: '', file: '', link: '', isStandard: false, copyLinkToToyota: false }];
    handleOnChangeAfter(newList);

    setItemList(newList);
  };

  const handleRemoveItem = (type: DnDType, uid: string) => {
    const listToFilter = handleAddCustomStartingIndex();

    const newList = listToFilter
      .filter(item => item.uid !== uid)
      .map((item, i) => ({
        ...item,
        sortOrder: indexToSortOrder(i),
      }));

    handleOnChangeAfter(newList);
    setItemList(newList);
  };

  // Sorting by input box
  // TODO make common util
  const handleSortItems = (newSortOrder: number, currentSortOrder: number) => {
    const itemListCopy = handleAddCustomStartingIndex();
    const maxIndex = itemListCopy.length - 1;

    // convert incoming sortOrder to array index
    let newIndex = sortOrderToIndex(newSortOrder);
    let currIndex = sortOrderToIndex(currentSortOrder);

    // adjust index to bounds
    newIndex = Math.max(Math.min(newIndex, maxIndex), 0);
    currIndex = Math.max(Math.min(currIndex, maxIndex), 0);

    // resets input boxes when sorting is skipped by dealing with standard offers or index is out of bounds
    if (newIndex === currIndex || itemListCopy[newIndex].isStandard || itemListCopy[currIndex].isStandard || itemListCopy[newIndex].isIdMe || itemListCopy[currIndex].isIdMe) {
      const adjustedList = itemList.map((item, i) => ({ ...item, sortOrder: indexToSortOrder(i) }));
      handleOnChangeAfter(adjustedList);
      setItemList(adjustedList);
      return;
    }

    const copyList = moveArrayItem(currIndex, newIndex, itemListCopy);
    const newList = copyList.map((item, i) => ({
      ...item,
      sortOrder: indexToSortOrder(i),
    }));

    handleOnChangeAfter(newList);
    setItemList(newList);
  };

  // Sorting by DnD
  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination || isDisabled) {
      return;
    }

    const itemListCopy = handleAddCustomStartingIndex();
    if (
      itemListCopy[result.source.index].isStandard ||
      itemListCopy[result.destination.index].isStandard ||
      itemListCopy[result.source.index].isIdMe ||
      itemListCopy[result.destination.index].isIdMe
    ) {
      return;
    }

    const [itemToMove] = itemListCopy.splice(result.source.index, 1);
    itemListCopy.splice(result.destination.index, 0, itemToMove);
    const reorderedList = itemListCopy.map((item, i) => ({
      ...item,
      sortOrder: indexToSortOrder(i),
    }));

    handleOnChangeAfter(reorderedList);
    setItemList(reorderedList);
  };

  const handleSetItemList = (type: DnDType, item: OfferMarketingItem) => {
    let itemListCopy: OfferMarketingItem[] = [...itemList];
    let found = itemListCopy.find(listItem => listItem.uid === item.uid);

    if (found) {
      found = { ...found, ...item };

      itemListCopy = itemListCopy.map(listItem => {
        if (item.uid === listItem.uid) {
          return { ...listItem, ...found };
        }

        return listItem;
      });

      handleOnChangeAfter(itemListCopy);
      setItemList(itemListCopy);
    }
  };

  return [itemList, setItemList, { handleAddItem, handleRemoveItem, handleSortItems, handleOnDragEnd, handleSetItemList }];
};

export default useDnd;
