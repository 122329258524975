import { BreadCrumbType } from 'oat-common-ui';
import MainHeader from '../../components/MainHeader';

type Props = {
  breadCrumbs?: BreadCrumbType[];
};

const Loading = ({ breadCrumbs }: Props) => {
  return <MainHeader breadCrumbs={breadCrumbs} showProgressBar />;
};

export default Loading;
