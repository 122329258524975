import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import {
  Button,
  ButtonGroup,
  DnDType,
  Dropdown,
  DropdownItem,
  InlineInputLabel,
  OATIcon,
  OfferTabBodyFooter,
  OfferTabError,
  OfferTypes,
  SnippetInput,
  Textarea,
  ToggleButton,
  Tooltip,
  useInput,
} from 'oat-common-ui';
import { useCallback, useMemo, useState } from 'react';
import { MarketingSites } from '../../../constants/global';
import { OfferMarketingInput, Snippet } from '../../../gql/generated';
import { UserType } from '../../../stores/UserInfoStore';
import useStores from '../../../stores/useStores';
import { getPrimaryOfferCardLabel, getSecondaryOfferCardLabel } from '../../../utils/getOfferCardLabels';
import { getDefaultPrimaryOfferCardLabel } from '../../OfferTemplates/OfferTemplateForm/util';
import { TokenRibbon } from '../../TokenSettings/RibbonsComponent';
import { formatBulletData, formatDisclaimerData, formatRestrictionData } from '../util';
import MarketingDndItems from './components/MarketingDndItems';
import MarketingModal from './components/MarketingModal';
import TdaMarketingTabNav from './components/TdaMarketingTabNav/TdaMarketingTabNav';
import TdaMarketingTabNavList from './components/TdaMarketingTabNav/TdaMarketingTabNavList';
import { OfferMarketing } from './models';
import OfferBadgeImage from './OfferBadgeImage';
import OfferImage from './OfferImage';
import styles from './styles.module.scss';
import useDnd from './useDnd';
import { copyDndItems, getRibbonId, getRibbonName } from './utils';
import { GraphQLError } from 'graphql';

interface Props {
  isLexus: boolean;
  onSubmit: (toBoth: boolean, payload: OfferMarketingInput, back: boolean) => void;
  snippets: Snippet[];
  tokens: string[];
  ribbons: TokenRibbon[];
  offerType: string;
  offerId: string;
  offeringId: string;
  tdaCode: string;
  lang: string;
  site: MarketingSites;
  httpError: GraphQLError | null;
  setLangParam?: (param: string) => void;
  defaultLanguage?: string;
  marketingData?: OfferMarketing;
  copyEngData?: OfferMarketing;
  copyEngBatData?: OfferMarketing;
  copyEspBatData?: OfferMarketing;
}

const labelWidth = 200;

const MarketingForm = ({
  isLexus,
  snippets,
  tokens,
  ribbons,
  onSubmit,
  offerType,
  offerId,
  offeringId,
  tdaCode,
  lang,
  site,
  setLangParam,
  marketingData,
  copyEngData,
  copyEngBatData,
  copyEspBatData,
  httpError
}: Props) => {
  const {
    tokenStore: { disclaimers, restrictions },
    offersStore: { currentOffer },
    userInfoStore: { isGst, userInfo },
    tdaStore: { currentTda },
    offerMarketingStore: { applyBat, applyTcom, setActiveOfferMarketingIndex, offerCategories },
  } = useStores();
  const isOther = currentOffer?.offerType === 'Other';

  const [primaryOfferCardLabel, , primaryOfferCardLabelError, setPrimaryOfferCardLabel] = useInput(
    getDefaultPrimaryOfferCardLabel(offerType, isLexus, marketingData?.primaryOfferCardLabel),
    {
      required: !isOther,
    },
  );
  const [secondaryOfferCardLabel, , secondaryOfferCardLabelError, setSecondaryOfferCardLabel] = useInput(marketingData?.secondaryOfferCardLabel || '');
  const [cashLabel, , cashLabelError, setCashLabel] = useInput(marketingData?.cashLabel || '');
  const [otherOfferCategory, , otherOfferCategoryError, setOtherOfferCategory] = useInput(marketingData?.otherOfferCategory, { required: isOther });
  const [otherMiscType, , otherMiscTypeError, setOtherMiscType] = useInput(marketingData?.otherMiscType || '', { required: isOther });
  const [offerCardTitle, , offerCardTitleError, setOfferCardTitle] = useInput(marketingData?.offerCardTitle || '', { required: true });
  const [offerCardAltText, , offerCardAltTextError, setOfferCardAltText] = useInput(marketingData?.offerCardAltText || '');
  const [offerDetailTitle, , offerDetailTitleError, setOfferDetailTitle] = useInput(marketingData?.offerDetailTitle || '', { required: true });
  const [offerDetailAltText, , offerDetailAltTextError, setOfferDetailAltText] = useInput(marketingData?.offerDetailAltText || '');
  const [offerCardDesc, , offerCardDescError, setOfferCardDesc] = useInput(marketingData?.offerCardDesc || '', { required: true });
  const [description, , descriptionError, setDescription] = useInput(marketingData?.description || '', { required: true });
  const [imageDisclaimer, , imageDisclaimerError, setImageDisclaimer] = useInput(marketingData?.imageDisclaimer || '');
  const [offerDetailImage, , offerDetailImageError, setOfferDetailImage] = useInput(marketingData?.offerDetailImage || '');
  const [offerCardImage, , offerCardImageError, setOfferCardImage] = useInput(marketingData?.offerCardImage || '');
  const [offerCardImage2, , offerCardImageError2, setOfferCardImage2] = useInput(marketingData?.offerCardImage2 || '');
  const [offerBadgeImage1, , offerBadgeImageError1, setOfferBadgeImage1] = useInput(marketingData?.offerBadgeImage1 || '');
  const [offerBadgeImage1DestinationUrlAltText, , offerBadgeImage1DestinationUrlAltTextError, setOfferBadgeImage1DestinationUrlAltText] = useInput(
    marketingData?.offerBadgeImage1DestinationUrlAltText || '',
  );
  const [offerBadgeImage1DestinationUrl, , offerBadgeImage1DestinationUrlError, setOfferBadgeImage1DestinationUrl] = useInput(marketingData?.offerBadgeImage1DestinationUrl || '');
  const [offerBadgeImage2, , offerBadgeImageError2, setOfferBadgeImage2] = useInput(marketingData?.offerBadgeImage2 || '');
  const [offerBadgeImage2DestinationUrlAltText, , offerBadgeImage2DestinationUrlAltTextError, setOfferBadgeImage2DestinationUrlAltText] = useInput(
    marketingData?.offerBadgeImage2DestinationUrlAltText || '',
  );
  const [offerBadgeImage2DestinationUrl, , offerBadgeImage2DestinationUrlError, setOfferBadgeImage2DestinationUrl] = useInput(marketingData?.offerBadgeImage2DestinationUrl || '');
  const [offerBadgeImage3, , offerBadgeImageError3, setOfferBadgeImage3] = useInput(marketingData?.offerBadgeImage3 || '');
  const [offerBadgeImage3DestinationUrlAltText, , offerBadgeImage3DestinationUrlAltTextError, setOfferBadgeImage3DestinationUrlAltText] = useInput(
    marketingData?.offerBadgeImage3DestinationUrlAltText || '',
  );
  const [offerBadgeImage3DestinationUrl, , offerBadgeImage3DestinationUrlError, setOfferBadgeImage3DestinationUrl] = useInput(marketingData?.offerBadgeImage3DestinationUrl || '');
  const [submitted, setSubmitted] = useState(false);
  const [showSecondaryLabel, setShowSecondaryLabel] = useState(!!secondaryOfferCardLabel);
  const [showSpanish, setShowSpanish] = useState(lang === 'es');
  const [offerRibbon, setOfferRibbon] = useState<DropdownItem>(getRibbonName(marketingData?.offerRibbonId || '', ribbons));
  const showCashLabel = offerType === OfferTypes.CUSTOMER_CASH || offerType === OfferTypes.MISCELLANEOUS;

  const isDisabledFields = !isLexus && currentOffer?.offerType === OfferTypes.OTHER && ((!applyBat && site === MarketingSites.BAT) || (!applyTcom && site === MarketingSites.TCOM));
  const disabledOnCreateOther = !isLexus && !currentOffer?.offerType ? (site === MarketingSites.BAT ? !applyBat : !applyTcom) : false;
  const disabledFormFields = isDisabledFields || disabledOnCreateOther;
  const offerCategoryError = otherOfferCategoryError || (!offerType && !otherOfferCategory);
  const miscTypeError = otherMiscTypeError || (!offerType && !otherMiscType);

  // for TDA when going to TCOM after creating a new Other offer
  const defaultOfferType = !offerType ? 'other' : offerType;

  // hooks for bullets, restrictions, disclaimers
  const [marketingBullets, setMarketingBullets, marketingBulletFn] = useDnd(marketingData?.bullets || [], DnDType.BULLETS, isDisabledFields);
  const [marketingRestrictions, setMarketingRestrictions, marketingRestrictionFn] = useDnd(marketingData?.restrictions || [], DnDType.RESTRICTIONS, isDisabledFields);
  const [marketingDisclaimers, setMarketingDisclaimers, marketingDisclaimerFn] = useDnd(marketingData?.disclaimers || [], DnDType.DISCLAIMERS, isDisabledFields);

  const areDndItemsValid = useCallback((dndItems: any) => {
    let isValid = true;

    dndItems.forEach((item: any) => {
      if (!item.text) {
        isValid = false;
      }
    });

    return isValid;
  }, []);

  const hasError = useMemo(() => {
    return (
      !offerCardTitle ||
      !offerDetailTitle ||
      !description ||
      (offerType === OfferTypes.OTHER && !offerCardDesc) ||
      (!isLexus && !marketingBullets.length) || // Lexus don't require bullets
      !areDndItemsValid(marketingBullets) ||
      !marketingDisclaimers.length ||
      !areDndItemsValid(marketingDisclaimers) ||
      !areDndItemsValid(marketingRestrictions)
    );
  }, [offerCardTitle, offerDetailTitle, description, marketingBullets, marketingRestrictions, marketingDisclaimers, areDndItemsValid, offerType, offerCardDesc, isLexus]);

  const handleShowSpanish = () => {
    if (setLangParam) {
      setLangParam(showSpanish ? 'en' : 'es');
    }

    setActiveOfferMarketingIndex(site === MarketingSites.BAT, showSpanish ? 'en' : 'es');
    setShowSpanish(!showSpanish);
  };

  const handleSubmit = (back: boolean, toBoth: boolean) => {
    if (!back && site === MarketingSites.BAT) {
      setSubmitted(true);
    }

    if (hasError) {
      return;
    }

    const offerRibbonId = getRibbonId(offerRibbon?.label || '', ribbons);
    const rev = marketingData?.rev || '';
    const id = marketingData?.id || '';

    onSubmit(
      toBoth,
      {
        bullets: formatBulletData(marketingBullets),
        cashLabel,
        description,
        disclaimers: formatDisclaimerData(marketingDisclaimers),
        imageDisclaimer,
        isBuyAToyota: isLexus ? false : site === MarketingSites.BAT,
        offerCardDesc,
        offerCardAltText,
        offerCardTitle,
        offerDetailAltText,
        offerDetailTitle,
        primaryOfferCardLabel,
        secondaryOfferCardLabel,
        restrictions: formatRestrictionData(marketingRestrictions),
        offerRibbonId,
        offerBadgeImage1,
        offerBadgeImage2,
        offerBadgeImage3,
        offerBadgeImage1DestinationUrlAltText,
        offerBadgeImage2DestinationUrlAltText,
        offerBadgeImage3DestinationUrlAltText,
        offerBadgeImage1DestinationUrl,
        offerBadgeImage2DestinationUrl,
        offerBadgeImage3DestinationUrl,
        offerCardImage,
        offerCardImage2,
        offerDetailImage,
        otherOfferCategory,
        otherMiscType,
        rev,
        id,
        language: isLexus ? 'en' : lang,
      },
      back,
    );
  };

  const copyValues = (copyData: OfferMarketing, isLangCopy = false) => {
    // used for both copy from English & copy from BAT
    setOfferCardTitle(copyData.offerCardTitle || '');
    setOfferCardAltText(copyData.offerCardAltText || '');
    setOfferDetailTitle(copyData.offerDetailTitle || '');
    setOfferDetailAltText(copyData.offerDetailAltText || '');
    setDescription(copyData.description || '');
    setImageDisclaimer(copyData.imageDisclaimer || '');
    setMarketingBullets(copyDndItems(DnDType.BULLETS, copyData.bullets, marketingBullets, isLangCopy));
    setMarketingDisclaimers(copyDndItems(DnDType.DISCLAIMERS, copyData.disclaimers, marketingDisclaimers, isLangCopy));
    setMarketingRestrictions([...copyDndItems(DnDType.RESTRICTIONS, copyData.restrictions || [], marketingRestrictions, isLangCopy)]);
    setOfferBadgeImage1(copyData.offerBadgeImage1 || '');
    setOfferBadgeImage1DestinationUrlAltText(copyData.offerBadgeImage1DestinationUrlAltText || '');
    setOfferBadgeImage1DestinationUrl(copyData.offerBadgeImage1DestinationUrl || '');
    setOfferBadgeImage2(copyData.offerBadgeImage2 || '');
    setOfferBadgeImage2DestinationUrlAltText(copyData.offerBadgeImage2DestinationUrlAltText || '');
    setOfferBadgeImage2DestinationUrl(copyData.offerBadgeImage2DestinationUrl || '');
    setOfferBadgeImage3(copyData.offerBadgeImage3 || '');
    setOfferBadgeImage3DestinationUrlAltText(copyData.offerBadgeImage3DestinationUrlAltText || '');
    setOfferBadgeImage3DestinationUrl(copyData.offerBadgeImage3DestinationUrl || '');
    setOfferCardDesc(copyData.offerCardDesc || '');
  };

  const conditionalCopyValues = (copyData: OfferMarketing) => {
    // used for both copy from English & copy from BAT
    if (offerType === OfferTypes.CUSTOMER_CASH || offerType === OfferTypes.MISCELLANEOUS) {
      setCashLabel(copyData.cashLabel || '');
    }

    if (offerType === OfferTypes.MISCELLANEOUS) {
      setPrimaryOfferCardLabel(copyData.primaryOfferCardLabel || '');
    }

    if (offerType === OfferTypes.OTHER) {
      setPrimaryOfferCardLabel(copyData.primaryOfferCardLabel || '');
      setOtherMiscType(copyData.otherMiscType || '');
      setOtherOfferCategory(copyData.otherOfferCategory || '');
    } else {
      setSecondaryOfferCardLabel(copyData.secondaryOfferCardLabel || '');

      if (copyData.secondaryOfferCardLabel) {
        setShowSecondaryLabel(true);
      }
    }
  };

  const handleCopyFromEnglish = (copyData: OfferMarketing) => {
    copyValues(copyData, true);
    conditionalCopyValues(copyData);

    if (isGst(userInfo) && site === MarketingSites.TCOM) {
      setOfferCardImage2(copyData.offerCardImage2 || '');
    }

    setOfferDetailImage(copyData.offerDetailImage || '');
    setOfferCardImage(copyData.offerCardImage || '');
  };

  const handleCopyFromBat = (copyData: OfferMarketing) => {
    copyValues(copyData);
    conditionalCopyValues(copyData);

    if (isGst(userInfo)) {
      // Image if selected is removed and replaced with shadow image on click of ‘Copy from BAT’
      setOfferCardImage('');
      setOfferCardImage2('');
      setOfferDetailImage('');
    } else {
      // For non-GST TDA's
      setOfferCardImage(copyData.offerCardImage || '');
      setOfferDetailImage(copyData.offerDetailImage || '');
    }

    setOfferRibbon(getRibbonName(copyData.offerRibbonId || '', ribbons) || '');
  };

  const handleToggleDisable = () => {
    let disabled = false;

    if (!isLexus) {
      if ((!currentOffer || currentOffer?.offerType === OfferTypes.OTHER) && ((site === MarketingSites.BAT && !applyBat) || (site === MarketingSites.TCOM && !applyTcom))) {
        disabled = true;
      }
    }

    return disabled;
  };

  return (
    <div className={styles.marketingForm}>
      {!isLexus && <TdaMarketingTabNav navList={TdaMarketingTabNavList(offeringId, tdaCode, defaultOfferType, lang, applyBat, applyTcom, site)} />}
      {site === MarketingSites.TCOM && (
        <InlineInputLabel vertical width={labelWidth} label="">
          <Button
            variant="primary"
            onClick={() => {
              if (copyEngBatData) {
                handleCopyFromBat(copyEngBatData);
              }

              if (copyEspBatData) {
                handleCopyFromBat(copyEspBatData);
              }
            }}
            disabled={handleToggleDisable()}
          >
            Copy From BAT
          </Button>
        </InlineInputLabel>
      )}
      {showCashLabel && !isOther && (
        <InlineInputLabel vertical width={labelWidth} label="Cash Label">
          <SnippetInput id="cash-label" darkTheme value={cashLabel} setInputValue={setCashLabel} error={cashLabelError} disabled={isDisabledFields} />
        </InlineInputLabel>
      )}
      {!isLexus && (
        <InlineInputLabel vertical width={labelWidth} label="View in Spanish">
          <ButtonGroup>
            <ToggleButton id={`${isLexus ? 'lda' : 'tda'}-language-toggle-btn`} selected={showSpanish} toggleSelected={handleShowSpanish} disabled={handleToggleDisable()} />
            {showSpanish && (
              <Button
                variant="primary"
                onClick={() => {
                  if (copyEngData) {
                    handleCopyFromEnglish(copyEngData);
                  }
                }}
              >
                Copy From English
              </Button>
            )}
          </ButtonGroup>
        </InlineInputLabel>
      )}
      {!isOther && offerType && (
        <div className={styles.offerCardLabelWrapper}>
          <InlineInputLabel width={labelWidth} label="Primary Offer Card Label" contentClass={styles.inputLabel} isRequired>
            <Dropdown
              id="primary-offer-card-label"
              value={primaryOfferCardLabel}
              options={getPrimaryOfferCardLabel(userInfo.brand, currentTda, currentOffer?.optionType)}
              onChange={(option: DropdownItem) => setPrimaryOfferCardLabel(option.value)}
              darkTheme
              disabled={offerType !== OfferTypes.MISCELLANEOUS}
              error={primaryOfferCardLabelError}
            />
          </InlineInputLabel>
          {showSecondaryLabel && (
            <InlineInputLabel width={labelWidth} label="Secondary Offer Card Label" contentClass={styles.inputLabel}>
              <Dropdown
                id="secondary-offer-card-label"
                value={secondaryOfferCardLabel}
                options={getSecondaryOfferCardLabel(userInfo.brand, currentTda, currentOffer?.offerType || '')}
                onChange={(option: DropdownItem) => setSecondaryOfferCardLabel(option.value)}
                darkTheme
                disabled={disabledFormFields}
                error={secondaryOfferCardLabelError}
              />
            </InlineInputLabel>
          )}
          <Button
            className={styles.iconButton}
            onClick={() => {
              setShowSecondaryLabel(!showSecondaryLabel);
              setSecondaryOfferCardLabel('');
            }}
            disabled={disabledOnCreateOther}
          >
            <OATIcon icon={showSecondaryLabel ? 'trash' : 'add'} size={18} />
          </Button>
        </div>
      )}
      {(isOther || !offerType) && (
        <>
          <div className={styles.offerCategory}>
            <InlineInputLabel vertical width={labelWidth} isRequired label="Offer Category" contentClass={styles.inputLabel}>
              <Dropdown
                id="offer-category"
                value={otherOfferCategory}
                options={offerCategories}
                onChange={(option: DropdownItem) => setOtherOfferCategory(option.value)}
                darkTheme
                error={offerCategoryError}
                disabled={disabledFormFields}
              />
            </InlineInputLabel>
            {otherOfferCategory === 'Other' && (
              <Tooltip
                id="info-icon-card-description"
                message="Other offer categories do not appear on TCOM/Other Sites by default. If this offer should appear on TCOM/Other Sites, please select Apply to TCOM / Other Sites."
              >
                <OATIcon className={styles.infoIcon} icon="info" size={16} />
              </Tooltip>
            )}
          </div>
          <InlineInputLabel vertical width={labelWidth} isRequired label="Miscellaneous Type">
            <SnippetInput
              id="miscellaneous-type"
              darkTheme
              value={otherMiscType}
              setInputValue={setOtherMiscType}
              snippets={snippets}
              tokens={tokens}
              error={miscTypeError}
              contentClass={styles.titleTextField}
              disabled={disabledFormFields}
            />
          </InlineInputLabel>
        </>
      )}
      <InlineInputLabel vertical width={labelWidth} isRequired label="Offer Card Title">
        <SnippetInput
          id="offer-card-title"
          darkTheme
          value={offerCardTitle}
          setInputValue={setOfferCardTitle}
          snippets={snippets}
          tokens={tokens}
          error={offerCardTitleError}
          contentClass={styles.titleTextField}
          disabled={disabledFormFields}
        />
      </InlineInputLabel>
      <InlineInputLabel vertical width={labelWidth} label="Offer Card Alt Text">
        <SnippetInput
          id="offer-card-alt-text"
          darkTheme
          value={offerCardAltText}
          setInputValue={setOfferCardAltText}
          snippets={snippets}
          tokens={tokens}
          error={offerCardAltTextError}
          disabled={disabledFormFields}
        />
      </InlineInputLabel>
      <InlineInputLabel vertical width={labelWidth} isRequired label="Offer Detail Title">
        <SnippetInput
          id="offer-detail-title"
          darkTheme
          value={offerDetailTitle}
          snippets={snippets}
          tokens={tokens}
          setInputValue={setOfferDetailTitle}
          error={offerDetailTitleError}
          contentClass={styles.titleTextField}
          disabled={disabledFormFields}
        />
      </InlineInputLabel>
      <InlineInputLabel vertical width={labelWidth} label="Offer Detail Alt Text">
        <SnippetInput
          id="offer-detail-alt-text"
          darkTheme
          value={offerDetailAltText}
          setInputValue={setOfferDetailAltText}
          snippets={snippets}
          tokens={tokens}
          error={offerDetailAltTextError}
          disabled={disabledFormFields}
        />
      </InlineInputLabel>
      {isOther && (
        <InlineInputLabel vertical width={labelWidth} label="Offer Card Desc" isRequired inputWrapperClass={styles.inputWrapper}>
          <SnippetInput
            id="offer-card-description"
            darkTheme
            value={offerCardDesc}
            setInputValue={setOfferCardDesc}
            snippets={snippets}
            tokens={tokens}
            error={offerCardDescError}
            disabled={disabledFormFields}
          />
        </InlineInputLabel>
      )}
      <InlineInputLabel vertical width={labelWidth} label="Description" isRequired inputWrapperClass={styles.inputWrapper}>
        <Textarea
          id="marketing-offer-description"
          className={clsx(styles.textArea, descriptionError && styles.error)}
          darkTheme
          value={description}
          onChange={e => setDescription(e.target.value)}
          tokens={tokens.map(name => ({ name, char: name }))}
          secondaryTokens={snippets.map(item => ({ name: item.name, char: item.text }))}
          error={descriptionError}
          disabled={disabledFormFields}
        />
      </InlineInputLabel>
      <InlineInputLabel vertical width={labelWidth} label="Image Disclaimer">
        <SnippetInput
          id="image-disclaimer"
          darkTheme
          value={imageDisclaimer}
          snippets={snippets}
          tokens={tokens}
          setInputValue={setImageDisclaimer}
          error={imageDisclaimerError}
          disabled={disabledFormFields}
        />
      </InlineInputLabel>
      {!isLexus && (
        <InlineInputLabel vertical width={labelWidth} label="Offer Ribbon">
          <ButtonGroup>
            <div className={styles.dropdown}>
              <Dropdown
                id="tda-offer-ribbon"
                darkTheme
                value={offerRibbon}
                onChange={(option: DropdownItem) => setOfferRibbon(option)}
                options={ribbons.map(item => item.name)}
                disabled={disabledFormFields}
              />
            </div>
            <Button variant="text" className={styles.trashIconBtn} onClick={() => setOfferRibbon({ value: '', label: '' })}>
              <OATIcon icon="trash" size={20} />
            </Button>
          </ButtonGroup>
        </InlineInputLabel>
      )}
      <MarketingDndItems
        {...marketingBulletFn}
        droppableId={DnDType.BULLETS}
        items={marketingBullets || []}
        label="Bullet"
        offerType={currentOffer?.offerType}
        isDisabledFields={disabledFormFields}
        site={site}
      />
      <MarketingDndItems
        {...marketingDisclaimerFn}
        droppableId={DnDType.DISCLAIMERS}
        items={marketingDisclaimers || []}
        label="Disclaimer"
        modalItems={disclaimers}
        offerType={currentOffer?.offerType}
        isDisabledFields={disabledFormFields}
        site={site}
      />
      <MarketingDndItems
        {...marketingRestrictionFn}
        droppableId={DnDType.RESTRICTIONS}
        items={marketingRestrictions || []}
        label="Restriction"
        modalItems={restrictions}
        offerType={currentOffer?.offerType}
        isDisabledFields={disabledFormFields}
        site={site}
      />
      {!isLexus && (
        <>
          <OfferImage
            labelWidth={labelWidth}
            label={`${tdaCode === UserType.GST ? (site === MarketingSites.BAT ? 'Buy a Toyota' : MarketingSites.TCOM) : ''} Offer Detail Image`}
            value={offerDetailImage}
            error={offerDetailImageError}
            isDisabledFields={isDisabledFields}
            setImagePath={setOfferDetailImage}
          />
          <OfferImage
            labelWidth={labelWidth}
            label={`${tdaCode === UserType.GST ? (site === MarketingSites.BAT ? 'Buy a Toyota' : MarketingSites.TCOM) : ''} Offer Card Image`}
            value={offerCardImage}
            error={offerCardImageError}
            isDisabledFields={isDisabledFields}
            setImagePath={setOfferCardImage}
          />
          {tdaCode === UserType.GST && site === MarketingSites.TCOM && (
            <OfferImage
              labelWidth={labelWidth}
              label={`${tdaCode === UserType.GST && site === MarketingSites.TCOM ? MarketingSites.TCOM : ''} Offer Card Image 2 Hover`}
              value={offerCardImage2}
              error={offerCardImageError2}
              isDisabledFields={isDisabledFields}
              setImagePath={setOfferCardImage2}
            />
          )}
          <OfferBadgeImage
            labelWidth={labelWidth}
            imagePathLabel={'Offer Badge Image 1'}
            imagePath={offerBadgeImage1}
            imagePathError={offerBadgeImageError1}
            imageAlt={offerBadgeImage1DestinationUrlAltText}
            imageAltLabel={'Image 1 alt text'}
            imageAltError={offerBadgeImage1DestinationUrlAltTextError}
            setImageAltValue={setOfferBadgeImage1DestinationUrlAltText}
            destinationUrl={offerBadgeImage1DestinationUrl}
            destinationUrlLabel={'Destination URL 1'}
            destinationUrlError={offerBadgeImage1DestinationUrlError}
            setDestinationUrlValue={setOfferBadgeImage1DestinationUrl}
            setImagePath={setOfferBadgeImage1}
            isDisabledFields={isDisabledFields}
          />
          <OfferBadgeImage
            labelWidth={labelWidth}
            imagePathLabel={'Offer Badge Image 2'}
            imagePath={offerBadgeImage2}
            imagePathError={offerBadgeImageError2}
            imageAlt={offerBadgeImage2DestinationUrlAltText}
            imageAltLabel={'Image 2 alt text'}
            imageAltError={offerBadgeImage2DestinationUrlAltTextError}
            setImageAltValue={setOfferBadgeImage2DestinationUrlAltText}
            destinationUrl={offerBadgeImage2DestinationUrl}
            destinationUrlLabel={'Destination URL 2'}
            destinationUrlError={offerBadgeImage2DestinationUrlError}
            setDestinationUrlValue={setOfferBadgeImage2DestinationUrl}
            setImagePath={setOfferBadgeImage2}
            isDisabledFields={isDisabledFields}
          />
          <OfferBadgeImage
            labelWidth={labelWidth}
            imagePathLabel={`Offer Badge Image 3`}
            imagePath={offerBadgeImage3}
            imagePathError={offerBadgeImageError3}
            imageAlt={offerBadgeImage3DestinationUrlAltText}
            imageAltLabel={'Image 3 alt text'}
            imageAltError={offerBadgeImage3DestinationUrlAltTextError}
            setImageAltValue={setOfferBadgeImage3DestinationUrlAltText}
            destinationUrl={offerBadgeImage3DestinationUrl}
            destinationUrlLabel={'Destination URL 3'}
            destinationUrlError={offerBadgeImage3DestinationUrlError}
            setDestinationUrlValue={setOfferBadgeImage3DestinationUrl}
            setImagePath={setOfferBadgeImage3}
            isDisabledFields={isDisabledFields}
          />
        </>
      )}
      {(hasError || !!httpError) && <OfferTabError message={httpError?.message} />}
      {submitted && !isLexus && !hasError && (
        <MarketingModal
          open
          title="Apply Changes"
          message="Would you like to apply these changes to the TCOM/Other Sites tab as well?"
          submitText="Save to BAT Only"
          addlSubmitText="Save to BAT & TCOM/Other Sites"
          onClose={() => setSubmitted(false)}
          onSubmitOne={() => handleSubmit(false, false)}
          onSubmitTwo={() => handleSubmit(false, true)}
        />
      )}
      <OfferTabBodyFooter>
        <ButtonGroup>
          <Button variant="primary" id="back-btn" onClick={() => handleSubmit(true, false)} disabled={hasError || isDisabledFields}>
            Back
          </Button>
          <Button
            variant="primary"
            id="save-btn"
            onClick={() => (!isLexus && site === MarketingSites.BAT ? setSubmitted(true) : handleSubmit(false, false))}
            disabled={hasError || isDisabledFields}
          >
            Save
          </Button>
        </ButtonGroup>
      </OfferTabBodyFooter>
    </div>
  );
};

export default observer(MarketingForm);
