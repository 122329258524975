import { observer } from 'mobx-react-lite';
import { InlineInputLabel, Input, OATIcon, OATLink, dateFormatISOString } from 'oat-common-ui';
import useStores from '../../../../../stores/useStores';
import styles from './styles.module.scss';

interface Props {
  labelWidth: number;
  showSpanish: boolean;
}

const StackedOffer = ({ labelWidth, showSpanish }: Props) => {
  const {
    stackedOffersStore: { stackedOffers, removeOffer },
  } = useStores();

  return (
    <>
      {stackedOffers.map(stackedOffer => {
        return (
          <div className={styles.section} key={stackedOffer.extraCashOfferId}>
            <InlineInputLabel label="Extra Cash Offer Name" vertical width={labelWidth}>
              <Input id="extra-cash-offer-name" darkTheme value={stackedOffer.extraCashOfferName} disabled />
            </InlineInputLabel>
            <InlineInputLabel label="Extra Cash Offer ID" vertical width={labelWidth}>
              <OATLink className={styles.offerIdLink}>{stackedOffer.extraCashOfferId}</OATLink>
            </InlineInputLabel>
            <InlineInputLabel vertical width={labelWidth} label="Extra Cash Start Date">
              <Input disabled darkTheme id="extra-cash-offer-start-date" value={dateFormatISOString(stackedOffer.extraCashOfferStartDate)} />
            </InlineInputLabel>
            <InlineInputLabel vertical width={labelWidth} label="Extra Cash End Date">
              <Input disabled darkTheme id="extra-cash-offer-end-date" value={dateFormatISOString(stackedOffer.extraCashOfferEndDate)} />
            </InlineInputLabel>
            <InlineInputLabel label="Extra Cash Label" vertical width={labelWidth}>
              <Input id="extra-cash-offer-label" darkTheme value={stackedOffer.extraCashLabel} />
            </InlineInputLabel>
            {showSpanish && (
              <InlineInputLabel label="Extra Cash Label (Spanish)" vertical width={labelWidth}>
                <Input id="extra-cash-offer-es-label" darkTheme value={stackedOffer.extraCashLabelSpanish} />
              </InlineInputLabel>
            )}
            <InlineInputLabel vertical width={labelWidth} label="Extra Cash Offer Type">
              <Input id="extra-cash-offer-type-dd" darkTheme value={stackedOffer.extraCashOfferType} disabled />
            </InlineInputLabel>
            <InlineInputLabel vertical width={labelWidth} label="Extra Cash Offer Amount">
              <Input id="extra-cash-offer-amount" darkTheme dollarSign value={stackedOffer.extraCashAmount} disabled />
            </InlineInputLabel>
            <InlineInputLabel vertical width={labelWidth} label="Funding Source">
              <Input id="stacked-offer-funding-source" darkTheme dollarSign value={stackedOffer.fundingSource} disabled />
            </InlineInputLabel>
            <div className={styles.trashIconWrapper}>
              <OATIcon className={styles.trashIcon} size={18} onClick={() => removeOffer(stackedOffer.extraCashOfferId)} icon="trash" colorTheme="blue" />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default observer(StackedOffer);
