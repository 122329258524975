import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Button, ButtonGroup, OATIcon, useInput } from 'oat-common-ui';
import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';

interface Props {
  id: string;
  ribbonValue: string;
  deleteRibbon: (uid: string) => void;
  setRibbonsCallback: (id: string, text: string) => void;
  handleDisableCheckmark: (uid: string, name: string) => boolean;
  isDragging: boolean;
  innerRef?: (element: HTMLElement | null) => any;
}

const RibbonInput = ({ id, ribbonValue, deleteRibbon, setRibbonsCallback, handleDisableCheckmark, innerRef, isDragging, ...rest }: Props) => {
  const [ribbonText, , ribbonTextError, setRibbonText] = useInput(ribbonValue || '');
  const [showOnMouseEnter, setShowOnMouseEnter] = useState(false);
  const [disableInput, setDisableInput] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!disableInput && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [disableInput, inputRef]);

  const handleOnMouseEnter = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e && e.type === 'mouseenter') {
      setShowOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e && e.type === 'mouseleave') {
      setShowOnMouseEnter(false);
    }
  };

  const handleEnableInput = () => {
    if (disableInput) {
      setDisableInput(false);
    }
  };

  const handleCancelInput = () => {
    if (!disableInput) {
      setRibbonText(ribbonValue || '');
      setDisableInput(true);
    }
  };

  const handleUpdateInputValue = () => {
    if (!disableInput && ribbonValue !== ribbonText) {
      setRibbonsCallback(id, ribbonText);
      return setDisableInput(true);
    }

    return setDisableInput(true);
  };

  return (
    <div
      ref={innerRef}
      className={clsx(styles.ribbonInputContainer, isDragging && styles.containerDragging)}
      onMouseEnter={e => handleOnMouseEnter(e)}
      onMouseLeave={e => handleOnMouseLeave(e)}
      {...rest}
    >
      {disableInput ? (
        <>
          <div className={styles.dragIcon}>
            <OATIcon icon="drag" size={20} />
          </div>
          <span className={clsx(styles.ribbonText)}>{ribbonText}</span>
        </>
      ) : (
        <input
          ref={inputRef}
          value={ribbonText}
          onChange={e => setRibbonText(e.target.value)}
          className={clsx(styles.ribbonInput, ribbonTextError && styles.error)}
          disabled={disableInput}
        />
      )}
      {showOnMouseEnter && disableInput && (
        <ButtonGroup className={styles.buttonGroup}>
          <Button id="update-ribbon" className={styles.iconBtn} variant="text" onClick={() => handleEnableInput()}>
            <FontAwesomeIcon icon={faPencilAlt} className={styles.icon} />
          </Button>
          <Button id="delete-ribbon" className={styles.iconBtn} variant="text" onClick={() => deleteRibbon(id)}>
            <OATIcon icon="trash" size={20} />
          </Button>
        </ButtonGroup>
      )}
      {!disableInput && (
        <ButtonGroup>
          <Button className={styles.iconBtn} variant="text" onClick={() => handleUpdateInputValue()} disabled={handleDisableCheckmark(id, ribbonText)}>
            <OATIcon icon="checkmark" size={20} />
          </Button>
          <Button className={styles.iconBtn} variant="text" onClick={() => handleCancelInput()}>
            <OATIcon icon="cancel-circle" size={20} />
          </Button>
        </ButtonGroup>
      )}
    </div>
  );
};

export default RibbonInput;
