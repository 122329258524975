/**
 *
 * @param offeringId
 * @param tdaCode
 * @param offerType
 * @param tab
 * @param lang
 * @param offerId
 * @param site
 * @returns
 */
const buildOfferUrl = (offeringId: string, tdaCode: string, offerType: string, tab: string, lang?: string, offerId?: string, site?: string) => {
  const params: string[] = [];
  if (offerId) {
    params.push(`offerId=${offerId}`);
  }
  if (lang) {
    params.push(`lang=${lang}`);
  }
  if (site) {
    params.push(`site=${site}`);
  }

  const qParams = params.length ? `?${params.join('&')}` : '';
  return `/${tdaCode}/offering/${offeringId}/${offerType.toLowerCase()}/${tab}${qParams}`;
};

export default buildOfferUrl;
