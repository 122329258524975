import { makeObservable, observable, runInAction } from 'mobx';
import { Offer } from '../gql/generated';

class OffersStore {
  offers: Offer[] = [];
  currentOffer: Offer | undefined = undefined;
  loadedOffersByOfferingId: { [offeringId: string]: boolean } = {};

  constructor() {
    makeObservable(this, {
      offers: observable,
      currentOffer: observable,
    });
  }

  setCurrentOffer = (offer: Offer | undefined) => {
    runInAction(() => {
      this.currentOffer = offer;
    });
  };

  setCurrentOffers = (offers: Offer[]) => {
    runInAction(() => {
      this.offers = offers;
    });
  };

  getOffersByOfferingId = (offeringId: string) => {
    return this.offers.filter(item => item.offeringId === offeringId);
  };

  hasLoadedOffers = (offeringId: string) => {
    return this.loadedOffersByOfferingId[offeringId];
  };

  loadOffers = (offeringId: string, offers: Offer[]) => {
    runInAction(() => {
      this.offers = [...offers];
      this.loadedOffersByOfferingId = { ...this.loadedOffersByOfferingId, [offeringId]: true };
    });
  };

  offerExists = (offerId: string) => {
    return this.offers.findIndex(item => item.id === offerId) !== -1;
  };

  getOfferById = (offerId: string) => {
    return this.offers.find(item => item.id === offerId);
  };

  updateSortOrder = () => {
    this.offers = this.offers.map((item, i) => ({ ...item, sortOrder: i + 1 }));
  };

  addOffer = (offer: Offer) => {
    runInAction(() => {
      this.offers = [...this.offers, offer];
    });
  };

  copyOffer = (parentOffer: Offer, childOffer: Offer) => {
    runInAction(() => {
      const index = this.offers.findIndex(item => item.id === parentOffer.id);
      this.offers.splice(index + 1, 0, childOffer);
      this.updateSortOrder();
    });
  };

  addOffers = (offers: Offer[]) => {
    runInAction(() => {
      this.offers = [...this.offers, ...offers];
    });
  };

  updateOffer = (offerId: string, props: Partial<Offer>) => {
    runInAction(() => {
      this.offers = this.offers.map(item => {
        if (item.id === offerId) {
          return {
            ...item,
            ...props,
          };
        }

        return item;
      });
    });
  };

  removeOffer = (offerId: string | undefined) => {
    runInAction(() => {
      if (offerId) {
        this.offers = this.offers.filter(item => item.id !== offerId);
        this.updateSortOrder();
      }
    });
  };

  removeOffersByOfferingId = (offeringId: string) => {
    runInAction(() => {
      this.offers = this.offers.filter(item => item.offeringId !== offeringId);
    });
  };
}

export default OffersStore;
