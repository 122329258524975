import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import { AdminConstants, LeaseCalcFns } from 'oat-admin-common';
import {
  Button,
  ButtonGroup,
  DefaultModal,
  Dropdown,
  InlineInputLabel,
  Input,
  OATIcon,
  OfferTabBodyLayout,
  OfferTabError,
  OfferTabsHeader,
  OfferTabsNav,
  assignNumberValue,
  assignStringValue,
  formatDecimal,
  formatNumber,
  stringToNumber,
  useInput,
  usePromiseLoading,
  useToast,
} from 'oat-common-ui';
import React, { useCallback, useState } from 'react';
import OfferTabNavList from '../../../../../components/OfferTabComponents/OfferTabNavList';
import { FEATURE_OR_4616, SEND_EMAIL_PROMPT, SEND_EMAIL_SUCCESS, extraCashBase } from '../../../../../constants/global';
import { LeaseDetailInput, Offer, OfferLeaseAccesory, OfferLeaseDetails, UpdateOfferInput, useRequestRegionalAtcMutation } from '../../../../../gql/generated';
import useUrlParams from '../../../../../hooks/useUrlParams';
import useStores from '../../../../../stores/useStores';
import { getEfcCarJelly } from '../../../../../utils/efc3dImageUtils';
import getSeriesInfoFromOffer from '../../../../../utils/getSeriesInfoFromOffer';
import LeaseTdaHeaderDetails from '../../../LeaseTdaHeaderDetails';
import FinanceModificationDialogue from '../../FinanceModificationDialogue';
import StackedOffer from '../../components/StackedOffer';
import StackedOfferModal from '../../components/StackedOfferModal';
import AccessoriesTable from './AccessoriesTable';
import styles from './styles.module.scss';

const { TdaCodes } = AdminConstants;

interface Props {
  offer: Offer;
  onSubmit: (id: string, rev: string, payload: Partial<UpdateOfferInput>, back: boolean, next: boolean) => Promise<void>;
}

const LeaseFinancialForm = ({ offer, onSubmit }: Props) => {
  const { leaseDetails, rates } = offer;
  const {
    efcStore,
    offersStore: { currentOffer },
    userInfoStore: { isLexus, userInfo },
    stackedOffersStore: { stackedOffers },
  } = useStores();

  const { tdaCode, lang, site } = useUrlParams();
  const { loadPromise, promiseLoading } = usePromiseLoading();

  const labelWidth = 200;
  const isGst = tdaCode === TdaCodes.GST;

  // lease details
  const [dispositionFee, dispositionFeeFn, dispositionFeeError] = useInput(leaseDetails ? leaseDetails[0].dispositionFee?.toString() : '', { isDecimal: true, required: true });
  const [targetPayment, targetPaymentFn, targetPaymentError, setTargetPayment] = useInput(leaseDetails ? leaseDetails[0].targetPayment.toString() : '', {
    isWhole: true,
    required: true,
    min: 0,
  });
  const [dueAtSigning, dueAtSigningFn, dueAtSigningError, setDueAtSigning] = useInput(leaseDetails ? leaseDetails[0].dueAtSigning.toString() : '', {
    isWhole: true,
    required: true,
    min: 0,
  });
  const [mileageOverageFee, mileageOverageFeeFn, mileageOverageFeeError] = useInput(leaseDetails ? leaseDetails[0].mileageOverageFee?.toString() : '', {
    isDecimal: true,
    required: true,
    min: 0,
  });
  const [netCapCost, setNetCapCost] = useState(leaseDetails ? leaseDetails[0].netCapCost.toString() : '');
  const [downPayment, , downPaymentError, setDownPayment] = useInput(leaseDetails ? leaseDetails[0].downPayment.toString() : '', { isWhole: true, required: true, min: 0 });

  // extra cash fields

  const [extraCashOfferAmount, extraCashOfferBindFn] = useInput(offer?.extraCashAmount, { isWhole: true, min: 0 });
  const [extraCashOfferAmountLabel, extraCashOfferAmountLabelBindFn] = useInput(offer?.extraCashLabel);
  const [extraCashOfferAmountLabelSpanish, extraCashOfferAmountLabelSpanishBindFn] = useInput(offer?.extraCashLabelSpanish);
  const [extraCashType, setExtraCashType] = useState(assignStringValue(offer?.extraCashOfferType));
  const [fundingSource, fundingSourceFn, fundingSourceError] = useInput(offer?.fundingSource, { required: true });
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [tdaPackage, tdaPackageBindFn, tdaPackageError, setTdaPackage] = useInput<string>(assignStringValue(offer?.tdaPackage), { required: true });

  const [isOpenAddStackedOfferModal, setIsOpenAddStackedOfferModal] = useState(false);

  const [requestRegionalAtc] = useRequestRegionalAtcMutation();

  const { success, error } = useToast();

  const showSpanish = !isLexus();
  const showTdaPackage = !offer?.isSpecialEdition && extraCashType === AdminConstants.SPECIAL_EDITION_BONUS_TYPE && isGst;
  const isExtraCashTypeDisabled = !extraCashOfferAmount && !extraCashOfferAmountLabel && (!showSpanish || !extraCashOfferAmountLabelSpanish);
  const isExtraCashTypeError = !isLexus() && !isExtraCashTypeDisabled && !extraCashType;

  const hasError =
    dispositionFeeError ||
    targetPaymentError ||
    dueAtSigningError ||
    mileageOverageFeeError ||
    isExtraCashTypeError ||
    fundingSourceError ||
    downPaymentError ||
    (showTdaPackage && !!tdaPackageError);
  const isPaymentOrDASEmpty = !targetPayment || !dueAtSigning;
  const showRequiredFieldMessage = targetPayment === '' || dueAtSigning === '' || mileageOverageFee === '' || isExtraCashTypeError;

  /**
   * NOTE: In legacy, grossCapCost, estimatedCost, residualAmount are also reset. However, calculations don't touch this for marketing :/
   */
  const resetToNational = () => {
    const details = (leaseDetails as OfferLeaseDetails[])[0];
    setDownPayment(details.approvedDownPayment.toString());
    setDueAtSigning(details.approvedDueAtSigning.toString());
    setNetCapCost(details.approvedNetCapCost.toString());
    setTargetPayment(details.approvedTargetPayment.toString());
  };

  /**
   * NOTE: In legacy, there is isAdditionalCashAppliedToDueAtSigning and dasAdditionalCash fields populated. However, none of the
   * following calculations touched those fields.
   */
  const getBaseValues = useCallback(() => {
    const details = (leaseDetails as OfferLeaseDetails[])[0];
    return LeaseCalcFns.getBaseValues({
      acquisitionFee: assignNumberValue(details.acquisitionFee),
      rcf: assignNumberValue(rates && rates[0].subventedRcf),
      term: assignNumberValue(rates && rates[0].term),
      subventionCash: assignNumberValue(offer.subventionCash),
      dealerCostPerInvoice: assignNumberValue(details.dealerCostPerInvoice),
      dealerGross: assignNumberValue(details.dealerGross),
      residualAmount: details.residualAmount,
      targetPayment: Number(targetPayment),
      dueAtSigning: Number(dueAtSigning),
    });
  }, [targetPayment, dueAtSigning, leaseDetails, offer, rates]);

  const calcTargetPayment = useCallback(
    (dueAtSigning: string) => {
      const baseValues = getBaseValues();
      baseValues.dueAtSigning = Number(dueAtSigning);
      baseValues.targetPayment = LeaseCalcFns.calculateTargetPayment(baseValues);

      const postValues = {
        ...baseValues,
        ...LeaseCalcFns.postCalculation(baseValues),
      };

      return postValues;
    },
    [getBaseValues],
  );

  const calcDueAtSigning = useCallback(
    (targetPayment: string) => {
      const baseValues = getBaseValues();
      baseValues.targetPayment = Number(targetPayment);
      baseValues.dueAtSigning = LeaseCalcFns.calculateDueAtSigning(baseValues);

      const postValues = {
        ...baseValues,
        ...LeaseCalcFns.postCalculation(baseValues),
      };

      return postValues;
    },
    [getBaseValues],
  );

  const handleTargetPaymentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isGst) {
      const values = calcDueAtSigning(e.currentTarget.value);
      setDueAtSigning(values.dueAtSigning.toString());
      setDownPayment(values.downPayment.toString());
      setNetCapCost(values.netCapCost.toString());
    }
    targetPaymentFn.onChange(e);
  };

  const handleDueAtSigningChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isGst) {
      const values = calcTargetPayment(e.currentTarget.value);
      setTargetPayment(values.targetPayment.toString());
      setDownPayment(values.downPayment.toString());
      setNetCapCost(values.netCapCost.toString());
    }
    dueAtSigningFn.onChange(e);
  };

  const checkEmptyExtraCashValues = (amount: string, label: string, labelSpanish: string) => {
    if (!amount && !label && !labelSpanish) {
      setExtraCashType('');
      setTdaPackage(assignStringValue(offer.tdaPackage));
    }
  };

  const handleExtraCashAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    checkEmptyExtraCashValues(e.currentTarget.value, extraCashOfferAmountLabel, extraCashOfferAmountLabelSpanish);
    extraCashOfferBindFn.onChange(e);
  };

  const handleExtraCashOfferLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    checkEmptyExtraCashValues(extraCashOfferAmount, e.currentTarget.value, extraCashOfferAmountLabelSpanish);
    extraCashOfferAmountLabelBindFn.onChange(e);
  };

  const handleExtraCashOfferLabelSpanishChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    checkEmptyExtraCashValues(extraCashOfferAmount, extraCashOfferAmountLabel, e.currentTarget.value);
    extraCashOfferAmountLabelSpanishBindFn.onChange(e);
  };

  const handleExtraCashTypeChange = (val: string) => {
    if (val !== AdminConstants.SPECIAL_EDITION_BONUS_TYPE && !offer?.isSpecialEdition) {
      setTdaPackage(assignStringValue(offer?.tdaPackage));
    }
    setExtraCashType(val);
  };

  const handleOnSave = async (back: boolean, next: boolean) => {
    const leaseDetailsInput: LeaseDetailInput[] =
      leaseDetails?.map(({ __typename, accessories, ...rest }, index) => {
        const accessoriesClean = (accessories || []).map(acc => {
          if (acc) {
            const { __typename, ...accRest } = acc;
            return { ...accRest };
          }
          return acc;
        });

        if (index === 0) {
          return {
            ...rest,
            accessories: accessoriesClean,
            dispositionFee: Number(dispositionFee),
            targetPayment: Number(targetPayment),
            dueAtSigning: Number(dueAtSigning),
            mileageOverageFee: Number(mileageOverageFee),
            netCapCost: Number(netCapCost),
            downPayment: Number(downPayment),
          };
        } else {
          return { ...rest, accessories: accessoriesClean };
        }
      }) || [];
    const offerPayload: Partial<UpdateOfferInput> = {
      extraCashAmount: stringToNumber(extraCashOfferAmount),
      extraCashLabel: extraCashOfferAmountLabel,
      extraCashLabelSpanish: extraCashOfferAmountLabelSpanish,
      extraCashOfferType: extraCashType,
      fundingSource,
      leaseDetails: leaseDetailsInput,
      tdaPackage,
    };
    await loadPromise(onSubmit(offer.id, offer.rev, offerPayload, back, next));
  };

  const handleSendEmail = async () => {
    try {
      await requestRegionalAtc({
        variables: {
          input: {
            offerId: assignStringValue(currentOffer?.id),
          },
        },
      });
      success(SEND_EMAIL_SUCCESS);
    } catch (e) {
      error((e as Error).message);
    } finally {
      setOpenEmailModal(false);
    }
  };

  if (!leaseDetails || !rates) {
    return <></>;
  }

  return (
    <>
      <OfferTabsHeader
        endDate={assignStringValue(offer?.endDate)}
        status={assignStringValue(offer?.status)}
        seriesMap={efcStore.uniqueYearSeries}
        offerImage={getEfcCarJelly(efcStore.seriesImages, ...getSeriesInfoFromOffer(offer))}
        offerSeries={assignStringValue(offer?.series)}
        offerSeriesYear={assignStringValue(offer?.seriesYear)}
        offerType={offer.offerType}
        renderHeaderContent={<LeaseTdaHeaderDetails dueAtSigning={dueAtSigning} targetPayment={targetPayment} term={rates[0]?.term.toString()} />}
        renderNav={<OfferTabsNav navList={OfferTabNavList(offer.offeringId, tdaCode, offer.offerType, lang, offer.id, site)} />}
      />

      <OfferTabBodyLayout
        renderContent={
          <div className={styles.leaseForm}>
            <div className={styles.fieldWrapper}>
              <InlineInputLabel width={labelWidth} label="Term">
                <Input id="term" value={rates[0].term} disabled />
              </InlineInputLabel>
            </div>
            <div className={styles.fieldWrapper}>
              <InlineInputLabel width={labelWidth} label="Disposition Fee">
                <Input id="dispositionFee" dollarSign className={styles.field} value={dispositionFee} {...dispositionFeeFn} error={dispositionFeeError} darkTheme />
              </InlineInputLabel>
            </div>
            <div className={styles.fieldWrapper}>
              <InlineInputLabel width={labelWidth} label="VIN">
                <Input id="vin" disabled className={styles.field} value={leaseDetails[0].vin} onChange={() => {}} darkTheme />
              </InlineInputLabel>
            </div>
            <div className={styles.fieldWrapper}>
              <InlineInputLabel width={labelWidth} label="Lease Trim">
                <Input id="trim" className={styles.field} darkTheme disabled onChange={() => {}} value={`(${leaseDetails[0].modelCode}) ${leaseDetails[0].vehicleDescription}`} />
              </InlineInputLabel>
            </div>
            {/* Payment Details */}
            <div className={styles.paymentDetailsWrapper}>
              <div className={styles.fieldWithInfo}>
                <InlineInputLabel className={styles.label} label="Monthly Payment">
                  <Input
                    id="monthlyPayment"
                    className={styles.field}
                    darkTheme
                    dollarSign
                    value={targetPayment}
                    displayValue={formatNumber(targetPayment, true)}
                    {...targetPaymentFn}
                    onChange={handleTargetPaymentChange}
                    error={targetPaymentError || isPaymentOrDASEmpty}
                  />
                </InlineInputLabel>
                <div className={styles.infoField}>Paid By: Customer</div>
              </div>
              <div className={styles.fieldWrapper}>
                <InlineInputLabel className={styles.label} label="Rent Charge Factor">
                  <Input id="rcf" disabled darkTheme className={cx(styles.field, styles.padded)} value={rates[0].subventedRcf?.toString()} />
                </InlineInputLabel>
              </div>
              <div className={styles.fieldWrapper}>
                <InlineInputLabel className={styles.label} label="Total MSRP">
                  <Input id="totalMsrp" dollarSign darkTheme disabled className={styles.field} value={formatNumber(leaseDetails[0].totalMsrp.toString(), true)} />
                </InlineInputLabel>
              </div>
              <div className={styles.fieldWrapper}>
                <InlineInputLabel className={styles.label} width={labelWidth} label="Net Capitalized Cost">
                  <Input id="netCapCost" dollarSign darkTheme disabled className={styles.field} value={formatNumber(netCapCost, true)} error={isPaymentOrDASEmpty} />
                </InlineInputLabel>
              </div>
              <div className={styles.fieldWrapper}>
                <InlineInputLabel className={styles.label} width={labelWidth} label="Gross Capitalized Cost">
                  <Input id="grossCapCost" dollarSign darkTheme disabled className={styles.field} value={formatNumber(leaseDetails[0].grossCapCost.toString(), true)} />
                </InlineInputLabel>
              </div>
              <div className={styles.fieldWrapper}>
                <InlineInputLabel className={styles.label} width={labelWidth} label="Due At Signing">
                  <Input
                    id="dueAtSigning"
                    className={styles.field}
                    darkTheme
                    dollarSign
                    value={dueAtSigning}
                    displayValue={formatNumber(dueAtSigning, true)}
                    {...dueAtSigningFn}
                    onChange={handleDueAtSigningChange}
                    error={dueAtSigningError || isPaymentOrDASEmpty}
                  />
                </InlineInputLabel>
              </div>
              <div className={styles.fieldWrapper}>
                <InlineInputLabel className={styles.label} width={labelWidth} label="Down Payment">
                  <Input
                    id="downPayment"
                    dollarSign
                    darkTheme
                    disabled
                    className={styles.field}
                    value={formatNumber(downPayment, true)}
                    error={downPaymentError || isPaymentOrDASEmpty}
                  />
                </InlineInputLabel>
              </div>
              <div className={styles.fieldWithInfo}>
                <InlineInputLabel className={styles.label} width={labelWidth} label="Acquisition Fee">
                  <Input id="acquisitionFee" dollarSign darkTheme disabled className={styles.field} value={formatNumber(leaseDetails[0].acquisitionFee.toString(), true)} />
                </InlineInputLabel>
                <div className={styles.infoField}>{`Included In: ${
                  LeaseCalcFns.isAcquisitionFeeCapitalized(leaseDetails[0].acquisitionFee, leaseDetails[0].dueAtSigning, leaseDetails[0].targetPayment, offer.subventionCash ?? 0)
                    ? 'Net Cap Cost'
                    : 'Due at Signing'
                }`}</div>
              </div>
              <div className={styles.fieldWrapper}>
                <InlineInputLabel className={styles.label} width={labelWidth} label="Mileage">
                  <Input id="mileage" darkTheme disabled className={cx(styles.field, styles.padded)} value={formatNumber(leaseDetails[0].mileage.toString(), true)} />
                </InlineInputLabel>
                <div className={styles.fieldWrapper}>
                  <InlineInputLabel className={styles.label} width={labelWidth} label="Mileage Overage Fee">
                    <Input
                      id="mileageOverageFee"
                      className={styles.field}
                      dollarSign
                      darkTheme
                      value={mileageOverageFee}
                      displayValue={formatDecimal(mileageOverageFee)}
                      {...mileageOverageFeeFn}
                      error={mileageOverageFeeError}
                    />
                  </InlineInputLabel>
                </div>
              </div>
              <div className={styles.fieldWrapper}>
                <InlineInputLabel className={styles.label} width={labelWidth} label="End Purchase Amount">
                  <Input id="endPurchaseAmount" disabled dollarSign darkTheme className={styles.field} value={formatNumber(leaseDetails[0].residualAmount.toString(), true)} />
                </InlineInputLabel>
              </div>
            </div>
            <div className={styles.resetBtnWrapper}>
              <Button id="reset-to-national-btn" variant="primary" disabled={false} onClick={resetToNational}>
                Reset to National
              </Button>
            </div>
            {!FEATURE_OR_4616 && (
              <>
                <div className={styles.fieldWrapper}>
                  <InlineInputLabel className={styles.label} width={labelWidth} label="Extra Cash Label">
                    <Input
                      id="extraCashLabeL"
                      darkTheme
                      className={styles.field}
                      value={extraCashOfferAmountLabel}
                      {...extraCashOfferAmountLabelBindFn}
                      onChange={handleExtraCashOfferLabelChange}
                    />
                  </InlineInputLabel>
                </div>
                {showSpanish && (
                  <div className={styles.fieldWrapper}>
                    <InlineInputLabel className={styles.label} width={labelWidth} label="Extra Cash Label (Spanish)">
                      <Input
                        id="extraCashLabelES"
                        darkTheme
                        className={styles.field}
                        value={extraCashOfferAmountLabelSpanish}
                        {...extraCashOfferAmountLabelSpanishBindFn}
                        onChange={handleExtraCashOfferLabelSpanishChange}
                      />
                    </InlineInputLabel>
                  </div>
                )}
                <div className={styles.fieldWrapper}>
                  <InlineInputLabel className={styles.label} width={labelWidth} label="Extra Cash Amount">
                    <Input id="extraCashAmount" darkTheme className={styles.field} value={extraCashOfferAmount} {...extraCashOfferBindFn} onChange={handleExtraCashAmountChange} />
                  </InlineInputLabel>
                </div>
                <div className={styles.fieldWrapper}>
                  <InlineInputLabel className={styles.label} width={labelWidth} label="Extra Cash Offer Type">
                    <Dropdown
                      id="extra-cash-offer-type"
                      value={extraCashType}
                      options={extraCashBase(userInfo.brand, tdaCode)}
                      disabled={isExtraCashTypeDisabled}
                      error={isExtraCashTypeError}
                      onChange={item => handleExtraCashTypeChange(item.value)}
                    />
                  </InlineInputLabel>
                </div>
              </>
            )}
            {showTdaPackage && (
              <div className={styles.fieldWrapper}>
                <InlineInputLabel className={styles.label} width={labelWidth} label="Package">
                  <Input
                    className={styles.field}
                    id="fin-series-package"
                    value={tdaPackage}
                    {...tdaPackageBindFn}
                    onChange={e => setTdaPackage(e.currentTarget.value)}
                    darkTheme
                    error={tdaPackageError}
                  />
                </InlineInputLabel>
              </div>
            )}
            <div className={styles.fieldWrapper}>
              <InlineInputLabel className={styles.label} width={labelWidth} label="Subvention Amount">
                <Input
                  id="subventionCash"
                  darkTheme
                  disabled
                  dollarSign
                  className={styles.field}
                  value={formatNumber(offer.subventionCash?.toString(), true)}
                  onChange={() => {}}
                />
              </InlineInputLabel>
            </div>
            <div className={styles.fieldWrapper}>
              <InlineInputLabel className={styles.label} width={labelWidth} label="Funding Source">
                <Input id="fundingSource" darkTheme className={styles.field} value={fundingSource} {...fundingSourceFn} error={fundingSourceError} />
              </InlineInputLabel>
            </div>
            <AccessoriesTable accessories={leaseDetails[0].accessories as OfferLeaseAccesory[]} />
            {offer?.needsReview && <FinanceModificationDialogue reset={resetToNational} email={() => setOpenEmailModal(true)} />}
            {showRequiredFieldMessage && <OfferTabError />}
            {FEATURE_OR_4616 && (
              <>
                <StackedOffer labelWidth={labelWidth} showSpanish={showSpanish} />
                <StackedOfferModal offerType="Lease" open={isOpenAddStackedOfferModal} onClose={() => setIsOpenAddStackedOfferModal(!isOpenAddStackedOfferModal)} />
                {stackedOffers.length < 2 && (
                  <Button className={styles.addStackedOfferCta} onClick={() => setIsOpenAddStackedOfferModal(true)}>
                    <OATIcon icon="add" size={18} colorTheme="blue" />
                    <span>Add Stacked Offer</span>
                  </Button>
                )}
              </>
            )}
          </div>
        }
        renderFooter={
          <ButtonGroup>
            <Button id="info-back-btn" variant="primary" disabled={promiseLoading || hasError} onClick={() => handleOnSave(true, false)}>
              Back
            </Button>
            <Button id="info-save-btn" variant="primary" disabled={promiseLoading || hasError} onClick={() => handleOnSave(false, false)}>
              Save
            </Button>
            <Button id="info-next-btn" variant="primary" disabled={promiseLoading || hasError} onClick={() => handleOnSave(false, true)}>
              Next
            </Button>
          </ButtonGroup>
        }
      />
      {openEmailModal && <DefaultModal title="Send Email" message={SEND_EMAIL_PROMPT} open onSubmit={handleSendEmail} onClose={() => setOpenEmailModal(false)} />}
    </>
  );
};

export default observer(LeaseFinancialForm);
