import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import authClient from './authClient';

const cache = new InMemoryCache({});

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = await authClient.getToken();
  if (!token && authClient.skip) {
    token = process.env.REACT_APP_TOKEN || '';
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// Initialize ApolloClient
const apolloClient: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
});

export default apolloClient;
