import { useMemo, useState } from 'react';
import { TdaImages } from '../../../../../gql/generated';

export class TreeNode {
  name: string;
  parent: string;
  children: TreeNode[];

  constructor(name: string, parent: string) {
    this.name = name;
    this.parent = parent;
    this.children = [];
  }

  getFullPath = () => {
    return this.parent + '/' + this.name;
  };

  getDisplayPath = () => {
    return this.getFullPath().substring(1).replaceAll('/', ' > ');
  };

  getChildrenList = () => {
    return this.children.forEach(child => child.name);
  };

  isLastNode = () => {
    return this.children.length < 1;
  };

  isTopNode = () => {
    return this.name === '' && this.parent === '';
  };
}

const getHierarchyTree = (tdaImages?: TdaImages) => {
  let result: any = [];

  // Setting the root element in result
  result[''] = new TreeNode('', '');

  const handlePath = (path: string) => {
    var parts = path.split('/');

    createByParts(parts);
  };

  const createByParts = (parts: string[]) => {
    if (parts.length > 0) {
      let current = parts.pop();
      let parent = parts.join('/');
      let treeNode = new TreeNode(current as string, parent);

      if (result[treeNode.getFullPath()]) {
        return;
      }

      result[treeNode.getFullPath()] = treeNode;

      if (!result[parent]) {
        createByParts(parts);
      }

      result[parent].children.push(treeNode);
    }
  };

  tdaImages?.imageNames.forEach((path: string) => {
    handlePath(path);
  });

  return result;
};

const useTeamsitePicker = (imagesData: TdaImages) => {
  const hierarchyTree = getHierarchyTree(imagesData);
  const [selectedImage, setSelectedImage] = useState<string>('');

  const [activeFolder, setActiveFolder] = useState(hierarchyTree['']); // Initaily we want root folder to be selected
  const [parentFolderPath, setParentFolderPath] = useState<undefined | string>(undefined);

  const imgSrc = useMemo(() => {
    if (selectedImage === '') {
      return '';
    }

    return `${imagesData?.imagePrefix}${selectedImage}`;
  }, [selectedImage, imagesData?.imagePrefix]);

  const selectFolder = (path: string) => {
    setParentFolderPath(hierarchyTree[path].isTopNode() ? undefined : hierarchyTree[path].parent);
    setActiveFolder(hierarchyTree[path]);
  };

  return { activeFolder, selectFolder, hierarchyTree, parentFolderPath, imgSrc, selectedImage, setSelectedImage };
};

export default useTeamsitePicker;
