import clsx from 'clsx';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import TokenSettingsComponent from './TokenSettingsComponent';
import { TokenGroup, TokenType } from './types';

type Props = {
  formDisabled?: boolean;
  groups: TokenGroup[];
  isVisibleSendToCta?: boolean;
  onCreate: (group: string, token: string, content: string, setSelectedCb: (token: TokenType) => void) => Promise<void>;
  onDelete: (group: string, id: string) => Promise<void>;
  onEdit: (group: string, id: string, token: string, content: string) => Promise<void>;
  tokens: string[];
};

const TokenSettingsLayout = ({ formDisabled, groups, isVisibleSendToCta, onCreate, onDelete, onEdit, tokens }: Props) => {
  const [selectedGroup, setSelectedGroup] = useState(groups.length ? groups[0] : undefined);

  const handleOnClick = (group: TokenGroup) => {
    setSelectedGroup(group);
  };

  return (
    <>
      <ul className={styles.tabs}>
        {groups.map(item => (
          <li key={item.name}>
            <button
              type="button"
              className={clsx(selectedGroup && selectedGroup.name === item.name && styles.active)}
              id={`ts-group-${item.name}`}
              onClick={() => handleOnClick(item)}
            >
              {item.name}
            </button>
          </li>
        ))}
      </ul>
      <fieldset disabled={formDisabled} className={styles.fieldSet}>
        {groups.map(item => (
          <React.Fragment key={item.name}>
            {selectedGroup?.name === item.name && item.tokenData && (
              <div>
                <TokenSettingsComponent
                  data={item.tokenData.data}
                  dataTokens={item.tokenData.dataTokens}
                  isVisibleSendToCta={isVisibleSendToCta}
                  modalName={item.modalName || ''}
                  name={item.name}
                  onCreate={onCreate}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  tokens={tokens}
                />
              </div>
            )}
            {selectedGroup?.name === item.name && item.component && <div>{item.component}</div>}
          </React.Fragment>
        ))}
      </fieldset>
    </>
  );
};

export default TokenSettingsLayout;
