import { BreadCrumbType, OATWrapper } from 'oat-common-ui';
import MainFooter from '../../components/MainFooter';
import MainHeader from '../../components/MainHeader';

type Props = {
  breadCrumbs?: BreadCrumbType[];
  message?: string;
};
const Error = ({ breadCrumbs, message = 'Service unavailable. Please try again later.' }: Props) => {
  return (
    <>
      <MainHeader breadCrumbs={breadCrumbs} />
      <OATWrapper>
        <h2>{message}</h2>
      </OATWrapper>
      <MainFooter />
    </>
  );
};

export default Error;
