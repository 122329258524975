import { observer } from 'mobx-react-lite';
import { OATWrapper, OfferTabReturnCta, OfferTabsHeader, OfferTabsNav, OfferTypes, useToast } from 'oat-common-ui';
import { ReactNode, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { Link, useHistory } from 'react-router-dom';
import MarketingTab from '.';
import MainFooter from '../../../components/MainFooter';
import MainHeader from '../../../components/MainHeader';
import OfferTabBreadCrumbs from '../../../components/OfferTabComponents/OfferTabBreadCrumbs';
import OfferTabNavList from '../../../components/OfferTabComponents/OfferTabNavList';
import { OfferMarketing, OfferMarketingInput, useCreateOfferMutation, useSaveOfferMarketingMutation, useUpdateOfferMutation } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import buildOfferUrl from '../../../utils/buildOfferUrl';
import { getEfcCarJelly } from '../../../utils/efc3dImageUtils';
import getSeriesInfoFromOffer from '../../../utils/getSeriesInfoFromOffer';
import { formatMarketingPayload } from '../util';
import { createNewOfferPayload } from './utils';
import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

interface Props {
  offerType: OfferTypes;
  renderHeaderContent: ReactNode;
}

const MarketingTabComponentBase = ({ offerType, renderHeaderContent }: Props) => {
  const [createOffer] = useCreateOfferMutation();
  const [updateOffer] = useUpdateOfferMutation();
  const [saveOfferMarketingMutation] = useSaveOfferMarketingMutation();
  const { success, error } = useToast();
  const { offerId, tdaCode, lang, offeringId, site } = useUrlParams();
  const [httpError, setHttpError] = useState<GraphQLError | null>(null);
  const history = useHistory();

  const {
    userInfoStore: { userInfo, properties, isLexus, isGst },
    offersStore: { currentOffer, setCurrentOffer },
    offeringsStore: { currentOffering },
    offerMarketingStore: { applyBat, applyTcom, setCurrentOfferMarketing, currentOfferMarketing, setOfferMarketing },
    tdaStore,
    efcStore,
    tokenStore: { getTokensByOfferType, snippets },
    vehiclesStore,
  } = useStores();

  const returnLink = `/${tdaCode}/offering/${offeringId}`;
  let filteredTokens = getTokensByOfferType(currentOffer?.offerType || '');

  const handleOnSubmit = async (toBoth: boolean, payload: OfferMarketingInput, back: boolean) => {
    try {
      let curOfferId = offerId;

      // Create new offer
      if (!offerId) {
        const offerRes = await trackPromise(
          createOffer({
            variables: {
              input: createNewOfferPayload(offeringId, currentOffering, vehiclesStore.otherOfferAllVehicles, applyBat, applyTcom),
            },
          }),
        );

        curOfferId = offerRes.data?.createOffer.offer.id || '';

        // If Other offer type, update offer's applyTo flags if applicable
      } else if (offerType === OfferTypes.OTHER && (currentOffer?.isOtherOfferAppliedToBat !== applyBat || currentOffer?.isOtherOfferAppliedToTcom !== applyTcom)) {
        const offerPayload = {
          id: currentOffer?.id || '',
          rev: currentOffer?.rev || '',
          isOtherOfferAppliedToBat: applyBat,
          isOtherOfferAppliedToTcom: applyTcom,
        };
        const result = await trackPromise(
          updateOffer({
            variables: {
              input: offerPayload,
            },
          }),
        );

        if (result.data?.updateOffer.success) {
          setCurrentOffer(result.data?.updateOffer.offer);
        }
      }

      // Upsert marketing data
      const res = await trackPromise(
        saveOfferMarketingMutation({
          variables: {
            input: {
              marketingDetails: formatMarketingPayload(payload as OfferMarketing, currentOfferMarketing, toBoth, offerType, isGst(userInfo)) as OfferMarketingInput[],
              offerId: curOfferId,
            },
          },
        }),
      );

      if (res.data?.saveOfferMarketing.success) {
        const newMarketingData = res.data?.saveOfferMarketing.marketingDetails as OfferMarketing[];
        setOfferMarketing(curOfferId || '', newMarketingData);
        setCurrentOfferMarketing(newMarketingData);
        setCurrentOffer(res.data?.saveOfferMarketing.offer);
      }

      // Navigation logic
      if (back) {
        history.push(buildOfferUrl(currentOffering.id, tdaCode, offerType, offerType !== OfferTypes.OTHER ? 'financial' : 'info', lang, curOfferId || '', site));
      }

      success('Marketing saved successfully.');
      setHttpError(null)
    } catch (e) {
      const graphQLErrors = (e as ApolloError)?.graphQLErrors ?? [];
      const missingTokensError = graphQLErrors.find((error) => error.extensions?.type === 'MISSING_TOKENS');

      setHttpError(missingTokensError ?? null);
      error((e as Error).message);
    }
  };

  const handleSetLangParam = (langParam: string) => {
    history.push(buildOfferUrl(offeringId, tdaCode, offerType, 'marketing', langParam, offerId, site));
  };

  return (
    <>
      <MainHeader breadCrumbs={OfferTabBreadCrumbs(tdaStore.currentTda, properties.fullName, currentOffering, offerType)} />
      <OATWrapper withPadding>
        <Link to={returnLink} component={OfferTabReturnCta} />
        <OfferTabsHeader
          endDate={currentOffer?.endDate || ''}
          status={currentOffer?.status || ''}
          seriesMap={efcStore.uniqueYearSeries}
          offerImage={getEfcCarJelly(efcStore.seriesImages, ...getSeriesInfoFromOffer(currentOffer))}
          offerSeries={currentOffer?.series || ''}
          offerSeriesYear={currentOffer?.seriesYear || ''}
          offerType={offerType}
          renderHeaderContent={renderHeaderContent}
          renderNav={<OfferTabsNav navList={OfferTabNavList(offeringId, tdaCode, offerType, lang, offerId, site)} />}
        />
        <MarketingTab
          onSubmit={handleOnSubmit}
          isLexus={isLexus()}
          offerType={currentOffer?.offerType || ''}
          snippets={snippets}
          httpError={httpError}
          tokens={filteredTokens}
          setLangParam={handleSetLangParam}
        />
      </OATWrapper>
      <MainFooter isShowPublish darkTheme />
    </>
  );
};

export default observer(MarketingTabComponentBase);
