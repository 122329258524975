const headerColumns = [
  { header: 'ID', sortable: true, field: 'id' },
  { header: 'Template Name', sortable: true, field: 'name' },
  { header: 'Offer Type', sortable: true, field: 'offerType' },
  { header: 'Offer Tier and Terms' },
  { header: 'Status', sortable: true, field: 'status' },
  { header: 'Action Items' },
];

export default headerColumns;
