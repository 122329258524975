import { FEATURE_OR_4616 } from '../../../../../constants/global';
import { OfferRate } from '../../../../../gql/generated';
import InlineSpanToggle from '../InlineSpanToggle';
import RateTierRow from './RateTierRow';
import styles from './styles.module.scss';

interface RateTierContainerProps {
  rateTiers?: OfferRate[];
  updateRates: (offerRate: OfferRate) => void;
  updateCounter: number;
}

const rateTierHeaders = ['Rate', '$ Per Thousand', ...(!FEATURE_OR_4616 ? ['Extra Cash Amount'] : []), 'Subvention Amount'];
const tierWidth = 170;

const RateTierContainer = ({ rateTiers, updateRates, updateCounter }: RateTierContainerProps) => {
  const handleOnUpdateRates = (rateObj: OfferRate, data: Partial<OfferRate>) => {
    updateRates({
      ...rateObj,
      ...data,
    });
  };

  return (
    <div className={styles.rateTierContainer}>
      <div className={styles.headers}>
        {rateTierHeaders.map(str => (
          <span key={str} className={styles.headerCell}>
            {str}
          </span>
        ))}
      </div>
      <div className={styles.body}>
        <div className={styles.tierCol}>
          <InlineSpanToggle label="Tier 1+" width={tierWidth} advertised />
        </div>
        <div className={styles.termCol}>
          {rateTiers &&
            rateTiers.map((rateTierData: OfferRate) => (
              <RateTierRow key={rateTierData.term} rateTier={rateTierData} updateRates={data => handleOnUpdateRates(rateTierData, data)} updateCounter={updateCounter} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default RateTierContainer;
