import React from 'react';
import { SeriesCodeToNameMap } from 'oat-common-ui';
import { BAT_DOMAIN, MULTI } from '../../../../../constants/global';
import useStores from '../../../../../stores/useStores';
import buildDefaultCarJellyUrl from '../../../../../utils/buildDefaultCarJellyUrl';
import useSeriesManagerTrim from '../../../InfoTab/useSeriesManagerTrim';

import styles from './styles.module.scss';

interface SelectSeriesProps {
  changePageType: () => void;
  changeActiveMultiSeries: (val: { series: string; year: string }) => void;
}

const SelectSeries = ({ changePageType, changeActiveMultiSeries }: SelectSeriesProps) => {
  const {
    vehiclesStore,
    offersStore: { currentOffer },
  } = useStores();

  const { includedSeriesManagerList } = useSeriesManagerTrim(currentOffer, currentOffer?.offerType, vehiclesStore.otherOfferAllVehicles);

  return (
    <>
      {currentOffer?.seriesYear === MULTI ? (
        <ul className={styles.efcModalList}>
          {includedSeriesManagerList.map((included, index) => {
            const modelYear = included.data.substring(0, 4);
            const modelSeries = included.data.substring(5, included.data.length);
            return (
              <li key={index}>
                <button
                  id={`select-series-btn-${index}`}
                  className={styles.btn}
                  onClick={() => {
                    changePageType();
                    changeActiveMultiSeries({ series: modelSeries, year: modelYear });
                  }}
                >
                  <img className={styles.img} src={buildDefaultCarJellyUrl(BAT_DOMAIN, modelSeries)} alt="" />
                </button>
                <p>
                  {modelYear} {SeriesCodeToNameMap[modelSeries]}
                </p>
              </li>
            );
          })}
        </ul>
      ) : (
        <div className={styles.selectSeriesImg}>
          <button id="select-series-btn" className={styles.btn} onClick={changePageType}>
            <img className={styles.img} src={buildDefaultCarJellyUrl(BAT_DOMAIN, currentOffer?.series || '')} alt="" />
          </button>
          <p>
            {currentOffer?.seriesYear} {SeriesCodeToNameMap[currentOffer?.series as string]}
          </p>
        </div>
      )}
    </>
  );
};

export default SelectSeries;
