import { get3dImages } from 'oat-admin-common';
import { Efc3dImagesResponse, EfcApi, SeriesV1Response, UniqueSeriesType } from 'oat-common-ui';
import authClient from '../authClient';
class EFCStore {
  _apiKey = process.env.REACT_APP_EFC_MS_API_KEY as string;
  _domain = process.env.REACT_APP_EFC_MS_URL as string;
  _3dImagesDomain = process.env.REACT_APP_REST_PROXY_URL as string;
  _3dImagesAppKey = process.env.REACT_APP_NEXT_TMS_APP_KEY as string;

  series: SeriesV1Response | undefined;
  seriesImages = new Map<string, Efc3dImagesResponse>();
  uniqueYearSeries: UniqueSeriesType[] = [];

  getSeriesV1 = async (brand: string) => {
    if (!this.series) {
      try {
        const res = await EfcApi.getSeriesV1(this._domain, this._apiKey, brand);
        this.series = res.data;
        this.uniqueYearSeries = EfcApi.getSeriesV1UniqueSeries(res.data);
      } catch (e) {}
    }
  };

  get3dMapKey = (year: string, series: string) => {
    return `${year}_${series}`;
  };

  has3dImages = (year: string, series: string) => {
    return this.seriesImages.has(this.get3dMapKey(year, series));
  };

  get3dImages = async (brand: string, year: string, series: string) => {
    const mapKey = this.get3dMapKey(year, series);
    if (year !== 'MULTI' && series !== 'MULTI' && !this.seriesImages.has(mapKey)) {
      let token = await authClient.getToken();

      if (!token) {
        token = process.env.REACT_APP_TOKEN || '';
      }

      try {
        const res = await get3dImages(this._3dImagesDomain, token, brand, year, series);
        this.seriesImages.set(mapKey, res.data);
      } catch (e) {}
    }
  };
}

export default EFCStore;
