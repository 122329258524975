import { BreadCrumbType } from 'oat-common-ui';
import { ReactNode } from 'react';
import useGetEfc3dImages from '../../hooks/useGetEfc3dImages';
import Error from '../../pages/Error';
import Loading from '../../pages/Loading';

type Props = {
  series: string;
  year: string;
  breadCrumbs: BreadCrumbType[];
  children?: ReactNode;
};

const EfcCarJellyLoader = ({ series, year, breadCrumbs, children }: Props) => {
  const { loading, error } = useGetEfc3dImages(year, series);

  if (loading) {
    return <Loading breadCrumbs={breadCrumbs} />;
  }

  if (error) {
    return <Error breadCrumbs={breadCrumbs} />;
  }

  return <>{children}</>;
};

export default EfcCarJellyLoader;
