import { MaintenanceLayout, OATToast, ResetLayout } from 'oat-common-ui';
import { HashRouter } from 'react-router-dom';
import '../node_modules/@fortawesome/fontawesome-svg-core/styles.css';
import '../node_modules/oat-common-ui/dist/index.css';
import Routes from './Routes';

const App = () => {
  return (
    <MaintenanceLayout url={`/maintenance.json`}>
      <ResetLayout />
      <HashRouter>
        <OATToast>
          <Routes />
        </OATToast>
      </HashRouter>
    </MaintenanceLayout>
  );
};

export default App;
