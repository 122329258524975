import { Table, TableContainer, TableTitle } from 'oat-common-ui';
import React from 'react';
import { OfferLeaseAccesory } from '../../../../../../gql/generated';
import styles from './styles.module.scss';

const AccessoriesTable: React.FC<{ accessories?: OfferLeaseAccesory[] }> = ({ accessories }) => {
  return accessories ? (
    <TableContainer className={styles.tableContainer}>
      <TableTitle className={styles.tableTitle}>
        <h3>Accessories</h3>
      </TableTitle>
      <Table>
        <thead>
          <tr className={styles.headRow}>
            <th className={styles.headingCell}>Code</th>
            <th className={styles.headingCell}>Description</th>
            <th className={styles.headingCell}>MSRP</th>
          </tr>
        </thead>
        <tbody>
          {accessories.map(acc => (
            <tr key={acc.code} className={styles.tableRow}>
              <td>{acc.code}</td>
              <td>{acc.description}</td>
              <td>${acc.msrp}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  ) : (
    <></>
  );
};

export default AccessoriesTable;
