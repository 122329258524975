import { OfferVehicle } from '../gql/generated';

export type TrimOption = {
  modelCode: string;
  vehicleDescription: string | null;
};

export type TrimVehicle = {
  id: number;
  tdaOfferId: number;
  series: string;
  seriesYear: string;
  isInclusion: boolean;
  isAdvertised: boolean;
  modelCode: string;
  vehicleDescription: string;
};

class TrimStore {
  isLoaded = false;
  trims: OfferVehicle[] = [];
  trimOptions: TrimOption[] = [];

  setVehicles = (vehicles: OfferVehicle[]) => {
    this.trims = vehicles;
  };
}

export default TrimStore;
