import { makeObservable, observable, runInAction } from 'mobx';

class ReportsStore {
  availableReports: string[] = [];

  constructor() {
    makeObservable(this, {
      availableReports: observable,
    });
  }

  setAvailableReports = (reports: string[]) => {
    runInAction(() => {
      this.availableReports = reports;
    });
  };
}

export default ReportsStore;
