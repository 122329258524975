import { Button, ButtonGroup, ModalBody, ModalFooter, SeriesCodeToNameMap } from 'oat-common-ui';
import { useEffect, useState } from 'react';
import LoadingModal from '../../../../../components/LoadingModal';
import useStores from '../../../../../stores/useStores';
import ConfirmPage from './ConfirmPage';
import SelectAngle from './SelectAngle';
import SelectColor from './SelectColor';
import SelectSeries from './SelectSeries';
import SelectTrim from './SelectTrim';
import styles from './styles.module.scss';

interface Props {
  setImagePath: (imageSrc: string) => void;
  imagePath: string;
  closeModal: () => void;
}

enum Steps {
  SELECT_SERIES = 'Select Series',
  SELECT_TRIM = 'Select Trim',
  SELECT_COLOR = 'Select Color',
  SELECT_ANGLE = 'Select Angle',
  CONFIRM = 'Confirm',
}

const EfcImagePickerModal = ({ setImagePath, imagePath, closeModal }: Props) => {
  const {
    efcStore: { seriesImages, get3dMapKey, get3dImages },
    offersStore: { currentOffer },
    userInfoStore,
  } = useStores();

  const [activeMultiSeries, setActiveMultiSeries] = useState({ series: '', year: '' });
  const mapKey = get3dMapKey(currentOffer?.seriesYear || '', currentOffer?.series || '');

  const [seriesImagesData, setSeriesImagesData] = useState(seriesImages.get(mapKey)?.DataArea);
  const modelCode = Object.keys(seriesImagesData?.model || '');

  const [pageType, setPageType] = useState(Steps.SELECT_SERIES);
  const [localImagePath, setLocalImagePath] = useState('');

  const [activeModelCode, setActiveModelCode] = useState('');
  const [loadingEfc, setLoadingEfc] = useState(false);
  const [activeExteriorColor, setActiveExteriorColor] = useState('');
  const [angleImageIndex, setAngleImageIndex] = useState(1);
  const [exteriorColorCode, setExteriorColorCode] = useState(Object.keys(seriesImagesData?.model[modelCode[0]].exteriorcolor || '') || []);

  const activeVehicle = currentOffer?.vehicles.find(v => {
    return v.modelCode === activeModelCode;
  });
  const activeEnumIndex = Object.values(Steps).indexOf(pageType);

  useEffect(() => {
    if (activeMultiSeries.year && activeMultiSeries.series) {
      setLoadingEfc(true);
      (async () => {
        await get3dImages(userInfoStore.userInfo.brand, activeMultiSeries.year, activeMultiSeries.series);
        setSeriesImagesData(seriesImages.get(`${activeMultiSeries.year}_${activeMultiSeries.series}`)?.DataArea);
        setLoadingEfc(false);
      })();
    }
  }, [activeMultiSeries, userInfoStore, get3dImages, seriesImages]);

  const ModalNavigation = () => {
    return (
      <p className={styles.efcModalNav}>
        {activeEnumIndex > 0 && (
          <>
            <button
              id={`change-page-to-${Steps.SELECT_SERIES}`}
              onClick={() => {
                setPageType(Steps.SELECT_SERIES);
              }}
            >
              {activeMultiSeries.series ? SeriesCodeToNameMap[activeMultiSeries.series] : SeriesCodeToNameMap[currentOffer?.series as string]}
            </button>
            <span>/</span>
          </>
        )}
        {activeEnumIndex > 1 && (
          <>
            <button
              id={`change-page-to-${Steps.SELECT_TRIM}`}
              onClick={() => {
                setPageType(Steps.SELECT_TRIM);
              }}
            >
              {`${activeVehicle?.vehicleDescription} (${activeVehicle?.seriesYear}-${activeVehicle?.modelCode})`}
            </button>
            <span>/</span>
          </>
        )}
        {activeEnumIndex > 2 && (
          <>
            <button
              id={`change-page-to-${Steps.SELECT_COLOR}`}
              onClick={() => {
                setPageType(Steps.SELECT_COLOR);
              }}
            >
              {seriesImagesData?.model[activeModelCode].exteriorcolor[activeExteriorColor].title.replace(' [extra_cost_color]', '')}
            </button>
            <span>/</span>
          </>
        )}
        {activeEnumIndex > 3 && (
          <>
            <button
              id={`change-page-to-${Steps.SELECT_ANGLE}`}
              onClick={() => {
                setPageType(Steps.SELECT_ANGLE);
              }}
            >
              {seriesImagesData?.['3dimagetags']['exterior'][angleImageIndex]}
            </button>
            <span>/</span>
          </>
        )}
        <span className={styles.efcModalNavText}>{pageType}</span>
      </p>
    );
  };

  return (
    <>
      <ModalBody className={styles.modalBody}>
        <ModalNavigation />
        {loadingEfc && <LoadingModal message="Loading Vehicle data…" />}
        {pageType === Steps.SELECT_SERIES && <SelectSeries changePageType={() => setPageType(Steps.SELECT_TRIM)} changeActiveMultiSeries={setActiveMultiSeries} />}
        {pageType === Steps.SELECT_TRIM && !loadingEfc && (
          <SelectTrim
            changePageType={() => {
              setPageType(Steps.SELECT_COLOR);
            }}
            activeMultiSeries={activeMultiSeries}
            setActiveModelCode={setActiveModelCode}
            setExteriorColorCode={setExteriorColorCode}
            seriesImagesData={seriesImagesData}
          />
        )}
        {pageType === Steps.SELECT_COLOR && (
          <SelectColor
            changePageType={() => setPageType(Steps.SELECT_ANGLE)}
            seriesImagesData={seriesImagesData}
            activeModelCode={activeModelCode}
            exteriorColorCode={exteriorColorCode}
            setActiveExteriorColor={setActiveExteriorColor}
          />
        )}
        {pageType === Steps.SELECT_ANGLE && (
          <SelectAngle
            seriesImagesData={seriesImagesData}
            changePageType={() => setPageType(Steps.CONFIRM)}
            activeExteriorColor={activeExteriorColor}
            activeModelCode={activeModelCode}
            setLocalImagePath={setLocalImagePath}
            setAngleImageIndex={setAngleImageIndex}
          />
        )}
        {pageType === Steps.CONFIRM && <ConfirmPage localImagePath={localImagePath} />}
      </ModalBody>
      {pageType === Steps.CONFIRM && (
        <ModalFooter>
          <ButtonGroup>
            <Button
              variant="primary"
              id="cancel-btn"
              onClick={() => {
                setLocalImagePath('');
                setImagePath(imagePath);
                closeModal();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              id="submit-btn"
              onClick={() => {
                setImagePath(localImagePath);
                closeModal();
              }}
            >
              Submit
            </Button>
          </ButtonGroup>
        </ModalFooter>
      )}
    </>
  );
};

export default EfcImagePickerModal;
