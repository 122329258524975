import { makeObservable, observable, runInAction } from 'mobx';
import { OfferTypes } from 'oat-common-ui';
import { Disclaimer, Restriction, Snippet, TokensMap } from '../gql/generated';
import { TokenRibbon } from '../pages/TokenSettings/RibbonsComponent';

class TokenStore {
  isLoaded = false;
  tokens: TokensMap | undefined;
  allTokens: string[] = [];
  snippets: Snippet[] = [];
  disclaimers: Disclaimer[] = [];
  restrictions: Restriction[] = [];
  ribbons: TokenRibbon[] = [];

  constructor() {
    makeObservable(this, {
      snippets: observable,
      disclaimers: observable,
      restrictions: observable,
      ribbons: observable,
    });
  }

  setTokenItems = (tokens: TokensMap, snippets: Snippet[] = [], disclaimers: Disclaimer[] = [], restrictions: Restriction[] = [], ribbons: TokenRibbon[] = []) => {
    runInAction(() => {
      this.tokens = tokens;
      this.processTokens(tokens);
      this.snippets = snippets;
      this.disclaimers = disclaimers;
      this.restrictions = restrictions;
      this.ribbons = ribbons;
      this.isLoaded = true;
    });
  };

  setTokens = (tokens: TokensMap) => {
    runInAction(() => {
      this.tokens = tokens;
      this.processTokens(tokens);
    });
  };

  setSnippets = (snippets: Snippet[] = []) => {
    runInAction(() => {
      this.snippets = snippets;
    });
  };

  setDisclaimers = (disclaimers: Disclaimer[] = []) => {
    runInAction(() => {
      this.disclaimers = disclaimers;
    });
  };

  setRestrictions = (restrictions: Restriction[] = []) => {
    runInAction(() => {
      this.restrictions = restrictions;
    });
  };

  setRibbons = (ribbons: TokenRibbon[] = []) => {
    runInAction(() => {
      this.ribbons = ribbons;
    });
  };

  getTokensByOfferType = (offerType: string) => {
    let filteredTokens: string[] = [];

    if (this.tokens) {
      switch (offerType) {
        case OfferTypes.CUSTOMER_CASH: {
          filteredTokens = this.tokens.Cash as string[];
          break;
        }
        case OfferTypes.APR: {
          filteredTokens = this.tokens.APR as string[];
          break;
        }
        case OfferTypes.LEASE: {
          filteredTokens = this.tokens.Lease as string[];
          break;
        }
        case OfferTypes.MISCELLANEOUS: {
          filteredTokens = this.tokens.Misc as string[];
          break;
        }
        default: {
          filteredTokens = this.tokens.Other as string[];
          break;
        }
      }
    }

    return filteredTokens;
  };

  processTokens = (tokens: TokensMap) => {
    const tokenSet = new Set<string>();
    tokens?.APR.forEach(token => tokenSet.add(token as string));
    tokens?.Cash.forEach(token => tokenSet.add(token as string));
    tokens?.Lease.forEach(token => tokenSet.add(token as string));
    tokens?.Misc.forEach(token => tokenSet.add(token as string));
    tokens?.Other?.forEach(token => tokenSet.add(token as string));
    this.allTokens = Array.from(tokenSet);
    this.isLoaded = true;
  };
}

export default TokenStore;
