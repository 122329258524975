import { AdminConstants } from 'oat-admin-common';

const { BRAND_LEXUS, BRAND_TOYOTA, SPECIAL_EDITION_BONUS_TYPE, TdaCodes } = AdminConstants;

export const HELP_URL = 'https://buyatoyota.atlassian.net/wiki/spaces/OATT/pages/188842022/TDA+How-To+Articles';

export enum Language {
  ENGLISH = 'english',
  SPANISH = 'spanish',
}

export enum LdaCode {
  LWA = 'LWA',
  LSA = 'LSA',
  LEA = 'LEA',
  LCA = 'LCA',
}

export enum Port {
  PT = 'PT',
  SJ = 'SJ',
  NY = 'NY',
  LA = 'LA',
  JX = 'JX',
  GT = 'GT',
  CM = 'CM',
}

export enum CashTypes {
  /**
   * @deprecated use COLLEGE instead
   */
  COLLEGE_GRADUATE = 'College Graduate',
  COLLEGE = 'College',
  MILITARY = 'Military',
  BONUS = 'Bonus',
  DOWN_PAYMENT_ASSISTANCE = 'Down Payment Assistance',
  TRADE_IN_ASSISTANCE = 'Trade-in Assistance',
  COMPLIMENTARY_FIRST_PAYMENT = 'Complimentary First Payment',
  LEASE_LOYALTY = 'Lease Loyalty',
  LOYALTY = 'Loyalty',
  CONQUEST = 'Conquest',
  OTHER = 'Other',
  /**
   * @deprecated use LFS_FINANCE_CASH instead
   */
  LFS_APR_CASH = 'LFS APR Cash',
  LFS_FINANCE_CASH = 'LFS Finance Cash',
  LFS_LEASE_CASH = 'LFS Lease Cash',
  /**
   * @deprecated use TFS_FINANCE_CASH instead
   */
  TFS_APR_CASH = 'TFS APR Cash',
  TFS_FINANCE_CASH = 'TFS Finance Cash',
  TFS_LEASE_CASH = 'TFS Lease Cash',
}

export const extraCashBase = (brand: string, tdaCode: string) => {
  const specialEdition = tdaCode === TdaCodes.GST ? [SPECIAL_EDITION_BONUS_TYPE] : [];
  return [
    process.env.REACT_APP_FEATURE_OR_5454_PHASE_2 === 'true' ? CashTypes.COLLEGE : CashTypes.COLLEGE_GRADUATE,
    CashTypes.MILITARY,
    CashTypes.BONUS,
    CashTypes.DOWN_PAYMENT_ASSISTANCE,
    CashTypes.TRADE_IN_ASSISTANCE,
    CashTypes.COMPLIMENTARY_FIRST_PAYMENT,
    CashTypes.LEASE_LOYALTY,
    CashTypes.LOYALTY,
    CashTypes.CONQUEST,
    CashTypes.OTHER,
    brand === BRAND_LEXUS
      ? FEATURE_OR_2947_PHASE_2
        ? CashTypes.LFS_FINANCE_CASH
        : CashTypes.LFS_APR_CASH
      : FEATURE_OR_2947_PHASE_2
      ? CashTypes.TFS_FINANCE_CASH
      : CashTypes.TFS_APR_CASH,
    brand === BRAND_LEXUS ? CashTypes.LFS_LEASE_CASH : CashTypes.TFS_LEASE_CASH,
    ...specialEdition,
  ];
};

export const CashMiscExtraCashTypes = (brand: string, tdaCode: string) => {
  if (brand === BRAND_TOYOTA) {
    return ['Customer Cash', ...extraCashBase(BRAND_TOYOTA, tdaCode)];
  }

  return [...extraCashBase(BRAND_LEXUS, tdaCode)];
};

export const AprExtraCashTypes = (brand: string, tdaCode: string) => {
  return [...extraCashBase(brand, tdaCode), 'Deferred Payment'];
};

export enum MarketingSites {
  BAT = 'BAT',
  TCOM = 'TCOM',
  TCOM_OTHER = 'TCOM/Other Sites',
}

export enum SecondaryCardLabels {
  BONUS = 'Bonus',
  BONUS_CASH = 'Bonus Cash',
  LOYALTY_CASH = 'Loyalty Cash',
  TRAILBLAZER_CASH = 'Trailblazer Cash',
  CASH = 'Cash',
  LEASE_CASH = 'Lease Cash',
  SPECIAL_EDITION_BONUS_TYPE = 'Special Edition Bonus',
}

export const SEND_EMAIL_PROMPT = 'Are you sure you want to send an email to the region requesting the changes?';
export const SEND_EMAIL_SUCCESS = 'Email request sent successfully';

export const MULTI = 'MULTI';

export const BAT_DOMAIN = 'https://www.buyatoyota.com';

export const FINANCE_OFFER_TYPE = 'Finance';
export const FINANCE_OFFER_TYPE_2 = 'APR/Finance'; // this is to handle any existing records

export const stackedOfferTableHeaderColumns = ['OFFER TYPE', 'OFFER DETAIL', 'OFFER ID'];

// FEATURE FLAGS
export const FeatureFlags = {
  OR_4619: 'OR_4619',
} as const;
export const FEATURE_OR_4159 = process.env.REACT_APP_FEATURE_OR_4159 === 'true';
export const FEATURE_OR_4767 = process.env.REACT_APP_FEATURE_OR_4767 === 'true';
export const FEATURE_OR_5454 = process.env.REACT_APP_FEATURE_OR_5454 === 'true';
export const FEATURE_OR_5454_PHASE_2 = process.env.REACT_APP_FEATURE_OR_5454_PHASE_2 === 'true';
export const FEATURE_OR_2947_PHASE_2 = process.env.REACT_APP_FEATURE_OR_2947_PHASE_2 === 'true';
export const FEATURE_OR_4616 = process.env.REACT_APP_FEATURE_OR_4616 === 'true';
