import { AdminConstants } from 'oat-admin-common';
import { OfferTypes } from 'oat-common-ui';
import { FEATURE_OR_5454, SecondaryCardLabels } from '../constants/global';
import TdaOption from '../models/TdaType';

const { BRAND_LEXUS, BRAND_TOYOTA, TdaCodes, SPECIAL_EDITION_BONUS_TYPE, OPTION_TYPE_NAMES } = AdminConstants;

const getFinanceOrAPRCashLabel = () => {
  return process.env.REACT_APP_FEATURE_OR_2947_PHASE_2 === 'true' ? 'Finance Cash' : 'APR Cash';
};

export const getPrimaryOfferCardLabel = (brand: string, tdaCode: TdaOption | undefined, optionType: string | undefined) => {
  let primaryOfferCardLabel: string[] = ['Cash', 'Bonus Cash', 'Loyalty Cash', 'Trailblazer Cash'];

  if (brand === BRAND_LEXUS) {
    primaryOfferCardLabel.push('Military');
    if (FEATURE_OR_5454) {
      primaryOfferCardLabel.push('College');
    } else {
      primaryOfferCardLabel.push('College Grad');
    }
  } else {
    primaryOfferCardLabel.push(getFinanceOrAPRCashLabel(), 'Lease Cash');

    if (tdaCode?.code === TdaCodes.GST) {
      primaryOfferCardLabel.push(SPECIAL_EDITION_BONUS_TYPE);
    }
  }

  if (brand === BRAND_LEXUS && optionType === OPTION_TYPE_NAMES.COMPLIMENTARY_FIRST_PAYMENT) {
    primaryOfferCardLabel.unshift(OfferTypes.LEASE, OfferTypes.APR);
  }
  return primaryOfferCardLabel;
};

export const getSecondaryOfferCardLabel = (brand: string, tdaCode: TdaOption | undefined, offerType: string) => {
  let secondaryCardLabel: string[] = [
    SecondaryCardLabels.BONUS,
    SecondaryCardLabels.BONUS_CASH,
    SecondaryCardLabels.LOYALTY_CASH,
    SecondaryCardLabels.TRAILBLAZER_CASH
  ];

  if (offerType === OfferTypes.MISCELLANEOUS) {
    secondaryCardLabel.unshift(SecondaryCardLabels.CASH);

    if (brand === BRAND_TOYOTA) {
      secondaryCardLabel.push(getFinanceOrAPRCashLabel(), SecondaryCardLabels.LEASE_CASH);
    }
  }

  if (offerType === OfferTypes.APR) {
    secondaryCardLabel.push(getFinanceOrAPRCashLabel());
  }

  if (offerType === OfferTypes.LEASE) {
    secondaryCardLabel.push(SecondaryCardLabels.LEASE_CASH);
  }

  if (brand === BRAND_TOYOTA) {
    if (tdaCode?.code === TdaCodes.GST && offerType !== OfferTypes.MISCELLANEOUS && offerType !== OfferTypes.OTHER) {
      secondaryCardLabel.push(SecondaryCardLabels.SPECIAL_EDITION_BONUS_TYPE);
    }
  }

  return secondaryCardLabel;
};
