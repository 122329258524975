import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { Button, SettingsLayout } from 'oat-common-ui';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import MainHeader from '../../components/MainHeader';
import SendToAreasModal from '../../components/SendToAreasModal';
import { SettingsBreadCrumbs, SettingsNav } from '../../components/SettingsComponents';
import { Template, useGetTemplatesByTdaQuery } from '../../gql/generated';
import useUrlParams from '../../hooks/useUrlParams';
import useStores from '../../stores/useStores';
import { getUserTdas } from '../../utils/getUserTdas';
import Error from '../Error';
import Loading from '../Loading';
import OfferTemplateMenuComponent from './OfferTemplateMenu/OfferTemplateMenuComponent';
import styles from './styles.module.scss';

const { BRAND_LEXUS, LDA_MASTER_TDA_CODE } = AdminConstants;

const OfferTemplates = () => {
  const [showSendToAreasModal, setShowSendToAreasModal] = useState(false);
  let activeTemplates: Template[] = [];

  const {
    userInfoStore: {
      properties: { fullName },
      isLexus,
      userInfo,
    },
    offerTemplatesStore,
    tdaStore,
  } = useStores();
  const { tdaCode } = useUrlParams();
  const history = useHistory();
  const location = useLocation();

  const isMasterTemplate = location.pathname.includes('master');

  const tdas = getUserTdas(userInfo.tdaCodes, BRAND_LEXUS);
  const { data, loading, error } = useGetTemplatesByTdaQuery({ variables: { tdaCode: isMasterTemplate ? LDA_MASTER_TDA_CODE : tdaCode }, fetchPolicy: 'no-cache' });

  if (loading) {
    return <Loading breadCrumbs={SettingsBreadCrumbs(fullName, tdaCode)} />;
  }

  if (error) {
    return <Error breadCrumbs={SettingsBreadCrumbs(fullName, tdaCode)} />;
  }

  if (data) {
    offerTemplatesStore.loadOfferTemplates(data);
    activeTemplates = data.active as Template[];
  }

  const handleCreate = () => {
    if (process.env.REACT_APP_FEATURE_OR_4594 === 'true' && isLexus()) {
      history.push(`/${tdaCode}/settings/offerTemplate/${isMasterTemplate ? 'master' : tdaCode}`);
    } else {
      history.push(`/${tdaCode}/settings/offerTemplate`);
    }

    window.scrollTo(0, 0);
  };

  return (
    <>
      <MainHeader showMiscLinks={false} breadCrumbs={SettingsBreadCrumbs(fullName, tdaCode, isMasterTemplate ? 'Master Template' : 'Template', isLexus())} />
      <SettingsLayout settings={SettingsNav(tdaCode, isLexus(), userInfo.tdaCodes, tdaStore.setCurrentTdaOption, false, true)}>
        <OfferTemplateMenuComponent isMasterTemplate={isMasterTemplate} />
        <div>
          <Button variant="primary" className={styles.createNewBtn} onClick={() => handleCreate()}>
            Create New
          </Button>
          {isMasterTemplate && (
            <Button variant="primary" className={styles.createNewBtn} onClick={() => setShowSendToAreasModal(true)} disabled={tdas.length !== 4}>
              Send to Area(s)
            </Button>
          )}
        </div>
      </SettingsLayout>

      {showSendToAreasModal && process.env.REACT_APP_FEATURE_OR_4594 === 'true' && <SendToAreasModal onClose={() => setShowSendToAreasModal(false)} template={activeTemplates} />}
    </>
  );
};

export default observer(OfferTemplates);
