import { makeAutoObservable } from 'mobx';
import { uuidv4 } from 'oat-common-ui';

export interface MockStackedOffer {
  extraCashOfferName: string;
  extraCashOfferId: string;
  extraCashOfferStartDate: Date;
  extraCashOfferEndDate: Date;
  extraCashLabel: string;
  extraCashLabelSpanish: string;
  extraCashOfferType: string;
  extraCashAmount: string;
  fundingSource: string;
}

class StackedOffersStore {
  stackedOffers: MockStackedOffer[] = [];
  mockStackedOffers: MockStackedOffer[] = [
    {
      extraCashAmount: '500',
      extraCashOfferName: 'Bonus',
      extraCashOfferId: uuidv4(),
      extraCashOfferStartDate: new Date(),
      extraCashOfferEndDate: new Date(),
      extraCashLabel: '',
      extraCashLabelSpanish: '',
      extraCashOfferType: '',
      fundingSource: '',
    },
    {
      extraCashAmount: '500',
      extraCashOfferName: 'Military',
      extraCashOfferId: uuidv4(),
      extraCashOfferStartDate: new Date(),
      extraCashOfferEndDate: new Date(),
      extraCashLabel: '',
      extraCashLabelSpanish: '',
      extraCashOfferType: '',
      fundingSource: '',
    },
    {
      extraCashAmount: '500',
      extraCashOfferName: 'College Graduate',
      extraCashOfferId: uuidv4(),
      extraCashOfferStartDate: new Date(),
      extraCashOfferEndDate: new Date(),
      extraCashLabel: '',
      extraCashLabelSpanish: '',
      extraCashOfferType: '',
      fundingSource: '',
    },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  addOffer = (offer: MockStackedOffer) => {
    if (this.stackedOffers.length < 2) {
      this.stackedOffers.push(offer);
    }
  };

  removeOffer = (offerId: string) => {
    this.stackedOffers = this.stackedOffers.filter(item => item.extraCashOfferId !== offerId);
  };
}

export default StackedOffersStore;
