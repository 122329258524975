import clsx from 'clsx';
import { useState } from 'react';
import { Template } from '../../../gql/generated';
import OfferTemplateMenuTable from './OfferTemplateMenuTable';
import styles from './styles.module.scss';

interface Props {
  activeTemplates: Template[];
  archivedTemplates: Template[];
  onDelete: (template: Template) => void;
  onArchive: (template: Template) => void;
  onStatusChange: (template: Template) => void;
  isMasterTemplate: boolean;
}

const OfferTemplateMenuLayout = ({ activeTemplates, archivedTemplates, onDelete, onArchive, onStatusChange, isMasterTemplate }: Props) => {
  const [activeTab, setActiveTab] = useState(false);

  const handleOnClick = () => {
    setActiveTab(!activeTab);
  };

  return (
    <>
      <ul className={styles.tabs}>
        <li key={'Templates'} className={clsx(isMasterTemplate && styles.master)}>
          <button type="button" id={`ts-group-${'Templates'}`} className={!activeTab ? styles.active : undefined} onClick={() => handleOnClick()}>
            Templates
          </button>
        </li>
        {!isMasterTemplate && (
          <li key={'Archived'}>
            <button type="button" id={`ts-group-${'Archived'}`} className={activeTab ? styles.active : undefined} onClick={() => handleOnClick()}>
              Archive
            </button>
          </li>
        )}
      </ul>
      <div className={styles.table}>
        <OfferTemplateMenuTable
          id="tdaLdaOfferTemplateTable"
          isArchived={activeTab}
          activeTemplates={activeTemplates}
          archivedTemplates={archivedTemplates}
          onDelete={onDelete}
          onArchive={onArchive}
          onStatusChange={onStatusChange}
          isMasterTemplate={isMasterTemplate}
        ></OfferTemplateMenuTable>
      </div>
    </>
  );
};

export default OfferTemplateMenuLayout;
