import { useState } from 'react';
import { SettingsBreadCrumbs } from '../../components/SettingsComponents';
import {
  Disclaimer,
  Restriction,
  Snippet,
  Template,
  TemplateMarketing,
  TokensMap,
  useGetTdaImagesQuery,
  useGetTemplateByIdQuery,
  useGetTemplateMarketingQuery,
  useGetTokensSnippetsDisclaimersRestrictionsQuery,
  useRibbonsByTdaQuery,
} from '../../gql/generated';
import useUrlParams from '../../hooks/useUrlParams';
import useStores from '../../stores/useStores';
import Error from '../Error';
import Loading from '../Loading';
import { TokenRibbon } from '../TokenSettings/RibbonsComponent';
import OfferTemplateComponentBase from './OfferTemplateComponentBase';
import { useLocation } from 'react-router-dom';

const OfferTemplateBase = () => {
  const {
    userInfoStore: {
      isLexus,
      properties: { fullName },
    },
    tokenStore,
    offerTemplatesStore: {
      setOfferTemplate,
      setCurrentOfferTemplate,
      setTemplateMarketing,
      setCurrentTemplateMarketing,
      setActiveTemplateMarketingIndex,
      defaultTemplateMarketing,
      defaultOfferTemplate,
      getTemplateMarketingById,
      getOfferTemplateById,
    },
    tdaImagesStore,
  } = useStores();
  const { tdaCode, offerTemplateId } = useUrlParams();
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();
  const isFeatureMasterToken = location.pathname.includes('master');
  const breadCrumbs = SettingsBreadCrumbs(fullName, tdaCode, isFeatureMasterToken ? 'Master Template' : 'Template', isLexus(), offerTemplateId, true);

  const {
    data: templateData,
    loading: templateLoading,
    error: templateError,
  } = useGetTemplateByIdQuery({
    variables: { id: offerTemplateId },
    fetchPolicy: 'no-cache',
    skip: !offerTemplateId,
  });

  const {
    data: templateMarketingData,
    loading: templateMarketingLoading,
    error: templateMarketingError,
  } = useGetTemplateMarketingQuery({
    variables: { offerId: offerTemplateId, tdaCode },
    fetchPolicy: 'no-cache',
    skip: !offerTemplateId,
  });

  const {
    data: tokenData,
    loading: tokenLoading,
    error: tokenError,
  } = useGetTokensSnippetsDisclaimersRestrictionsQuery({
    variables: { tdaCode },
    fetchPolicy: 'no-cache',
    skip: offerTemplateId ? true : false,
  });

  const {
    data: ribbonsData,
    loading: ribbonsLoading,
    error: ribbonsError,
  } = useRibbonsByTdaQuery({
    variables: { tdaCode },
    fetchPolicy: 'no-cache',
    skip: isLexus(),
  });

  const { data: tdaImagesData, loading: tdaImagesLoading } = useGetTdaImagesQuery({
    variables: { tdaCode },
    fetchPolicy: 'no-cache',
  });

  if (tdaImagesData) {
    tdaImagesStore.setTdaImagesData(tdaImagesData.imagesByTda);
  }

  if (templateLoading || templateMarketingLoading || ribbonsLoading || tdaImagesLoading || tokenLoading) {
    return <Loading breadCrumbs={breadCrumbs} />;
  }

  if (templateError || templateMarketingError || ribbonsError || tokenError) {
    return <Error breadCrumbs={breadCrumbs} />;
  }

  if (templateData && templateMarketingData && (isLexus() || ribbonsData)) {
    setOfferTemplate(offerTemplateId, templateData.template as Template);
    setTemplateMarketing(offerTemplateId, templateMarketingData.templateMarketingBytemplateId as TemplateMarketing[]);
    setActiveTemplateMarketingIndex(true, 'en');

    tokenStore.setTokenItems(
      templateMarketingData.tokens as TokensMap,
      templateMarketingData.snippets as Snippet[],
      templateMarketingData.disclaimers as Disclaimer[],
      templateMarketingData.restrictions as Restriction[],
      ribbonsData?.ribbonsByTda as TokenRibbon[],
    );
  }

  if (!templateData && !templateMarketingData && tokenData && (isLexus() || ribbonsData)) {
    setCurrentOfferTemplate(defaultOfferTemplate);
    setCurrentTemplateMarketing(defaultTemplateMarketing);
    setActiveTemplateMarketingIndex(isLexus() ? false : true, 'en');

    tokenStore.setTokenItems(
      tokenData?.tokens as TokensMap,
      tokenData?.snippets as Snippet[],
      tokenData?.disclaimers as Disclaimer[],
      tokenData?.restrictions as Restriction[],
      ribbonsData?.ribbonsByTda as TokenRibbon[],
    );
  }

  if (!loaded) {
    if (offerTemplateId) {
      setCurrentOfferTemplate(getOfferTemplateById(offerTemplateId));
      setCurrentTemplateMarketing(getTemplateMarketingById(offerTemplateId));
    } else {
      setCurrentOfferTemplate(defaultOfferTemplate);
      setCurrentTemplateMarketing(defaultTemplateMarketing);
    }

    setLoaded(true);
  }

  return <OfferTemplateComponentBase />;
};

export default OfferTemplateBase;
