import { useEffect, useState } from 'react';
import useStores from '../stores/useStores';
import useUrlParams from './useUrlParams';

const useGetEfc3dImages = (year: string, series: string) => {
  const { userInfoStore, efcStore, offersStore } = useStores();
  const { lang } = useUrlParams();

  const [loading, setLoading] = useState(!efcStore.has3dImages(year, series));
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    (async () => {
      try {
        await efcStore.get3dImages(userInfoStore.userInfo.brand, year, series);
        setLoading(false);
      } catch (e) {
        setError(e as Error);
        setLoading(false);
      }
    })();
  }, [efcStore, userInfoStore, lang, offersStore.currentOffer, series, year]);

  return {
    loading,
    error,
  };
};

export default useGetEfc3dImages;
