import { makeObservable, observable, runInAction } from 'mobx';
import { MarketingSites } from '../constants/global';
import { OfferMarketing } from '../gql/generated';
import { OfferMarketingItem } from '../pages/OfferTabs/MarketingTab/useDnd';

interface IOfferMarketing {
  [offerId: string]: OfferMarketing[];
}

class OfferMarketingStore {
  offerMarketing = {} as IOfferMarketing;
  currentOfferMarketing: OfferMarketing[] = [];
  activeOfferMarketingIndex: number = 0; // Index of active OfferMarketing (es/en and BAT/TCOM). Expected value: 0, 1, 2, 3

  applyBat = false;
  applyTcom = false;

  offerCategories: string[] = ['Certified Used', 'Trade-In', 'Parts & Services', 'Safety', 'Rent a Car', 'Other'];

  constructor() {
    makeObservable(this, {
      offerMarketing: observable,
      currentOfferMarketing: observable,
      activeOfferMarketingIndex: observable,
      applyBat: observable,
      applyTcom: observable,
    });
  }

  getOfferMarketingById = (offerId: string) => {
    return this.offerMarketing[offerId];
  };

  setOfferMarketing = (offerId: string, offerMarketing: OfferMarketing[]) => {
    runInAction(() => {
      this.offerMarketing = { ...this.offerMarketing, [offerId]: offerMarketing };
    });
  };

  setCurrentOfferMarketing = (offerMarketing: OfferMarketing[]) => {
    runInAction(() => {
      this.currentOfferMarketing = offerMarketing;
    });
  };

  setApplyValues = (applyBat: boolean, applyTcom: boolean) => {
    runInAction(() => {
      this.applyBat = applyBat;
      this.applyTcom = applyTcom;
    });
  };

  setApply = (checkbox: MarketingSites) => {
    runInAction(() => {
      if (checkbox === MarketingSites.BAT) {
        this.applyBat = !this.applyBat;
      } else {
        this.applyTcom = !this.applyTcom;
      }
    });
  };

  getCurrentOfferMarketingIndex = (isBuyAToyota: boolean, language: string) => {
    return this.currentOfferMarketing.findIndex(offerMarketing => offerMarketing.isBuyAToyota === isBuyAToyota && language === offerMarketing.language);
  };

  setActiveOfferMarketingIndex = (isBuyAToyota: boolean, language: string) => {
    runInAction(() => {
      this.activeOfferMarketingIndex = this.getCurrentOfferMarketingIndex(isBuyAToyota, language);
    });
  };

  updateOfferMarketingItems = (field: string, data: OfferMarketingItem[] | any) => {
    const activeOfferMarketing = this.currentOfferMarketing[this.activeOfferMarketingIndex];
    this.currentOfferMarketing[this.activeOfferMarketingIndex] = { ...activeOfferMarketing, [field]: data };
  };
}

export default OfferMarketingStore;
