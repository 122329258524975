import { DnDType, uuidv4 } from 'oat-common-ui';
import { Offering, OfferMarketing, OfferVehicle, VehicleInput } from '../../../gql/generated';
import { TokenRibbon } from '../../TokenSettings/RibbonsComponent';

export const getRibbonName = (ribbonId: string, ribbons: TokenRibbon[]) => {
  const foundRibbon = ribbons.find(ribbon => ribbon.id === ribbonId);

  if (foundRibbon) {
    return { value: foundRibbon.id, label: foundRibbon.name };
  } else {
    return { value: '', label: '' };
  }
};

export const getRibbonId = (ribbonName: string, ribbons: TokenRibbon[]) => {
  const foundRibbon = ribbons.find(ribbon => ribbon.name === ribbonName);

  if (foundRibbon) {
    return foundRibbon.id;
  }
};

const useBatLink = (isLangCopy: boolean, batItem: any, batIndex: number, tcomItems: any) => {
  let link = '';
  if (isLangCopy || batItem.copyLinkToToyota || !tcomItems?.length) {
    link = batItem.link;
  } else if (tcomItems[batIndex] && !tcomItems[batIndex].isStandard) {
    link = tcomItems[batIndex].link;
  }

  return link;
};

export const copyDndItems = (type: DnDType, batItems: any[], tcomItems?: any[], isLangCopy = false) => {
  let copiedItems = [];

  // If TCOM has less items than BAT we need to:
  // Override existing TCOM items with corresponding BAT items text/links
  if (!tcomItems?.length || tcomItems.length <= batItems.length) {
    const tcomStandard = tcomItems?.find(item => item.isStandard);
    if (tcomStandard) {
      copiedItems.unshift(tcomStandard);
    }
    const tcomIdMe = tcomItems?.find(item => item.isIdMe);
    if (tcomIdMe) {
      copiedItems.push(tcomIdMe);
    }

    batItems.forEach((batItem, index) => {
      if (!batItem.isStandard && !batItem.isIdMe) {
        let link = useBatLink(isLangCopy, batItem, index, tcomItems);

        copiedItems.push({
          uid: uuidv4(),
          ...batItem,
          ...(type === DnDType.DISCLAIMERS && {
            file: batItem.tdaDisclaimerFileName,
          }),
          ...(type === DnDType.RESTRICTIONS && {
            file: batItem.tdaRestrictionFileName,
          }),
          ...(type === DnDType.BULLETS && { link }),
        });
      }
    });
  }

  // If TCOM has more items than BAT we need to:
  // 1. Override TCOM items corresponding with existing BAT items text/links
  // 2. Persist the additional TCOM items (the system should NOT delete the additional TCOM items)
  else {
    copiedItems = tcomItems.map((item, tcomIndex) => {
      const batItem = batItems[tcomIndex];
      if (batItem && !item.isStandard && !batItem.isStandard && !item.isIdMe && !batItem.isIdMe) {
        return {
          uid: batItem.uid || uuidv4(),
          ...batItem,
          ...(type === DnDType.DISCLAIMERS && {
            file: batItem.tdaDisclaimerFileName,
          }),
          ...(type === DnDType.RESTRICTIONS && {
            file: batItem.tdaRestrictionFileName,
          }),
          ...(type === DnDType.BULLETS && { link: isLangCopy || batItem.copyLinkToToyota ? batItem.link : item.link }),
        };
      } else {
        return {
          ...item,
        };
      }
    });
  }

  return copiedItems;
};

export const getCopyDataFromSource = (currentTemplateMarketing: OfferMarketing[] | undefined, offerId: string | undefined) => {
  let englishBatData = undefined;
  let spanishBatData = undefined;
  let englishTcomData = undefined;
  let spanishTcomData = undefined;

  if (currentTemplateMarketing && offerId) {
    currentTemplateMarketing.forEach(data => {
      if (data.language === 'en' && data.isBuyAToyota) {
        englishBatData = data;
      }

      if (data.language === 'es' && data.isBuyAToyota) {
        spanishBatData = data;
      }

      if (data.language === 'en' && !data.isBuyAToyota) {
        englishTcomData = data;
      }

      if (data.language === 'es' && !data.isBuyAToyota) {
        spanishTcomData = data;
      }
    });
  }

  return {
    englishBatData,
    spanishBatData,
    englishTcomData,
    spanishTcomData,
  };
};

export const createNewOfferPayload = (offeringId: string, offering: Offering, vehiclesToCompare: OfferVehicle[], applyBat: boolean, applyTcom: boolean) => {
  const vehicles: VehicleInput[] = [];
  vehiclesToCompare.forEach(veh => {
    vehicles.push({
      isAdvertised: veh.isAdvertised,
      modelCode: veh.modelCode,
      series: veh.series,
      seriesYear: veh.seriesYear,
      vehicleDescription: veh.vehicleDescription,
      isInclusion: true,
    });
  });

  return {
    offeringId,
    startDate: offering.startDate,
    endDate: offering.endDate,
    series: 'MULTI',
    seriesYear: 'MULTI',
    vehicles,
    isOtherOfferAppliedToBat: applyBat,
    isOtherOfferAppliedToTcom: applyTcom,
    isEndDateHidden: false,
    isFeatured: false,
    includedStates: [],
    excludedStates: [],
    includedPorts: [],
    excludedPorts: [],
    includedLdas: [],
    excludedLdas: [],
  };
};
