import { observer } from 'mobx-react-lite';
import { SettingsLayout, useToast } from 'oat-common-ui';
import { trackPromise } from 'react-promise-tracker';
import MainHeader from '../../components/MainHeader';
import { SettingsBreadCrumbs, SettingsNav } from '../../components/SettingsComponents';
import {
  Disclaimer,
  Restriction,
  Snippet,
  useCreateDisclaimerMutation,
  useCreateRestrictionMutation,
  useCreateSnippetMutation,
  useDeleteDisclaimerMutation,
  useDeleteRestrictionMutation,
  useDeleteSnippetMutation,
  useUpdateDisclaimerMutation,
  useUpdateRestrictionMutation,
  useUpdateSnippetMutation,
} from '../../gql/generated';
import useUrlParams from '../../hooks/useUrlParams';
import useStores from '../../stores/useStores';
import RibbonsComponent from './RibbonsComponent';
import { useLocation } from 'react-router-dom';
import TokenSettingsLayout from './TokenSettingsLayout';
import { AdminConstants } from 'oat-admin-common';

const _SNIPPETS_HEADER = 'Snippets';
const _SNIPPETS_MODAL_HEADER = 'Snippet';
const _DISCLAIMERS_HEADER = 'Disclaimers';
const _DISCLAIMERS_MODAL_HEADER = 'Disclaimer';
const _RESTRICTIONS_HEADER = 'Restrictions';
const _RESTRICTIONS_MODAL_HEADER = 'Restriction';
const _RIBBONS_HEADER = 'Ribbons';

const { LDA_MASTER_TDA_CODE } = AdminConstants;

const TokenSettingsComponent = () => {
  const {
    tokenStore,
    userInfoStore: {
      properties: { fullName },
      userInfo: { brand, tdaCodes },
      userInfo,
      hasUserAccessToAllAreas,
      isLexus,
    },
    tdaStore,
  } = useStores();

  const [createSnippet] = useCreateSnippetMutation();
  const [createRestriction] = useCreateRestrictionMutation();
  const [createDisclaimer] = useCreateDisclaimerMutation();
  const [deleteSnippet] = useDeleteSnippetMutation();
  const [deleteRestriction] = useDeleteRestrictionMutation();
  const [deleteDisclaimer] = useDeleteDisclaimerMutation();
  const [updateSnippet] = useUpdateSnippetMutation();
  const [updateRestriction] = useUpdateRestrictionMutation();
  const [updateDisclaimer] = useUpdateDisclaimerMutation();
  const { error } = useToast();

  const { tdaCode } = useUrlParams();
  const location = useLocation();
  const isFeatureMasterToken = location.pathname.includes('master');

  const groups: any = [
    {
      name: _SNIPPETS_HEADER,
      modalName: _SNIPPETS_MODAL_HEADER,
      tokenData: { data: tokenStore.snippets, dataTokens: [] },
    },
    {
      name: _DISCLAIMERS_HEADER,
      modalName: _DISCLAIMERS_MODAL_HEADER,
      tokenData: { data: tokenStore.disclaimers, dataTokens: tokenStore.snippets },
    },
    {
      name: _RESTRICTIONS_HEADER,
      modalName: _RESTRICTIONS_MODAL_HEADER,
      tokenData: { data: tokenStore.restrictions, dataTokens: tokenStore.snippets },
    },
  ];

  if (brand === 'Toyota') {
    groups.push({
      name: _RIBBONS_HEADER,
      component: <RibbonsComponent ribbons={tokenStore.ribbons} />,
    });
  }

  const addToken = async (group: string, name: string, text: string, setSelectedCb: (token: any) => void) => {
    if (group === _SNIPPETS_HEADER) {
      const res = await trackPromise(
        createSnippet({
          variables: {
            tdaCode: isFeatureMasterToken ? LDA_MASTER_TDA_CODE : tdaCode,
            name,
            text,
          },
        }),
      );

      const newSnippet = res.data?.createSnippet.snippet as Snippet;
      tokenStore.setSnippets([...tokenStore.snippets, newSnippet]);
      setSelectedCb(newSnippet);
    }

    if (group === _DISCLAIMERS_HEADER) {
      const res = await trackPromise(
        createDisclaimer({
          variables: {
            tdaCode: isFeatureMasterToken ? LDA_MASTER_TDA_CODE : tdaCode,
            name,
            text,
          },
        }),
      );

      const newDisclaimer = res.data?.createDisclaimer.disclaimer as Disclaimer;
      tokenStore.setDisclaimers([...tokenStore.disclaimers, newDisclaimer]);
      setSelectedCb(newDisclaimer);
    }

    if (group === _RESTRICTIONS_HEADER) {
      const res = await trackPromise(
        createRestriction({
          variables: {
            tdaCode: isFeatureMasterToken ? LDA_MASTER_TDA_CODE : tdaCode,
            name,
            text,
          },
        }),
      );

      const newRestriction = res.data?.createRestriction.restriction as Restriction;
      tokenStore.setRestrictions([...tokenStore.restrictions, newRestriction]);
      setSelectedCb(newRestriction);
    }
  };

  const deleteToken = async (group: string, id: string) => {
    try {
      if (group === _SNIPPETS_HEADER) {
        const snippItem = tokenStore.snippets.find(item => item.id === id);
        await trackPromise(deleteSnippet({ variables: { id, rev: snippItem?.rev || '' } }));
        tokenStore.setSnippets(tokenStore.snippets.filter(item => item.id !== id));
      }

      if (group === _DISCLAIMERS_HEADER) {
        const discItem = tokenStore.disclaimers.find(item => item.id === id);
        await trackPromise(deleteDisclaimer({ variables: { id, rev: discItem?.rev || '' } }));
        tokenStore.setDisclaimers(tokenStore.disclaimers.filter(item => item.id !== id));
      }

      if (group === _RESTRICTIONS_HEADER) {
        const restrictionItem = tokenStore.restrictions.find(item => item.id === id);
        await trackPromise(deleteRestriction({ variables: { id, rev: restrictionItem?.rev || '' } }));
        tokenStore.setRestrictions(tokenStore.restrictions.filter(item => item.id !== id));
      }
    } catch (e) {
      error((e as Error).message);
    }
  };

  const editToken = async (group: string, id: string, name: string, text: string) => {
    if (group === _SNIPPETS_HEADER) {
      const snippItem = tokenStore.snippets.find(item => item.id === id);
      const res = await trackPromise(updateSnippet({ variables: { id, rev: snippItem?.rev || '', name, text } }));
      tokenStore.setSnippets(tokenStore.snippets.map(item => (item.id === id ? { ...(res.data?.updateSnippet.snippet as Snippet) } : item)));
    }

    if (group === _DISCLAIMERS_HEADER) {
      const discItem = tokenStore.disclaimers.find(item => item.id === id);
      const res = await trackPromise(updateDisclaimer({ variables: { id, rev: discItem?.rev || '', name, text } }));
      tokenStore.setDisclaimers(tokenStore.disclaimers.map(item => (item.id === id ? { ...(res.data?.updateDisclaimer.disclaimer as Disclaimer) } : item)));
    }

    if (group === _RESTRICTIONS_HEADER) {
      const restrictionItem = tokenStore.restrictions.find(item => item.id === id);
      const res = await trackPromise(updateRestriction({ variables: { id, rev: restrictionItem?.rev || '', name, text } }));
      tokenStore.setRestrictions(tokenStore.restrictions.map(item => (item.id === id ? { ...(res.data?.updateRestriction.restriction as Restriction) } : item)));
    }
  };

  return (
    <>
      <MainHeader showMiscLinks={false} breadCrumbs={SettingsBreadCrumbs(fullName, tdaCode, isFeatureMasterToken ? 'Master Token' : 'Token', isLexus())} />
      <SettingsLayout settings={SettingsNav(tdaCode, isLexus(), tdaCodes, tdaStore.setCurrentTdaOption, true)}>
        <TokenSettingsLayout
          formDisabled={isLexus() && isFeatureMasterToken && !hasUserAccessToAllAreas(userInfo)}
          groups={groups}
          isVisibleSendToCta={isLexus() && isFeatureMasterToken}
          onCreate={addToken}
          onDelete={deleteToken}
          onEdit={editToken}
          tokens={tokenStore.allTokens}
        />
      </SettingsLayout>
    </>
  );
};

export default observer(TokenSettingsComponent);
