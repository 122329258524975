import { Button, DragDrop, Draggable, DraggableProvided, DraggableStateSnapshot, DropResult, OATIcon, uuidv4 } from 'oat-common-ui';
import React from 'react';
import { TokenRibbon } from '.';
import useUrlParams from '../../../hooks/useUrlParams';
import Language from './Language';
import RibbonInput from './RibbonInput';
import styles from './styles.module.scss';

interface Props {
  ribbons: TokenRibbon[];
  title: string;
  droppableId: string;
  handleOnUpdate: (ribbons: TokenRibbon[]) => void;
}

const RibbonList = ({ ribbons, title, droppableId, handleOnUpdate }: Props) => {
  const { tdaCode } = useUrlParams();

  const addRibbon = () => {
    handleOnUpdate([
      ...ribbons,
      {
        name: '',
        language: title === 'English' ? Language.ENGLISH : Language.SPANISH,
        uid: uuidv4(),
        tdaCode,
        id: '',
        rev: '',
        created: '',
        createdBy: '',
        updated: '',
        updatedBy: '',
        sortOrder: ribbons.length || 0,
      },
    ]);
  };

  const deleteRibbon = (uid: string) => {
    handleOnUpdate(ribbons.filter(ribbon => ribbon.uid !== uid));
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const ribbonsCopy = [...ribbons];

    const [itemToMove] = ribbonsCopy.splice(result.source.index, 1);
    ribbonsCopy.splice(result.destination.index, 0, itemToMove);
    handleOnUpdate(ribbonsCopy.map((item, i) => ({ ...item, sortOrder: i })));
  };

  const setRibbonsCallback = (uid: string, text: string) => {
    const ribbonToUpdate = ribbons.find(ribbon => ribbon.uid === uid);
    if (ribbonToUpdate) {
      ribbonToUpdate.name = text;
    }

    handleOnUpdate(ribbons);
  };

  const handleDisableCheckmark = (uid: string, name: string) => {
    return !!ribbons.find(ribbon => ribbon.name.toLowerCase().trim() === name.toLowerCase().trim() && ribbon.uid !== uid);
  };

  return (
    <div className={styles.ribbonContainer}>
      <div className={styles.listTitle}>{title}</div>
      <DragDrop droppableId={droppableId} handleOnDragEnd={handleOnDragEnd}>
        {ribbons.map((item, i) => {
          return (
            <Draggable key={item.uid} draggableId={item.uid || ''} index={i}>
              {(draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
                return (
                  <RibbonInput
                    innerRef={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    key={item.uid}
                    id={item.uid || ''}
                    ribbonValue={item.name}
                    deleteRibbon={deleteRibbon}
                    setRibbonsCallback={setRibbonsCallback}
                    handleDisableCheckmark={handleDisableCheckmark}
                    isDragging={snapshot.isDragging}
                  />
                );
              }}
            </Draggable>
          );
        })}
      </DragDrop>
      <Button onClick={() => addRibbon()} className={styles.addBtn}>
        <OATIcon icon="add" size={14} />
        <span>Add Ribbon</span>
      </Button>
    </div>
  );
};

export default RibbonList;
