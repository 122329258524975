import { AdminConstants } from 'oat-admin-common';
import { formatDecimal, formatDollars, OATIcon, OfferTypes } from 'oat-common-ui';
import { Offer, OfferMarketing } from '../../../gql/generated';
import getAdvertisedRate from '../../../utils/getAdvertisedRate';
import styles from './styles.module.scss';

const { OPTION_TYPE_NAMES } = AdminConstants;

const renderCash = (offer: Offer) => {
  const cashAmount = offer.combinedPerUnitCost;
  const cashDisplay = cashAmount !== null && cashAmount !== undefined ? `$${formatDollars(cashAmount.toString())} per customer` : '';
  return (
    <>
      {cashDisplay}
      {offerDetailsArrows(offer)}
    </>
  );
};

const renderMisc = (offer: Offer) => {
  const cashAmount = offer.combinedPerUnitCost;
  const cashDisplay = cashAmount !== null && cashAmount !== undefined ? `$${formatDollars(cashAmount.toString())} ` : '';
  const months = offer.optionType === OPTION_TYPE_NAMES.TFS_LEASE_SUBVENTION_CASH ? `; ${offer.subCashTerm} mo` : '';

  return (
    <>
      {`${cashDisplay} ${offer.optionType}${months}`}
      {offerDetailsArrows(offer)}
    </>
  );
};

const renderAPR = (offer: Offer) => {
  const vals = [];
  const rateObj = getAdvertisedRate(offer);
  if (rateObj) {
    if (rateObj.subventionRate) {
      vals.push(formatDecimal(rateObj.subventionRate.toString()));
    }
    vals.push(rateObj.term.toString());
    if (rateObj.subventionCash) {
      vals.push(`($${formatDollars(rateObj.subventionCash.toString())})`);
    }
  }

  return (
    <>
      {vals.join(' | ')}
      {offerDetailsArrows(offer)}
    </>
  );
};

const renderLease = (offer: Offer) => {
  const vals = [];
  const rateObj = offer.rates && offer.rates.length ? offer.rates[0] : undefined;
  const leaseDetails = offer.leaseDetails && offer.leaseDetails.length ? offer.leaseDetails[0] : undefined;

  if (leaseDetails) {
    vals.push(`$${formatDollars(leaseDetails.targetPayment.toString())}`);
  }

  if (rateObj) {
    vals.push(rateObj.term);
  }

  if (leaseDetails) {
    vals.push(`$${formatDollars(leaseDetails.dueAtSigning.toString())}`);
  }

  if (offer.subventionCash) {
    vals.push(`($${formatDollars(offer.subventionCash.toString())})`);
  }

  if (leaseDetails) {
    vals.push(`(${leaseDetails.modelCode})`);
  }

  return (
    <>
      {vals.join(' | ')}
      {offerDetailsArrows(offer)}
    </>
  );
};

const renderOther = (offer: Offer, isLexus: boolean, offerMarketing?: OfferMarketing[]) => {
  let offerDetailName: string | undefined | null = '';

  // sort priority for TDA
  // 1. BAT en offer card title
  // 2. TCOM en offer card title
  offerDetailName = offerMarketing
    ?.filter(marketing => (isLexus && marketing.offerCardTitle) || (!isLexus && marketing.offerCardTitle && marketing.language === 'en'))
    .sort((a, b) => Number(b.isBuyAToyota) - Number(a.isBuyAToyota))[0]?.offerCardTitle;

  if (!offerDetailName) {
    offerDetailName = offer.optionType === `${OPTION_TYPE_NAMES.DEFERRED_PAYMENT}` ? `${OPTION_TYPE_NAMES.DEFERRED_PAYMENT}` : OfferTypes.OTHER;
  }

  return <>{offerDetailName}</>;
};

const offerDetailsArrows = (offer: Offer) => {
  if (offer.betterThanPrior) {
    return <OATIcon className={styles.arrowIcon} colorTheme="green" icon="angle-up" strokeWidth="200px" />;
  } else if (offer.worseThanPrior) {
    return <OATIcon className={styles.arrowIcon} colorTheme="red" icon="angle-down" strokeWidth="200px" />;
  }
  return null;
};

const renderOfferDetails = (offer: Offer, offerMarketing?: OfferMarketing[]) => {
  switch (offer.offerType) {
    case OfferTypes.CUSTOMER_CASH: {
      return renderCash(offer);
    }
    case OfferTypes.APR: {
      return renderAPR(offer);
    }
    case OfferTypes.LEASE: {
      return renderLease(offer);
    }
    case OfferTypes.MISCELLANEOUS: {
      return renderMisc(offer);
    }
    case OfferTypes.OTHER: {
      return renderOther(offer, false, offerMarketing);
    }
    default: {
      return null;
    }
  }
};

export default renderOfferDetails;
