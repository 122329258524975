import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DroppableProvided,
  DropResult,
  FieldTitle,
  IOfferStatusError,
  OfferStatus,
  Table,
  TableContainer,
} from 'oat-common-ui';
import { ReactNode, useState } from 'react';
import { Offer } from '../../../gql/generated';
import headerColumns from './headerColumns';
import OfferingTableRowBase from './OfferingTableRowBase';
import styles from './styles.module.scss';

interface ColumnProps {
  descendingFirst?: boolean;
  field?: string;
  header: string;
  onClick?: () => void;
  sortable?: boolean;
  width?: number;
}

interface Props {
  canPreview: boolean;
  defaultSortAscending?: boolean;
  defaultSortField?: string;
  id: string;
  isDragDisabled: boolean;
  maxSortOrder: number;
  offerMarketFlagMap: Map<string, IOfferStatusError>;
  offers: Offer[];
  onChangeOfferingStatus: (offer: Offer, status: OfferStatus) => void;
  onColumnSort: (header: string, asc: boolean) => void;
  onCopy: (offer: Offer) => void;
  onDelete: (offer: Offer) => void;
  onSort: (currIndex: number, newIndex: number) => void;
  renderOfferLink: (offer: Offer) => ReactNode;
}

const OfferingTable = ({
  canPreview,
  defaultSortAscending = false,
  defaultSortField = '',
  id,
  isDragDisabled,
  maxSortOrder,
  offerMarketFlagMap,
  offers,
  onChangeOfferingStatus,
  onColumnSort,
  onCopy,
  onDelete,
  onSort,
  renderOfferLink,
}: Props) => {
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortAscending, setSortAscending] = useState(defaultSortAscending);

  const isDndDisabled = sortField !== '' || isDragDisabled;

  const handleSortOffers = (currIndex: number, newIndex: number) => {
    onSort(currIndex, newIndex);
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    onSort(result.source.index + 1, result.destination.index + 1);
  };

  const handleOnClick = (headerField: string, descendingFirst?: boolean) => {
    const sortAsc = sortField !== headerField ? !descendingFirst : !sortAscending;
    setSortField(headerField);
    setSortAscending(sortAsc);
    onColumnSort(headerField, sortAsc);
  };

  const HeaderRow = () => {
    return (
      <thead>
        <tr>
          {headerColumns.map((col: ColumnProps) => {
            const style = col.width ? { width: col.width } : undefined;
            const key = `${id}-${col.header}-hdr-btn`;

            return (
              <th key={key} style={style} className={sortField === col.field ? styles.sortedField : undefined}>
                {col.sortable ? (
                  <FieldTitle
                    id={key}
                    field={col.field}
                    header={col.header}
                    sortField={sortField}
                    sortAscending={sortAscending}
                    descendingFirst={col.descendingFirst}
                    handleOnClick={handleOnClick}
                  />
                ) : (
                  <>{col.header}</>
                )}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  const BodyRows = () => {
    return (
      <>
        {offers.map((item, i) =>
          !isDndDisabled ? (
            <Draggable key={item.id} draggableId={item.id || ''} index={i}>
              {(draggableProvided: DraggableProvided) => {
                return (
                  <OfferingTableRowBase
                    innerRef={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                    key={item.id}
                    sortOrderVal={item.sortOrder}
                    offer={item}
                    renderOfferLink={renderOfferLink}
                    handleSortOffers={handleSortOffers}
                    maxSortOrder={maxSortOrder}
                    onCopy={onCopy}
                    onDelete={onDelete}
                    onChangeOfferingStatus={onChangeOfferingStatus}
                    offerMarketFlagMap={offerMarketFlagMap}
                    canPreview={canPreview}
                  />
                );
              }}
            </Draggable>
          ) : (
            <OfferingTableRowBase
              key={item.id}
              sortOrderVal={item.sortOrder}
              offer={item}
              renderOfferLink={renderOfferLink}
              handleSortOffers={handleSortOffers}
              disabled={isDndDisabled}
              maxSortOrder={maxSortOrder}
              onCopy={onCopy}
              onDelete={onDelete}
              onChangeOfferingStatus={onChangeOfferingStatus}
              offerMarketFlagMap={offerMarketFlagMap}
              canPreview={canPreview}
            />
          ),
        )}
      </>
    );
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <TableContainer>
        <Table>
          <HeaderRow />
          <Droppable droppableId="tda-lda-table">
            {(droppableProvided: DroppableProvided) => (
              <tbody ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                <BodyRows />
                {droppableProvided.placeholder}
              </tbody>
            )}
          </Droppable>
        </Table>
      </TableContainer>
    </DragDropContext>
  );
};

export default OfferingTable;
