import { observer } from 'mobx-react-lite';
import { AdminConstants } from 'oat-admin-common';
import { BlockInputLabel, BoxSelector, Button, Dropdown, DropdownItem, InlineInputLabel, Input, useInput } from 'oat-common-ui';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import MainFooter from '../../../components/MainFooter';
import { FINANCE_OFFER_TYPE, HELP_URL, MarketingSites } from '../../../constants/global';
import { Snippet } from '../../../gql/generated';
import useUrlParams from '../../../hooks/useUrlParams';
import useStores from '../../../stores/useStores';
import { TokenRibbon } from '../../TokenSettings/RibbonsComponent';
import OfferTemplateNav from '../OfferTemplateNav';
import { OfferTemplateNavList } from '../OfferTemplateNav/OfferTemplateNavList';
import OfferTemplateTitle from '../OfferTemplateTitle';
import OfferTemplateWrapper from '../OfferTemplateWrapper';
import { aprTermsList, leaseTermsList, tiersList } from '../defaultLists';
import styles from '../styles.module.scss';
import OfferDetailsForm from './OfferDetailsForm';
import { formatTemplateMarketingData, getCopyDataFromSource, processTiersTerms } from './util';

const { OfferTypes, LDA_MASTER_TDA_CODE } = AdminConstants;
const offerBadgeImagesNav = 'Offer Badge Images';

interface Props {
  snippets: Snippet[];
  ribbons: TokenRibbon[];
  onSubmit: (
    discard: boolean,
    templatePayload: {
      name: string;
      status: string;
      offerType: string;
      tdaCode: string;
      subventionCashIncluded: boolean;
      tiers?: string;
      terms?: string;
      rev?: string;
      id?: string;
    },
    templateMarketingPayload: {
      marketingDetails: any;
      offerTemplateId?: string;
      rev?: string;
    },
  ) => void;
}

const OfferTemplateForm = ({ snippets, ribbons, onSubmit }: Props) => {
  const {
    userInfoStore: { isLexus },
    tokenStore: { disclaimers, restrictions, getTokensByOfferType },
    offerTemplatesStore: { currentTemplateMarketing, currentOfferTemplate, setActiveTemplateMarketingIndex, setCurrentTemplateMarketing, activeTemplateMarketingIndex },
  } = useStores();
  const { tdaCode, copy } = useUrlParams();
  const location = useLocation();
  const isMaster = location.pathname.includes('master');

  // Offer Types, Tiers, Terms values
  const [templateName, , templateNameError, setTemplateName] = useInput(!copy ? currentOfferTemplate?.name : `${currentOfferTemplate?.name} Copy` || '', { required: true });
  const [offerType, setOfferType] = useState<DropdownItem>(
    { value: currentOfferTemplate?.offerType || '', label: currentOfferTemplate?.offerType || '' } || { value: '', label: '' },
  );
  const termsList = offerType.value.includes(OfferTypes.APR) ? aprTermsList : leaseTermsList;
  const [selectedTerms, setSelectedTerms] = useState<string[]>(currentOfferTemplate?.terms?.length ? currentOfferTemplate?.terms?.split(',') : []);
  const [selectedTiers, setSelectedTiers] = useState<string[]>(currentOfferTemplate?.tiers?.length ? currentOfferTemplate?.tiers?.split(',') : []);
  const [isSubventionCashIncluded, setIsSubventionCashIncluded] = useState(currentOfferTemplate?.subventionCashIncluded || false);

  // Offer details values
  const [showSpanish, setShowSpanish] = useState(false);
  const [siteSelected, setSiteSelected] = useState(MarketingSites.BAT.toString());

  const handleSubmit = (discard: boolean, saveAndClose: boolean, finish: boolean) => {
    const revToSubmit = copy ? '' : currentOfferTemplate?.rev || '';
    const idToSubmit = copy ? '' : currentOfferTemplate?.id || '';

    const templateStatus = saveAndClose && !finish ? 'Draft' : 'Unpublished';
    const templatePayload = {
      name: templateName,
      status: templateStatus,
      offerType: offerType.value,
      tdaCode: isMaster ? LDA_MASTER_TDA_CODE : tdaCode,
      subventionCashIncluded: isSubventionCashIncluded,
      tiers: processTiersTerms(selectedTiers),
      terms: processTiersTerms(selectedTerms),
      rev: revToSubmit,
      id: idToSubmit,
    };
    const templateMarketingPayload = {
      marketingDetails: formatTemplateMarketingData(offerType.value, currentTemplateMarketing, isLexus(), !!copy),
      templateId: idToSubmit,
    };

    onSubmit(discard, templatePayload, templateMarketingPayload);
  };

  const handleActiveTemplateMarketingIndex = (val: string) => {
    setSiteSelected(val);
    setActiveTemplateMarketingIndex(val === MarketingSites.BAT, showSpanish ? 'es' : 'en');
  };

  const footerRightContent = (
    <div className={styles.footerRightContent}>
      <Button variant="text" onClick={() => handleSubmit(true, false, false)}>
        Discard Changes
      </Button>
      <Button variant="primary" onClick={() => handleSubmit(false, true, false)}>
        Save & Close
      </Button>
      <Button variant="primary" onClick={() => handleSubmit(false, false, true)}>
        Finish
      </Button>
    </div>
  );

  const { englishBatData, spanishBatData, englishTcomData, spanishTcomData } = getCopyDataFromSource(currentTemplateMarketing, isLexus());

  const marketingDataProps = {
    showSpanish,
    setShowSpanish,
    offerType: offerType.value === OfferTypes.APR && isLexus() ? FINANCE_OFFER_TYPE : offerType.value,
    snippets,
    tokens: getTokensByOfferType(offerType.value === OfferTypes.CUSTOMER_CASH ? 'Cash' : offerType.value),
    ribbons,
    disclaimers,
    restrictions,
  };

  const handleUpdateTemplateMarketing = (val: string) => {
    let templateMarketingCopy = [...currentTemplateMarketing];

    if (val === OfferTypes.APR || val === OfferTypes.LEASE || val === OfferTypes.CUSTOMER_CASH) {
      templateMarketingCopy = templateMarketingCopy.map(item => ({ ...item, primaryOfferCardLabel: item.primaryOfferCardLabel }));
    } else {
      templateMarketingCopy = templateMarketingCopy.map(item => {
        const processedLabel =
          item.primaryOfferCardLabel === OfferTypes.CUSTOMER_CASH || item.primaryOfferCardLabel === OfferTypes.APR || item.primaryOfferCardLabel === OfferTypes.LEASE
            ? ''
            : item.primaryOfferCardLabel;

        return {
          ...item,
          primaryOfferCardLabel: processedLabel,
        };
      });
    }

    setCurrentTemplateMarketing(templateMarketingCopy);
  };

  const displayDetailsForm = (site: string, isSpanish: boolean) => {
    return {
      display: site === siteSelected && isSpanish === showSpanish ? 'block' : 'none',
    };
  };

  const returnTemplateIdString = (section: string) => {
    const currentMarketingTab = currentTemplateMarketing[activeTemplateMarketingIndex];
    const currentMarketingParams = !isLexus()
      ? `${currentMarketingTab.isBuyAToyota ? MarketingSites.BAT : MarketingSites.TCOM_OTHER}-${showSpanish ? 'es' : 'en'}`
      : `${MarketingSites.BAT}-en`;

    return `${currentMarketingParams}-${section}`;
  };

  return (
    <div className={styles.offerTemplateForm}>
      <OfferTemplateNav
        navList={!isLexus() ? OfferTemplateNavList : OfferTemplateNavList.filter(nav => nav !== offerBadgeImagesNav)}
        returnTemplateIdString={returnTemplateIdString}
        addIndex
        main
        scrollTo
      />

      <div className={styles.whiteSmoke}>
        <OfferTemplateWrapper>
          {/* Offer Types, Tiers, Terms */}
          <div className={styles.offerTTT}>
            <OfferTemplateTitle sectionTitle={`Offer Types, Tiers, and Terms ${!isLexus() ? '(BAT and TCOM/Other Sites)' : ''}`} />
            <div className={styles.formInputs}>
              <BlockInputLabel label="Template Name" isRequired>
                <Input value={templateName} error={templateNameError} onChange={e => setTemplateName(e.target.value)} />
              </BlockInputLabel>
              <BlockInputLabel label="Offer Type" isRequired>
                <Dropdown
                  options={AdminConstants.TEMPLATE_OFFERTYPES}
                  id="Offer Type Dropdown"
                  value={offerType}
                  onChange={(option: DropdownItem) => {
                    setOfferType(option);
                    handleUpdateTemplateMarketing(option.value);
                  }}
                />
              </BlockInputLabel>
              {(offerType.value === OfferTypes.APR || offerType.value === OfferTypes.LEASE) && (
                <>
                  <BlockInputLabel label="Subvention Cash Included?" isRequired>
                    <InlineInputLabel
                      vertical
                      labelComponent={<input type="radio" onClick={() => setIsSubventionCashIncluded(true)} checked={isSubventionCashIncluded} />}
                      className={styles.subCashLabel}
                      contentClass={styles.radioButtonText}
                    >
                      Yes
                    </InlineInputLabel>
                    <InlineInputLabel
                      vertical
                      labelComponent={<input type="radio" onClick={() => setIsSubventionCashIncluded(false)} checked={!isSubventionCashIncluded} />}
                      className={styles.subCashLabel}
                      contentClass={styles.radioButtonText}
                    >
                      No
                    </InlineInputLabel>
                  </BlockInputLabel>
                  <BlockInputLabel label="Tiers" isRequired>
                    <BoxSelector id="Tiers Selector" options={tiersList} selected={selectedTiers} setSelected={setSelectedTiers} />
                  </BlockInputLabel>
                  <BlockInputLabel label="Terms" isRequired>
                    <BoxSelector id="Terms Selector" options={termsList} selected={selectedTerms} setSelected={setSelectedTerms} />
                  </BlockInputLabel>
                </>
              )}
            </div>
          </div>
          <div className={styles.dividerLine} />

          {!isLexus() && (
            <OfferTemplateNav
              navList={[MarketingSites.BAT, MarketingSites.TCOM_OTHER]}
              className={styles.batTcomTabNav}
              setValue={handleActiveTemplateMarketingIndex}
              returnTemplateIdString={returnTemplateIdString}
              addActiveBg
            />
          )}

          {isLexus() && (
            <OfferDetailsForm {...marketingDataProps} marketingData={englishTcomData} siteSelected={siteSelected as MarketingSites} lang="en" style={{ display: 'block' }} />
          )}

          {/* BAT English */}
          {!isLexus() && (
            <>
              <OfferDetailsForm
                {...marketingDataProps}
                marketingData={englishBatData}
                siteSelected={siteSelected as MarketingSites}
                lang="en"
                isBAT
                style={displayDetailsForm(MarketingSites.BAT, false)}
              />

              {/* BAT Spanish */}
              <OfferDetailsForm
                {...marketingDataProps}
                marketingData={spanishBatData}
                siteSelected={siteSelected as MarketingSites}
                lang="es"
                copyEngData={englishBatData}
                isBAT
                style={displayDetailsForm(MarketingSites.BAT, true)}
              />

              {/* TCOM English */}
              <OfferDetailsForm
                {...marketingDataProps}
                marketingData={englishTcomData}
                siteSelected={siteSelected as MarketingSites}
                lang="en"
                copyEngBatData={englishBatData}
                style={displayDetailsForm(MarketingSites.TCOM_OTHER, false)}
              />

              {/* TCOM Spanish */}
              <OfferDetailsForm
                {...marketingDataProps}
                marketingData={spanishTcomData}
                siteSelected={siteSelected as MarketingSites}
                lang="es"
                copyEngData={englishTcomData}
                copyEspBatData={spanishBatData}
                style={displayDetailsForm(MarketingSites.TCOM_OTHER, true)}
              />
            </>
          )}
        </OfferTemplateWrapper>
      </div>
      <MainFooter darkTheme helpUrl={HELP_URL} rightContent={footerRightContent} />
    </div>
  );
};

export default observer(OfferTemplateForm);
