import { AdminConstants } from 'oat-admin-common';
import { SettingsBreadCrumbs } from '../../components/SettingsComponents';
import { Disclaimer, Restriction, Snippet, TokensMap, useGetTokensSnippetsDisclaimersRestrictionsQuery, useRibbonsByTdaQuery } from '../../gql/generated';
import useUrlParams from '../../hooks/useUrlParams';
import useStores from '../../stores/useStores';
import Error from '../Error';
import Loading from '../Loading';
import { TokenRibbon } from './RibbonsComponent';
import TokenSettingsComponent from './TokenSettingsComponent';
import { useLocation } from 'react-router-dom';
import { FEATURE_OR_4159 } from '../../constants/global';

const { LDA_MASTER_TDA_CODE } = AdminConstants;

const TokenSettings = () => {
  const {
    userInfoStore: {
      properties: { fullName },
      isLexus,
    },
    tokenStore,
  } = useStores();
  const { tdaCode } = useUrlParams();
  const location = useLocation();
  const isFeatureMasterToken = location.pathname.includes('master') && FEATURE_OR_4159;

  const { data, loading, error } = useGetTokensSnippetsDisclaimersRestrictionsQuery({
    variables: { tdaCode: isFeatureMasterToken ? LDA_MASTER_TDA_CODE : tdaCode },
    fetchPolicy: 'no-cache',
  });

  const {
    data: ribbonsData,
    loading: ribbonsLoading,
    error: ribbonsError,
  } = useRibbonsByTdaQuery({
    variables: { tdaCode },
    fetchPolicy: 'no-cache',
    skip: isLexus(),
  });

  if (loading || ribbonsLoading) {
    return <Loading breadCrumbs={SettingsBreadCrumbs(fullName, tdaCode)} />;
  }

  if (error || ribbonsError) {
    console.log(error);
    return <Error breadCrumbs={SettingsBreadCrumbs(fullName, tdaCode)} />;
  }

  if (data && (isLexus() || ribbonsData)) {
    tokenStore.setTokenItems(
      data.tokens as TokensMap,
      data.snippets as Snippet[],
      data.disclaimers as Disclaimer[],
      data.restrictions as Restriction[],
      ribbonsData?.ribbonsByTda as TokenRibbon[],
    );
  }

  return <TokenSettingsComponent />;
};

export default TokenSettings;
