import { IMultiItem } from 'oat-common-ui';
import { LdaCode } from '../constants/global';
import centralLDAjson from '../json/centralLDA.json';
import easternLDAjson from '../json/easternLDA.json';
import ldasJSON from '../json/ldas.json';
import southernLDAjson from '../json/southernLDA.json';
import westernLDAjson from '../json/westernLDA.json';
import { processLdaOptions } from '../pages/OfferTabs/InfoTab/utils';

export type LdaByCode = {
  name: string;
  code: string;
  regionCode: number;
  agency: string | null;
  previewLocation: string | null;
};

class LdaStore {
  isLoaded = false;
  ldas = ldasJSON.ldas;
  ldaOptions: IMultiItem[] = processLdaOptions(ldasJSON.ldas);
  includedLdas: string[] = [];
  ldasByCode: LdaByCode[] = [];

  setLdaOptions = (tdaCode: string) => {
    switch (tdaCode) {
      case LdaCode.LWA:
        this.ldaOptions = processLdaOptions(westernLDAjson.westernLDA);
        this.ldasByCode = westernLDAjson.westernLDA;
        break;
      case LdaCode.LEA:
        this.ldaOptions = processLdaOptions(easternLDAjson.easternLDA);
        this.ldasByCode = easternLDAjson.easternLDA;
        break;
      case LdaCode.LSA:
        this.ldaOptions = processLdaOptions(southernLDAjson.southernLDA);
        this.ldasByCode = southernLDAjson.southernLDA;
        break;
      case LdaCode.LCA:
        this.ldaOptions = processLdaOptions(centralLDAjson.centralLDA);
        this.ldasByCode = centralLDAjson.centralLDA;
        break;
      default:
        break;
    }
  };

  setIncludedLdas = (ldaStrings: string[]) => {
    ldaStrings.forEach(ldaString => {
      let stringToCompare = ldaString;

      if (ldaString.split('-').length > 1) {
        stringToCompare = ldaString.split('-')[1];
      }

      this.ldas.forEach(ldaItem => {
        if (ldaItem.code === stringToCompare) {
          this.includedLdas.push(`${ldaItem.name}-${ldaItem.code}`);
        }
      });
    });
  };
}

export default LdaStore;
