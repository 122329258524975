import { runInAction } from 'mobx';
import TdaOption from '../models/TdaType';

class TdaStore {
  isLoaded = false;
  tdaOptions: TdaOption[] = [];
  currentTda: TdaOption | undefined = undefined;

  setTdaOptions = (tdaOptions: TdaOption[], userInfoTdaCodes: string) => {
    runInAction(() => {
      const tdaItems: TdaOption[] = [];
      userInfoTdaCodes.split(',').forEach((tdaCode, index) => {
        const found = tdaOptions.find(item => item.code === tdaCode);
        if (found) {
          tdaItems.push(found);

          // take index 1 - from legacy app
          if (index === 1) {
            this.currentTda = found;
          }
        }
      });

      // fallback when currentTda is not set
      if (!this.currentTda && tdaItems.length) {
        this.currentTda = tdaItems[0];
      }

      this.tdaOptions = tdaItems;
      this.isLoaded = true;
    });
  };

  setCurrentTdaOption = (tdaOption: TdaOption | undefined) => {
    this.currentTda = tdaOption;
  };

  setCurrentTdaOptionByCode = (tdaCode: string | undefined) => {
    if (tdaCode) {
      this.currentTda = this.tdaOptions.find(item => item.code === tdaCode);
    }
  };
}

export default TdaStore;
