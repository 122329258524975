import { getRemainingStates } from 'oat-admin-common';
import { OATIcon, Tooltip } from 'oat-common-ui';
import { Offer, Offering } from '../../../gql/generated';
import styles from './styles.module.scss';

const renderNotes = (currentOffering: Offering, offer: Offer) => {
  const modifiedDates = currentOffering.startDate !== offer.startDate || currentOffering.endDate !== offer.endDate ? `${offer.startDate} - ${offer.endDate}` : undefined;

  const excludedStates = getRemainingStates(currentOffering.regionCode, offer.includedStates?.join(',').split(',') ?? []);
  const notesArray = [`States: ${!excludedStates.length ? 'ALL' : offer.includedStates?.sort().join(',')}`];
  // Special Edition
  if (offer.isSpecialEdition) {
    notesArray.push('Special Edition');
  }
  // Modified Dates
  if (modifiedDates) {
    notesArray.push(modifiedDates);
  }
  // Notes
  if (offer.notes) {
    notesArray.push(offer.notes);
  }

  return (
    <div>
      {notesArray.map(note => (
        <div>{note}</div>
      ))}
      {offer.tdaNotes && (
        <div>
          <Tooltip id={`infoIcon-${offer.id}`} message={offer.tdaNotes} clickable>
            <OATIcon className={styles.infoIcon} icon="info" size={14} />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default renderNotes;
